import axios from "axios";
import { setIsAuthenticated } from "../store/authSlice";
const getToken = () => {
  return localStorage.getItem("token");
};

export const HttpClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "x-auth-token": getToken() ? getToken() : "",
    "verify-affluence-hash": process.env.REACT_APP_TRANSACTION_HASH || "",
  },
});

export const setupInterceptors = (navigate: any, dispatch: any) => {
  HttpClient.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response) {
        switch (error.response.status) {
          case 401:
            dispatch(setIsAuthenticated(false));
            localStorage.removeItem("token");
            navigate("/login");
            break;
          case 403:
            dispatch(setIsAuthenticated(false));
            localStorage.removeItem("token");
            navigate("/login");
            break;
          default:
            break;
        }
      }
      return Promise.reject(error);
    }
  );
};
