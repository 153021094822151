import PageTitle from "../components/Pagetitle";
import Img from "../assets/img/Mask group.png";
import { AiOutlineBank } from "react-icons/ai";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { PiHandWithdrawLight } from "react-icons/pi";
import { LiaPercentageSolid } from "react-icons/lia";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { HttpClient } from "../api/HttpClient";
import { isAxiosError } from "axios";
import ToastComponent from "../components/ToastComponent";
import { ScaleLoader } from "react-spinners";
import { RiMenu2Fill } from "react-icons/ri";
import ProfilePicture from "../components/ProfilePicture";
import { formatCurrency } from "../components/CurrencyFormat";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { Account, VirtualAccount } from "../assets/Typeprops";
import { TbCopy, TbCopyCheckFilled } from "react-icons/tb";
import CopyToClipboard from "react-copy-to-clipboard";
type SavingsParam = {
  tab?: string;
};

interface AuthenticatedLayoutContext {
  toggleNav: () => void;
}
const Savings = () => {
  const { tab } = useParams<SavingsParam>();
  const [savingsTab, setSavingsTab] = useState(tab || "");
  const [isLoading, setIsLoading] = useState(false);
  const [copied, setCopied] = useState<boolean>(false);
  const [fetchVirtualAccount, setFetchVirtualAccount] =
    useState<VirtualAccount | null>(null);
  useEffect(() => {
    if (tab && tab === savingsTab) {
      setSavingsTab(tab);
    }
  }, [tab, savingsTab]);
  const navigate = useNavigate();
  const handleTabChange = (tabName: string) => {
    setSavingsTab(tabName);
    navigate(`/wallets/${tabName}`);
  };
  const [data, setData] = useState<Account | null>(null);
  const fetchUserData = useCallback(async () => {
    setIsLoading(true);
    try {
      const [userDataRes, virtualAccountRes] = await Promise.all([
        HttpClient.get("/users/me", {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }),

        HttpClient.get("/payments/providus/reserved-account", {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }).catch((error) => {
          if (isAxiosError(error) && error.response?.status === 400) {
            ToastComponent.error(
              "Please click the create virtual account button at the top of this page!"
            );
          }
          return null;
        }),
      ]);
      setData(userDataRes?.data?.data);
      if (virtualAccountRes?.data && virtualAccountRes.data.data) {
        setFetchVirtualAccount(virtualAccountRes.data.data);
      }
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      }
    } finally {
      setIsLoading(false);
    }
  }, []);
  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);
  const { toggleNav } = useOutletContext<AuthenticatedLayoutContext>();
  const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
  const handleShowProfile = () => {
    navigate("/my_account", {
      state: { openShowProfileModal: true },
    });
  };
  const handleSetVirtualAccount = () => {
    navigate("/wallets/balance", {
      state: { openSetVirtualAccountModal: true },
    });
  };
  return (
    <>
      <PageTitle title="Affluence - Savings" />
      {isLoading ? (
        <div className="w-full h-screen bg-white dark:bg-darkPrimary flex items-center justify-center">
          <ScaleLoader color={isDarkMode ? "#FFFFFF" : "#6B006B"} />
        </div>
      ) : (
        <div className="text-textcolor bg-white dark:bg-darkPrimary dark:text-white pb-[70px] pt-[5rem]">
          <button onClick={toggleNav} className="md:hidden block px-[3%]">
            <RiMenu2Fill size={30} className="text-primary dark:text-white" />
          </button>
          <div className="pb-7 px-[3%] flex justify-between">
            <div className="md:text-[24px] sm:text-[22px] text-[20px]">
              <h1 className=" font-PoppinsBold">My Wallets</h1>
              {!data?.virtualAccountStatus && (
                <button
                  onClick={handleSetVirtualAccount}
                  className="border-[2px] border-primary mt-4 dark:border-secondary bg-opacity-20 sm:p-3 p-[6px] flex justify-between sm:gap-7 gap-4 group hover:bg-primary dark:hover:bg-secondary rounded-[8px] items-center"
                >
                  <h1 className="dark:text-secondary text-primary group-hover:text-white dark:group-hover:text-darkPrimary flex items-center gap-2 md:text-[14px] text-[12px] font-PoppinsMedium">
                    Create virtual account
                  </h1>
                </button>
              )}
            </div>
            <div>
              <ProfilePicture
                profilePicsClass="cursor-pointer"
                onClick={handleShowProfile}
                profilePics={data?.photo}
              />
            </div>
          </div>
          <div className="flex gap-3 justify-between items-center px-[3%] mb-4 xs:pb-0 pb-4">
            <div className="bg-primary bg-opacity-5 border-[1.5px] w-full border-primary dark:border-realSecondary flex items-center px-7 py-4 rounded-[8px]">
              <div className="flex items-center gap-2 justify-between w-full">
                <div>
                  <h1 className="font-PoppinsMedium xs:text-[14px] text-[12px]">
                    TOTAL BALANCE
                  </h1>
                  <h1 className="md:text-[28px] text-[24px] font-PoppinsBold dark:text-realSecondary text-primary">
                    {data?.account
                      ? data?.hideBalance
                        ? "*******"
                        : formatCurrency(
                            data.account.balance +
                              data.account.investments +
                              data.account.flexible_investments +
                              data.account.regular_investments +
                              data.account.kids_investments +
                              data.account.profit +
                              data.account.referral
                          )
                      : "₦0"}
                  </h1>
                </div>
                {fetchVirtualAccount && (
                  <div className="flex flex-col font-PoppinsMedium text-white bg-[black] gap-4 rounded-[8px]">
                    <div
                      key={fetchVirtualAccount.accountNumber}
                      className="rounded-[8px]"
                    >
                      <div className="flex items-center pt-4 justify-between px-4 pb-2">
                        <div>
                          <h1 className="text-[14px] text-white dark:text-disabled">
                            {fetchVirtualAccount.accountName}
                          </h1>
                          <div className="relative">
                            <input
                              className="text-secondary p-1 outline-none my-1 border-[1.5px] bg-textcolor dark:bg-white w-full border-placeholdercolor rounded-[5px]"
                              value={fetchVirtualAccount.accountNumber}
                              readOnly
                            />
                            <CopyToClipboard
                              text={fetchVirtualAccount.accountNumber}
                              onCopy={() => setCopied(true)}
                            >
                              <button
                                className={`text-[20px] absolute right-2 font-PoppinsRegular rounded-[8px] h-[40px] ${
                                  copied ? "text-referral" : "text-textcolor"
                                }`}
                              >
                                {copied ? <TbCopyCheckFilled /> : <TbCopy />}
                              </button>
                            </CopyToClipboard>
                          </div>
                          <h1 className="text-white  dark:text-disabled">
                            {fetchVirtualAccount.bankName}
                          </h1>
                        </div>
                      </div>
                      {/* <button
                      onClick={() => setWithdrawalModal(true)}
                      className="bg-white flex w-full border-t-[1.5px] border-transactionCard text-realSecondary items-center justify-center gap-2 py-3"
                    >
                      <PiHandWithdrawLight size={20} />
                      Withdraw Funds
                    </button> */}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className=" p-7 rounded-[8px] lg:block hidden">
              <img src={Img} className="w-[300px]" alt="img" />
            </div>
          </div>
          <div className="grid xs:grid-cols-3 grid-cols-2 gap-3 px-[3%]">
            <div
              onClick={() => handleTabChange("balance")}
              className="bg-primary cursor-pointer text-white p-5 rounded-[8px]"
            >
              <MdOutlineAccountBalanceWallet size={25} className="mb-6" />
              <h1 className="xs:text-[18px] text-base font-PoppinsSemiBold">
                Balance
              </h1>
              <h1 className="xs:text-[14px] text-[12px] font-PoppinsRegular pb-5">
                Aggregate available balance across all accounts
              </h1>
              <h1 className="xs:text-[18px] text-base font-PoppinsSemiBold">
                {data?.account.balance
                  ? data?.hideBalance
                    ? "*******"
                    : formatCurrency(data.account.balance)
                  : "₦0"}
              </h1>
            </div>

            <div
              className="bg-investment cursor-pointer text-white p-5 rounded-[8px]"
              onClick={() => handleTabChange("investment")}
            >
              <AiOutlineBank size={25} className="mb-6" />
              <h1 className="xs:text-[18px] text-base font-PoppinsSemiBold">
                Investment
              </h1>
              <h1 className="xs:text-[14px] text-[12px] font-PoppinsRegular pb-5">
                Total amount spent on investment currently.
              </h1>
              <h1 className="xs:text-[18px] text-base font-PoppinsSemiBold">
                {" "}
                {data?.account
                  ? data?.hideBalance
                    ? "*******"
                    : formatCurrency(
                        data?.account?.investments +
                          data?.account?.flexible_investments +
                          data?.account?.regular_investments +
                          data?.account?.kids_investments
                      )
                  : "₦0"}
              </h1>
            </div>
            <div
              onClick={() => handleTabChange("profit")}
              className="bg-secondary text-white cursor-pointer p-5 rounded-[8px]"
            >
              <LiaPercentageSolid size={25} className="mb-6" />
              <h1 className="xs:text-[18px] text-base font-PoppinsSemiBold">
                Profit
              </h1>
              <h1 className="xs:text-[14px] text-[12px] font-PoppinsRegular pb-5">
                Aggregate profit balance accrued from investments
              </h1>
              <h1 className="xs:text-[18px] text-base font-PoppinsSemiBold">
                {" "}
                {data?.account.profit
                  ? data?.hideBalance
                    ? "*******"
                    : formatCurrency(data.account.profit)
                  : "₦0"}
              </h1>
            </div>
            <div
              onClick={() => handleTabChange("referral")}
              className="bg-referral cursor-pointer text-white p-5 rounded-[8px]"
            >
              <PiHandWithdrawLight size={25} className="mb-6" />
              <h1 className="xs:text-[18px] text-base font-PoppinsSemiBold">
                Referral
              </h1>
              <h1 className="xs:text-[14px] text-[12px] font-PoppinsRegular pb-5">
                Aggregate available balance across all accounts
              </h1>
              <h1 className="xs:text-[18px] text-base font-PoppinsSemiBold">
                {data?.account.referral
                  ? data?.hideBalance
                    ? "*******"
                    : formatCurrency(data.account.referral)
                  : "₦0"}
              </h1>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Savings;
