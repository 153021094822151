import { useCallback, useEffect, useMemo, useState } from "react";
import { AiOutlineLineChart } from "react-icons/ai";
import { BiSearchAlt } from "react-icons/bi";
import { IoCheckmarkDoneCircleOutline } from "react-icons/io5";
import { SlList } from "react-icons/sl";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import PageTitle from "../../../components/Pagetitle";
import SelectInputWithLabelAndPlaceholder from "../../../components/SelectInputWithLabelAndPlaceholder";
import InvestmentBtn from "../../../components/InvestmentBtn";
import SelectInputWithLabel from "../../../components/SelectInputWithLabel";
import { Form, Formik } from "formik";
import Logo from "../../../assets/img/affluence-logo-small.png";
import Modal from "../../../components/Modal";

import { RiMenu2Fill } from "react-icons/ri";
import ProfilePicture from "../../../components/ProfilePicture";
import { HttpClient } from "../../../api/HttpClient";
import { isAxiosError } from "axios";
import ToastComponent from "../../../components/ToastComponent";
import { MoonLoader, ScaleLoader } from "react-spinners";
import { formatCurrency } from "../../../components/CurrencyFormat";
import { IoIosArrowForward, IoMdAddCircle } from "react-icons/io";
import * as Yup from "yup";
import { RootState } from "../../../store/store";
import { useSelector } from "react-redux";
import { Account, InvestmentItem } from "../../../assets/Typeprops";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import ReactPaginate from "react-paginate";
import { TiWarningOutline } from "react-icons/ti";
import { ConfigProvider, Switch } from "antd";
import TextInputWithLabel from "../../../components/TextInputWithLabel";
import MaturedInvestmentMobile from "./FlexibleInvestmentsTabPages/MaturedInvestmentMobile";
import MyInvestments from "./FlexibleInvestmentsTabPages/MyInvestmentPage";
import AllInvestments from "./FlexibleInvestmentsTabPages/AllInvestments";
import ActiveInvestments from "./FlexibleInvestmentsTabPages/ActiveInvestments";
import MaturedInvestment from "./FlexibleInvestmentsTabPages/MaturedInvestment";
import MyInvestmentMobile from "./FlexibleInvestmentsTabPages/MyInvestmentMobile";
import ActiveInvestmentMobile from "./FlexibleInvestmentsTabPages/ActiveInvestmentMobile";
import { FaArrowLeft } from "react-icons/fa6";
import Default from "../../../assets/img/default-img.webp";
type InvestmentParams = {
  tab?: string;
};
interface AuthenticatedLayoutContext {
  toggleNav: () => void;
}

const FlexibleInvestments = () => {
  const { tab } = useParams<InvestmentParams>();
  const [InvestmentTab, setInvestmentTab] = useState(tab || "my_investments");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedInvestment, setSelectedInvestment] =
    useState<InvestmentItem | null>(null);
  const [investNowModal, setInvestNowModal] = useState(false);
  const [data, setData] = useState<Account | null>(null);
  const [investmentData, setInvestmentData] = useState<InvestmentItem[]>([]);
  const [allInvestmentData, setAllInvestmentData] = useState<InvestmentItem[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [viewMore, setViewMore] = useState(false);
  const [filterCategory, setFilterCategory] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [currentModalPage, setCurrentModalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalModalPages, setTotalModalPages] = useState(0);
  const [modalInvestments, setModalInvestments] = useState<InvestmentItem[]>(
    []
  );
  const [viewAllIsLoading, setViewAllIsLoading] = useState(false);
  const [onHoverFollowBtn, setOnHoverFollowBtn] = useState(false);
  const [following, setFollowing] = useState(false);
  const [aboutModal, setAboutModal] = useState(false);
  const [switch1, setSwitch1] = useState(false);
  const [switch2, setSwitch2] = useState(false);
  const [isBooking, setIsBooking] = useState(false);
  const navigate = useNavigate();
  const handleGoback = () => {
    navigate("/investments");
  };
  useEffect(() => {
    if (tab && tab !== InvestmentTab) {
      setInvestmentTab(tab);
    }
  }, [tab, InvestmentTab]);
  const handleTabChange = (tabName: string) => {
    setInvestmentTab(tabName);
    navigate(`/investments/flexible-investments/${tabName}`);
  };

  const openShowInvestmentModal = (investment: InvestmentItem) => {
    setSelectedInvestment(investment);
    setIsModalOpen(true);
  };

  const closeShowInvestmentModal = () => {
    setIsModalOpen(false);
    setSelectedInvestment(null);
  };

  const openInvestNowModal = (investment: InvestmentItem) => {
    setSelectedInvestment(investment);
    setInvestNowModal(true);
  };
  const closeInvestNowModal = () => {
    setInvestNowModal(false);
  };
  const fetchInvestmentData = useCallback(async () => {
    setIsLoading(true);
    try {
      const Page = Math.min(currentPage, totalPages) || 1;
      const Limit = 10;
      const [userDataRes, investmentRes, allInvestmentsRes] = await Promise.all(
        [
          HttpClient.get("/users/me", {
            headers: {
              "x-auth-token": localStorage.getItem("token"),
            },
          }),
          HttpClient.get("/flexible-investments/plan", {
            params: {
              page: 1,
              limit: 10,
              dir: "desc",
              sort: "createdAt",
              search: InvestmentTab,
            },
            headers: { "x-auth-token": localStorage.getItem("token") },
          }),
          HttpClient.get("/flexible-investments/plan", {
            params: {
              page: Page,
              limit: Limit,
              dir: "desc",
              sort: "createdAt",
              search: "",
            },
            headers: { "x-auth-token": localStorage.getItem("token") },
          }),
        ]
      );
      setData(userDataRes?.data?.data);
      setInvestmentData(investmentRes?.data?.data?.data);
      setAllInvestmentData(allInvestmentsRes?.data?.data?.data);
      const numOfTransactions = investmentRes?.data?.data.total;
      const totalNumOfPages = Math.ceil(numOfTransactions / Limit);
      setTotalPages(totalNumOfPages);
    } catch (error) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      }
    } finally {
      setIsLoading(false);
    }
  }, [InvestmentTab, currentPage, totalPages]);
  useEffect(() => {
    fetchInvestmentData();
  }, [fetchInvestmentData]);

  const fetchAllModalInvestment = useCallback(async () => {
    setViewAllIsLoading(true);
    try {
      const Page = Math.min(currentModalPage + 1, totalModalPages) || 1;
      const Limit = 10;
      const investmentRes = await HttpClient.get("/flexible-investments/plan", {
        params: {
          page: Page,
          limit: Limit,
          dir: "desc",
          sort: "createdAt",
          search: "",
        },
        headers: { "x-auth-token": localStorage.getItem("token") },
      });
      setModalInvestments(investmentRes?.data?.data?.data);
      const numOfTransactions = investmentRes?.data?.data.total;
      const totalNumOfPages = Math.ceil(numOfTransactions / Limit);
      setTotalModalPages(totalNumOfPages);
    } catch (error) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      }
    } finally {
      setViewAllIsLoading(false);
    }
  }, [currentModalPage, totalModalPages]);

  useEffect(() => {
    if (viewMore) {
      fetchAllModalInvestment();
    }
  }, [fetchAllModalInvestment, viewMore]);

  const wallet = [
    {
      value: "balance",
      label: `Balance (${
        data?.account?.balance ? formatCurrency(data.account.balance) : "₦0"
      })`,
    },
    {
      value: "profit",
      label: `Profits (${
        data?.account?.profit ? formatCurrency(data.account.profit) : "₦0"
      })`,
    },
    {
      value: "referral",
      label: `Referrals (${
        data?.account?.referral ? formatCurrency(data.account.referral) : "₦0"
      })`,
    },
  ];
  const durationNumberData = useMemo(() => {
    return Array.from({ length: 366 }, (_, i) => {
      const value = i + 1;
      return { value: value, label: `${value}` };
    });
  }, []);
  const durationIntervalData = [
    {
      value: "days",
      label: "Day(s)",
    },
    {
      value: "weeks",
      label: "Week(s)",
    },
    {
      value: "months",
      label: "Month(s)",
    },
  ];
  const bookInvestmentSchema = Yup.object().shape({
    amount: Yup.number()
      .required("Please type an amount")
      .min(5000, "Please select an amount not less than ₦5,000")
      .test(
        "is-multiple-of-5000",
        "Amount must be a multiple of ₦5,000",
        (value) => {
          return value % 5000 === 0;
        }
      ),
    durationNumber: Yup.number(),
    durationInterval: Yup.string().required("Please select a duration"),
    fundingSource: Yup.string().required("Please select a funding source"),
    answer: Yup.string().required("Please enter security answer"),
  });

  const handleBookInvestment = async (values: any) => {
    setIsBooking(true);
    try {
      const bookingRes = await HttpClient.post(
        "/flexible-investments/booking",
        { ...values, id: selectedInvestment?._id },
        {
          headers: { "x-auth-token": localStorage.getItem("token") },
        }
      );
      navigate("/investments/project-investments/my_investments");
      setInvestNowModal(false);
      setIsModalOpen(false);
      ToastComponent.success(bookingRes.data.message);
    } catch (error) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      }
    } finally {
      setIsBooking(false);
    }
  };
  const { toggleNav } = useOutletContext<AuthenticatedLayoutContext>();
  const filteredData = useMemo(() => {
    let result = investmentData;

    if (InvestmentTab === "running") {
      result = result.filter((item) => item.status === "running");
    } else if (InvestmentTab === "matured") {
      result = result.filter((item) => item.status === "matured");
    }

    if (filterCategory) {
      result = result.filter((item) => item.category === filterCategory);
    }
    if (searchQuery) {
      result = result.filter((item) =>
        item.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    return result;
  }, [InvestmentTab, filterCategory, investmentData, searchQuery]);
  const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
  const handlePageChange = ({ selected }: any) => {
    setCurrentModalPage(selected);
  };

  const handleFollowInvestment = async () => {
    setFollowing(true);
    try {
      const res = await HttpClient.post(
        `/flexible-investments/follow/${selectedInvestment?._id}`
      );
      ToastComponent.success(res.data.message);
      fetchInvestmentData();
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      } else if (error instanceof Error) {
        ToastComponent.error(error.message);
      } else {
        ToastComponent.error("An unknown error occurred");
      }
    } finally {
      setFollowing(false);
    }
  };
  const handleShowProfile = () => {
    navigate("/my_account", {
      state: { openShowProfileModal: true },
    });
  };
  return (
    <>
      <PageTitle title="Affluence - Flexible Investments" />
      {isLoading ? (
        <div className="w-full h-screen bg-white dark:bg-darkPrimary flex items-center justify-center">
          <ScaleLoader color={isDarkMode ? "#FFFFFF" : "#6B006B"} />
        </div>
      ) : (
        <div className=" text-textcolor dark:text-white overflow-hidden pt-[5rem]">
          <button onClick={toggleNav} className="md:hidden block px-[3%]">
            <RiMenu2Fill size={30} className="text-primary dark:text-white" />
          </button>
          <div className="pb-7 flex items-center justify-between px-[3%]">
            <div className="md:text-[24px] sm:text-[22px] text-[20px]">
              <div className="flex items-center gap-3">
                <button onClick={handleGoback}>
                  <FaArrowLeft className="lg:text-[25px] md:text-[22px] text-[20px]" />
                </button>
                <h1 className=" font-PoppinsBold">Flexible Investments</h1>
              </div>
            </div>
            <ProfilePicture
              profilePicsClass="cursor-pointer"
              onClick={handleShowProfile}
              profilePics={data?.photo}
            />
          </div>
          <div className="flex xxs:flex-row flex-col gap-3 px-[3%]">
            <div className="bg-primary dark:bg-realSecondary dark:bg-opacity-5 bg-opacity-5 border-[1.5px] minixlg:w-[60%] xxs:w-1/2 w-full border-primary dark:border-realSecondary p-7 rounded-[8px]">
              <h1 className="font-PoppinsMedium dark:text-realSecondary extra:text-[14px] text-[12px]">
                TOTAL FLEXIBLE INVESTMENT
              </h1>
              <h1 className="extra:text-[28px] text-[22px] font-PoppinsBold text-primary dark:text-white">
                {data?.account.flexible_investments
                  ? data?.hideBalance
                    ? "*******"
                    : formatCurrency(data.account.flexible_investments)
                  : "₦0"}
              </h1>
            </div>
            <div className="bg-primary dark:bg-realSecondary dark:bg-opacity-5 dark:border-realSecondary bg-opacity-5 border-[1.5px] minixlg:w-[40%] xxs:w-1/2 w-full border-primary p-7 rounded-[8px]">
              <h1 className="font-PoppinsMedium dark:text-realSecondary md:text-[14px] text-[12px]">
                EXPECTED ROI
              </h1>
              <h1 className="extra:text-[28px] md:text-[22px] font-PoppinsBold text-primary dark:text-white">
                Up to 18.2%{" "}
                <span className="font-PoppinsRegular extra:text-[14px] text-[12px] text-disabledtext">
                  per annum
                </span>
              </h1>
            </div>
          </div>
          <div className="pt-6">
            <div className="min-h-[60vh] xmd:block hidden mx-[3%] border-[1.5px] overflow-hidden rounded-[8px] border-transactionCard dark:border-textcolor">
              <div className="flex w-full items-center overflow-x-scroll justify-between border-b-[1.5px] font-PoppinsRegular text-[14px] text-textcolor dark:text-white border-transactionCard dark:border-textcolor pt-4">
                <div className="w-[25%]">
                  <div
                    onClick={() => handleTabChange("my_investments")}
                    className={`flex items-center justify-center text-[14px] gap-2 pb-1 cursor-pointer ${
                      InvestmentTab === "my_investments"
                        ? "font-PoppinsSemiBold"
                        : ""
                    }`}
                  >
                    <AiOutlineLineChart className="text-[20px]" />
                    <div className="text-center">My Investments</div>
                  </div>
                  {InvestmentTab === "my_investments" ? (
                    <div className="h-1 bg-primary dark:bg-white w-full rounded-full"></div>
                  ) : null}
                </div>
                <div className="w-[25%]">
                  <div
                    onClick={() => handleTabChange("all_investments")}
                    className={`flex items-center justify-center text-[14px] gap-2 pb-1 cursor-pointer ${
                      InvestmentTab === "all_investments"
                        ? "font-PoppinsSemiBold"
                        : ""
                    }`}
                  >
                    <BiSearchAlt className="text-[20px]" />
                    <div className="text-center">All Investments</div>
                  </div>
                  {InvestmentTab === "all_investments" ? (
                    <div className="h-1 bg-primary w-full rounded-full dark:bg-white"></div>
                  ) : null}
                </div>
                <div className="w-[25%]">
                  <div
                    onClick={() => handleTabChange("running")}
                    className={`flex items-center justify-center text-[14px] gap-2 pb-1 cursor-pointer ${
                      InvestmentTab === "/running" ? "font-PoppinsSemiBold" : ""
                    }`}
                  >
                    <SlList className="text-[20px]" />
                    <div className="text-center">Running</div>
                  </div>
                  {InvestmentTab === "running" ? (
                    <div className="h-1 bg-primary w-full rounded-full dark:bg-white"></div>
                  ) : null}
                </div>
                <div className="w-[25%]">
                  <div
                    onClick={() => handleTabChange("matured")}
                    className={`flex items-center justify-center gap-2 text-[14px] pb-1 cursor-pointer ${
                      InvestmentTab === "project-investments/matured"
                        ? "font-PoppinsSemiBold"
                        : ""
                    }`}
                  >
                    <IoCheckmarkDoneCircleOutline size={22} />
                    <div className="text-center">Matured</div>
                  </div>
                  {InvestmentTab === "matured" ? (
                    <div className="h-1 bg-primary w-full rounded-full dark:bg-white"></div>
                  ) : null}
                </div>
              </div>
              <div>
                {InvestmentTab === "my_investments" && <MyInvestments />}
                {InvestmentTab === "all_investments" && (
                  <AllInvestments walletBal={data} />
                )}
                {InvestmentTab === "running" && (
                  <ActiveInvestments
                    accountData={data}
                    data={filteredData}
                    following={following}
                    handleFollow={handleFollowInvestment}
                    setCategoryTab={setFilterCategory}
                    categoryTab={filterCategory}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    setCurrentPage={setCurrentPage}
                  />
                )}
                {InvestmentTab === "matured" && (
                  <MaturedInvestment
                    data={filteredData}
                    setCategoryTab={setFilterCategory}
                    categoryTab={filterCategory}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    setCurrentPage={setCurrentPage}
                  />
                )}
              </div>
            </div>
            <div className="xmd:hidden block">
              <div className="px-[3%]">
                <div className="flex justify-between pb-2 items-center">
                  <h1 className="font-PoppinsMedium text-[12px]">
                    INVESTMENT OPPORTUNITIES
                  </h1>
                  <h1
                    onClick={() => setViewMore(true)}
                    className="font-PoppinsRegular flex items-center space-x-1 cursor-pointer text-[11px] dark:text-white text-primary"
                  >
                    View more
                    <IoIosArrowForward />
                  </h1>
                </div>
                {viewMore && (
                  <Modal
                    isOpenModal={() => setViewMore(true)}
                    closeModal={() => setViewMore(false)}
                  >
                    <div className="px-4 pt-[120px]">
                      <h1 className="text-[20px] font-PoppinsBold">
                        All Investments
                      </h1>
                      {viewAllIsLoading ? (
                        <div className="w-full h-[50vh] bg-white dark:bg-darkPrimary flex items-center justify-center">
                          <ScaleLoader
                            color={isDarkMode ? "#FFFFFF" : "#6B006B"}
                          />
                        </div>
                      ) : (
                        <div className="mt-10">
                          {modalInvestments.length > 0 ? (
                            <div>
                              {modalInvestments?.map((item, index) => (
                                <div
                                  key={index}
                                  className="border-[1.5px] cursor-pointer border-transactionCard dark:border-textcolor my-2 py-2 shadow-sm"
                                  onClick={() => openShowInvestmentModal(item)}
                                >
                                  <div className="flex items-center gap-[50px] px-[3%]">
                                    <div className="w-[150px] h-[100px] relative">
                                      <img
                                        src={
                                          item?.previewImage
                                            ? `${process.env.REACT_APP_FILES_URL}/${item.previewImage}`
                                            : item?.previewImage === ""
                                            ? Default
                                            : Default
                                        }
                                        className="w-full h-full bg-cover object-cover rounded-[10px]"
                                        alt="investment-img"
                                      />
                                    </div>
                                    <div>
                                      <h1 className="font-PoppinsSemiBold uppercase text-[12px] pb-2">
                                        {item.title}
                                      </h1>
                                      <h1 className="text-success font-PoppinsSemiBold text-[12px]">
                                        {item.rate}%{" "}
                                        <span className=" font-PoppinsRegular text-[10px] text-tabletext">
                                          returns in your desired duration
                                        </span>
                                      </h1>
                                      <div className="flex items-center gap-9 py-2">
                                        <div>
                                          <h1 className="text-textcolor dark:text-white  font-PoppinsRegular text-[10px]">
                                            NUMBER OF INVESTORS:{" "}
                                            <span className="font-PoppinsSemiBold">
                                              {item.numberOfInvestors}
                                            </span>
                                          </h1>
                                        </div>
                                      </div>
                                      <div
                                        className={`${
                                          item.status === "running"
                                            ? "text-referral bg-referral"
                                            : item.status === "matured"
                                            ? "text-success bg-success"
                                            : item.status === "pending"
                                            ? "text-secondary bg-secondary"
                                            : "text-error bg-error"
                                        } bg-opacity-[28%] inline-block py-[0.7px] px-2 rounded-[10px] font-PoppinsMedium text-[8px]`}
                                      >
                                        {item.status === "running"
                                          ? "RUNNING"
                                          : item.status === "matured"
                                          ? "MATURED"
                                          : item.status === "pending"
                                          ? "PENDING"
                                          : "STOPPED"}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className="text-center text-tabletext text-[12px]">
                              No investments found.
                            </div>
                          )}
                          <ReactPaginate
                            breakLabel={<span className="mr-4">...</span>}
                            nextLabel={
                              currentModalPage < totalModalPages - 1 && (
                                <span className="w-8 h-8 flex items-center justify-center bg-authbordercolor dark:text-white bg-opacity-25 rounded-[4px]">
                                  <BsChevronRight />
                                </span>
                              )
                            }
                            pageRangeDisplayed={5}
                            onPageChange={handlePageChange}
                            pageCount={totalModalPages}
                            previousLabel={
                              currentModalPage > 0 && (
                                <span className="w-8 h-8 flex mr-3 items-center justify-center bg-authbordercolor dark:text-white bg-opacity-25 rounded-[4px]">
                                  <BsChevronLeft />
                                </span>
                              )
                            }
                            renderOnZeroPageCount={null}
                            containerClassName="flex items-center justify-center mt-8 mb-4"
                            pageClassName="element"
                            activeClassName="active-element"
                          />
                        </div>
                      )}
                    </div>
                  </Modal>
                )}
                <div className="flex gap-3 overflow-x-scroll hide-scroll">
                  {allInvestmentData?.map((item, index) => (
                    <div
                      key={index}
                      className="inline-block cursor-pointer w-[150px]"
                      onClick={() => openShowInvestmentModal(item)}
                    >
                      <div className="w-[150px] h-[100px] relative">
                        <img
                          src={
                            item?.previewImage
                              ? `${process.env.REACT_APP_FILES_URL}/${item.previewImage}`
                              : item?.previewImage === ""
                              ? Default
                              : Default
                          }
                          className="w-full h-full bg-cover object-cover rounded-[10px]"
                          alt="investment-img"
                        />
                        <div
                          className={`${
                            item.status === "running"
                              ? "bg-referral"
                              : item.status === "matured"
                              ? "bg-success"
                              : item.status === "pending"
                              ? "bg-secondary"
                              : "bg-error"
                          } text-white rounded-[20px] absolute top-2 right-2 font-PoppinsMedium text-[8px] justify-center items-center inline-flex py-[0.7px] px-2`}
                        >
                          {item.status === "running"
                            ? "RUNNING"
                            : item.status === "matured"
                            ? "MATURED"
                            : item.status === "pending"
                            ? "PENDING"
                            : "STOPPED"}
                        </div>
                      </div>
                      <div className="pt-2">
                        <h1 className="text-textcolor dark:text-white  font-PoppinsRegular text-[10px]">
                          NUMBER OF INVESTORS:{" "}
                          <span className="font-PoppinsSemiBold">
                            {item.numberOfInvestors}
                          </span>
                        </h1>

                        <h1 className="text-textcolor dark:text-white uppercase font-PoppinsSemiBold text-[12px] whitespace-nowrap overflow-hidden text-ellipsis w-[150px]">
                          {item.title}
                        </h1>
                        <h1 className="font-PoppinsRegular text-[11px] dark:text-white text-textcolor">
                          Interest rate at{" "}
                          <span className="font-PoppinsSemiBold text-success">
                            {`${item.rate}%`}
                          </span>
                        </h1>
                        <h1 className="font-PoppinsRegular text-[11px] dark:text-white text-textcolor">
                          Returns in your desired duration
                        </h1>
                      </div>
                    </div>
                  ))}
                </div>
                {isModalOpen && selectedInvestment && (
                  <Modal
                    isOpenModal={openShowInvestmentModal}
                    closeModal={closeShowInvestmentModal}
                  >
                    <div
                      className="flex items-center mt-[90px] justify-center"
                      style={{
                        backgroundImage: `url(${
                          selectedInvestment?.previewImage
                            ? `${process.env.REACT_APP_FILES_URL}/${selectedInvestment.previewImage}`
                            : selectedInvestment?.previewImage === ""
                            ? Default
                            : Default
                        })`,
                        height: "250px",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                      }}
                    >
                      <div className="bg-white opacity-50 inline-block p-4 rounded-[8px]">
                        <img src={Logo} className="w-[30px]" alt="" />
                      </div>
                    </div>
                    <div className="px-4">
                      <div className="bg-success mt-6 mb-8 py-1 px-4 inline-block text-[12px] font-PoppinsLight rounded-[10px] bg-opacity-[28%] text-success">
                        VERIFIED INVESTMENT
                      </div>
                      <div className="w-1/2">
                        <div>
                          <h1 className="font-PoppinsBold uppercase text-[20px]">
                            {selectedInvestment.title}
                          </h1>
                        </div>
                      </div>
                      <div className="flex flex-col gap-4 mt-5">
                        {selectedInvestment.status === "stopped" ? (
                          <button className="bg-error cursor-not-allowed border-[1.5px] border-error text-white font-PoppinsSemiBold w-full py-4 rounded-[8px]">
                            STOPPED
                          </button>
                        ) : selectedInvestment.status === "running" ? (
                          <button
                            onClick={() =>
                              openInvestNowModal(selectedInvestment)
                            }
                            className="bg-investment border-[1.5px] border-investment text-white font-PoppinsSemiBold w-full py-4 rounded-[8px]"
                          >
                            INVEST NOW
                          </button>
                        ) : null}
                        {selectedInvestment.status === "stopped" ? (
                          <button
                            onClick={() => setAboutModal(true)}
                            className="border-[1.5px] border-error text-error font-PoppinsSemiBold py-4 w-full rounded-[8px]"
                          >
                            ABOUT THIS INVESTMENT
                          </button>
                        ) : (
                          <button
                            onClick={() => setAboutModal(true)}
                            className="border-[1.5px] border-investment text-investment font-PoppinsSemiBold py-4 w-full rounded-[8px]"
                          >
                            ABOUT THIS INVESTMENT
                          </button>
                        )}
                      </div>
                      <div
                        className={`text-[14px] font-PoppinsRegular text-center mt-3 ${
                          selectedInvestment.status === "sold out"
                            ? "text-error"
                            : selectedInvestment.status === "pending"
                            ? "text-success"
                            : selectedInvestment.status === "running"
                            ? "text-referral"
                            : "text-success"
                        }`}
                      >
                        {selectedInvestment.status === "sold out"
                          ? "This investment is currently sold out to Investors. Please look for other investment plans."
                          : selectedInvestment.status === "pending"
                          ? "This investment plan is still available for purchase."
                          : selectedInvestment.status === "running"
                          ? "This investment plan is already running for all Investors."
                          : null}
                      </div>

                      <hr className="text-authbordercolor my-10" />
                      <button
                        onClick={handleFollowInvestment}
                        onMouseEnter={() => setOnHoverFollowBtn(true)}
                        onMouseLeave={() => setOnHoverFollowBtn(false)}
                        className="font-PoppinsMedium text-[13px] flex items-center justify-center mb-7 bg-primary text-white py-3 px-4 w-full rounded-[8px]"
                      >
                        {following ? (
                          <MoonLoader color="#ffffff" size={17} />
                        ) : onHoverFollowBtn ? (
                          <IoMdAddCircle className="text-white text-[19.5px]" />
                        ) : (
                          <span>Follow Investment</span>
                        )}
                      </button>
                      <div>
                        <div className="flex justify-center gap-7 mb-5">
                          <div className="w-full border-[1.5px] rounded-[8px] p-3 border-transactionCard">
                            <h1 className="text-[13px] font-PoppinsMedium text-disabledtext">
                              Investment Type
                            </h1>
                            <h1 className="text-[13px] font-PoppinsMedium">
                              {selectedInvestment.category}
                            </h1>
                          </div>
                          <div className="w-full border-[1.5px] rounded-[8px] p-3 border-transactionCard">
                            <h1 className="text-[13px] font-PoppinsMedium text-disabledtext">
                              Payout Type
                            </h1>
                            <h1 className="text-[13px] font-PoppinsMedium">
                              Capital + profit to be paid
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal>
                )}
                {aboutModal && selectedInvestment && (
                  <Modal
                    isOpenModal={() => setAboutModal(true)}
                    closeModal={() => setAboutModal(false)}
                  >
                    <div className="px-4 pt-[120px]">
                      <div>
                        <h1 className="text-primary dark:text-white text-[25px] font-PoppinsSemiBold">
                          About Investment
                        </h1>
                        <div className="py-2">
                          <p className="text-[13px] font-PoppinsRegular text-disabledtext dark:text-disabled">
                            Read about this investment below
                          </p>
                          {selectedInvestment.disclaimer && (
                            <div className="mt-4">
                              <h1 className="font-PoppinsMedium text-[14px] mb-1 flex items-center gap-2">
                                Disclaimer{" "}
                                <TiWarningOutline className="text-error" />
                              </h1>
                              <p className="font-PoppinsLight text-[13px]">
                                {selectedInvestment?.disclaimer}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                      <div>
                        <div
                          className="flex items-center my-7 justify-center"
                          style={{
                            backgroundImage: `url(${
                              selectedInvestment?.previewImage
                                ? `${process.env.REACT_APP_FILES_URL}/${selectedInvestment.previewImage}`
                                : selectedInvestment?.previewImage === ""
                                ? Default
                                : Default
                            })`,
                            height: "250px",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                          }}
                        >
                          <div className="bg-white opacity-50 inline-block p-4 rounded-[8px]">
                            <img src={Logo} className="w-[30px]" alt="" />
                          </div>
                        </div>
                        <p className="text-white text-[13px]">
                          {selectedInvestment.description}
                        </p>
                      </div>
                    </div>
                  </Modal>
                )}
                {investNowModal && selectedInvestment && (
                  <Modal
                    isOpenModal={openInvestNowModal}
                    closeModal={closeInvestNowModal}
                  >
                    <div className="mt-[120px] px-4">
                      <h1 className="text-primary dark:text-white text-[25px] font-PoppinsSemiBold">
                        Invest Now
                      </h1>
                      <div className="bg-primary rounded-[8px] mt-4 p-4 text-white">
                        <h1 className="text-[18px] font-PoppinsMedium">
                          Information
                        </h1>
                        <h1 className="font-PoppinsRegular text-[14px]">
                          Fill the form below to start investing
                        </h1>
                      </div>
                      <h1 className="mt-[60px] mb-[20px] font-PoppinsMedium text-[14px] text-textcolor dark:text-white">
                        Please enter an Amount in multiple of ₦5,000 in{"  "}
                        <span className="uppercase">
                          {selectedInvestment.title}
                        </span>
                      </h1>
                      <Formik
                        initialValues={{
                          amount: "",
                          durationNumber: "",
                          durationInterval: "",
                          fundingSource: "",
                          answer: "",
                        }}
                        onSubmit={handleBookInvestment}
                        validationSchema={bookInvestmentSchema}
                        enableReinitialize={false}
                      >
                        {({ values, isValid, dirty }) => (
                          <Form>
                            <TextInputWithLabel
                              label="Amount(in multiple of ₦5,000)"
                              name="amount"
                              type="number"
                            />
                            <SelectInputWithLabel
                              name="durationNumber"
                              options={durationNumberData}
                              label="Duration number"
                            />
                            <SelectInputWithLabelAndPlaceholder
                              name="durationInterval"
                              options={durationIntervalData}
                              label="Duration Interval(in days, weeks or months)"
                              placeholder="Select Duration Interval"
                            />
                            <SelectInputWithLabelAndPlaceholder
                              name="fundingSource"
                              options={wallet}
                              placeholder="Select a funding source"
                              label="Choose a Source of Funds"
                            />
                            {values.amount &&
                              values.durationNumber &&
                              values.durationInterval &&
                              values.fundingSource && (
                                <TextInputWithLabel
                                  name="answer"
                                  type="text"
                                  label="Answer for the security question set by you"
                                />
                              )}
                            <ConfigProvider
                              theme={{
                                token: {
                                  colorPrimary: "#6B006B",
                                },
                              }}
                            >
                              {values.answer !== "" && (
                                <div>
                                  <div className="flex items-center space-x-4 justify-between mb-4">
                                    <Switch
                                      checked={switch1}
                                      onChange={() =>
                                        setSwitch1((prev) => !prev)
                                      }
                                    />
                                    <span className="text-primary text-[13px] font-PoppinsMedium dark:text-white">
                                      {`I confirm my purchase of this investment for ${formatCurrency(
                                        Number(values.amount)
                                      )}. I agree to retain this investment for ${
                                        values.durationNumber
                                      } ${
                                        Number(values.durationNumber) === 1
                                          ? values.durationInterval.slice(0, -1) // Singular form
                                          : values.durationInterval // Plural form
                                      } before cashing out.`}
                                    </span>
                                  </div>
                                  <div className="flex items-center justify-between space-x-4 mb-4">
                                    <Switch
                                      checked={switch2}
                                      onChange={() =>
                                        setSwitch2((prev) => !prev)
                                      }
                                    />
                                    <span className="text-primary text-[13px] font-PoppinsMedium dark:text-white">
                                      I acknowledge that I have read and
                                      comprehended the investment information. I
                                      recognize that investing in third-party
                                      instruments carries risks, which may, in
                                      rare instances, involve loss of capital
                                      and lack of liquidity.
                                    </span>
                                  </div>
                                </div>
                              )}
                            </ConfigProvider>
                            <div>
                              <InvestmentBtn
                                disabled={
                                  !isValid || !dirty || !switch1 || !switch2
                                }
                                isLoading={isBooking}
                              >
                                INVEST NOW
                              </InvestmentBtn>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </Modal>
                )}
              </div>
              <div className="pt-8 pb-10">
                <div className="flex font-PoppinsRegular text-[14px] text-textcolor dark:text-white w-full items-center justify-between  border-b border-transactionCard pt-4">
                  <div className="w-[33.33%] ">
                    <div
                      onClick={() => handleTabChange("my_investments")}
                      className="flex justify-center text-[12px] pb-1.5 cursor-pointer"
                    >
                      <div className="text-center">My Investments</div>
                    </div>
                    {InvestmentTab === "my_investments" ? (
                      <div className="h-1 bg-primary w-full rounded-full"></div>
                    ) : null}
                  </div>
                  <div className="w-[33.33%] ">
                    <div
                      onClick={() => handleTabChange("running")}
                      className="flex justify-center text-[12px] pb-1.5 cursor-pointer"
                    >
                      <div className="text-center">Running</div>
                    </div>
                    {InvestmentTab === "running" ? (
                      <div className="h-1 bg-primary w-full rounded-full"></div>
                    ) : null}
                  </div>
                  <div className="w-[33.33%] ">
                    <div
                      onClick={() => handleTabChange("matured")}
                      className="flex justify-center text-[12px] pb-1.5 cursor-pointer"
                    >
                      <div className="text-center">Matured</div>
                    </div>
                    {InvestmentTab === "matured" ? (
                      <div className="h-1 bg-primary w-full rounded-full"></div>
                    ) : null}
                  </div>
                </div>
                <div className="pt-4">
                  {InvestmentTab === "my_investments" && <MyInvestmentMobile />}
                  {InvestmentTab === "running" && <ActiveInvestmentMobile />}
                  {InvestmentTab === "matured" && (
                    <MaturedInvestmentMobile data={filteredData} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FlexibleInvestments;
