import { FC } from "react";
import { InvestmentItem } from "../../../../assets/Typeprops";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import Default from "../../../../assets/img/default-img.webp";
import ReactPaginate from "react-paginate";

interface ActiveInvestmentsProps {
  data: InvestmentItem[];
  categoryTab: any;
  setCategoryTab: any;
  currentPage?: number | undefined;
  setCurrentPage: any;
  totalPages?: number | undefined;
}
const MaturedInvestment: FC<ActiveInvestmentsProps> = ({
  data,
  categoryTab,
  setCategoryTab,
  currentPage,
  totalPages,
  setCurrentPage,
}) => {
  const handlePageChange = ({ selected }: any) => {
    setCurrentPage(selected);
  };
  return (
    <div className="pt-6">
      <div className="border-b-[1.5px] border-transactionCard pb-5">
        <div className="flex text-[12px] font-PoppinsMedium justify-between items-center gap-10 pt-6 px-[3%]">
          <div
            onClick={() => setCategoryTab(null)}
            className={`border-[1.5px] cursor-pointer py-0.5 border-textcolor rounded-[10px] w-full text-center ${
              categoryTab === null ? "bg-primary text-white" : ""
            }`}
          >
            All
          </div>
          <div
            onClick={() => setCategoryTab("Fixed Income")}
            className={`border-[1.5px] cursor-pointer py-0.5 border-textcolor rounded-[10px] w-full text-center ${
              categoryTab === "Fixed Income" ? "bg-primary text-white" : ""
            }`}
          >
            Fixed Income
          </div>
          <div
            onClick={() => setCategoryTab("Real Estate")}
            className={`border-[1.5px] cursor-pointer py-0.5 border-textcolor rounded-[10px] w-full text-center ${
              categoryTab === "Real Estate" ? "bg-primary text-white" : ""
            }`}
          >
            Real Estate
          </div>
          <div
            onClick={() => setCategoryTab("Agriculture")}
            className={`border-[1.5px] cursor-pointer py-0.5 border-textcolor rounded-[10px] w-full text-center ${
              categoryTab === "Agriculture" ? "bg-primary text-white" : ""
            }`}
          >
            Agriculture
          </div>
          <div
            onClick={() => setCategoryTab("Transportation")}
            className={`border-[1.5px] cursor-pointer py-0.5 border-textcolor rounded-[10px] w-full text-center ${
              categoryTab === "Transportation" ? "bg-primary text-white" : ""
            }`}
          >
            Transportation
          </div>
        </div>
      </div>
      <div>
        {data && data.length > 0 ? (
          data?.map((item, index) => (
            <div
              key={index}
              className="border-y-[1.5px] cursor-pointer w-full border-transactionCard dark:border-textcolor mb-2 py-2 dark:shadow-[#000000] shadow-transactionCard shadow-sm"
            >
              <div className="flex items-center gap-[50px] px-[3%]">
                <div className="w-[200px] h-[150px] relative">
                  <img
                    src={
                      item?.previewImage
                        ? `${process.env.REACT_APP_FILES_URL}/${item.previewImage}`
                        : item?.previewImage === ""
                        ? Default
                        : Default
                    }
                    className="w-full h-full object-cover rounded-[10px]"
                    alt="investment-img"
                  />
                </div>
                <div>
                  <h1 className="font-PoppinsSemiBold uppercase text-[18px] pb-2">
                    {item.title}
                  </h1>
                  <h1 className="text-success font-PoppinsSemiBold text-[12px]">
                    {`${item.rate}% `}
                    <span className=" font-PoppinsRegular text-[10px] text-tabletext">
                      returns in your desired duration
                    </span>
                  </h1>
                  <div className="bg-opacity-[28%] dark:bg-opacity-[28%] dark:text-darkPrimary dark:bg-[#C8A2C8] text-primary bg-primary inline-block py-1 px-4 rounded-[10px] font-PoppinsMedium text-[10px]">
                    Matured
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center pt-[40px]">
            <div>
              <h1 className="font-PoppinsBold pb-3 sm:text-[24px] xs:text-[22px] text-[20px] text-primary">
                There are no matured investments currently!
              </h1>
            </div>
          </div>
        )}
        <ReactPaginate
          breakLabel={<span className="mr-4">...</span>}
          nextLabel={
            (currentPage ?? 0) < (totalPages ?? 0) - 1 && (
              <span className="w-8 h-8 flex items-center justify-center bg-authbordercolor dark:text-white bg-opacity-25 rounded-[4px]">
                <BsChevronRight />
              </span>
            )
          }
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          pageCount={totalPages ?? 0}
          previousLabel={
            (currentPage ?? 0) > 0 && (
              <span className="w-8 h-8 flex mr-3 items-center justify-center bg-authbordercolor dark:text-white bg-opacity-25 rounded-[4px]">
                <BsChevronLeft />
              </span>
            )
          }
          renderOnZeroPageCount={null}
          containerClassName="flex items-center justify-center mt-8 mb-4"
          pageClassName="element"
          activeClassName="active-element"
        />
      </div>
    </div>
  );
};

export default MaturedInvestment;
