import React from "react";
import PageTitle from "../../../components/Pagetitle";
import Sidebar from "../../sidebar/Sidebar";

function SavingsCategory() {
  return (
    <>
      <PageTitle title="Savings Category" />
      <div className="flex h-screen dark:bg-darkPrimary">
        <Sidebar />
        <div className="flex-1 overflow-y-auto">
          <div className="mt-28 p-4 shadow-lg border border-transactionCard rounded-lg mx-4">
            <div className="flex flex-col md:flex-row justify-between p-2 mb-4">
              <span className="dark:text-white text-semibold text-textcolor font-PoppinsBold">
                Savings Category
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SavingsCategory;
