import Sidebar from "../../../sidebar/Sidebar";
import { Input, Table } from "antd";
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import PageTitle from "../../../../components/Pagetitle";
import { useCallback, useEffect, useState } from "react";
import { HttpClient } from "../../../../api/HttpClient";
import { ScaleLoader } from "react-spinners";
import { format } from "date-fns";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Legend, Plugin } from "chart.js";
import ErrorHandler from "../../../ErrorHandler";
import NoTransaction from "../../../../assets/img/Wavy_Tech-31_Single-03.jpg";
import NoData from "../../../../components/NoDataIllustration";
import { formatCurrency } from "../../../../components/CurrencyFormat";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";

ChartJS.register(ArcElement, Legend);

interface InvestData {
  _id?: string;
  key?: string;
  user: string;
  amount: number;
  roiExpected: number;
  totalPayable: number;
  createdAt: string;
  maturityDate: string;
  numberOfUnits: number;
  investment_details: {
    _id: string;
    title: string;
    units: number;
    investmentDurationInDays: string;
  };
  user_details: {
    firstName: string;
    lastName: string;
  };
}

interface InvestmentStatusChart {
  name: string;
  value: number;
  fill?: string;
}

interface ChartDataItem {
  _id: {
    category: string;
  };
  categoryCount: number;
}

interface ChartData {
  labels: string[];
  datasets: {
    data: number[];
    backgroundColor: string[];
    borderColor: string[];
    borderWidth: number;
  }[];
}

interface CustomPlugin extends Plugin<"doughnut"> {
  id: string;
}

function StandardInvestmentLog() {
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState<InvestData[]>([]);
  const [allInvestData, setAllInvestData] = useState<InvestData[]>([]);
  const [barData, setBarData] = useState<InvestmentStatusChart[]>([]);
  const [chartData, setChartData] = useState<ChartData>({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [
          "#009306",
          "#6B006B",
          "#0357D1",
          "#C59832",
          "#FFC700",
          "#00936D",
        ],
        borderColor: ["#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
        borderWidth: 2,
      },
    ],
  });
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 5,
    total: 0,
  });
  const [filters, setFilters] = useState({
    name: "",
  });

  const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);

  const centerTextPlugin = (isDarkMode: boolean) => ({
    id: "centerTextPlugin",
    beforeDraw: (chart: any) => {
      const { ctx, chartArea, data } = chart;
      const { top, bottom, left, right } = chartArea;
      ctx.save();
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";

      const total = data.datasets[0].data.reduce(
        (acc: number, value: number) => acc + value,
        0
      );

      const centerX = (left + right) / 2;
      const centerY = (top + bottom) / 2;

      ctx.font = "14px PoppinsLight";
      ctx.fillStyle = isDarkMode ? "#FFF" : "#3D3C3A";

      ctx.fillText("Total:", centerX, centerY - 10);
      ctx.fillText(`${total}`, centerX, centerY + 10);
      ctx.restore();
    },
  });

  const applyFilters = useCallback(
    (data: InvestData[]) => {
      let filtered = data;

      if (filters.name) {
        filtered = filtered.filter((item) => {
          const firstName = item?.user_details?.firstName?.toLowerCase() || "";
          const lastName = item?.user_details?.lastName?.toLowerCase() || "";
          const fullName = `${firstName} ${lastName}`;
          return fullName.includes(filters.name.toLowerCase());
        });
      }

      setFilteredData(filtered);
      setPagination((prev) => ({
        ...prev,
        total: filtered.length,
      }));
    },
    [filters]
  );

  const fetchAllInvestData = useCallback(async () => {
    try {
      const [investChartRes, donutChartRes, investDataRes] = await Promise.all([
        HttpClient.get("/regular-investments/status-statistics", {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          params: {
            search: "",
            startdate: "",
            enddate: "",
          },
        }),
        HttpClient.get("/regular-investments/category-statistics", {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          params: {
            search: "",
            startdate: "",
            enddate: "",
          },
        }),
        HttpClient.get("/regular-investments/booking", {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }),
      ]);

      const investData = investChartRes.data?.data;

      const formattedData: InvestmentStatusChart[] = [
        {
          name: "All Investments",
          value: investData.all_investment_data,
          fill: "#0357D1",
        },
        {
          name: "Pending",
          value: investData.pending_investment_data,
          fill: "#C59832",
        },
        {
          name: "Running",
          value: investData.running_investment_data,
          fill: "#009306",
        },
        {
          name: "Matured",
          value: investData.matured_investment_data,
          fill: "#6B006B",
        },
        {
          name: "Sold Out",
          value: investData.sold_out_investment_data,
          fill: "#E80404",
        },
      ];

      setBarData(formattedData);

      const dataItems: ChartDataItem[] = donutChartRes.data?.data;
      const dataValues = dataItems.map((item) => item.categoryCount);
      const labels = dataItems.map((item) => item._id.category);

      setChartData((prevData) => ({
        ...prevData,
        labels: labels,
        datasets: [
          {
            ...prevData.datasets[0],
            data: dataValues,
          },
        ],
      }));

      const investLog = investDataRes.data?.data?.data.map(
        (item: any, index: number) => ({
          key: item._id,
          serialNumber: index + 1,
          createdAt: item?.createdAt,
          name:
            item?.user_details?.firstName + " " + item?.user_details?.lastName,
          roiExpected: item?.roiExpected,
          description: item?.description,
          totalPayable: item?.totalPayable,
          numberOfUnits: item?.numberOfUnits,
          amount: item?.amount,
          maturityDate: item?.maturityDate,
          investmentTitle: item?.investment_details?.title,
          investmentUnits: item?.investment_details?.units,
          investmentDurationInDays:
            item?.investment_details?.investmentDurationInDays,
          user_details: item?.user_details,
        })
      );

      setAllInvestData(investLog);
      setPagination((prev) => ({
        ...prev,
        total: investDataRes.data.data?.data?.total,
      }));
      applyFilters(investLog);
    } catch (error: any) {
      ErrorHandler(error);
    } finally {
      setLoading(false);
    }
  }, [applyFilters]);

  useEffect(() => {
    fetchAllInvestData();
  }, [fetchAllInvestData]);

  useEffect(() => {
    applyFilters(allInvestData);
  }, [filters, allInvestData, applyFilters]);

  const handleFilterChange = (field: string, value: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));

    if (!filters.name) {
      setFilteredData(allInvestData);
    }
  };

  const columns: ColumnsType<InvestData> = [
    {
      key: "serialNumber",
      title: "S/N",
      dataIndex: "serialNumber",
    },
    {
      key: "createdAt",
      title: "Date",
      dataIndex: "createdAt",
      render: (dateTime: string) => {
        const date = new Date(dateTime);
        const formattedDate = format(date, "MMM dd, yyyy");
        const formattedTime = format(date, "hh:mm a");

        return (
          <div className="text-textcolor" style={{width: "100px"}}>
            <div className="font-PoppinsRegular">{formattedDate}</div>
            <div className="font-PoppinsLight">{formattedTime}</div>
          </div>
        );
      },
    },
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      render: (name: string) => {
        return <div className="capitalize" style={{width: "100px"}}>{name}</div>
      },
      filterDropdown: () => (
        <Input
          placeholder="Jon Doe"
          value={filters.name}
          onChange={(e) => handleFilterChange("name", e.target.value)}
        />
      ),
      onFilterDropdownVisibleChange: () => applyFilters(allInvestData),
    },
    {
      key: "investmentTitle",
      title: "Title",
      dataIndex: "investmentTitle",
    },
    {
      key: "maturityDate",
      title: "Maturity Date",
      dataIndex: "maturityDate",
      render: (dateTime: string) => {
        const date = new Date(dateTime);
        const formattedDate = format(date, "MMM dd, yyyy");
        const formattedTime = format(date, "hh:mm a");
        return (
          <div className="text-textcolor" style={{width:"100px"}}>
            <div className="font-PoppinsRegular">{formattedDate}</div>
            <div className="font-PoppinsLight">{formattedTime}</div>
          </div>
        );
      },
    },
    {
      key: "amount",
      title: "Amount",
      dataIndex: "amount",
      render: (amount: number) => formatCurrency(amount),
    },
    {
      key: "roiExpected",
      title: "ROI",
      dataIndex: "roiExpected",
      render: (roiExpected: number) => formatCurrency(roiExpected),
    },
    {
      key: "totalPayable",
      title: "Total Payable",
      dataIndex: "totalPayable",
      render: (totalPayable: number) => formatCurrency(totalPayable),
    },
  ];

  return (
    <>
      <PageTitle title="Affluence - Admin: Investment Log" />
      <div className="flex h-screen">
        <Sidebar />
        <div className="flex-1 overflow-y-auto dark:bg-darkPrimary">
          {loading ? (
            <div className="w-full h-screen bg-white dark:bg-darkPrimary flex items-center justify-center">
              <ScaleLoader color={isDarkMode ? "#FFFFFF" : "#6B006B"} />
            </div>
          ) : (
            <div className="p-4">
              <div className="flex flex-col lg:flex-row gap-4 mt-28">
                <div className="border shadow-md border-transactionCard rounded-lg p-5 h-[370px] w-full">
                  <span className="dark:text-white text-semibold text-textcolor font-PoppinsBold pb-4">
                   Regular Investment Status Statistics
                  </span>
                  {barData && barData.length > 0 ? (
                    <div className="overflow-x-auto">
                      <div style={{ width: 650, height: 300 }}>
                        <BarChart width={600} height={300} data={barData}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            dataKey="name"
                            tick={{ fill: isDarkMode ? "#FFFFFF" : "#3D3C3A" }}
                          />
                          <YAxis
                            tick={{ fill: isDarkMode ? "#FFFFFF" : "#3D3C3A" }}
                          />
                          <Tooltip formatter={(value: number) => `${value}`} />
                          <Bar dataKey="value" />
                        </BarChart>
                      </div>
                    </div>
                  ) : (
                    <NoData paragraph="Oops! No data found" />
                  )}
                </div>

                <div className="border shadow-md border-transactionCard rounded-lg p-5 h-[370px] w-full">
                  <span className="dark:text-white text-semibold text-textcolor font-PoppinsBold">
                    Regular Investment Category Statistics
                  </span>
                  {chartData &&
                  chartData.datasets &&
                  chartData.datasets.length > 0 ? (
                    <div className="flex justify-center">
                      <div style={{ height: 300 }}>
                        <Doughnut
                          data={chartData}
                          plugins={[centerTextPlugin(isDarkMode)]}
                          options={{
                            responsive: true,
                            plugins: {
                              legend: {
                                display: true,
                                labels: {
                                  color: isDarkMode ? "#FFFFFF" : "#3D3C3A", // Set legend text color based on dark mode
                                },
                              },
                            },
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <NoData paragraph="Oops! No data found" />
                  )}
                </div>
              </div>

              <div>
                <div className="w-full shadow-md mt-8 border border-transactionCard rounded-lg p-4">
                  <div className="flex gap-4 mb-4 items-center">
                    <span className="dark:text-white text-semibold text-textcolor font-PoppinsBold block mb-4">
                      Regular Investment Logs
                    </span>
                  </div>
                  {filteredData && filteredData.length > 0 ? (
                    <div className="dark:text-white dark:bg-white overflow-x-auto">
                      <Table
                        dataSource={filteredData}
                        columns={columns}
                        pagination={pagination}
                        onChange={(newPagination) =>
                          setPagination(newPagination)
                        }
                      />
                    </div>
                  ) : allInvestData.length === 0 ? (
                    <div className="flex items-center justify-center py-5">
                      <div>
                        <div className="flex items-center justify-center">
                          <img
                            src={NoTransaction}
                            className="w-[200px]"
                            alt="illustrator"
                          />
                        </div>
                        <h1 className="dark:text-white font-PoppinsBold pb-3 text-[20px] text-primary">
                          No Investment Logs currently!
                        </h1>
                      </div>
                    </div>
                  ) : (
                    <Table
                      dataSource={filteredData}
                      columns={columns}
                      pagination={pagination}
                      onChange={(newPagination) => setPagination(newPagination)}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default StandardInvestmentLog;
