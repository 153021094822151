import React, { useState } from "react";
import PageTitle from "../../components/Pagetitle";
import Sidebar from "../sidebar/Sidebar";
import TextAreaInput from "../../components/TextAreaInput";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import errorHandler from "../ErrorHandler";
import { HttpClient } from "../../api/HttpClient";
import ToastComponent from "../../components/ToastComponent";
import TextInput from "../../components/TextInput";
import CreateBtn from "./investment/CreateBtn";

const PromotionalEmail = () => {
  const [loading, setLoading] = useState(false);

  const promoEmailValidation = Yup.object({
    subject: Yup.string().required("Email Subject is required"),
    body: Yup.string().required("Message is required"),
  });

  const handleSubmit = async (values: any) => {
    try {
      const formData = new FormData();
      formData.append("subject", values.subject);
      formData.append("body", values.body);

      const promoEmailRes = await HttpClient.post(
        "/admin/send-promotional-email",
        formData,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      if (promoEmailRes) {
        ToastComponent.success(
          promoEmailRes.data.message || "Promotional Email sent successfully"
        );
      } else {
        ToastComponent.error("Failed to send Promotional Email");
      }
    } catch (error: any) {
      errorHandler(error);
    }
  };
  return (
    <>
      <PageTitle title="Admin - Promotional Email" />
      <div className="flex h-screen dark:bg-darkPrimary">
        <Sidebar />
        <div className="flex-1 items-center justify-center mt-20 p-4 overflow-x-hidden">
          <div className="overflow-hidden whitespace-nowrap">
            <div className="mb-10 text-primary font-PoppinsRegular inline-block animate-scroll">
              Only verified users can receive email!
            </div>
          </div>

          <Formik
            initialValues={{
              subject: "",
              body: "",
            }}
            validationSchema={promoEmailValidation}
            onSubmit={handleSubmit}
          >
            <Form className="w-full">
              <div className="flex flex-col sm:flex-row justify-center mb-4">
                <label
                  htmlFor="subject"
                  className="text-textcolor font-PoppinsMedium mb-1 sm:mb-0 sm:mr-6 dark:text-white"
                >
                  Subject:
                </label>
                <div className="w-full">
                  <TextInput
                    type="text"
                    name="subject"
                    placeholder="Enter the subject of the email"
                  />
                </div>
              </div>

              <div className="flex flex-col sm:flex-row justify-center">
                <label
                  htmlFor="body"
                  className="text-textcolor font-PoppinsMedium mb-1 sm:mb-0 sm:mr-2 dark:text-white"
                >
                  Message:
                </label>
                <div className="w-full">
                  <TextAreaInput
                    type="text"
                    name="body"
                    placeholder="Enter your message"
                    className="h-80"
                  />
                </div>
              </div>

              <div className="w-full flex justify-end mt-8">
                    <button
                      type="submit"
                      className="w-full sm:w-full md:w-40 font-PoppinsMedium rounded-lg text-primary hover:before:bg-primary border-primary relative h-[50px] overflow-hidden border bg-white px-3 transition-all before:absolute before:bottom-0 before:left-0 before:top-0 before:z-0 before:h-full before:w-0 before:bg-primary before:transition-all before:duration-500 hover:text-white hover:before:left-0 hover:before:w-full"
                    >
                      <span className="relative z-10">Send</span>
                    </button>
                  </div>
            </Form>
          </Formik>
        </div>
      </div>
    </>
  );
};

export default PromotionalEmail;
