"use client";
import { FC } from "react";
import { MoonLoader } from "react-spinners";

interface InvestmentBtnProps {
  isLoading: boolean;
  disabled: boolean;
  children: React.ReactNode;
  withdrawal?: boolean;
}

const InvestmentBtn: FC<InvestmentBtnProps> = ({
  isLoading,
  disabled,
  children,
  withdrawal,
}) => {
  const baseStyles =
    "sm:p-3.5 p-3 rounded-[8px] w-full font-PoppinsBold text-[14px] flex justify-center items-center";

  const enabledStyles = `${
    withdrawal ? "bg-primary border-primary" : "bg-investment border-investment"
  } text-white border`;
  const disabledStyles =
    "bg-disabled text-disabledtext border border-authbordercolor cursor-not-allowed";

  return (
    <button
      type="submit"
      className={`${baseStyles} ${disabled ? disabledStyles : enabledStyles}`}
      disabled={disabled || isLoading}
    >
      {isLoading ? <MoonLoader color="#ffffff" size={17} /> : children}
    </button>
  );
};

export default InvestmentBtn;
