import React, { useCallback, useEffect, useState } from "react";
import { AiOutlineBank } from "react-icons/ai";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import PageTitle from "../../../components/Pagetitle";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import "chart.js/auto";
import { PiHandDepositBold, PiHandWithdrawLight } from "react-icons/pi";
import { LiaPercentageSolid } from "react-icons/lia";
import { GiTakeMyMoney } from "react-icons/gi";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { HttpClient } from "../../../api/HttpClient";
import ToastComponent from "../../../components/ToastComponent";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../sidebar/Sidebar";
import { FaRegEye } from "react-icons/fa6";
import { CiImageOff } from "react-icons/ci";
import ProfilePicture from "../../ProfilePicture";
import Modal from "react-modal";
import { OneUserTransact } from "./OneUserTransact";
import { ScaleLoader } from "react-spinners";
import { format } from "date-fns";
import ErrorHandler from "../../ErrorHandler";
import NoTransaction from "../../../assets/img/Wavy_Tech-31_Single-03.jpg";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import TextInput from "../../../components/TextInput";
import { formatCurrency } from "../../../components/CurrencyFormat";
import PasswordInput from "../../../components/PasswordInput";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import SelectInput from "../../../components/SelectInput";
import { OneUserAccount } from "../../../assets/Typeprops";
import { TransactionHistory } from "../../../assets/Typeprops";
import { AuditHistory } from "../../../assets/Typeprops";
import { UserInvestData } from "../../../assets/Typeprops";
import { Investment } from "../../../assets/Typeprops";

ChartJS.register(ArcElement, Tooltip, Legend);

const OneCustomer = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const { customerId } = useParams<{
    customerId: string;
  }>();
  const [loading, setLoading] = useState<boolean>(true);
  const [accountData, setAccountData] = useState<OneUserAccount | null>(null);

  const [, setKycStatus] = useState<OneUserAccount | null>(null);
  const [transactHistory, setTransactHistory] = useState<TransactionHistory[]>(
    []
  );
  const [auditHistory, setAuditHistory] = useState<AuditHistory[]>([]);
  const [investHistory, setInvestHistory] = useState<UserInvestData[]>([]);
  const [comment, setComment] = useState("");
  const [, setPaymentTypes] = useState<string[]>([]);
  const [userId, setUserId] = useState("");
  // const [pagination, setPagination] = useState<any>({
  //   current: 1,
  //   pageSize: 5,
  //   total: 0,
  // });

  const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);

  const creditDebitSchema = Yup.object().shape({
    amount: Yup.number()
      .required("Amount is required")
      .min(100, "Amount must be greater than 100")
      .positive("Amount must be positive"),
    fundingAction: Yup.string().required("Funding Action is required"),
    otp: Yup.string().required("OTP is required"),
    accountType: Yup.string().required("Funding Destination is required."),
    userId: Yup.string().required("User ID is required"),
    pin: Yup.string().required("Your pin is required"),
  });

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);

      const userDataRes = await HttpClient.get(
        `/admin/view-user/${customerId}`,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      const userAccountDetails = userDataRes.data?.data?.userAccountDetails;
      const userID = userAccountDetails?.user;

      const transHisRes = await HttpClient.get(
        `/transactions/user-history/${customerId}`,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          params: {
            page: "",
            limit: "",
            startdate: "",
            enddate: "",
            dir: "desc",
            sort: "",
            search: "",
          },
        }
      );

      const investDataRes = await HttpClient.get("/investments/booking", {
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          userId: userID,
        },
      });

      const auditHisRes = await HttpClient.get(`/users/audit-log`, {
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          user: userID,
        },
      });

      const auditHis = auditHisRes.data?.data.map(
        (item: any, index: number) => ({
          key: item._id,
          serialNumber: index + 1,
          createdAt: item?.createdAt || "",
          updatedAt: item?.updatedAt || "",
          action: item?.action || "",
          description: item?.description || "",
          deviceInfo: item?.deviceInfo || "",
        })
      );

      const { data } = transHisRes.data?.data;
      const transHis = data.map((item: any, index: number) => ({
        key: item._id,
        serialNumber: index + 1,
        transactionDate: item?.transactionDate || "",
        transactionType: item?.transactionType || "",
        transactionRefCode: item?.transactionRefCode || "",
        transactionId: item?.transactionId || "",
        amount: item?.amount || "",
        paymentStatus: item?.paymentStatus || "",
        isInFlow: item?.isInFlow || "",
        responseMsg: item?.responseMsg || "",
        paymentGateway: item?.paymentGateway || "",
        paymentMethod: item?.paymentMethod || "",
      }));

      const allInvestments: Investment[] = investDataRes.data?.data?.data || [];

      const filteredInvestments = allInvestments.filter(
        (investment: Investment) => {
          if (!investment.user_details || !investment.user_details._id) {
            return false;
          }
          return investment.user_details._id === userID;
        }
      );

      const investLog: UserInvestData[] = filteredInvestments.map(
        (item: Investment, index: number) => ({
          key: item._id,
          serialNumber: index + 1,
          createdAt: item?.createdAt,
          name:
            item?.user_details?.firstName + " " + item?.user_details?.lastName,
          roiExpected: item?.roiExpected,
          description: item?.investment_details?.description,
          totalPayable: item?.totalPayable,
          numberOfUnits: item?.numberOfUnits,
          amount: item?.amount,
          maturityDate: item?.maturityDate,
          investmentTitle: item?.investment_details?.title,
          investmentUnits: item?.investment_details?.units,
          investmentDurationInDays: item?.investment_details?.durationInDays,
        })
      );

      setAccountData(userDataRes.data?.data);
      setAuditHistory(auditHis);
      setTransactHistory(transHis);
      setInvestHistory(investLog);
      setPaymentTypes(transHisRes.data?.data?.transactionType);
      setUserId(userID);
    } catch (error: any) {
      ErrorHandler(error);
    } finally {
      setLoading(false);
    }
  }, [customerId]);
  useEffect(() => {
    fetchData();
  }, [customerId, fetchData]);

  const transactionColumns: ColumnsType<TransactionHistory> = [
    {
      key: "serialNumber",
      title: "S/N",
      dataIndex: "serialNumber",
    },
    {
      key: "transactionDate",
      title: "Date",
      dataIndex: "transactionDate",
      render: (dateTime: string) => {
        const date = new Date(dateTime);
        const formattedDate = format(date, "MMM dd, yyyy");
        const formattedTime = format(date, "hh:mm a");
        return (
          <div className="text-textcolor" style={{ width: "100px" }}>
            <div className="font-PoppinsRegular">{formattedDate}</div>
            <div className="font-PoppinsLight">{formattedTime}</div>
          </div>
        );
      },
    },
    {
      key: "transactionRefCode",
      title: "Description",
      dataIndex: "transactionRefCode",
    },
    {
      key: "isInFlow",
      title: "DR/CR",
      dataIndex: "isInFlow",
      render: (isInFlow: boolean) => (
        <span
          className={`font-PoppinsRegular ${
            isInFlow ? "text-success" : "text-error"
          }`}
        >
          {isInFlow ? "Credit" : "Debit"}
        </span>
      ),
    },
    {
      key: "responseMsg",
      title: "Response Msg",
      dataIndex: "responseMsg",
      render: (responseMsg: string) => {
        return (
          <div style={{ width: "150px" }} className="capitalize">
            {responseMsg}
          </div>
        );
      },
    },
    {
      key: "transactionType",
      title: "Type",
      dataIndex: "transactionType",
      className: "capitalize",
      render: (transactionType: string) => (
        <div
          className={`${
            transactionType === "investments" ||
            transactionType === "standard_investments" ||
            transactionType === "regular_investments" ||
            transactionType === "fixed_investments" ||
            transactionType === "flexible_investments" ||
            transactionType === "kids_investments"
              ? "text-investment"
              : transactionType === "withdrawals"
              ? "text-primary"
              : transactionType === "wallet"
              ? "text-primary"
              : transactionType === "profits"
              ? "text-secondary"
              : transactionType === "savings"
              ? "text-secondary"
              : transactionType === "deposits"
              ? "text-deposit"
              : transactionType === "referral"
              ? "text-referral"
              : "text-textcolor"
          }`}
        >
          {transactionType.replace(/_/, " ")}
        </div>
      ),
    },

    {
      key: "transactionId",
      title: "Transaction ID",
      dataIndex: "transactionId",
    },
    {
      key: "paymentGateway",
      title: "Payment Gateway",
      dataIndex: "paymentGateway",
      render: (paymentGateway: string) => {
        return (
          <div className="capitalize" style={{ width: "100px" }}>
            {paymentGateway}
          </div>
        );
      },
    },
    {
      key: "paymentMethod",
      title: "Payment Method",
      dataIndex: "paymentMethod",
      render: (paymentMethod: string) => {
        return (
          <div className="capitalize" style={{ width: "100px" }}>
            {paymentMethod.replace(/_/g, " ")}
          </div>
        );
      },
    },
    {
      key: "amount",
      title: "Amount",
      dataIndex: "amount",
      render: (amount: number) => formatCurrency(amount),
    },

    {
      key: "paymentStatus",
      title: "Status",
      dataIndex: "paymentStatus",
      render: (paymentStatus: string) => (
        <span
          className={`px-[0.9rem] py-[0.35rem] capitalize rounded-[30px] bg-opacity-[28%] text-center ${
            paymentStatus === "pending"
              ? "bg-realSecondary text-realSecondary"
              : paymentStatus === "successful"
              ? "bg-success text-success"
              : "bg-error text-error"
          }`}
        >
          {paymentStatus}
        </span>
      ),
    },
  ];

  const auditColumns: ColumnsType<AuditHistory> = [
    {
      key: "serialNumber",
      title: "S/N",
      dataIndex: "serialNumber",
    },
    {
      key: "createdAt",
      title: "Created Date",
      dataIndex: "createdAt",
      render: (dateTime: string) => {
        const date = new Date(dateTime);
        const formattedDate = format(date, "MMM dd, yyyy");
        const formattedTime = format(date, "hh:mm:ss a");
        return (
          <div className="text-textcolor">
            <div className="font-PoppinsRegular">{formattedDate}</div>
            <div className="font-PoppinsLight">{formattedTime}</div>
          </div>
        );
      },
    },
    {
      key: "updatedAt",
      title: "Updated Date",
      dataIndex: "updatedAt",
      render: (dateTime: string) => {
        const date = new Date(dateTime);
        const formattedDate = format(date, "MMM dd, yyyy");
        const formattedTime = format(date, "hh:mm:ss a");
        return (
          <div className="text-textcolor">
            <div className="font-PoppinsRegular">{formattedDate}</div>
            <div className="font-PoppinsLight">{formattedTime}</div>
          </div>
        );
      },
    },
    {
      key: "action",
      title: "Action",
      dataIndex: "action",
      className: "capitalize",
      render: (text: string) =>
        text.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase()),
    },
    {
      key: "description",
      title: "Description",
      dataIndex: "description",
    },
    {
      key: "deviceInfo",
      title: "Device Information",
      dataIndex: "deviceInfo",
    },
  ];

  const investColumns: ColumnsType<UserInvestData> = [
    {
      key: "serialNumber",
      title: "S/N",
      dataIndex: "serialNumber",
    },
    {
      key: "createdAt",
      title: "Date",
      dataIndex: "createdAt",
      render: (dateTime: string) => {
        const date = new Date(dateTime);
        const formattedDate = format(date, "MMM dd, yyyy");
        const formattedTime = format(date, "hh:mm a");

        return (
          <div className="text-textcolor">
            <div className="font-PoppinsRegular">{formattedDate}</div>
            <div className="font-PoppinsLight">{formattedTime}</div>
          </div>
        );
      },
    },
    {
      key: "investmentTitle",
      title: "Title",
      dataIndex: "investmentTitle",
    },
    {
      key: "investmentUnits",
      title: "Units",
      dataIndex: "investmentUnits",
    },
    {
      key: "numberOfUnits",
      title: "Units Purchased",
      dataIndex: "numberOfUnits",
    },
    {
      key: "maturityDate",
      title: "Maturity Date",
      dataIndex: "maturityDate",
      render: (dateTime: string) => {
        const date = new Date(dateTime);
        const formattedDate = format(date, "MMM dd, yyyy");
        const formattedTime = format(date, "hh:mm a");
        return (
          <div className="text-textcolor">
            <div className="font-PoppinsRegular">{formattedDate}</div>
            <div className="font-PoppinsLight">{formattedTime}</div>
          </div>
        );
      },
    },

    {
      key: "amount",
      title: "Amount",
      dataIndex: "amount",
      render: (amount: number) => formatCurrency(amount),
    },
    {
      key: "roiExpected",
      title: "ROI",
      dataIndex: "roiExpected",
      render: (roiExpected: number) => formatCurrency(roiExpected),
    },
    {
      key: "totalPayable",
      title: "Total Payable",
      dataIndex: "totalPayable",
      render: (totalPayable: number) => formatCurrency(totalPayable),
    },
  ];

  const handleNavigateToAllCustomers = () => {
    navigate("/admin/allCustomers");
  };

  const handleImageClick = (initialValues: any) => {
    setModalImage(initialValues);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalImage("");
  };

  // KYC APPROVAL
  const handleApproval = async (val: string) => {
    setLoading(true);
    try {
      const userDataRes = await HttpClient.get(
        `/admin/view-user/${customerId}`,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        }
      );

      const userData = userDataRes.data?.data as OneUserAccount;
      const kycDetail = userData.kycDetails;
      const kycID = kycDetail?._id;

      if (!kycID) {
        throw new Error("No KYC available for approval.");
      }

      const approveRes = await HttpClient.patch(
        `/admin/kyc-approval/${kycID}/${val}`,
        {},
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );

      setKycStatus(approveRes.data.data.kyc);
      ToastComponent.success(approveRes.data.message);
      await fetchData();
    } catch (error: any) {
      if (error.message === "No KYC available for approval.") {
        ToastComponent.error(error.message);
      } else {
        ErrorHandler(error);
      }
    } finally {
      setLoading(false);
    }
  };

  //  KYC REJECTION
  const handleRejection = async (val: string) => {
    setLoading(true);
    try {
      const userDataRes = await HttpClient.get(
        `/admin/view-user/${customerId}`,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );

      const kycDetails = userDataRes.data.data.kyc;
      const kycID = kycDetails?._id;

      if (!kycID) {
        throw new Error("No KYC available for rejection.");
      }

      const approveRes = await HttpClient.patch(
        `/admin/kyc-rejection/${kycID}/${val}`,
        { comment: comment },
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        }
      );

      setKycStatus(approveRes.data.data.kyc);
      ToastComponent.success(approveRes.data.message);
      await fetchData();
    } catch (error: any) {
      if (error.message === "No KYC available for rejection.") {
        ToastComponent.error(error.message);
      } else {
        ErrorHandler(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleRejectReasonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setComment(e.target.value);
  };

  const fetchAdminsAndGenerateOTP = async () => {
    try {
      const adminEmail = localStorage.getItem("adminEmail");
      const adminRole = localStorage.getItem("adminRole");

      if (!adminRole || adminRole !== "admin") {
        throw new Error("Only admins can generate OTPs.");
      }

      if (!adminEmail) {
        throw new Error("Admin email is missing.");
      }

      const otpResponse = await HttpClient.post("/users/generate-token", {
        email: adminEmail,
      });

      ToastComponent.success(
        otpResponse.data.message || "OTP sent successfully!"
      );
    } catch (error: any) {
      ErrorHandler(error);
    }
  };

  const handleSubmit = async (
    values: any,
    {
      setFieldValue,
    }: {
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean
      ) => void;
    }
  ) => {
    try {
      const formData = new FormData();
      formData.append("fundingAction", values.fundingAction);
      formData.append("amount", values.amount);
      formData.append("otp", values.otp);
      formData.append("accountType", values.accountType);
      formData.append("userId", values.userId);
      formData.append("pin", values.pin);

      const creditDebitRes = await HttpClient.put(
        "/admin/credit-debit-user",
        formData,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      if (creditDebitRes) {
        ToastComponent.success(
          creditDebitRes.data.message || "Operation Successful"
        );
        await fetchData();
      } else {
        ToastComponent.error("Operation Failed");
      }
    } catch (error: any) {
      ErrorHandler(error);
    }
  };

  const pagination = {
    pageSize: 5,
    total: 0,
  };

  const totalInvestment =
    (accountData?.userAccountDetails?.investments || 0) +
    (accountData?.userAccountDetails?.project_investments || 0) +
    (accountData?.userAccountDetails?.kids_investments || 0) +
    (accountData?.userAccountDetails?.regular_investments || 0) +
    (accountData?.userAccountDetails?.flexible_investments || 0);
  return (
    <>
      <PageTitle
        title={`Affluence - ${accountData?.firstName} ${accountData?.lastName}`}
      />
      <div className="flex h-screen dark:bg-darkPrimary">
        <Sidebar />
        <div className="flex-1 m-4 overflow-y-auto dark:bg-darkPrimary">
          {loading ? (
            <div className="w-full h-screen bg-white dark:bg-darkPrimary flex items-center justify-center">
              <ScaleLoader color={isDarkMode ? "#FFFFFF" : "#6B006B"} />
            </div>
          ) : (
            <>
              <div className="flex justify-end mt-10">
                <button
                  className={`py-2 px-4 rounded-md align-middle border text-primary border-primary dark:text-white dark:border-white`}
                  onClick={handleNavigateToAllCustomers}
                >
                  <FaRegEye size={20} className="inline mr-2" />
                  View all customers
                </button>
              </div>
              <div className="flex items-center justify-between mt-8">
                <span className="text-bold text-textcolor font-PoppinsBold dark:text-white">
                  {accountData?.firstName} {accountData?.lastName}
                </span>
                <ProfilePicture
                  profilePics={accountData?.photo}
                  firstName={accountData?.firstName}
                  lastName={accountData?.lastName}
                />
              </div>

              <div className="mt-8">
                {/* Wrapper for all cards */}
                <div className="grid lg:grid-cols-3 gap-4 lg:gap-8 overflow-x-auto whitespace-nowrap md:grid-cols-2 sm:grid-cols-3 grid-cols-1 sm:overflow-visible">
                  {/* Wallet Balance Card */}
                  <div className="bg-primary bg-opacity-5 p-5 rounded-[8px] border border-primary">
                    <div className="bg-primary text-white p-5 h-[120px] block rounded-[8px]">
                      <div className="flex items-center gap-2">
                        <MdOutlineAccountBalanceWallet size={25} />
                        <div>
                          <h1 className="text-[12px] font-PoppinsMedium">
                            Wallet Balance
                          </h1>
                          <h1 className="text-[25px] font-PoppinsSemiBold">
                            {accountData?.userAccountDetails?.balance
                              ? formatCurrency(
                                  accountData.userAccountDetails?.balance
                                )
                              : "₦0"}
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Total Investment Card */}
                  <div className="bg-investment bg-opacity-5 p-5 rounded-[8px] border border-investment">
                    <div className="bg-investment text-white p-5 h-[120px] block rounded-[8px]">
                      <div className="flex items-center gap-2">
                        <AiOutlineBank size={25} />
                        <div>
                          <h1 className="text-[12px] font-PoppinsMedium">
                            Total Investment
                          </h1>
                          <h1 className="text-[25px] font-PoppinsSemiBold">
                            {totalInvestment
                              ? formatCurrency(totalInvestment)
                              : "₦0"}
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Profit */}
                  <div className="bg-secondary bg-opacity-5 p-5 rounded-[8px] border border-secondary">
                    <div className="bg-secondary text-white p-5 h-[120px] block rounded-[8px]">
                      <div className="flex items-center gap-2">
                        <LiaPercentageSolid size={25} />
                        <div>
                          <h1 className="text-[12px] font-PoppinsMedium">
                            Total Profit
                          </h1>
                          <h1 className="text-[25px] font-PoppinsSemiBold">
                            {accountData?.userAccountDetails?.profit
                              ? formatCurrency(
                                  accountData.userAccountDetails.profit
                                )
                              : "₦0"}
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Total Deposit Card */}
                  <div className="bg-deposit bg-opacity-5 p-5 rounded-[8px] border border-deposit">
                    <div className="bg-deposit text-white p-5 h-[120px] block rounded-[8px]">
                      <div className="flex items-center gap-2">
                        <PiHandDepositBold size={25} />
                        <div>
                          <h1 className="text-[12px] font-PoppinsMedium">
                            Total Deposit
                          </h1>
                          <h1 className="text-[25px] font-PoppinsSemiBold">
                            {accountData?.userAccountDetails?.deposit
                              ? formatCurrency(
                                  accountData.userAccountDetails.deposit
                                )
                              : "₦0"}
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Total Withdrawal */}
                  <div className="bg-primary bg-opacity-5 p-5 rounded-[8px] border border-primary">
                    <div className="bg-primary text-white p-5 h-[120px] block rounded-[8px]">
                      <div className="flex items-center gap-2">
                        <PiHandWithdrawLight size={25} />
                        <div>
                          <h1 className="text-[12px] font-PoppinsMedium">
                            Total Withdrawal
                          </h1>
                          <h1 className="text-[25px] font-PoppinsSemiBold">
                            {accountData?.userAccountDetails?.withdrawal
                              ? formatCurrency(
                                  accountData.userAccountDetails.withdrawal
                                )
                              : "₦0"}
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Total Referral */}
                  <div className="bg-referral bg-opacity-5 p-5 rounded-[8px] border border-referral">
                    <div className="bg-referral text-white p-5 h-[120px] block rounded-[8px]">
                      <div className="flex items-center gap-2">
                        <GiTakeMyMoney size={25} />
                        <div>
                          <h1 className="text-[12px] font-PoppinsMedium">
                            Total Referral
                          </h1>
                          <h1 className="text-[25px] font-PoppinsSemiBold">
                            {accountData?.userAccountDetails?.referral
                              ? formatCurrency(
                                  accountData.userAccountDetails.referral
                                )
                              : "₦0"}
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Transaction History */}
              <OneUserTransact />

              {/* Bio Data and Wallet Fund */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-8">
                <div className="border border-transactionCard rounded-md p-5">
                  <span className="text-semibold text-textcolor font-PoppinsSemiBold block mb-4 dark:text-white">
                    Bio Data
                  </span>
                  <div className="mb-6">
                    <label
                      htmlFor="username"
                      className="block text-primary font-PoppinsLight text-[14px] mb-1 dark:text-white"
                    >
                      Username
                    </label>
                    <input
                      type="text"
                      name="username"
                      value={accountData?.username}
                      className="w-full h-12 text-textcolor border border-transactionCard font-PoppinsRegular px-2 py-1 rounded-lg focus:outline-none focus:border-primary"
                    />
                  </div>

                  <div className="mb-6">
                    <label
                      htmlFor="fullName"
                      className="dark:text-white block text-primary font-PoppinsLight text-[14px] mb-1"
                    >
                      Full Name
                    </label>
                    <input
                      type="text"
                      name="fullName"
                      value={
                        accountData?.firstName + " " + accountData?.lastName
                      }
                      className="w-full h-12 border border-transactionCard text-textcolor font-PoppinsRegular px-2 py-1 rounded-lg focus:outline-none focus:border-primary"
                    />
                  </div>

                  <div className="mb-6">
                    <label
                      htmlFor="email"
                      className="dark:text-white block text-primary font-PoppinsLight text-[14px] mb-1"
                    >
                      Email Address
                    </label>
                    <input
                      type="text"
                      name="email"
                      value={accountData?.email}
                      className="w-full h-12 border border-transactionCard font-PoppinsRegular text-textcolor px-2 py-1 rounded-lg focus:outline-none focus:border-primary"
                    />
                  </div>

                  <div className="mb-6">
                    <label
                      htmlFor="phone"
                      className="dark:text-white block text-primary font-PoppinsLight text-[14px] mb-1"
                    >
                      Phone Number
                    </label>
                    <input
                      type="tel"
                      name="phone"
                      value={accountData?.phone}
                      className="w-full h-12 border border-transactionCard font-PoppinsRegular text-textcolor px-2 py-1 rounded-lg focus:outline-none focus:border-primary"
                    />
                  </div>

                  <div className="mb-4 flex items-center gap-8">
                    <label
                      htmlFor="status"
                      className="dark:text-white block text-primary font-PoppinsLight text-[14px] mb-1"
                    >
                      Email Verification
                    </label>

                    <div className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        name="emailVerification"
                        checked={
                          accountData?.emailVerificationStatus === true
                            ? true
                            : false
                        }
                        className="w-6 h-6 bg-transactionCard border border-transactionCard rounded-lg checked:bg-success checked:border-success"
                      />
                      <span className=" text-textcolor font-PoppinsRegular dark:text-white">
                        Email verified
                      </span>
                    </div>
                  </div>
                </div>

                {/* Wallet Funding */}
                <div className="border border-transactionCard rounded-md p-5">
                  <span className="dark:text-white text-semibold text-textcolor font-PoppinsSemiBold block mb-4">
                    Wallet Funding
                  </span>
                  <Formik
                    initialValues={{
                      fundingAction: "credit",
                      amount: "",
                      otp: "",
                      accountType: "balance",
                      userId: userId,
                      pin: "",
                    }}
                    validationSchema={creditDebitSchema}
                    onSubmit={handleSubmit}
                  >
                    <Form className="w-full">
                      <div className="mb-4">
                        <label
                          htmlFor="amount"
                          className="dark:text-white block text-primary font-PoppinsLight text-[14px] mb-1"
                        >
                          Amount
                        </label>
                        <TextInput
                          type="number"
                          name="amount"
                          placeholder="₦0.00"
                          className="w-full h-12 text-textcolor border border-transactionCard font-PoppinsRegular px-2 rounded-lg focus:outline-none focus:border-primary"
                        />
                      </div>
                      <div className="mb-6">
                        <label
                          htmlFor="accountType"
                          className="dark:text-white block text-primary font-PoppinsLight text-[14px] mb-1"
                        >
                          Choose Funding Destination
                        </label>
                        <SelectInput
                          name="accountType"
                          options={[
                            { value: "balance", label: "Balance" },
                            { value: "profit", label: "Profit" },
                            { value: "referral", label: "Referral" },
                          ]}
                          placeholder="Select Funding Destination"
                        />
                      </div>

                      <div className="mb-6">
                        <label
                          htmlFor="fundingAction"
                          className="dark:text-white block text-primary font-PoppinsLight text-[14px] mb-1"
                        >
                          Choose Action
                        </label>
                        <SelectInput
                          name="fundingAction"
                          options={[
                            { value: "credit", label: "Credit" },
                            { value: "debit", label: "Debit" },
                          ]}
                          placeholder="Select Funding Action"
                        />
                      </div>

                      <div className="mb-6">
                        <Field type="hidden" name="userId" value={userId} />
                      </div>

                      <div className="mb-4">
                        <label
                          htmlFor="accountPin"
                          className="dark:text-white block text-primary font-PoppinsLight text-[14px] mb-1"
                        >
                          Transaction Pin
                        </label>
                        <PasswordInput
                          type="password"
                          name="pin"
                          placeholder="****"
                          className="w-full h-12 border border-transactionCard text-textcolor font-PoppinsRegular px-2 py-1 rounded-lg focus:outline-none focus:border-primary"
                        />
                      </div>

                      <div className="mb-6">
                        <label
                          htmlFor="otp"
                          className="dark:text-white block text-primary font-PoppinsLight text-[14px] mb-1"
                        >
                          Enter OTP
                        </label>
                        <TextInput
                          type="text"
                          name="otp"
                          placeholder="1W2E3RR"
                          className="dark:text-white w-full h-12 border border-transactionCard text-textcolor font-PoppinsRegular px-2 py-1 rounded-lg focus:outline-none focus:border-primary"
                        />

                        <button
                          onClick={fetchAdminsAndGenerateOTP}
                          type="button"
                          className="dark:text-white mb-5 font-PoppinsLight hover:text-primary text-textcolor text-[13px] underline"
                        >
                          Click to generate OTP
                        </button>
                      </div>

                      <button
                        type="submit"
                        className="py-2 px-6 font-PoppinsMedium rounded-lg text-success hover:before:bg-success border-success relative overflow-hidden border  bg-white transition-all before:absolute before:bottom-0 before:left-0 before:top-0 before:z-0 before:h-full before:w-0 before:bg-success before:transition-all before:duration-500 hover:text-white hover:before:left-0 hover:before:w-full md:w-40 lg:w-40 w-full"
                      >
                        <span className="relative z-10">Save</span>
                      </button>
                    </Form>
                  </Formik>
                </div>
              </div>

              {/* KYC 1 Verification */}
              <div className="border border-transactionCard rounded-md p-5 mt-8">
                <div className="flex flex-col md:flex-row gap-4">
                  <span className="dark:text-white text-semibold text-textcolor font-PoppinsSemiBold block mb-4">
                    KYC Level 1 Verification
                  </span>
                  <div>
                    <div
                      className={`text-center text-[0.8em] rounded-[30px] font-PoppinsMedium px-[0.9rem] py-[0.35rem] bg-opacity-[28%] capitalize ${
                        accountData?.kycDetails?.kycLevelOneStatus ===
                        "rejected"
                          ? "bg-error text-error"
                          : accountData?.kycDetails?.kycLevelOneStatus ===
                            "approved"
                          ? "bg-success text-success"
                          : accountData?.kycDetails?.kycLevelOneStatus ===
                            "pending"
                          ? "bg-secondary text-secondary"
                          : ""
                      }`}
                    >
                      {accountData?.kycDetails?.kycLevelOneStatus}
                    </div>
                  </div>
                </div>

                <div className="flex flex-col lg:flex-row justify-between gap-8 mt-4">
                  <div className="block w-full">
                    <div className="mb-6">
                      <label
                        htmlFor="homeAddress"
                        className="dark:text-white block text-primary font-PoppinsLight text-[14px] mb-1"
                      >
                        Home Address
                      </label>
                      <input
                        type="text"
                        name="homeAddress"
                        value={accountData?.kycDetails?.homeAddress || ""}
                        className="w-full md:w-[449px] h-12 text-textcolor border border-transactionCard font-PoppinsRegular px-2 py-1 rounded-lg focus:outline-none focus:border-primary"
                      />
                    </div>

                    <div className="mb-6">
                      <label
                        htmlFor="purposeOfInvestment"
                        className="dark:text-white block text-primary font-PoppinsLight text-[14px] mb-1"
                      >
                        Investment Purpose
                      </label>
                      <input
                        type="text"
                        name="purposeOfInvestment"
                        value={
                          accountData?.kycDetails?.purposeOfInvestment || ""
                        }
                        className="w-full md:w-[449px] h-12 text-textcolor border border-transactionCard font-PoppinsRegular px-2 py-1 rounded-lg focus:outline-none focus:border-primary"
                      />
                    </div>
                  </div>

                  <div className="w-full">
                    <div className="mb-6">
                      <label
                        htmlFor="comment"
                        className="dark:text-white block text-textcolor font-PoppinsLight text-[14px] mb-1"
                      >
                        Reason(s) for Rejection
                      </label>
                      <input
                        type="text"
                        name="comment"
                        onChange={handleRejectReasonChange}
                        className="w-full md:w-[449px] h-12 text-textcolor border border-transactionCard font-PoppinsRegular px-2 py-1 rounded-lg focus:outline-none focus:border-primary"
                      />
                    </div>

                    <div className="flex flex-col md:flex-row gap-4 mt-4">
                      <button
                        type="submit"
                        onClick={() => handleApproval("1")}
                        className="py-2 px-6 font-PoppinsMedium rounded-lg text-success hover:before:bg-success border-success relative overflow-hidden border  bg-white transition-all before:absolute before:bottom-0 before:left-0 before:top-0 before:z-0 before:h-full before:w-0 before:bg-success before:transition-all before:duration-500 hover:text-white hover:before:left-0 hover:before:w-full md:w-40 lg:w-40 w-full"
                      >
                        <span className="relative z-10">Approve</span>
                      </button>
                      <button
                        type="submit"
                        onClick={() => handleRejection("1")}
                        className="py-2 px-6 font-PoppinsMedium rounded-lg text-error hover:before:bg-error border-error relative overflow-hidden border  bg-white transition-all before:absolute before:bottom-0 before:left-0 before:top-0 before:z-0 before:h-full before:w-0 before:bg-error before:transition-all before:duration-500 hover:text-white hover:before:left-0 hover:before:w-full md:w-40 lg:w-40 w-full"
                      >
                        <span className="relative z-10">Reject</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* KYC 2 Verification */}
              <div className="border border-transactionCard rounded-md p-5 mt-8">
                <div className="flex flex-col md:flex-row gap-4">
                  <span className="dark:text-white text-semibold text-textcolor font-PoppinsSemiBold block mb-4">
                    KYC Level 2 Verification
                  </span>
                  <div>
                    <div
                      className={`text-center text-[0.8em] rounded-[30px] font-PoppinsMedium px-[0.9rem] py-[0.35rem] bg-opacity-[28%] capitalize ${
                        accountData?.kycDetails?.kycLevelTwoStatus ===
                        "rejected"
                          ? "bg-error text-error"
                          : accountData?.kycDetails?.kycLevelTwoStatus ===
                            "approved"
                          ? "bg-success text-success"
                          : accountData?.kycDetails?.kycLevelTwoStatus ===
                            "pending"
                          ? "bg-secondary text-secondary"
                          : ""
                      } `}
                    >
                      {accountData?.kycDetails?.kycLevelTwoStatus}
                    </div>
                  </div>
                </div>

                <div className="flex flex-col lg:flex-row justify-between gap-8 mt-4">
                  <div className="block w-full">
                    <div className="mb-6">
                      <label
                        htmlFor="nextOfKinFullName"
                        className="dark:text-white block text-primary font-PoppinsLight text-[14px] mb-1"
                      >
                        Name of Next-of-kin
                      </label>
                      <input
                        type="text"
                        name="nextOfKinFullName"
                        value={accountData?.kycDetails?.nextOfKinFullName || ""}
                        className="w-full md:w-[449px] h-12 text-textcolor border border-transactionCard font-PoppinsRegular px-2 py-1 rounded-lg focus:outline-none focus:border-primary"
                      />
                    </div>

                    <div className="mb-6">
                      <label
                        htmlFor="nextOfKinAddress"
                        className="dark:text-white block text-primary font-PoppinsLight text-[14px] mb-1"
                      >
                        Contact Address
                      </label>
                      <input
                        type="text"
                        name="nextOfKinAddress"
                        value={accountData?.kycDetails?.nextOfKinAddress || ""}
                        className="w-full md:w-[449px] h-12 text-textcolor border border-transactionCard font-PoppinsRegular px-2 py-1 rounded-lg focus:outline-none focus:border-primary"
                      />
                    </div>

                    <div className="mb-6">
                      <label
                        htmlFor="nextOfKinPhone"
                        className="dark:text-white block text-primary font-PoppinsLight text-[14px] mb-1"
                      >
                        Phone Number
                      </label>
                      <input
                        type="text"
                        name="nextOfKinPhone"
                        value={accountData?.kycDetails?.nextOfKinPhone || ""}
                        className="w-full md:w-[449px] h-12 text-textcolor border border-transactionCard font-PoppinsRegular px-2 py-1 rounded-lg focus:outline-none focus:border-primary"
                      />
                    </div>
                    <div className="mb-6">
                      <label
                        htmlFor="nextOfKinRelationship"
                        className="dark:text-white block text-primary font-PoppinsLight text-[14px] mb-1"
                      >
                        Relationship
                      </label>
                      <input
                        type="text"
                        name="nextOfKinRelationship"
                        value={
                          accountData?.kycDetails?.nextOfKinRelationship || ""
                        }
                        className="w-full md:w-[449px] h-12 text-textcolor border border-transactionCard font-PoppinsRegular px-2 py-1 rounded-lg focus:outline-none focus:border-primary"
                      />
                    </div>
                  </div>

                  <div className="block w-full">
                    <div className="mb-6">
                      <label
                        htmlFor="comment"
                        className="dark:text-white block text-textcolor font-PoppinsLight text-[14px] mb-1"
                      >
                        Reason(s) for Rejection
                      </label>
                      <input
                        type="text"
                        name="comment"
                        className="w-full md:w-[449px] h-12 text-textcolor border border-transactionCard font-PoppinsRegular px-2 py-1 rounded-lg focus:outline-none focus:border-primary"
                      />
                    </div>

                    <div className="flex flex-col md:flex-row gap-4 mt-4">
                      <button
                        type="submit"
                        onClick={() => handleApproval("2")}
                        className="py-2 px-6 font-PoppinsMedium rounded-lg text-success hover:before:bg-success border-success relative overflow-hidden border  bg-white transition-all before:absolute before:bottom-0 before:left-0 before:top-0 before:z-0 before:h-full before:w-0 before:bg-success before:transition-all before:duration-500 hover:text-white hover:before:left-0 hover:before:w-full md:w-40 lg:w-40 w-full"
                      >
                        <span className="relative z-10">Approve</span>
                      </button>
                      <button
                        type="submit"
                        onClick={() => handleRejection("2")}
                        className="py-2 px-6 font-PoppinsMedium rounded-lg text-error hover:before:bg-error border-error relative overflow-hidden border  bg-white transition-all before:absolute before:bottom-0 before:left-0 before:top-0 before:z-0 before:h-full before:w-0 before:bg-error before:transition-all before:duration-500 hover:text-white hover:before:left-0 hover:before:w-full md:w-40 lg:w-40 w-full"
                      >
                        <span className="relative z-10">Reject</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* KYC 3 Verification */}
              <div className="border border-transactionCard rounded-md p-5 mt-8">
                <div className="flex flex-col md:flex-row gap-4 mb-4">
                  <span className="dark:text-white text-semibold text-textcolor font-PoppinsSemiBold">
                    KYC Level 3 Verification
                  </span>
                  <div>
                    <div
                      className={`text-center text-[0.8em] rounded-[30px] font-PoppinsMedium px-[0.9rem] py-[0.35rem] bg-opacity-[28%] capitalize ${
                        accountData?.kycDetails?.kycLevelThreeStatus ===
                        "rejected"
                          ? "bg-error text-error"
                          : accountData?.kycDetails?.kycLevelThreeStatus ===
                            "approved"
                          ? "bg-success text-success"
                          : accountData?.kycDetails?.kycLevelThreeStatus ===
                            "pending"
                          ? "bg-secondary text-secondary"
                          : ""
                      } `}
                    >
                      {accountData?.kycDetails?.kycLevelThreeStatus}
                    </div>
                  </div>
                </div>

                <div className="flex flex-col lg:flex-row gap-6 justify-between">
                  <div className="block w-full">
                    <div className="flex flex-col md:w-[449px] gap-4">
                      {/* ID Card */}
                      <div className="block">
                        <span className="dark:text-white block text-primary font-PoppinsLight text-sm mb-1">
                          ID Card -{" "}
                          <span className="text-primary dark:text-white font-PoppinsRegular text-sm">
                            {accountData?.kycDetails?.identificationType ||
                              "Not available"}
                          </span>
                        </span>
                        <div className="h-[200px] border border-transactionCard rounded-lg flex items-center justify-center cursor-pointer">
                          {accountData?.kycDetails?.uploadSelectedId ? (
                            <img
                              // src={`${process.env.REACT_APP_FILES_URL}/${accountData?.kycDetails?.uploadSelectedId}`}
                              src={accountData?.kycDetails?.uploadSelectedId}
                              alt="ID card"
                              className="dark:text-white w-full h-full object-cover p-2 rounded-lg items-center justify-center"
                              onClick={() =>
                                handleImageClick(
                                  accountData?.kycDetails?.uploadSelectedId
                                )
                              }
                            />
                          ) : (
                            <div className="dark:text-white flex text-textcolor justify-center items-center">
                              <CiImageOff size={20} />
                              <p>ID card Not Available</p>
                            </div>
                          )}
                        </div>
                      </div>

                      {/* Proof of Address */}
                      <div className="block">
                        <span className="dark:text-white block text-primary font-PoppinsLight text-sm mb-1">
                          Proof of Address
                        </span>
                        <div className="h-[200px] border border-transactionCard rounded-lg flex items-center justify-center cursor-pointer">
                          {accountData?.kycDetails?.uploadProofOfAddress ? (
                            <img
                              // src={`${process.env.REACT_APP_FILES_URL}/${accountData?.kycDetails?.uploadProofOfAddress}`}
                              src={accountData?.kycDetails?.uploadProofOfAddress}
                              alt="Proof of Address"
                              className="dark:text-white w-full h-full object-cover p-2 rounded-lg"
                              onClick={() =>
                                handleImageClick(
                                  accountData?.kycDetails?.uploadProofOfAddress
                                )
                              }
                            />
                          ) : (
                            <div className="dark:text-white flex p-2 justify-center items-center text-textcolor">
                              <CiImageOff size={20} />
                              <p>Proof of Address Not Available</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="block w-full">
                    <div className="flex flex-col w-full md:w-2/3 gap-6">
                      <div className="mb-4">
                        <label
                          htmlFor="identificationType"
                          className="dark:text-white block text-primary font-PoppinsLight text-[14px] mb-1"
                        >
                          Identification Type
                        </label>
                        <input
                          type="text"
                          name="identificationType"
                          value={
                            accountData?.kycDetails?.identificationType || ""
                          }
                          className="w-full md:w-[449px] h-12 text-textcolor border border-transactionCard font-PoppinsRegular px-2 py-1 rounded-lg focus:outline-none focus:border-primary"
                        />
                      </div>

                      <div className="mb-4">
                        <label
                          htmlFor="identificationNumber"
                          className="dark:text-white block text-primary font-PoppinsLight text-[14px] mb-1"
                        >
                          Identification Number
                        </label>
                        <input
                          type="text"
                          name="identificationNumber"
                          value={
                            accountData?.kycDetails?.identificationNumber || ""
                          }
                          className="w-full md:w-[449px] h-12 text-textcolor border border-transactionCard font-PoppinsRegular px-2 py-1 rounded-lg focus:outline-none focus:border-primary"
                        />
                      </div>

                      <div className="block w-full">
                        <div className="mb-4">
                          <label
                            htmlFor="comment"
                            className="dark:text-white block text-textcolor font-PoppinsLight text-[14px] mb-1"
                          >
                            Reason(s) for Rejection
                          </label>
                          <input
                            type="text"
                            name="comment"
                            className="w-full md:w-[449px] h-12 text-textcolor border border-transactionCard font-PoppinsRegular px-2 py-1 rounded-lg focus:outline-none focus:border-primary"
                          />
                        </div>

                        <div className="flex flex-col md:flex-row gap-4 mt-4">
                          <button
                            type="submit"
                            onClick={() => handleApproval("3")}
                            className="py-2 px-6 font-PoppinsMedium rounded-lg text-success hover:before:bg-success border-success relative overflow-hidden border  bg-white transition-all before:absolute before:bottom-0 before:left-0 before:top-0 before:z-0 before:h-full before:w-0 before:bg-success before:transition-all before:duration-500 hover:text-white hover:before:left-0 hover:before:w-full md:w-40 lg:w-40 w-full"
                          >
                            <span className="relative z-10">Approve</span>
                          </button>
                          <button
                            type="submit"
                            onClick={() => handleRejection("3")}
                            className="py-2 px-6 font-PoppinsMedium rounded-lg text-error hover:before:bg-error border-error relative overflow-hidden border  bg-white transition-all before:absolute before:bottom-0 before:left-0 before:top-0 before:z-0 before:h-full before:w-0 before:bg-error before:transition-all before:duration-500 hover:text-white hover:before:left-0 hover:before:w-full md:w-40 lg:w-40 w-full"
                          >
                            <span className="relative z-10">Reject</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Approve All and Reject All Buttons */}
              <div className="flex gap-4 mt-8 items-center justify-center lg:justify-start">
                <button
                  type="submit"
                  className="py-2 px-4  bg-success text-white  font-PoppinsMedium rounded-md relative border hover:before:border-success hover:before:bg-white overflow-hidden transition-all before:absolute before:bottom-0 before:left-0 before:top-0 before:z-0 before:h-full before:w-0 before:bg-white before:transition-all before:duration-500 hover:text-success hover:before:left-0 hover:before:w-full"
                  onClick={() => handleApproval("all")}
                >
                  <span className="relative z-10">Approve All KYC</span>
                </button>
                <button
                  type="reset"
                  className="py-2 px-6 bg-error text-white font-PoppinsMedium rounded-md relative border hover:before:border-error hover:before:bg-white overflow-hidden transition-all before:absolute before:bottom-0 before:left-0 before:top-0 before:z-0 before:h-full before:w-0 before:bg-white before:transition-all before:duration-500 hover:text-error hover:before:left-0 hover:before:w-full"
                  onClick={() => handleRejection("all")}
                >
                  <span className="relative z-10">Reject All KYC</span>
                </button>
              </div>

              {/* Transaction History */}
              <div className="border border-transactionCard rounded-md p-5 mt-8">
                <span className="dark:text-white text-semibold text-textcolor font-PoppinsSemiBold block mb-4">
                  Transaction History
                </span>
                {transactHistory && transactHistory.length > 0 ? (
                  <div className="dark:bg-white overflow-x-auto">
                    <Table
                      dataSource={transactHistory}
                      columns={transactionColumns}
                      pagination={pagination}
                      loading={loading}
                    />
                  </div>
                ) : (
                  <div className="flex items-center justify-center py-5">
                    <div>
                      <div className="flex items-center justify-center">
                        <img
                          src={NoTransaction}
                          className="w-[200px]"
                          alt="illustrator"
                        />
                      </div>
                      <h1 className="dark:text-white font-PoppinsBold pb-3 text-[20px] text-primary">
                        No Transaction History currently!
                      </h1>
                    </div>
                  </div>
                )}
              </div>

              {/* Investment History */}
              <div className="border border-transactionCard rounded-md p-5 mt-8">
                <span className="dark:text-white text-semibold text-textcolor font-PoppinsSemiBold block mb-4">
                  Investment History
                </span>
                {investHistory && investHistory.length > 0 ? (
                  <div className="dark:bg-white overflow-x-auto">
                    <Table
                      dataSource={investHistory}
                      columns={investColumns}
                      pagination={pagination}
                      loading={loading}
                    />
                  </div>
                ) : (
                  <div className="flex items-center justify-center py-5">
                    <div>
                      <div className="flex items-center justify-center">
                        <img
                          src={NoTransaction}
                          className="w-[200px]"
                          alt="illustrator"
                        />
                      </div>
                      <h1 className="dark:text-white font-PoppinsBold pb-3 text-[20px] text-primary">
                        No Investment History currently!
                      </h1>
                    </div>
                  </div>
                )}
              </div>

              {/* Audit History */}
              <div className="dark:text-white border border-transactionCard rounded-md p-5 mt-8">
                <span className="dark:text-white text-semibold text-textcolor font-PoppinsSemiBold block mb-4">
                  Audit History
                </span>
                {auditHistory && auditHistory.length > 0 ? (
                  <div className="dark:bg-white overflow-x-auto">
                    <Table
                      dataSource={auditHistory}
                      columns={auditColumns}
                      loading={loading}
                      pagination={pagination}
                    />
                  </div>
                ) : (
                  <div className="flex items-center justify-center py-5">
                    <div>
                      <div className="flex items-center justify-center">
                        <img
                          src={NoTransaction}
                          className="w-[200px]"
                          alt="illustrator"
                        />
                      </div>
                      <h1 className="dark:text-white font-PoppinsBold pb-3 text-[20px] text-primary">
                        No Audit History currently!
                      </h1>
                    </div>
                  </div>
                )}
              </div>

              {/* Modal for image preview */}
              <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                className="fixed inset-0 bg-[black] bg-opacity-[50%] flex items-center justify-center"
                overlayClassName="fixed inset-0 bg-black bg-opacity-50"
                ariaHideApp={false}
              >
                <div
                  className="p-4 rounded-lg flex items-center justify-center"
                  onClick={closeModal}
                >
                  <img
                    src={modalImage}
                    alt="Preview"
                    className="w-[50%] h-[50%] object-contain rounded-lg cursor-pointer"
                  />
                </div>
              </Modal>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default OneCustomer;
