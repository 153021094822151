import { useCallback, useEffect, useState } from "react";
import PageTitle from "../../components/Pagetitle";
import Sidebar from "../sidebar/Sidebar";
import AccountSettingsCard from "../../components/AccountSettingsCard";
import {
  MdOutlineDarkMode,
  MdOutlineLightMode,
  MdOutlinePercent,
} from "react-icons/md";
import { IoIosArrowForward, IoMdAdd } from "react-icons/io";
import Modal from "../../components/Modal";
import { AiOutlineUser } from "react-icons/ai";
import { ConfigProvider, Switch, Table } from "antd";
import { HttpClient } from "../../api/HttpClient";
import { isAxiosError } from "axios";
import ToastComponent from "../../components/ToastComponent";
import { FiPhone, FiShare2 } from "react-icons/fi";
import { FaQuestion } from "react-icons/fa6";
import SetAccountSettingsCard from "../../components/SetAccountSettingsCard";
import { RiLockPasswordLine } from "react-icons/ri";
import { FaRegQuestionCircle } from "react-icons/fa";
import { TbPasswordMobilePhone } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { toggleTheme } from "../../store/themeSlice";
import { MoonFilled, SunOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import PasswordInput from "../../components/PasswordInput";
import AuthButton from "../../components/AuthButton";
import { MoonLoader } from "react-spinners";
import TextInputWithLabel from "../../components/NumberInputWithLabel";
import errorHandler from "../ErrorHandler";
import { ColumnsType } from "antd/es/table";
import SModal from "../Modal";

export type Account = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  username: string;
  roles: string[];
  emailVerificationStatus: boolean;
  account: {
    balance: number;
    investments: number;
    profit: number;
    referral: number;
    savings: number;
    deposit: number;
    withdrawal: number;
    user: string;
  };
  photo: string;
  hideBalance: boolean;
};

interface FAQ {
  _id: string;
  key: string;
  question: string;
  answer: string;
}

function Configuration() {
  const [accountData, setAccountData] = useState<Account | null>(null);
  const [isOpenTodayRate, setIsOpenTodayRate] = useState(false);
  const [isOpenAccountSettings, setIsOpenAccountSettings] = useState(false);
  const [isOpenChangePassword, setIsOpenChangePassword] = useState(false);
  const [isOpenChangePin, SetIsOpenChangePin] = useState(false);
  const [isOpenSecurityQuestion, setIsOpenSecurityQuestion] = useState(false);
  const [isOpenFaq, setIsOpenFaq] = useState(false);
  const [isOpenContactUs, setIsOpenContactUs] = useState(false);
  const [changing, setChanging] = useState(false);
  const [setting, setSetting] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);
  const [isOpenAddFaq, setIsOpenAddFaq] = useState(false);
  const [selectedFaq, setSelectedFaq] = useState<FAQ | null>(null);
  const [faqList, setFaqList] = useState<FAQ[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);

  const changePasswordSchema = Yup.object().shape({
    password: Yup.string().required("Enter your password"),
    newPassword: Yup.string().required("Enter your new password"),
    confirmNewPassword: Yup.string()
      .required("Enter your confirmation password")
      .test(
        "matches",
        "New Passwords do not match",
        function (confirmNewPassword) {
          const { newPassword } = this.parent;
          return confirmNewPassword === newPassword;
        }
      ),
  });
  const setPinSchema = Yup.object().shape({
    accountPin: Yup.string().required("Enter transaction pin"),
    accountPassword: Yup.string().required("Enter your password"),
    otp: Yup.string().required("Enter otp"),
  });
  const setSecurityQuestionSchema = Yup.object().shape({
    question: Yup.string().required("Enter Security Question"),
    answer: Yup.string().required("Enter answer"),
  });

  const handleChangePassword = useCallback(
    async (values: any) => {
      setChanging(true);
      try {
        const changePasswordRes = await HttpClient.post(
          "/users/update-password",
          values,
          {
            headers: {
              "x-auth-token": localStorage.getItem("token"),
            },
          }
        );
        ToastComponent.success(changePasswordRes.data.message);
        navigate("/login");
        localStorage.removeItem("token");
      } catch (error: unknown) {
        if (isAxiosError(error)) {
          ToastComponent.error(error.response?.data.message);
        } else if (error instanceof Error) {
          ToastComponent.error(error.message);
        } else {
          ToastComponent.error("An unknown error occurred");
        }
      } finally {
        setChanging(false);
      }
    },
    [navigate]
  );

  const handleUpdateAccountPin = async (
    values: any,
    {
      setFieldValue,
    }: {
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean
      ) => void;
    }
  ) => {
    try {
      const res = await HttpClient.post("/users/update-account-pin", values, {
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      });
      ToastComponent.success(res.data.message);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      } else if (error instanceof Error) {
        ToastComponent.error(error.message);
      } else {
        ToastComponent.error("An unknown error occurred");
      }
      setFieldValue("accountPassword", "", false);
      setFieldValue("otp", "", false);
    } finally {
      // setLoading(false);
    }
  };

  const fetchAdminsAndGenerateOTP = async () => {
    try {
      const adminEmail = localStorage.getItem("adminEmail");
      const adminRole = localStorage.getItem("adminRole");

      if (!adminRole || adminRole !== "admin") {
        throw new Error("Only admins can generate OTPs.");
      }

      if (!adminEmail) {
        throw new Error("Admin email is missing.");
      }

      const otpResponse = await HttpClient.post("/users/generate-token", {
        email: adminEmail,
      });

      ToastComponent.success(
        otpResponse.data.message || "OTP sent successfully!"
      );
    } catch (error: any) {
      errorHandler(error);
    }
  };

  const handleFaq = async (
    values: any,
    {
      setFieldValue,
    }: {
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean
      ) => void;
    }
  ) => {
    setSetting(true);
    try {
      const res = await HttpClient.post("/admin/faq", values, {
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      });
      ToastComponent.success(res.data.message);
      setIsOpenSecurityQuestion(false);
      setIsOpenAccountSettings(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      } else if (error instanceof Error) {
        ToastComponent.error(error.message);
      } else {
        ToastComponent.error("An unknown error occurred");
      }
    } finally {
      setSetting(false);
    }
  };

  const fetchFaq = async () => {
    try {
      const faqRes = await HttpClient.get("/admin/faq", {
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      });
      const Faq = faqRes.data.data.map((item: any, index: number) => ({
        key: item._id,
        serialNumber: index + 1,
        question: item.question,
        answer: item.answer,
      }));
      setFaqList(Faq);
    } catch (error: any) {
      errorHandler(error);
    }
  };
  useEffect(() => {
    fetchFaq();
  }, []);

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedFaq(null);
  };

  const showDeleteModal = (faq: FAQ) => {
    setSelectedFaq(faq);
    setIsModalVisible(true);
  };

  const handleFaqDelete = async () => {
    try {
      const faqId = selectedFaq?.key;
      const deleteFaqRes = await HttpClient.delete(`/admin/faq/${faqId}`, {
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      });
      ToastComponent.success(
        deleteFaqRes.data.message || "FAQ deleted successfully"
      );
      setIsModalVisible(false);
      fetchFaq();
    } catch (error: any) {
      errorHandler(error);
    }
  };

  const columns: ColumnsType<FAQ> = [
    {
      key: "serialNumber",
      title: "S/N",
      dataIndex: "serialNumber",
    },
    {
      key: "question",
      title: "Question",
      dataIndex: "question",
    },
    {
      key: "answer",
      title: "Answer",
      dataIndex: "answer",
    },
    {
      key: "actions",
      title: "Action",
      render: (_, record: FAQ) => (
        <div className="flex space-x-2" key={record._id}>
          <button
            className="bg-error text-white px-3 py-1 rounded-md"
            onClick={() => showDeleteModal(record)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  const handleToggle = (checked: boolean) => {
    dispatch(toggleTheme());
  };

  return (
    <>
      <PageTitle title="Affluence - Admin: Configuration" />
      <div className="flex h-screen dark:bg-darkPrimary">
        <Sidebar />
        <div className="flex-1">
          <h1 className="dark:text-white mt-14 p-4 md:text-[24px] text-[20px] font-PoppinsBold text-textcolor">
            Configuration
          </h1>
          <div className="dark:text-white p-4 w-full lg:w-[70%] block gap-2 pt-7">
            <AccountSettingsCard
              icon1={MdOutlinePercent}
              icon2={IoIosArrowForward}
              iconClass1=""
              onClick={() => setIsOpenTodayRate(true)}
            >
              Today's Rate
            </AccountSettingsCard>
            <AccountSettingsCard
              icon1={AiOutlineUser}
              icon2={IoIosArrowForward}
              iconClass1=""
              onClick={() => setIsOpenAccountSettings(true)}
            >
              My Account Settings
            </AccountSettingsCard>
            <AccountSettingsCard
              icon1={FiShare2}
              icon2={IoIosArrowForward}
              iconClass1=""
            >
              Refer & Earn
            </AccountSettingsCard>
            <div
              className="p-4 rounded-[4px] mb-2 flex items-center justify-between border-[1.5px] border-transactionCard
            "
              //   onClick={onClick}
            >
              <div className="flex items-center space-x-5">
                <div className="md:text-[22px] sm:text-[20px] text-primary dark:text-white p-2 rounded-full bg-primary dark:bg-white dark:bg-opacity-25 bg-opacity-25 text-[18px]">
                  {isDarkMode ? <MdOutlineLightMode /> : <MdOutlineDarkMode />}
                </div>
                <h1 className="font-PoppinsRegular text-[14px]">
                  {isDarkMode ? "Disable Dark Mode" : "Enable Dark Mode"}
                </h1>
              </div>
              <div className="md:text-[22px] sm:text-[20px] text-[18px]">
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: "#6B006B",
                    },
                  }}
                >
                  <Switch
                    checked={isDarkMode}
                    onChange={handleToggle}
                    checkedChildren={<MoonFilled size={20} />}
                    unCheckedChildren={<SunOutlined size={20} />}
                  />
                </ConfigProvider>
              </div>
            </div>
            <AccountSettingsCard
              icon1={FaQuestion}
              icon2={IoIosArrowForward}
              iconClass1=""
              onClick={() => setIsOpenFaq(true)}
            >
              FAQ
            </AccountSettingsCard>
            <AccountSettingsCard
              icon1={FiPhone}
              icon2={IoIosArrowForward}
              iconClass1=""
              onClick={() => setIsOpenContactUs(true)}
            >
              Contact Us
            </AccountSettingsCard>
          </div>
          {isOpenTodayRate && (
            <Modal
              isOpenModal={() => setIsOpenTodayRate(true)}
              closeModal={() => setIsOpenTodayRate(false)}
            >
              <div className="dark:text-white text-textcolor px-4 pt-[120px]">
                <h1 className="text-[20px] font-PoppinsBold">
                  Set Today's Rate
                </h1>
                <h1 className="dark:text-white text-textcolor text-[11px] font-PoppinsRegular">
                  Kindly set the investment and the rate associated below.
                </h1>
              </div>
            </Modal>
          )}
          {isOpenAccountSettings && (
            <Modal
              isOpenModal={() => setIsOpenAccountSettings(true)}
              closeModal={() => setIsOpenAccountSettings(false)}
            >
              <div className="dark:text-white text-textcolor px-4 pt-[120px]">
                <h1 className="text-[20px] font-PoppinsBold">
                  Account Settings
                </h1>
                <div className="mt-10">
                  <SetAccountSettingsCard
                    onClick={() => setIsOpenChangePassword(true)}
                    icon1={RiLockPasswordLine}
                  >
                    Change Password
                  </SetAccountSettingsCard>
                  <SetAccountSettingsCard
                    onClick={() => SetIsOpenChangePin(true)}
                    icon1={TbPasswordMobilePhone}
                  >
                    Change Pin
                  </SetAccountSettingsCard>
                  <SetAccountSettingsCard
                    onClick={() => setIsOpenSecurityQuestion(true)}
                    icon1={FaRegQuestionCircle}
                  >
                    Set Security Questions
                  </SetAccountSettingsCard>
                </div>
              </div>
            </Modal>
          )}
          {isOpenChangePassword && (
            <Modal
              isOpenModal={() => setIsOpenChangePassword(true)}
              closeModal={() => setIsOpenChangePassword(false)}
            >
              <div className="dark:text-white text-textcolor px-4 pt-[120px]">
                <h1 className="dark:text-white text-[20px] font-PoppinsBold">
                  Change Password
                </h1>
                <div>
                  <div className="py-7 text-referral text-[12px] font-PoppinsRegular">
                    Please be informed that you will be logged out. Log back in
                    with your{" "}
                    <span className="dark:text-white uppercase font-PoppinsSemiBold">
                      new password.
                    </span>
                  </div>
                  <Formik
                    initialValues={{
                      password: "",
                      newPassword: "",
                      confirmNewPassword: "",
                    }}
                    validationSchema={changePasswordSchema}
                    onSubmit={handleChangePassword}
                  >
                    {({ isValid, dirty }) => (
                      <Form>
                        <PasswordInput
                          name="password"
                          type="password"
                          label="Current Password"
                          updatePass="top-[35px] right-[13px]"
                        />
                        <PasswordInput
                          name="newPassword"
                          type="password"
                          label="New Password"
                          updatePass="top-[35px] right-[13px]"
                        />
                        <PasswordInput
                          name="confirmNewPassword"
                          type="password"
                          label="Confirm New Password"
                          updatePass="top-[35px] right-[13px]"
                        />
                        <AuthButton
                          isLoading={changing}
                          disabled={!isValid || !dirty}
                        >
                          Update Password
                        </AuthButton>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </Modal>
          )}
          {isOpenChangePin && (
            <Modal
              isOpenModal={() => SetIsOpenChangePin(true)}
              closeModal={() => SetIsOpenChangePin(false)}
            >
              <div className="dark:text-white px-4 pt-[120px]">
                <div className="flex items-center justify-between pb-[60px]">
                  <h1 className="text-[20px] font-PoppinsBold">
                    Set Transaction Pin
                  </h1>
                </div>
                <div>
                  <Formik
                    initialValues={{
                      accountPin: "",
                      accountPassword: "",
                      otp: "",
                    }}
                    validationSchema={setPinSchema}
                    onSubmit={handleUpdateAccountPin}
                  >
                    {({ isValid, dirty }) => (
                      <Form>
                        <PasswordInput
                          name="accountPin"
                          type="password"
                          placeholder="4-digit pin"
                          label="Enter Transaction Pin"
                          updatePass="top-[35px] right-[13px]"
                        />
                        <PasswordInput
                          name="accountPassword"
                          type="password"
                          label="Enter Account Password"
                          updatePass="top-[35px] right-[13px]"
                        />
                        <PasswordInput
                          name="otp"
                          type="password"
                          label="Enter OTP"
                          updatePass="top-[35px] right-[13px]"
                        />
                        <button
                          onClick={fetchAdminsAndGenerateOTP}
                          type="button"
                          className="mb-5 text-[13px] underline"
                        >
                          Click to generate OTP
                        </button>
                        <AuthButton
                          isLoading={changing}
                          disabled={!isValid || !dirty}
                        >
                          Set Pin
                        </AuthButton>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </Modal>
          )}
          {isOpenFaq && (
            <Modal
              isOpenModal={() => setIsOpenFaq(true)}
              closeModal={() => setIsOpenFaq(false)}
            >
              <div className="dark:text-white text-textcolor px-4 pt-[120px]">
                <h1 className="text-[20px] font-PoppinsBold">
                  Frequently Asked Questions
                </h1>
                <div className="mt-10">
                  <div className="flex md:justify-end">
                    <button
                      className="dark:border-white dark:text-white mb-4 w-full md:w-auto py-2 px-4 rounded-md align-middle text-primary border border-primary"
                      onClick={() => setIsOpenAddFaq(true)}
                    >
                      <IoMdAdd
                        className="inline mr-2 dark:text-white text-primary"
                        size={20}
                      />
                      Set FAQ
                    </button>
                  </div>
                  <div className="dark:bg-white overflow-x-auto">
                    <Table dataSource={faqList} columns={columns} />
                  </div>
                </div>
              </div>
            </Modal>
          )}
          {isOpenAddFaq && (
            <Modal
              isOpenModal={() => setIsOpenAddFaq(true)}
              closeModal={() => setIsOpenAddFaq(false)}
            >
              <div className="dark:text-white text-textcolor px-4 pt-[120px]">
                <h1 className="text-[20px] font-PoppinsBold">
                  Set Frequently Asked Questions
                </h1>
                <div className="mt-10">
                  <Formik
                    initialValues={{
                      question: "",
                      answer: "",
                    }}
                    validationSchema={setSecurityQuestionSchema}
                    onSubmit={handleFaq}
                  >
                    {({ isValid, dirty }) => (
                      <Form>
                        <TextInputWithLabel
                          name="question"
                          type="text"
                          placeholder="e.g: What is Affluence?"
                          label="Enter Frequently Asked Question"
                        />
                        <TextInputWithLabel
                          name="answer"
                          type="text"
                          placeholder="e.g: Affluence is the best"
                          label="Answer"
                        />
                        <AuthButton
                          isLoading={setting}
                          disabled={!isValid || !dirty}
                        >
                          Set FAQ
                        </AuthButton>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </Modal>
          )}
          {isOpenContactUs && (
            <Modal
              isOpenModal={() => setIsOpenContactUs(true)}
              closeModal={() => setIsOpenContactUs(false)}
            >
              <div className="dark:text-white px-4 pt-[120px]">
                <h1 className="text-[20px] font-PoppinsBold">Set Contact Us</h1>
                <div className="mt-10">
                  <Formik
                    initialValues={{
                      question: "",
                      answer: "",
                    }}
                    validationSchema={setSecurityQuestionSchema}
                    onSubmit={(values) => {}}
                  >
                    {({ isValid, dirty }) => (
                      <Form>
                        <TextInputWithLabel
                          name="phoneNumber"
                          type="number"
                          placeholder="e.g: What is Affluence?"
                          label="Enter Phone Number"
                        />
                        <TextInputWithLabel
                          name="email"
                          type="text"
                          placeholder="e.g: example@gmail.com"
                          label="Enter Email Address"
                        />
                        <TextInputWithLabel
                          name="address"
                          type="text"
                          placeholder="e.g: example@gmail.com"
                          label="Enter Contact Address"
                        />
                        <AuthButton
                          isLoading={setting}
                          disabled={!isValid || !dirty}
                        >
                          Set Contact
                        </AuthButton>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </Modal>
          )}
          {isOpenSecurityQuestion && (
            <Modal
              isOpenModal={() => setIsOpenSecurityQuestion(true)}
              closeModal={() => setIsOpenSecurityQuestion(false)}
            >
              <div className="dark:text-white text-textcolor px-4 pt-[120px]">
                <div className="flex items-center justify-between pb-[60px]">
                  <h1 className="text-[20px] font-PoppinsBold">
                    Security Question
                  </h1>
                </div>
                <div>
                  <Formik
                    initialValues={{
                      question: "",
                      answer: "",
                      accountPassword: "",
                      otp: "",
                    }}
                    validationSchema={setSecurityQuestionSchema}
                    onSubmit={(values) => {}}
                  >
                    {({ isValid, dirty }) => (
                      <Form>
                        <TextInputWithLabel
                          name="question"
                          type="text"
                          placeholder="e.g: How are you?"
                          label="Enter Security Question"
                        />
                        <PasswordInput
                          name="answer"
                          type="password"
                          placeholder="Answer"
                          label="Enter Answer"
                          updatePass="top-[35px] right-[13px]"
                        />
                        <PasswordInput
                          name="accountPassword"
                          type="password"
                          placeholder="******"
                          label="Enter Account Password"
                          updatePass="top-[35px] right-[13px]"
                        />
                        <PasswordInput
                          name="otp"
                          type="password"
                          label="Enter OTP"
                          updatePass="top-[35px] right-[13px]"
                        />
                        {isRequesting ? (
                          <div className="mb-5">
                            <MoonLoader color="#6B006B" size={17} />
                          </div>
                        ) : (
                          <button
                            onClick={fetchAdminsAndGenerateOTP}
                            type="button"
                            className="mb-5 text-[13px] underline"
                          >
                            Click to generate OTP
                          </button>
                        )}
                        <AuthButton
                          isLoading={setting}
                          disabled={!isValid || !dirty}
                        >
                          Set Security Question
                        </AuthButton>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </Modal>
          )}
        </div>
        {isModalVisible && (
          <SModal onClose={handleCancel}>
            <h2 className="text-primary font-PoppinsSemiBold">
              Confirm Delete
            </h2>
            <div className="mt-4 text-textcolor">
              <span className="mt-6">Are you sure you want to delete</span>{" "}
              <span className="text-primary font-PoppinsMedium">
                {selectedFaq?.question}
              </span>
            </div>
            <button
              className="text-success mt-4 flex justify-end"
              type="submit"
              onClick={handleFaqDelete}
            >
              Confirm
            </button>
          </SModal>
        )}
      </div>
    </>
  );
}

export default Configuration;
