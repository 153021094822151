import React from "react";

interface ProfilePictureProps {
  profilePics: string | undefined;
  firstName?: string;
  lastName?: string;
  profilePicsClass?: string;
  onClick?: () => void;
}

const ProfilePicture: React.FC<ProfilePictureProps> = ({
  profilePics,
  firstName,
  lastName,
  profilePicsClass,
  onClick,
}) => {
  const getInitials = (first: string | undefined, last: string | undefined) => {
    const firstInitial = first ? first.charAt(0).toUpperCase() : "";
    const lastInitial = last ? last.charAt(0).toUpperCase() : "";
    return `${firstInitial}${lastInitial}`;
  };

  return (
    <div>
      <div
        className={`h-10 w-10 flex items-center justify-center rounded-full overflow-hidden relative ${profilePicsClass}`}
        onClick={onClick}
        style={{
          backgroundColor: profilePics ? "transparent" : "#6B006B",
        }}
      >
        {profilePics ? (
          <img
            src={profilePics}
            className="w-full h-full aspect-square object-cover"
            alt="profile-picture"
          />
        ) : (
          <span className="text-xl font-bold text-white">
            {getInitials(firstName, lastName)}
          </span>
        )}
      </div>
    </div>
  );
};

export default ProfilePicture;
