import {
  MdArrowForwardIos,
  MdOutlineAccountBalanceWallet,
} from "react-icons/md";
import { PiHandDepositBold, PiHandWithdrawLight } from "react-icons/pi";
import { AiOutlineBank, AiOutlineLike } from "react-icons/ai";
import { GiTakeMyMoney } from "react-icons/gi";
import { LiaPercentageSolid } from "react-icons/lia";
import TransactionCard from "../components/TransactionCard";
import { GrGift } from "react-icons/gr";
import { ProgressBar } from "primereact/progressbar";
import React, { useState, useEffect, useCallback, useRef } from "react";
import { Chart } from "primereact/chart";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import PageTitle from "../components/Pagetitle";
import { HttpClient } from "../api/HttpClient";
import { isAxiosError } from "axios";
import ToastComponent from "../components/ToastComponent";
import NumberInputWithLabel from "../components/NumberInputWithLabel";
import { formatCurrency } from "../components/CurrencyFormat";
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInWeeks,
  differenceInYears,
} from "date-fns";
import NoTransaction from "../assets/img/Wavy_Tech-31_Single-03.jpg";
import { ScaleLoader } from "react-spinners";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import Modal from "../components/Modal";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import { RiMenu2Fill } from "react-icons/ri";
import DateFilter from "../components/DateFilter";
import ReactPaginate from "react-paginate";
import {
  BsChevronLeft,
  BsChevronRight,
  BsFillArrowDownLeftCircleFill,
  BsFillArrowUpRightCircleFill,
} from "react-icons/bs";
import AuthButton from "../components/AuthButton";
import SelectInputWithLogo from "../components/SelectInputWithLogo";
import { Form, Formik } from "formik";
import PayStack from "../assets/img/paystack.png";
import Flutter from "../assets/img/flutter.png";
import * as Yup from "yup";
import ProfilePicture from "../components/ProfilePicture";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { Account, Transaction, ViewAllTransaction } from "../assets/Typeprops";
import { IoIosWarning } from "react-icons/io";
import { TiCancel } from "react-icons/ti";

interface AuthenticatedLayoutContext {
  toggleNav: () => void;
}
const HomePage = () => {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [accountData, setAccountData] = useState<Account | null>(null);
  const [walletTransactions, setwalletTransactions] = useState<Transaction[]>(
    []
  );
  const [showWalletModal, setShowWalletModal] = useState(false);
  const [modalTransactions, setModalTransactions] = useState<
    ViewAllTransaction[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [viewAllTransactions, setViewAllTransactions] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [typeOfTransaction, setTypeOfTransaction] = useState("");
  const [viewAllIsLoading, setViewAllIsLoading] = useState(false);
  const [modalFilter, setModalFilter] = useState({
    startDate: null,
    endDate: null,
  });
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 965,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: true,
        },
      },
    ],
  };
  const paymentType = [
    { value: "flutterwave", label: "Flutterwave (Recommended)", logo: Flutter },
    { value: "paystack", label: "Paystack", logo: PayStack },
  ];
  const fundWalletSchema = Yup.object().shape({
    amount: Yup.number()
      .required("Amount is required")
      .min(100, "Amount must be at least 100")
      .max(500, "Amount must be at most 500"),
    paymentGateway: Yup.string().required("Payment type is required"),
  });
  const handleFundWallet = async (values: any) => {
    const getToken = () => {
      return localStorage.getItem("token");
    };
    const token = getToken();
    setIsLoading(true);
    try {
      const res = await HttpClient.post(
        "/payments/flutterwave/payment",
        values,
        {
          headers: {
            "x-auth-token": token,
          },
        }
      );
      if (
        res.data &&
        res.data.statusCode === 200 &&
        res.data.data.data &&
        res.data.data.data.link
      ) {
        window.location.href = res.data.data.data.link;
      }
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      } else if (error instanceof Error) {
        ToastComponent.error(error.message);
      } else {
        ToastComponent.error("An unknown error occurred");
      }
    } finally {
      setIsLoading(false);
    }
  };
  const formatTransactionDate = (date: string) => {
    const parsedDate = new Date(date);
    const now = new Date();
    const minutes = differenceInMinutes(now, parsedDate);
    const hours = differenceInHours(now, parsedDate);
    const days = differenceInDays(now, parsedDate);
    const weeks = differenceInWeeks(now, parsedDate);
    const months = differenceInMonths(now, parsedDate);
    const years = differenceInYears(now, parsedDate);

    if (minutes < 60) {
      return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
    } else if (hours < 24) {
      return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
    } else if (days < 7) {
      return `${days} day${days !== 1 ? "s" : ""} ago`;
    } else if (weeks < 4) {
      return `${weeks} week${weeks !== 1 ? "s" : ""} ago`;
    } else if (months < 12) {
      return `${months} month${months !== 1 ? "s" : ""} ago`;
    } else {
      return `${years} year${years !== 1 ? "s" : ""} ago`;
    }
  };

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const [userDataRes, transactionRes] = await Promise.all([
        HttpClient.get("/users/me", {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }),
        HttpClient.get("/transactions/history", {
          params: {
            page: 1,
            limit: "50",
            dir: "desc",
            sort: "transactionDate",
            search: "",
          },
          headers: { "x-auth-token": localStorage.getItem("token") },
        }),
      ]);

      setAccountData(userDataRes?.data?.data);
      const transactions = transactionRes?.data?.data?.data || [];
      setwalletTransactions(transactions);
    } catch (error) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      }
    } finally {
      setIsLoading(false);
    }
  }, []);
  useEffect(() => {
    const fetchViewAllTransactions = async () => {
      setViewAllIsLoading(true);
      try {
        const Page = Math.min(currentPage + 1, totalPages);
        const Limit = 5;
        const params: { [key: string]: any } = {
          page: Page,
          limit: Limit,
          dir: "desc",
          sort: "transactionDate",
          search: typeOfTransaction,
        };

        if (modalFilter.startDate && modalFilter.endDate) {
          const endDate = new Date(modalFilter.endDate);
          const currentDate = new Date();

          if (endDate > currentDate) {
            ToastComponent.error("End date cannot be in the future.");
            return;
          }
          params.startdate = modalFilter.startDate;
          params.enddate = modalFilter.endDate;
        }

        const transactionRes = await HttpClient.get("/transactions/history", {
          params,
          headers: { "x-auth-token": localStorage.getItem("token") },
        });

        const transactions = transactionRes?.data?.data?.data || [];
        setModalTransactions(transactions);
        const numOfTransactions = transactionRes?.data?.data.total;
        const totalNumOfPages = Math.ceil(numOfTransactions / Limit);
        setTotalPages(totalNumOfPages);
      } catch (error) {
        if (isAxiosError(error)) {
          ToastComponent.error(error.response?.data.message);
        }
      } finally {
        setViewAllIsLoading(false);
      }
    };

    if (viewAllTransactions) {
      fetchViewAllTransactions();
    }
  }, [
    modalFilter,
    currentPage,
    viewAllTransactions,
    totalPages,
    typeOfTransaction,
  ]);
  const handleFilter = (newFilter: any) => {
    if (newFilter.startDate && newFilter.endDate) {
      const endDate = new Date(newFilter.endDate);
      const currentDate = new Date();

      if (endDate > currentDate) {
        ToastComponent.error("End date cannot be in the future.");
        return;
      }

      setModalFilter({
        startDate: newFilter.startDate,
        endDate: newFilter.endDate,
      });
    }
  };
  const handlePageChange = ({ selected }: any) => {
    setCurrentPage(selected);
  };
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  const scrollableRef = useRef<HTMLDivElement>(null);
  const [isScrollVisible, setIsScrollVisible] = useState(true);

  useEffect(() => {
    checkScroll();
    window.addEventListener("resize", checkScroll);
    return () => {
      window.removeEventListener("resize", checkScroll);
    };
  }, []);

  const checkScroll = () => {
    if (scrollableRef.current) {
      if (
        scrollableRef.current.scrollWidth > scrollableRef.current.clientWidth
      ) {
        setIsScrollVisible(true);
      }
    }
  };

  const onScroll = (offset: number) => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollLeft += offset;
    }
  };
  const [tab, setTab] = useState("");

  const handleSelectTab = (tabName: string) => {
    setTab(tabName);
    setTypeOfTransaction(tabName);
  };
  const { toggleNav } = useOutletContext<AuthenticatedLayoutContext>();
  // const dispatch = useDispatch();
  const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
  useEffect(() => {
    const walletBalance = accountData?.account.balance ?? 0;
    const totalInvestment = accountData?.account.investments ?? 0;
    const totalProfit = accountData?.account.profit ?? 0;
    const totalDeposit = accountData?.account.deposit ?? 0;
    const totalWithdrawal = accountData?.account.withdrawal ?? 0;
    const totalReferral = accountData?.account.referral ?? 0;
    const totalAmount =
      walletBalance +
      totalInvestment +
      totalProfit +
      totalDeposit +
      totalWithdrawal +
      totalReferral;

    const data = {
      labels: [
        "Wallet balance",
        "Total investment",
        "Total Profit",
        "Total deposit",
        "Total withdrawal",
        "Total referral",
      ],
      datasets: [
        {
          data: [
            walletBalance,
            totalInvestment,
            totalProfit,
            totalDeposit,
            totalWithdrawal,
            totalReferral,
          ],
          backgroundColor: [
            "#6B006B",
            "#0357D1",
            "#FFC700",
            "#A03DF0",
            "#6B006B",
            "#00936D",
          ],
          hoverBackgroundColor: [
            "#8B008B",
            "#0573F2",
            "#FFD700",
            "#C06BF0",
            "#8B008B",
            "#00B388",
          ],
          borderWidth: 0,
        },
      ],
    };

    const options = {
      cutout: "60%",
      plugins: {
        tooltip: {
          callbacks: {
            label: (tooltipItem: any) => {
              const value = tooltipItem.raw;
              const percentage = ((value / totalAmount) * 100).toFixed(2);
              const formattedValue = formatCurrency(value);
              return `${tooltipItem.label}: ${formattedValue} (${percentage}%)`;
            },
          },
        },
        legend: {
          labels: {
            color: isDarkMode ? "#FFFFFF" : "#3D3C3A",
          },
        },
      },
      elements: {
        arc: {
          borderWidth: 0,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
    };

    setChartData(data);
    setChartOptions(options);
  }, [accountData, isDarkMode]);
  const navigate = useNavigate();
  const handleSetSecurityQuestion = () => {
    navigate("/my_account", { state: { openSecurityQuestionModal: true } });
  };
  const handleSetSettlementAcct = () => {
    navigate("/my_account", { state: { openSettlementAcctModal: true } });
  };
  const handleSetTransactionPin = () => {
    navigate("/my_account", { state: { openSetTransactionPinModal: true } });
  };
  const handleSetVirtualAccount = () => {
    navigate("/wallets/balance", {
      state: { openSetVirtualAccountModal: true },
    });
  };
  const handleShowProfile = () => {
    navigate("/my_account", {
      state: { openShowProfileModal: true },
    });
  };
  return (
    <>
      <PageTitle title="Affluence - Home" />
      {isLoading ? (
        <div className="w-full h-screen bg-white dark:bg-darkPrimary flex items-center justify-center">
          <ScaleLoader color={isDarkMode ? "#FFFFFF" : "#6B006B"} />
        </div>
      ) : (
        <div className="text-textcolor bg-white dark:bg-darkPrimary pb-[70px] pt-[4rem] overflow-hidden">
          <div className="flex items-center md:justify-end justify-between px-[3%] pb-7 ">
            <button onClick={toggleNav} className="md:hidden block">
              <RiMenu2Fill size={30} className="text-primary dark:text-white" />
            </button>
            <ProfilePicture
              profilePicsClass="cursor-pointer"
              onClick={handleShowProfile}
              profilePics={accountData?.photo}
            />
          </div>
          <div className="px-[3%] pb-4">
            <div className="flex items-center justify-between">
              <div className="md:text-[24px] sm:text-[22px] text-[20px]">
                <h1 className=" font-PoppinsMedium dark:text-white">
                  Welcome,{" "}
                  <span className="font-PoppinsSemiBold">
                    {accountData?.firstName}
                  </span>
                </h1>
              </div>
              {!accountData?.virtualAccountStatus && (
                <button
                  onClick={handleSetVirtualAccount}
                  className="border-[2px] border-primary dark:border-secondary bg-opacity-20 sm:p-3 p-[6px] flex justify-between sm:gap-7 gap-4 group hover:bg-primary dark:hover:bg-secondary rounded-[8px] items-center"
                >
                  <h1 className="dark:text-secondary text-primary group-hover:text-white dark:group-hover:text-darkPrimary flex items-center gap-2 md:text-[14px] text-[12px] font-PoppinsMedium">
                    Create virtual account
                  </h1>
                </button>
              )}
            </div>
          </div>

          {/* Amount in Wallet */}
          <div className="slider-container">
            <Slider {...settings}>
              <div className="bg-primary mx-4 bg-opacity-5 p-5 inline-block rounded-[8px] border border-primary">
                <div>
                  <Link
                    to="/wallets/balance"
                    className="bg-primary text-white p-5 h-[120px] inline-block w-full rounded-[8px]"
                  >
                    <div className="flex items-center gap-2 ">
                      <MdOutlineAccountBalanceWallet size={25} />
                      <div>
                        <h1 className="text-[12px] font-PoppinsMedium">
                          Wallet Balance
                        </h1>
                        <h1 className="text-[25px] font-PoppinsSemiBold">
                          {accountData?.hideBalance
                            ? "*******"
                            : accountData?.account?.balance
                            ? formatCurrency(accountData.account.balance)
                            : "₦0"}
                        </h1>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div className="bg-investment mx-4 bg-opacity-5 p-5 inline-block rounded-[8px] border border-investment">
                <div>
                  <Link
                    to="/investments"
                    className="bg-investment text-white p-5 h-[120px] inline-block w-full rounded-[8px]"
                  >
                    <div className=" flex items-center gap-2 ">
                      <AiOutlineBank size={25} />
                      <div>
                        <h1 className="text-[12px] font-PoppinsMedium">
                          Total investments
                        </h1>
                        <h1 className="text-[25px] font-PoppinsSemiBold">
                          {accountData?.hideBalance
                            ? "*******"
                            : accountData?.account
                            ? formatCurrency(
                                accountData?.account?.investments +
                                  accountData?.account?.flexible_investments +
                                  accountData?.account?.regular_investments +
                                  accountData?.account?.kids_investments
                              )
                            : "₦0"}
                        </h1>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="bg-secondary mx-4 bg-opacity-5 p-5 inline-block rounded-[8px] border border-secondary">
                <Link
                  to="/wallets/profits"
                  className="bg-secondary text-white p-5 h-[120px] inline-block w-full rounded-[8px]"
                >
                  <div className=" flex items-center gap-2 ">
                    <LiaPercentageSolid size={25} />
                    <div>
                      <h1 className="text-[12px] font-PoppinsMedium">
                        Total profit
                      </h1>
                      <h1 className="text-[25px] font-PoppinsSemiBold">
                        {accountData?.hideBalance
                          ? "*******"
                          : accountData?.account?.profit
                          ? formatCurrency(accountData.account.profit)
                          : "₦0"}
                      </h1>
                    </div>
                  </div>
                </Link>
              </div>
              {/* <div className="bg-deposit mx-4 bg-opacity-5 p-5 inline-block rounded-[8px] border border-deposit">
                <div>
                  <Link
                    to="/wallets/deposit"
                    className="bg-deposit text-white p-5 h-[120px] inline-block w-full rounded-[8px]"
                  >
                    <div className=" flex items-center gap-2 ">
                      <PiHandDepositBold size={25} />
                      <div>
                        <h1 className="text-[12px] font-PoppinsMedium">
                          Total deposit
                        </h1>
                        <h1 className="text-[25px] font-PoppinsSemiBold">
                          {accountData?.hideBalance
                            ? "*******"
                            : accountData?.account?.deposit
                            ? formatCurrency(accountData.account.deposit)
                            : "₦0"}
                        </h1>
                      </div>
                    </div>
                  </Link>
                </div>
              </div> */}
              {/* <div className="bg-primary mx-4 bg-opacity-5 p-5 inline-block rounded-[8px] border border-primary">
                <div>
                  <Link
                    to="/wallets/withdrawal"
                    className="bg-primary text-white p-5 h-[120px] inline-block w-full rounded-[8px]"
                  >
                    <div className=" flex items-center gap-2 ">
                      <PiHandWithdrawLight size={25} />
                      <div>
                        <h1 className="text-[12px] font-PoppinsMedium">
                          Total withdrawal
                        </h1>
                        <h1 className="text-[25px] font-PoppinsSemiBold">
                          {accountData?.hideBalance
                            ? "*******"
                            : accountData?.account?.withdrawal
                            ? formatCurrency(accountData.account.withdrawal)
                            : "₦0"}
                        </h1>
                      </div>
                    </div>
                  </Link>
                </div>
              </div> */}
              <div className="bg-referral mx-4 bg-opacity-5 p-5 inline-block rounded-[8px] border border-referral">
                <div>
                  <Link
                    to="/wallets/referral"
                    className="bg-referral text-white p-5 h-[120px] inline-block w-full rounded-[8px]"
                  >
                    <div className=" flex items-center gap-2 ">
                      <GiTakeMyMoney size={25} />
                      <div>
                        <h1 className="text-[12px] font-PoppinsMedium">
                          Total referral
                        </h1>
                        <h1 className="text-[25px] font-PoppinsSemiBold">
                          {accountData?.hideBalance
                            ? "*******"
                            : accountData?.account?.referral
                            ? formatCurrency(accountData.account.referral)
                            : "₦0"}
                        </h1>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </Slider>
          </div>
          <div></div>
          <div className="px-[3%] pt-[35px] cxmd:gap-0 gap-2 flex justify-between">
            <div className="extra:w-[60%] w-full">
              <div className="w-full extra:hidden block">
                <div>
                  <div>
                    {accountData?.kyc ? (
                      accountData?.kyc.kycLevel === 0 ? (
                        <div className="bg-error mb-2 bg-opacity-20 p-3 flex justify-between gap-7 rounded-[8px] items-end">
                          <div>
                            <div className="mb-2">
                              <h1 className="text-error text-[12px] font-PoppinsRegular">
                                You have not completed your KYC
                              </h1>
                            </div>
                            <ProgressBar
                              style={{ height: "5px" }}
                              className="custom-levelone-progress-bar"
                              value={0}
                              displayValueTemplate={() => null}
                            ></ProgressBar>
                          </div>
                          <Link
                            to="/kyc"
                            className="font-PoppinsSemiBold text-[10px] text-white bg-error py-1 px-4 rounded-[8px]"
                          >
                            Go
                          </Link>
                        </div>
                      ) : accountData?.kyc.kycLevel === 1 ? (
                        <div className="bg-error mb-2 bg-opacity-20 p-3 flex justify-between gap-7 rounded-[8px] items-end">
                          <div>
                            <div className="mb-2">
                              <h1 className="text-error text-[12px] font-PoppinsRegular">
                                You have not completed your KYC
                              </h1>
                            </div>
                            <ProgressBar
                              style={{ height: "5px" }}
                              className="custom-levelone-progress-bar"
                              value={33.33}
                              displayValueTemplate={() => null}
                            ></ProgressBar>
                          </div>
                          <Link
                            to="/kyc"
                            className="font-PoppinsSemiBold text-[10px] text-white bg-error py-1 px-4 rounded-[8px]"
                          >
                            Go
                          </Link>
                        </div>
                      ) : accountData?.kyc.kycLevel === 2 ? (
                        <div className="bg-secondary mb-2 bg-opacity-20 p-3 flex justify-between gap-7 rounded-[8px] items-end">
                          <div>
                            <div className="mb-2">
                              <h1 className="text-secondary text-[12px] font-PoppinsRegular">
                                Almost done, Please complete your KYC
                              </h1>
                            </div>
                            <ProgressBar
                              style={{ height: "5px" }}
                              className="custom-leveltwo-progress-bar"
                              value={66.66}
                              displayValueTemplate={() => null}
                            ></ProgressBar>
                          </div>
                          <Link
                            to="/kyc"
                            className="font-PoppinsSemiBold text-[10px] text-white bg-secondary py-1 px-4 rounded-[8px]"
                          >
                            Go
                          </Link>
                        </div>
                      ) : accountData?.kyc.kycLevel === 3 &&
                        accountData?.kyc.kycApprovalStatus === true ? (
                        <div className="bg-referral mb-2 bg-opacity-20 p-3 flex justify-between gap-7 rounded-[8px] items-end">
                          <div>
                            <div className="mb-2">
                              <h1 className="text-referral text-[12px] font-PoppinsRegular">
                                You have finally completed your KYC
                              </h1>
                            </div>
                            <ProgressBar
                              style={{ height: "5px" }}
                              className="custom-levelthree-progress-bar"
                              value={100}
                              displayValueTemplate={() => null}
                            ></ProgressBar>
                          </div>
                          <Link
                            to="#"
                            className="font-PoppinsSemiBold text-[10px] text-white bg-secondary py-1 px-4 rounded-[8px]"
                          >
                            Claim referral
                          </Link>
                        </div>
                      ) : accountData?.kyc.kycLevel === 3 &&
                        accountData?.kyc.kycApprovalStatus === false ? (
                        <div className="bg-secondary mb-2 bg-opacity-20 p-3 flex justify-between gap-7 rounded-[8px] items-end">
                          <div>
                            <div className="mb-2">
                              <h1 className="text-secondary text-[12px] font-PoppinsRegular">
                                KYC not approved yet
                              </h1>
                            </div>
                            <ProgressBar
                              style={{ height: "5px" }}
                              className="custom-leveltwo-progress-bar"
                              value={100}
                              displayValueTemplate={() => null}
                            ></ProgressBar>
                          </div>
                          <Link
                            to="/kyc"
                            className="font-PoppinsSemiBold text-[10px] text-white bg-secondary py-1 px-4 rounded-[8px]"
                          >
                            Check KYC
                          </Link>
                        </div>
                      ) : null
                    ) : (
                      <div className="bg-error bg-opacity-20 p-3 mb-2 flex justify-between gap-7 rounded-[8px] items-end">
                        <div>
                          <div className="mb-2">
                            <h1 className="text-error text-[12px] font-PoppinsRegular">
                              You have not completed your KYC
                            </h1>
                          </div>
                          <ProgressBar
                            style={{ height: "5px" }}
                            className="custom-levelone-progress-bar"
                            value={0}
                            displayValueTemplate={() => null}
                          ></ProgressBar>
                        </div>
                        <Link
                          to="/kyc"
                          className="font-PoppinsSemiBold text-[10px] text-white bg-error py-1 px-4 rounded-[8px]"
                        >
                          Go
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  {!accountData?.securityQuestionStatus && (
                    <div className="dark:bg-secondary bg-primary mb-2 bg-opacity-20 dark:bg-opacity-20 sm:p-3 p-[6px] flex justify-between sm:gap-7 gap-4 rounded-[8px] items-center">
                      <div>
                        <div>
                          <h1 className="dark:text-secondary text-primary flex items-center gap-2 text-[12px] font-PoppinsRegular">
                            <IoIosWarning className="text-[14px]" />
                            Please set your security question
                          </h1>
                        </div>
                      </div>
                      <button
                        onClick={handleSetSecurityQuestion}
                        className="font-PoppinsSemiBold text-[10px] text-white bg-primary dark:bg-secondary dark:text-white py-1 px-4 rounded-[8px]"
                      >
                        Go
                      </button>
                    </div>
                  )}
                  {!accountData?.settlementAccountStatus && (
                    <div className="dark:bg-secondary bg-primary mb-2 bg-opacity-20 dark:bg-opacity-20 sm:p-3 p-[6px] flex justify-between sm:gap-7 gap-4 rounded-[8px] items-center">
                      <div>
                        <div>
                          <h1 className="dark:text-secondary text-primary flex items-center gap-2 text-[12px] font-PoppinsRegular">
                            <IoIosWarning className="text-[14px]" />
                            Please set your withdrawal bank account details
                          </h1>
                        </div>
                      </div>
                      <button
                        onClick={handleSetSettlementAcct}
                        className="font-PoppinsSemiBold text-[10px] text-white bg-primary dark:bg-secondary dark:text-white py-1 px-4 rounded-[8px]"
                      >
                        Go
                      </button>
                    </div>
                  )}
                  {!accountData?.changePinStatus && (
                    <div className="dark:bg-secondary bg-primary mt-2 mb-2 bg-opacity-20 sm:p-3 p-[6px] flex justify-between dark:bg-opacity-20 sm:gap-7 gap-4 rounded-[8px] items-center">
                      <div>
                        <div>
                          <h1 className="dark:text-secondary text-primary flex items-center gap-2 text-[12px] font-PoppinsRegular">
                            <IoIosWarning className="text-[14px]" />
                            Please set your transaction pin
                          </h1>
                        </div>
                      </div>
                      <button
                        onClick={handleSetTransactionPin}
                        className="font-PoppinsSemiBold text-[10px] text-white bg-primary dark:bg-secondary dark:text-white py-1 px-4 rounded-[8px]"
                      >
                        Go
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex items-center pb-4 justify-between">
                <h1 className=" font-PoppinsSemiBold dark:text-white text-[16px]">
                  Transaction History
                </h1>
                <h1
                  onClick={() => setViewAllTransactions(true)}
                  className="font-PoppinsSemiBold flex items-center gap-2 text-primary dark:text-white cursor-pointer"
                >
                  View all
                  <MdArrowForwardIos />
                </h1>
              </div>
              <div className="mb-7">
                {walletTransactions.length > 0 ? (
                  <div>
                    {walletTransactions.slice(0, 5).map((item) => (
                      <div>
                        <TransactionCard
                          transactionActivity={
                            item.isInFlow &&
                            item.paymentStatus === "successful" ? (
                              <>
                                <div className="flex gap-2 items-center">
                                  <div className="text-referral  mr-2">
                                    <BsFillArrowDownLeftCircleFill size={16} />
                                  </div>
                                </div>
                              </>
                            ) : !item.isInFlow &&
                              item.paymentStatus === "successful" ? (
                              <>
                                <div className="flex gap-2 items-center">
                                  <span>{item.responseMsg}</span>
                                  <div className="text-error mr-2">
                                    <BsFillArrowUpRightCircleFill size={16} />
                                  </div>
                                </div>
                              </>
                            ) : item.isInFlow &&
                              item.paymentStatus === "failed" ? (
                              <>
                                <div className="flex gap-2 items-center">
                                  <span>{item.responseMsg}</span>
                                  <div className="text-error mr-2">
                                    <TiCancel size={22} />
                                  </div>
                                </div>
                              </>
                            ) : !item.isInFlow &&
                              item.paymentStatus === "failed" ? (
                              <>
                                <div className="flex gap-2 items-center">
                                  <span>{item.responseMsg}</span>
                                  <div className="text-error mr-2">
                                    <TiCancel size={22} />
                                  </div>
                                </div>
                              </>
                            ) : null
                          }
                          transactionTime={formatTransactionDate(
                            item.transactionDate
                          )}
                          transactionAmount={formatCurrency(item.amount)}
                          paymentStatus={item.paymentStatus}
                          icon={
                            item.transactionType === "wallet"
                              ? MdOutlineAccountBalanceWallet
                              : item.transactionType === "investments"
                              ? AiOutlineBank
                              : item.transactionType === "profits"
                              ? LiaPercentageSolid
                              : item.transactionType === "deposits"
                              ? PiHandDepositBold
                              : item.transactionType === "withdrawals"
                              ? PiHandWithdrawLight
                              : GiTakeMyMoney
                          }
                          iconClassname={`bg-opacity-[20%] rounded-full p-2 ${
                            item.transactionType === "wallet"
                              ? "text-primary bg-primary dark:bg-white dark:text-white dark:bg-opacity-25"
                              : item.transactionType === "investments"
                              ? "text-investment bg-investment"
                              : item.transactionType === "profits"
                              ? "text-secondary bg-secondary"
                              : item.transactionType === "deposits"
                              ? "text-deposit bg-deposit"
                              : item.transactionType === "withdrawals"
                              ? "text-primary bg-primary dark:bg-white dark:text-white dark:bg-opacity-25"
                              : "text-referral bg-referral"
                          }`}
                          isInFlow={item.isInFlow}
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="flex items-center justify-center py-5">
                    <div>
                      <div className="flex items-center justify-center">
                        <img
                          src={NoTransaction}
                          className="w-[200px]"
                          alt="illustrator"
                        />
                      </div>
                      <h1 className="font-PoppinsBold pb-3 text-[20px] text-primary">
                        No wallet transaction currently!
                      </h1>
                    </div>
                  </div>
                )}
              </div>
              {viewAllTransactions && (
                <Modal
                  isOpenModal={() => setViewAllTransactions(true)}
                  closeModal={() => setViewAllTransactions(false)}
                >
                  <div className="px-4 pt-[120px]">
                    <div className="pb-5">
                      <h1 className="text-[22px] text-primary dark:text-white font-PoppinsSemiBold">
                        Transaction History
                      </h1>
                      <div className="py-2">
                        <p className="text-[13px] text-disabledtext dark:text-disabled">
                          Below are the recent transaction activities in your
                          Affluence account
                        </p>
                      </div>
                    </div>
                    <div className="relative">
                      {isScrollVisible && (
                        <>
                          <button
                            className="absolute left-[-15px] top-1/2 transform -translate-y-1/2 bg-white"
                            onClick={() => onScroll(-100)}
                          >
                            <FaArrowLeft />
                          </button>
                          <button
                            className="absolute right-[-15px] top-1/2 transform -translate-y-1/2 bg-white"
                            onClick={() => onScroll(100)}
                          >
                            <FaArrowRight />
                          </button>
                        </>
                      )}
                      <div
                        ref={scrollableRef}
                        className="overflow-x-auto hide-scroll"
                      >
                        <div className="flex items-center gap-4 w-max">
                          <button
                            onClick={() => handleSelectTab("")}
                            className={`border-[1.5px] rounded-[8px] dark:text-white text-[black] py-1 px-3 font-PoppinsMedium ${
                              tab === ""
                                ? "bg-[black] text-white border-[black]"
                                : "border-transactionCard"
                            }`}
                          >
                            All
                          </button>
                          <button
                            onClick={() => handleSelectTab("balance")}
                            className={`border-[1.5px] rounded-[8px] text-primary py-1 px-3 dark:text-white font-PoppinsMedium ${
                              tab === "balance"
                                ? "bg-primary text-white border-primary dark:text-darkPrimary dark:bg-white dark:border-white"
                                : "border-transactionCard"
                            }`}
                          >
                            Balance
                          </button>
                          <button
                            onClick={() => handleSelectTab("investments")}
                            className={`border-[1.5px] rounded-[8px] text-investment py-1 px-3 font-PoppinsMedium ${
                              tab === "investments"
                                ? "bg-investment text-white border-investment"
                                : "border-transactionCard"
                            }`}
                          >
                            Investments
                          </button>
                          <button
                            onClick={() => handleSelectTab("profits")}
                            className={`border-[1.5px] rounded-[8px] text-secondary py-1 px-3 font-PoppinsMedium ${
                              tab === "profits"
                                ? "bg-secondary text-white border-secondary"
                                : "border-transactionCard"
                            }`}
                          >
                            Profits
                          </button>
                          <button
                            onClick={() => handleSelectTab("deposits")}
                            className={`border-[1.5px] rounded-[8px] text-deposit py-1 px-3 font-PoppinsMedium ${
                              tab === "deposits"
                                ? "bg-deposit text-white border-deposit"
                                : "border-transactionCard"
                            }`}
                          >
                            Deposits
                          </button>
                          <button
                            onClick={() => handleSelectTab("withdrawals")}
                            className={`border-[1.5px] rounded-[8px] text-primary dark:text-white py-1 px-3 font-PoppinsMedium ${
                              tab === "withdrawals"
                                ? "bg-primary text-white border-primary dark:bg-white dark:text-darkPrimary dark:border-white"
                                : "border-transactionCard"
                            }`}
                          >
                            Withdrawals
                          </button>
                          <button
                            onClick={() => handleSelectTab("referrals")}
                            className={`border-[1.5px] rounded-[8px] text-referral py-1 px-3 font-PoppinsMedium ${
                              tab === "referrals"
                                ? "bg-referral text-white border-referral"
                                : "border-transactionCard"
                            }`}
                          >
                            Referrals
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="pb-2 ">
                      <DateFilter onFilter={handleFilter} />
                    </div>
                    {viewAllIsLoading ? (
                      <div className="w-full h-[50vh] bg-white dark:bg-darkPrimary flex items-center justify-center">
                        <ScaleLoader
                          color={isDarkMode ? "#FFFFFF" : "#6B006B"}
                        />
                      </div>
                    ) : (
                      <>
                        {modalTransactions.length > 0 ? (
                          <div className="pt-4">
                            {modalTransactions.map((item, index) => (
                              <div key={index}>
                                <TransactionCard
                                  transactionActivity={
                                    item.isInFlow &&
                                    item.paymentStatus === "successful" ? (
                                      <>
                                        <div className="flex gap-2 items-center">
                                          <span>{item.responseMsg}</span>
                                          <div className="text-referral mr-2">
                                            <BsFillArrowDownLeftCircleFill
                                              size={16}
                                            />
                                          </div>
                                        </div>
                                      </>
                                    ) : !item.isInFlow &&
                                      item.paymentStatus === "successful" ? (
                                      <>
                                        <div className="flex gap-2 items-center">
                                          <span>{item.responseMsg}</span>
                                          <div className="text-error mr-2">
                                            <BsFillArrowUpRightCircleFill
                                              size={16}
                                            />
                                          </div>
                                        </div>
                                      </>
                                    ) : item.isInFlow &&
                                      item.paymentStatus === "failed" ? (
                                      <>
                                        <div className="flex gap-2 items-center">
                                          <span>{item.responseMsg}</span>
                                          <div className="text-error mr-2">
                                            <TiCancel size={22} />
                                          </div>
                                        </div>
                                      </>
                                    ) : !item.isInFlow &&
                                      item.paymentStatus === "failed" ? (
                                      <>
                                        <div className="flex gap-2 items-center">
                                          <span>{item.responseMsg}</span>
                                          <div className="text-error mr-2">
                                            <TiCancel size={22} />
                                          </div>
                                        </div>
                                      </>
                                    ) : null
                                  }
                                  transactionTime={formatTransactionDate(
                                    item.transactionDate
                                  )}
                                  transactionAmount={formatCurrency(
                                    item.amount
                                  )}
                                  paymentStatus={item.paymentStatus}
                                  icon={
                                    item.transactionType === "wallet"
                                      ? MdOutlineAccountBalanceWallet
                                      : item.transactionType === "investments"
                                      ? AiOutlineBank
                                      : item.transactionType === "profits"
                                      ? LiaPercentageSolid
                                      : item.transactionType === "deposits"
                                      ? PiHandDepositBold
                                      : item.transactionType === "withdrawals"
                                      ? PiHandWithdrawLight
                                      : GiTakeMyMoney
                                  }
                                  iconClassname={`bg-opacity-[20%] rounded-full p-2 ${
                                    item.transactionType === "wallet"
                                      ? "text-primary bg-primary dark:bg-white dark:text-white dark:bg-opacity-25"
                                      : item.transactionType === "investments"
                                      ? "text-investment bg-investment"
                                      : item.transactionType === "profits"
                                      ? "text-secondary bg-secondary"
                                      : item.transactionType === "deposits"
                                      ? "text-deposit bg-deposit"
                                      : item.transactionType === "withdrawals"
                                      ? "text-primary bg-primary dark:bg-white dark:text-white dark:bg-opacity-25"
                                      : "text-referral bg-referral"
                                  }`}
                                  isInFlow={item.isInFlow}
                                />
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className="flex items-center justify-center py-5">
                            <div>
                              <div className="flex items-center justify-center">
                                <img
                                  src={NoTransaction}
                                  className="w-[200px]"
                                  alt="illustrator"
                                />
                              </div>
                              <h1 className="font-PoppinsBold pb-3 text-[20px] text-primary">
                                No wallet transaction currently!
                              </h1>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                    <ReactPaginate
                      breakLabel={<span className="mr-4">...</span>}
                      nextLabel={
                        currentPage < totalPages - 1 && (
                          <button>
                            <span className="w-8 h-8 flex items-center justify-center bg-authbordercolor dark:text-white bg-opacity-25 rounded-[4px]">
                              <BsChevronRight />
                            </span>
                          </button>
                        )
                      }
                      pageRangeDisplayed={5}
                      onPageChange={handlePageChange}
                      pageCount={totalPages}
                      previousLabel={
                        currentPage > 0 && (
                          <button>
                            <span className="w-8 h-8 flex mr-3 items-center justify-center bg-authbordercolor dark:text-white bg-opacity-25 rounded-[4px]">
                              <BsChevronLeft />
                            </span>
                          </button>
                        )
                      }
                      renderOnZeroPageCount={null}
                      containerClassName="flex items-center justify-center mt-8 mb-4"
                      // pageClassName="block border border-primary dark:border-authbordercolor dark:text-white hover:bg-authbordercolor text-[14px] font-poppinsMedium w-8 p-3 h-8 flex items-center justify-center rounded-md mr-3"
                      pageClassName="element"
                      activeClassName="active-element"
                    />
                  </div>
                </Modal>
              )}

              {showWalletModal && (
                <Modal
                  isOpenModal={() => setShowWalletModal(true)}
                  closeModal={() => setShowWalletModal(false)}
                >
                  <div className="mt-[120px] px-4 dark:text-white">
                    <h1 className="text-[25px] font-PoppinsSemiBold">
                      Fund Wallet
                    </h1>
                    <div className="pt-10">
                      <Formik
                        initialValues={{ amount: "", paymentGateway: "" }}
                        validationSchema={fundWalletSchema}
                        onSubmit={(values) => handleFundWallet(values)}
                        validateOnChange={true}
                        validateOnBlur={true}
                      >
                        {({ isValid, dirty }) => (
                          <Form>
                            <NumberInputWithLabel
                              name="amount"
                              type="number"
                              placeholder="e.g 20,000"
                              label="Enter Amount in Naira"
                            />
                            <SelectInputWithLogo
                              name="paymentGateway"
                              label="Select preferred option"
                              options={paymentType}
                              placeholder="Select Payment Type"
                            />
                            <div className="absolute left-4 right-4 bottom-4">
                              <AuthButton
                                isLoading={isLoading}
                                disabled={!isValid || !dirty}
                              >
                                CONTINUE
                              </AuthButton>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </Modal>
              )}
              <div>
                <div className="bg-dashboardAds flex items-center mb-4 gap-5 p-5 rounded-[8px]">
                  <GrGift size={25} className="text-primary" />
                  <div>
                    <h1 className="text-primary text-[14px] sm:text-base font-PoppinsMedium">
                      Unlock up to 18.2% returns on your initial investment!
                    </h1>
                    <h1 className="font-PoppinsRegular sm:text-[14px] text-[12px]">
                      Explore our investment opportunities and start earning
                      interest.
                    </h1>
                  </div>
                </div>
                <div className="bg-dashboardAds flex items-center mb-4 gap-5 p-5 rounded-[8px]">
                  <AiOutlineLike size={25} className="text-primary" />
                  <div>
                    <h1 className="text-primary font-PoppinsMedium text-[14px] sm:text-base">
                      Boost your KYC status to reveal exclusive deals and perks
                    </h1>
                    <h1 className="font-PoppinsRegular sm:text-[14px] text-[12px]">
                      Enhance your KYC profile to gain access to our investment
                      opportunities and begin earning interest.
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[40%] cxmd:w-[35%] extra:block hidden">
              <div>
                <div>
                  {accountData?.kyc ? (
                    accountData?.kyc.kycLevel === 0 ? (
                      <div className="bg-error mb-2 bg-opacity-20 p-3 flex justify-between gap-7 rounded-[8px] items-end">
                        <div>
                          <div className="mb-2">
                            <h1 className="text-error  text-[12px] font-PoppinsRegular">
                              You have not completed your KYC
                            </h1>
                          </div>
                          <ProgressBar
                            style={{ height: "5px" }}
                            className="custom-levelone-progress-bar"
                            value={0}
                            displayValueTemplate={() => null}
                          ></ProgressBar>
                        </div>
                        <button>
                          <Link
                            to="/kyc"
                            className="font-PoppinsSemiBold text-[10px] text-white bg-error py-1 px-4 rounded-[8px]"
                          >
                            Go
                          </Link>
                        </button>
                      </div>
                    ) : accountData?.kyc.kycLevel === 1 ? (
                      <div className="bg-error mb-2 bg-opacity-20 p-3 flex justify-between gap-7 rounded-[8px] items-end">
                        <div>
                          <div className="mb-2">
                            <h1 className="text-error  text-[12px] font-PoppinsRegular">
                              You have not completed your KYC
                            </h1>
                          </div>
                          <ProgressBar
                            style={{ height: "5px" }}
                            className="custom-levelone-progress-bar"
                            value={33.33}
                            displayValueTemplate={() => null}
                          ></ProgressBar>
                        </div>
                        <button>
                          <Link
                            to="/kyc"
                            className="font-PoppinsSemiBold text-[10px] text-white bg-error py-1 px-4 rounded-[8px]"
                          >
                            Go
                          </Link>
                        </button>
                      </div>
                    ) : accountData?.kyc.kycLevel === 2 ? (
                      <div className="bg-secondary mb-2 bg-opacity-20 p-3 flex justify-between gap-7 rounded-[8px] items-end">
                        <div>
                          <div className="mb-2">
                            <h1 className="text-secondary  text-[12px] font-PoppinsRegular">
                              Almost done, Please complete your KYC
                            </h1>
                          </div>
                          <ProgressBar
                            style={{ height: "5px" }}
                            className="custom-leveltwo-progress-bar"
                            value={66.66}
                            displayValueTemplate={() => null}
                          ></ProgressBar>
                        </div>
                        <button>
                          <Link
                            to="/kyc"
                            className="font-PoppinsSemiBold text-[10px] text-white bg-secondary py-1 px-4 rounded-[8px]"
                          >
                            Go
                          </Link>
                        </button>
                      </div>
                    ) : accountData?.kyc.kycLevel === 3 &&
                      accountData?.kyc.kycApprovalStatus === true ? (
                      <div className="bg-referral mb-2 bg-opacity-20 p-3 flex justify-between gap-7 rounded-[8px] items-end">
                        <div>
                          <div className="mb-2">
                            <h1 className="text-referral text-[12px] font-PoppinsRegular">
                              You have finally completed your KYC
                            </h1>
                          </div>
                          <ProgressBar
                            style={{ height: "5px" }}
                            className="custom-levelthree-progress-bar"
                            value={100}
                            displayValueTemplate={() => null}
                          ></ProgressBar>
                        </div>
                        <Link
                          to="#"
                          className="font-PoppinsSemiBold text-[10px] text-white bg-secondary py-1 px-4 rounded-[8px]"
                        >
                          Claim referral
                        </Link>
                      </div>
                    ) : accountData?.kyc.kycLevel === 3 &&
                      accountData?.kyc.kycApprovalStatus === false ? (
                      <div className="bg-secondary mb-2 bg-opacity-20 p-3 flex justify-between gap-7 rounded-[8px] items-end">
                        <div>
                          <div className="mb-2">
                            <h1 className="text-secondary text-[12px] font-PoppinsRegular">
                              KYC not approved yet
                            </h1>
                          </div>
                          <ProgressBar
                            style={{ height: "5px" }}
                            className="custom-leveltwo-progress-bar"
                            value={100}
                            displayValueTemplate={() => null}
                          ></ProgressBar>
                        </div>
                        <Link
                          to="/kyc"
                          className="font-PoppinsSemiBold text-[10px] text-white bg-secondary py-1 px-4 rounded-[8px]"
                        >
                          Check KYC
                        </Link>
                      </div>
                    ) : null
                  ) : (
                    <div className="bg-error bg-opacity-20 p-3 mb-2 flex justify-between gap-7 rounded-[8px] items-end">
                      <div>
                        <div className="mb-2">
                          <h1 className="text-error  text-[12px] font-PoppinsRegular">
                            You have not completed your KYC
                          </h1>
                        </div>
                        <ProgressBar
                          style={{ height: "5px" }}
                          className="custom-levelone-progress-bar"
                          value={0}
                          displayValueTemplate={() => null}
                        ></ProgressBar>
                      </div>
                      <button>
                        <Link
                          to="/kyc"
                          className="font-PoppinsSemiBold text-[10px] text-white bg-error py-1 px-4 rounded-[8px]"
                        >
                          Go
                        </Link>
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div>
                {!accountData?.securityQuestionStatus && (
                  <div className="dark:bg-secondary bg-primary mb-2 bg-opacity-20 sm:p-3 p-[6px] flex justify-between sm:gap-7 dark:bg-opacity-20 gap-4 rounded-[8px] items-center">
                    <div>
                      <div>
                        <h1 className="dark:text-secondary text-primary flex items-center gap-2 text-[12px] font-PoppinsRegular">
                          <IoIosWarning className="text-[14px]" />
                          Please set your security question
                        </h1>
                      </div>
                    </div>
                    <button
                      onClick={handleSetSecurityQuestion}
                      className="font-PoppinsSemiBold text-[10px] text-white bg-primary dark:bg-secondary dark:text-white py-1 px-4 rounded-[8px]"
                    >
                      Go
                    </button>
                  </div>
                )}
                {!accountData?.settlementAccountStatus && (
                  <div className="dark:bg-secondary bg-primary mb-2 bg-opacity-20 sm:p-3 p-[6px] flex justify-between sm:gap-7 dark:bg-opacity-20 gap-4 rounded-[8px] items-center">
                    <div>
                      <div>
                        <h1 className="dark:text-secondary text-primary flex items-center gap-2 text-[12px] font-PoppinsRegular">
                          <IoIosWarning className="text-[14px]" />
                          Please set your withdrawal bank account details
                        </h1>
                      </div>
                    </div>
                    <button
                      onClick={handleSetSettlementAcct}
                      className="font-PoppinsSemiBold text-[10px] text-white bg-primary dark:bg-secondary dark:text-white py-1 px-4 rounded-[8px]"
                    >
                      Go
                    </button>
                  </div>
                )}
                {!accountData?.changePinStatus && (
                  <div className="dark:bg-secondary bg-primary mt-2 mb-2 bg-opacity-20 sm:p-3 p-[6px] flex justify-between dark:bg-opacity-20 sm:gap-7 gap-4 rounded-[8px] items-center">
                    <div>
                      <div>
                        <h1 className="dark:text-secondary text-primary flex items-center gap-2 text-[12px] font-PoppinsRegular">
                          <IoIosWarning className="text-[14px]" />
                          Please set your transaction pin
                        </h1>
                      </div>
                    </div>
                    <button
                      onClick={handleSetTransactionPin}
                      className="font-PoppinsSemiBold text-[10px] text-white bg-primary dark:bg-secondary dark:text-white py-1 px-4 rounded-[8px]"
                    >
                      Go
                    </button>
                  </div>
                )}
              </div>
              <div className="mt-8 shadow-md rounded-[8px] border border-transactionCard p-7">
                <h1 className="font-PoppinsSemiBold dark:text-white text-center">
                  Summary of transactions
                </h1>
                <div className=" flex justify-center">
                  <div className="w-[300px] h-[300px]">
                    <Chart
                      type="doughnut"
                      data={chartData}
                      options={chartOptions}
                      className="w-full"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HomePage;
