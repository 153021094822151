import { AiOutlineAudit } from "react-icons/ai";
import { GrTransaction } from "react-icons/gr";
import { MdOutlineDashboardCustomize, MdOutlineSavings } from "react-icons/md";
import { PiHandDeposit, PiHandWithdraw, PiUsersThree } from "react-icons/pi";
import { VscSettings } from "react-icons/vsc";
import { SiAmazonsimpleemailservice } from "react-icons/si";
import { GoBriefcase } from "react-icons/go";
import { PiBuildings } from "react-icons/pi";
import { TbEmergencyBed, TbMoodKid } from "react-icons/tb";
import { PiProjectorScreen } from "react-icons/pi";
import { TbUserSearch } from "react-icons/tb";

export const sidebarLinks = [
    {
      to: "/admin/home",
      label: "Home",
      icon: <MdOutlineDashboardCustomize size={24} />,
    },
    {
      to: "/admin/allCustomers",
      label: "Customers",
      icon: <PiUsersThree size={24} />,
    },
    {
      to: "/admin/kyc",
      label: "KYC",
      icon: <TbUserSearch size={24} />,
    },
    {
      to: "/admin/deposits",
      label: "Deposit",
      icon: <PiHandDeposit size={24} />,
    },
    {
      to: "#",
      label: "Withdrawal",
      icon: <PiHandWithdraw size={24} />,
      submenu: [
        { to: "/admin/withdrawal/withdrawal_log", label: "Withdrawal Log" },
        {
          to: "/admin/withdrawal/withdrawal_request",
          label: "Withdrawal Request",
        },
      ],
    },
    {
      to: "#",
      label: "Savings",
      icon: <MdOutlineSavings size={24} />,
      submenu: [
        { to: "/admin/savings/savings_plan", label: "Savings Plan" },
        { to: "/admin/savings/savings_log", label: "Savings Log" },
        { to: "/admin/savings/savings_category", label: "Savings Category" },
      ],
    },
    {
      to: "#",
      label: "Fixed Investment",
      icon: <PiBuildings size={24} />,
      submenu: [
        { to: "/admin/investment/fixed_investment_plan", label: "Fixed Investment Plan" },
        { to: "/admin/investment/fixed_investment_log", label: "Fixed Investment Log" },
        { to: "/admin/investment/add_fixed_category", label: "Fixed Investment Category" },
      ],
    },
    {
      to: "#",
      label: "Regular Investment",
      icon: <GoBriefcase size={24} />,
      submenu: [
        { to: "/admin/investment/regular_investment_plan", label: "Regular Investment Plan" },
        { to: "/admin/investment/regular_investment_log", label: "Regular Investment Log" },
      ],
    },
    {
      to: "#",
      label: "Emergency Fund",
      icon: <TbEmergencyBed size={24}  />,
      submenu: [
        { to: "/admin/investment/emergency_fund_plan", label: "Emergency Fund Plan" },
        { to: "/admin/investment/emergency_fund_log", label: "Emergency Fund Log" },
      ],
    },
    {
      to: "#",
      label: "Kids Investment",
      icon: <TbMoodKid size={24} />,
      submenu: [
        { to: "/admin/investment/kids_investment_plan", label: "Kids Investment Plan" },
        { to: "/admin/investment/kids_investment_log", label: "Kids Investment Log" },
      ],
    },
    {
      to: "#",
      label: "Transaction",
      icon: <GrTransaction size={24} />,
      submenu: [
        { to: "/admin/transactions/transaction_log", label: "Transaction Log" },
        { to: "/admin/transactions/payment_gateway", label: "Payment Gateway" },
        { to: "/admin/transactions/wallet_funding", label: "Wallet Funding" },
      ],
    },
    {
      to: "/admin/audit",
      label: "Audit Log",
      icon: <AiOutlineAudit size={24} />,
    },
    {
      to: "/admin/promotional_emails",
      label: "Promotional Emails",
      icon: <SiAmazonsimpleemailservice size={24} />,
    },
    {
      to: "/admin/configuration",
      label: "Configuration",
      icon: <VscSettings size={24} />,
    },
  ];