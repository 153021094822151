import { FC } from "react";
import { IconType } from "react-icons";

interface AccountSettingsProps {
  children: any;
  icon1?: IconType;
  icon2?: IconType;
  onClick?: () => void;
  iconClass1: string;
  className?: string;
  comingSoon?: boolean;
}
const AccountSettingsCard: FC<AccountSettingsProps> = ({
  children,
  icon1: IconComponent1,
  icon2: IconComponent2,
  onClick,
  iconClass1,
  comingSoon,
}) => {
  return (
    <div
      className="p-4 rounded-[4px] cursor-pointer mb-2 flex items-center justify-between border-[1.5px] border-transactionCard
            "
      onClick={onClick}
    >
      <div className="flex items-center space-x-5">
        <div
          className={`md:text-[22px] sm:text-[20px] text-primary dark:text-white p-2 rounded-full bg-primary bg-opacity-25 dark:bg-white dark:bg-opacity-25 text-[18px] ${iconClass1}`}
        >
          {IconComponent1 && <IconComponent1 />}
        </div>
        <h1 className="font-PoppinsRegular text-[14px]">{children}</h1>
      </div>
      <div className="flex gap-1 items-center">
        {comingSoon && (
          <div className="text-[10px] dark:text-white text-secondary bg-secondary bg-opacity-20 px-2 py-1 dark:bg-white dark:bg-opacity-20 rounded-full">
            Coming Soon
          </div>
        )}
        <div className="md:text-[22px] sm:text-[20px] text-[18px]">
          {IconComponent2 && <IconComponent2 />}
        </div>
      </div>
    </div>
  );
};

export default AccountSettingsCard;
