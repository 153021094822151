import { Outlet } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import MobileNav from "./components/MobileNav";
import { useState } from "react";

const AuthenticatedLayout = () => {
  const [openNav, setOpenNav] = useState(false);

  const toggleNav = () => {
    setOpenNav(!openNav);
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar />
      <MobileNav openNav={openNav} closeNav={toggleNav} />
      <div className="flex-1 overflow-auto dark:bg-darkPrimary">
        <Outlet context={{ toggleNav }} />
      </div>
    </div>
  );
};

export default AuthenticatedLayout;

