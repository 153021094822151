import PageTitle from "../components/Pagetitle";
import Img from "../assets/img/Mask group.png";
import { AiOutlinePieChart } from "react-icons/ai";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { HttpClient } from "../api/HttpClient";
import { isAxiosError } from "axios";
import ToastComponent from "../components/ToastComponent";
import { ScaleLoader } from "react-spinners";
import { RiMenu2Fill, RiPlantLine } from "react-icons/ri";
import ProfilePicture from "../components/ProfilePicture";
import { formatCurrency } from "../components/CurrencyFormat";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { Account } from "../assets/Typeprops";
import { VscProject } from "react-icons/vsc";
import { GiMoneyStack } from "react-icons/gi";
type InvestmentParam = {
  tab?: string;
};

interface AuthenticatedLayoutContext {
  toggleNav: () => void;
}
const InvestmentOptions = () => {
  const { tab } = useParams<InvestmentParam>();
  const [investmentOptionSelect, setInvestmentOptionSelect] = useState(
    tab || ""
  );
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (tab && tab === investmentOptionSelect) {
      setInvestmentOptionSelect(tab);
    }
  }, [tab, investmentOptionSelect]);
  const navigate = useNavigate();
  const handleTabChange = (tabName: string) => {
    setInvestmentOptionSelect(tabName);
    navigate(`/investments/${tabName}`);
  };
  const [data, setData] = useState<Account | null>(null);
  const fetchUserData = useCallback(async () => {
    setIsLoading(true);
    try {
      const userDataRes = await HttpClient.get("/users/me", {
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      });
      setData(userDataRes?.data?.data);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      }
    } finally {
      setIsLoading(false);
    }
  }, []);
  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);
  const { toggleNav } = useOutletContext<AuthenticatedLayoutContext>();
  const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
  const handleShowProfile = () => {
    navigate("/my_account", {
      state: { openShowProfileModal: true },
    });
  };
  return (
    <>
      <PageTitle title="Affluence - Investments" />
      {isLoading ? (
        <div className="w-full h-screen bg-white dark:bg-darkPrimary flex items-center justify-center">
          <ScaleLoader color={isDarkMode ? "#FFFFFF" : "#6B006B"} />
        </div>
      ) : (
        <div className="text-textcolor bg-white dark:bg-darkPrimary dark:text-white pb-[70px] pt-[5rem]">
          <button onClick={toggleNav} className="md:hidden block px-[3%]">
            <RiMenu2Fill size={30} className="text-primary dark:text-white" />
          </button>
          <div className="pb-7 px-[3%] flex justify-between">
            <div className="md:text-[24px] sm:text-[22px] text-[20px]">
              <h1 className=" font-PoppinsBold">Investments</h1>
            </div>
            <div>
              <ProfilePicture
                profilePicsClass="cursor-pointer"
                onClick={handleShowProfile}
                profilePics={data?.photo}
              />
            </div>
          </div>
          <div className="flex gap-3 justify-between items-center px-[3%] xs:pb-0 pb-4">
            <div className="bg-primary bg-opacity-5 h-[120px] border-[1.5px] xs:w-[65%] w-full border-primary dark:border-realSecondary p-7 rounded-[8px]">
              <h1 className="font-PoppinsMedium xs:text-[14px] text-[12px]">
                TOTAL INVESTMENTS
              </h1>
              <h1 className="md:text-[28px] text-[24px] font-PoppinsBold dark:text-realSecondary text-primary">
                {data?.account
                  ? data?.hideBalance
                    ? "*******"
                    : formatCurrency(
                        data.account.investments +
                          data.account.flexible_investments +
                          data.account.regular_investments +
                          data.account.kids_investments
                      )
                  : "₦0"}
              </h1>
            </div>
            <div className=" p-7 rounded-[8px] xs:block hidden">
              <img src={Img} className="w-[300px]" alt="img" />
            </div>
          </div>
          <div className="grid xs:grid-cols-3 grid-cols-2 gap-3 px-[3%]">
            <div
              onClick={() =>
                navigate("/investments/project-investments/my_investments")
              }
              className="bg-primary cursor-pointer text-white p-5 rounded-[8px]"
            >
              <VscProject size={25} className="mb-6" />
              <h1 className="xs:text-[18px] text-base font-PoppinsSemiBold">
                Fixed Term investment
              </h1>
              <div className="xs:text-[14px] text-[12px] font-PoppinsRegular pb-5">
                <h1>- Lock in your funds</h1>
                <h1>- Earn higher interest</h1>
                <h1>- 100% secure</h1>
              </div>
              <h1 className="xs:text-[18px] text-base font-PoppinsSemiBold">
                {data?.account.investments
                  ? data?.hideBalance
                    ? "*******"
                    : formatCurrency(data.account.investments)
                  : "₦0"}
              </h1>
            </div>

            <div
              className="bg-investment cursor-pointer text-white p-5 rounded-[8px]"
              onClick={() =>
                navigate("/investments/standard-investments/my_investments")
              }
            >
              <AiOutlinePieChart size={25} className="mb-6" />
              <h1 className="xs:text-[18px] text-base font-PoppinsSemiBold">
                Flexible investment
              </h1>
              <div className="xs:text-[14px] text-[12px] font-PoppinsRegular pb-5">
                <h1>- Invest as you earn</h1>
                <h1>- Invest at any time of the month</h1>
                <h1>- Earn higher interests</h1>
                <h1>- 100% secure</h1>
              </div>
              <h1 className="xs:text-[18px] text-base font-PoppinsSemiBold">
                {" "}
                {data?.account.regular_investments
                  ? data?.hideBalance
                    ? "*******"
                    : formatCurrency(data.account.regular_investments)
                  : "₦0"}
              </h1>
            </div>
            {/* <div
              onClick={() =>
                navigate("/investments/flexible-investments/my_investments")
              }
              className="bg-secondary text-white cursor-pointer p-5 rounded-[8px]"
            >
              <GiMoneyStack size={25} className="mb-6" />
              <h1 className="xs:text-[18px] text-base font-PoppinsSemiBold">
                Flexible Investments
              </h1>
              <div className="xs:text-[14px] text-[12px] font-PoppinsRegular pb-5">
                <h1>- Build your emergency fund</h1>
                <h1>- Prepare for sudden needs</h1>
                <h1>- Earn an interest</h1>
                <h1>- Take it anytime there’s an emergency</h1>
                <h1>- 100% secure</h1>
              </div>
              <h1 className="xs:text-[18px] text-base font-PoppinsSemiBold">
                {" "}
                {data?.account.flexible_investments
                  ? data?.hideBalance
                    ? "*******"
                    : formatCurrency(data.account.flexible_investments)
                  : "₦0"}
              </h1>
            </div> */}
            {/* <div
              onClick={() =>
                navigate("/investments/kids-investments/my_investments")
              }
              className="bg-deposit text-white cursor-pointer p-5 rounded-[8px]"
            >
              <RiPlantLine size={25} className="mb-6" />
              <h1 className="xs:text-[18px] text-base font-PoppinsSemiBold">
                Kids Investments
              </h1>
              <div className="xs:text-[14px] text-[12px] font-PoppinsRegular pb-5">
                <h1>- Invest for your kids in their name</h1>
                <h1>- Hand it over at a specified age</h1>
                <h1>- View kids' investment on your dashboard</h1>
                <h1>- Invest for multiple kids</h1>
                <h1>- Earn higher interest</h1>
                <h1>- 100% secure</h1>
              </div>
              <h1 className="xs:text-[18px] text-base font-PoppinsSemiBold">
                {" "}
                {data?.account.kids_investments
                  ? data?.hideBalance
                    ? "*******"
                    : formatCurrency(data.account.kids_investments)
                  : "₦0"}
              </h1>
            </div> */}
          </div>
        </div>
      )}
    </>
  );
};

export default InvestmentOptions;
