import { Table } from "antd";
import PageTitle from "../../../components/Pagetitle";
import Sidebar from "../../sidebar/Sidebar";
import { useEffect, useState } from "react";
import { HttpClient } from "../../../api/HttpClient";
import { ColumnsType } from "antd/es/table";
import errorHandler from "../../ErrorHandler";
import { format } from "date-fns";
import { ScaleLoader } from "react-spinners";
import { IoAddOutline, IoArrowBackOutline } from "react-icons/io5";
import Modal from "../../Modal";
import ToastComponent from "../../../components/ToastComponent";
import * as Yup from "yup";
import TextInput from "../../../components/TextInput";
import { Formik, Form, Field } from "formik";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

interface CategoryType {
  _id: string;
  key?: string;
  name: string;
  createdAt: string;
  updatedAt: string;
}

function InvestmentCategory() {
  const [loading, setLoading] = useState(false);
  const [isAddCatVisible, setAddCatVisible] = useState(false);
  const [isEditCatVisible, setEditCatVisible] = useState(false);
  const [isDeleteCatVisible, setDeleteCatVisible] = useState(false);
  const [selectedCat, setSelectedCat] = useState<CategoryType | null>(null);
  const [categoryList, setCategoryList] = useState<CategoryType[]>([]);

  const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);

  const addCatSchema = Yup.object().shape({
    name: Yup.string().required("Category name is required"),
  });

  const fetchCategory = async () => {
    setLoading(true);
    try {
      const catRes = await HttpClient.get("/investments/category", {
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      });

      const categories = catRes.data.data.map((cat: any, index: number) => ({
        key: cat._id,
        serialNumber: index + 1,
        name: cat?.name,
        createdAt: cat?.createdAt,
        updatedAt: cat?.updatedAt,
      }));

      setCategoryList(categories);
    } catch (error: any) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchCategory();
  }, []);

  const handleSubmitCat = async (
    values: any,
    {
      setFieldValue,
    }: {
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean
      ) => void;
    }
  ) => {
    try {
      const addNewCat = new FormData();
      addNewCat.append("name", values.name);

      const addCatRes = await HttpClient.post(
        "/investments/category",
        addNewCat,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );

      ToastComponent.success(
        addCatRes.data.message || "Category added successfully"
      );
      await fetchCategory();
      setAddCatVisible(false);
    } catch (error: any) {
      errorHandler(error);
    }
  };

  const handleEdit = async (values: any) => {
    try {
      const catId = selectedCat?.key;
      const editCatRes = await HttpClient.put(
        `/investments/category/${catId}`,
        { name: values.name },
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      ToastComponent.success(
        editCatRes.data.message || "Category updated successfully"
      );
      await fetchCategory();
      setEditCatVisible(false);
      setSelectedCat(null);
    } catch (error: any) {
      errorHandler(error);
    }
  };

  const showDeleteModal = (cat: CategoryType) => {
    setSelectedCat(cat);
    setDeleteCatVisible(true);
  };

  const handleDelete = async () => {
    try {
      const catId = selectedCat?.key;

      const catRes = await HttpClient.delete(`/investments/category/${catId}`, {
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      });

      ToastComponent.success(
        catRes.data.message || "Category deleted successfully"
      );

      await fetchCategory();
      setDeleteCatVisible(false);
      setSelectedCat(null);
    } catch (error: any) {
      errorHandler(error);
    }
  };

  const handleCancel = () => {
    setDeleteCatVisible(false);
    setSelectedCat(null);
  };

  const columns: ColumnsType<CategoryType> = [
    {
      key: "serialNumber",
      title: "S/N",
      dataIndex: "serialNumber",
    },
    {
      key: "createdAt",
      title: "Date Created",
      dataIndex: "createdAt",
      render: (dateTime: string) => {
        const date = new Date(dateTime);
        const formattedDate = format(date, "MMM dd, yyyy");
        const formattedTime = format(date, "hh:mm a");
        return (
          <div className="text-textcolor">
            <div className="font-PoppinsRegular">{formattedDate}</div>
            <div className="font-PoppinsLight">{formattedTime}</div>
          </div>
        );
      },
    },
    {
      key: "updatedAt",
      title: "Date Updated",
      dataIndex: "updatedAt",
      render: (dateTime: string) => {
        const date = new Date(dateTime);
        const formattedDate = format(date, "MMM dd, yyyy");
        const formattedTime = format(date, "hh:mm:ss a");
        return (
          <div className="text-textcolor">
            <div className="font-PoppinsRegular">{formattedDate}</div>
            <div className="font-PoppinsLight">{formattedTime}</div>
          </div>
        );
      },
    },
    {
      key: "name",
      title: "Category Name",
      dataIndex: "name",
    },
    {
      key: "actions",
      title: "Actions",
      render: (_, record: CategoryType) => (
        <div className="flex gap-2" key={record._id}>
          <button
            className="text-white bg-success px-5 py-1 rounded-md"
            onClick={() => {
              setSelectedCat(record);
              setEditCatVisible(true);
            }}
          >
            Edit
          </button>
          <button
            className="text-white bg-error px-3 py-1 rounded-md"
            onClick={() => showDeleteModal(record)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  return (
    <>
      <PageTitle title="Investment Category" />
      <div className="flex h-screen dark:bg-darkPrimary">
        <Sidebar />
        <div className="flex-1 overflow-y-auto">
          {loading ? (
            <div className="w-full h-screen bg-white dark:bg-darkPrimary flex items-center justify-center">
              <ScaleLoader color={isDarkMode ? "#FFFFFF" : "#6B006B"} />
            </div>
          ) : (
            <div className="mt-28 p-4 shadow-lg border border-transactionCard rounded-lg mx-4">
              <div className="flex flex-col md:flex-row justify-between p-2 mb-4">
                <span className="dark:text-white text-semibold text-textcolor font-PoppinsBold mb-4">
                  Investment Category
                </span>

                <div className="flex space-x-4">
                  {!isAddCatVisible && !isEditCatVisible && (
                    <button
                      className="dark:text-white dark:border-white py-2 px-4 border border-primary text-primary rounded-md flex md:items-center justify-center w-full sm:w-auto"
                      onClick={() => setAddCatVisible(true)}
                    >
                      <IoAddOutline
                        size={20}
                        className="dark:text-white inline mr-2"
                      />
                      Add Category
                    </button>
                  )}

                  {(isAddCatVisible || isEditCatVisible) && (
                    <button
                      className="dark:text-white dark:border-white py-2 px-4 border border-primary text-primary rounded-md flex items-center"
                      onClick={() => {
                        setAddCatVisible(false);
                        setEditCatVisible(false);
                      }}
                    >
                      <IoArrowBackOutline
                        size={20}
                        className="dark:text-white inline mr-2"
                      />
                      Back to Category
                    </button>
                  )}
                </div>
              </div>

              {isAddCatVisible && (
                <div className="overflow-x-auto">
                  <Formik
                    initialValues={{ name: "" }}
                    validationSchema={addCatSchema}
                    onSubmit={handleSubmitCat}
                  >
                    <Form className="w-full">
                      <div className="mb-6">
                        <label
                          htmlFor="name"
                          className="dark:text-white block text-primary font-PoppinsLight text-[14px] mb-1"
                        >
                          Add Investment Category
                        </label>
                        <TextInput
                          type="text"
                          name="name"
                          className="dark:border-white dark:focus:border-white w-full h-12 text-textcolor border border-transactionCard font-PoppinsRegular px-2 py-1 rounded-lg focus:outline-none focus:border-primary"
                        />
                      </div>

                      <button
                        type="submit"
                        className="dark:text-white dark:border-white py-2 px-4 border border-primary text-primary font-PoppinsMedium rounded-lg"
                      >
                        Add Category
                      </button>
                    </Form>
                  </Formik>
                </div>
              )}

              {isEditCatVisible && (
                <div className="overflow-x-auto">
                  <Formik
                    initialValues={{ name: selectedCat?.name || "" }}
                    enableReinitialize={true}
                    validationSchema={addCatSchema}
                    onSubmit={handleEdit}
                  >
                    {({ values, handleChange }) => (
                      <Form className="w-full">
                        <div className="mb-6">
                          <label
                            htmlFor="name"
                            className="dark:text-white block text-primary font-PoppinsLight text-[14px] mb-1"
                          >
                            Edit Investment Category
                          </label>
                          <Field
                            type="text"
                            name="name"
                            className="w-full h-12 text-textcolor border border-transactionCard font-PoppinsRegular px-2 py-1 rounded-lg focus:outline-none focus:border-primary"
                            value={values.name}
                            onChange={handleChange}
                          />
                        </div>

                        <button
                          type="submit"
                          className="dark:text-white dark:border-white py-2 px-4 border border-primary text-primary font-PoppinsMedium rounded-lg"
                        >
                          Save Changes
                        </button>
                      </Form>
                    )}
                  </Formik>
                </div>
              )}

              {!isAddCatVisible && !isEditCatVisible && (
                <div className="dark:bg-white overflow-x-auto">
                  <Table dataSource={categoryList} columns={columns} />
                </div>
              )}
            </div>
          )}
        </div>

        {isDeleteCatVisible && (
          <Modal onClose={handleCancel}>
            <h2 className="text-primary font-PoppinsSemiBold">
              Confirm Delete
            </h2>
            <div className="mt-4 text-textcolor">
              <span className="mt-6">Are you sure you want to delete</span>{" "}
              <span className="text-primary font-PoppinsMedium">
                {selectedCat?.name}
              </span>
              ?
            </div>
            <div className="flex justify-end space-x-4 mt-8">
              <button
                className="text-error"
                onClick={handleCancel}
                type="button"
              >
                Cancel
              </button>
              <button
                className="text-success"
                type="submit"
                onClick={handleDelete}
              >
                Confirm
              </button>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
}

export default InvestmentCategory;
