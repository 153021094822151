import { ScaleLoader } from "react-spinners";
import PageTitle from "../../../../components/Pagetitle";
import Sidebar from "../../../sidebar/Sidebar";
import { useEffect, useState } from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { IoArrowBackOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { HttpClient } from "../../../../api/HttpClient";
import ErrorHandler from "../../../ErrorHandler";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { formatCurrency } from "../../../../components/CurrencyFormat";
import { format } from "date-fns";

interface UserDetails {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

interface InvestmentDetails {
  title: string;
}
interface Investment {
  _id: string;
  user_details: UserDetails;
  investment_details: InvestmentDetails;
  createdAt: string;
  amount: number;
  roiExpected: number;
  isRoiSettled: boolean;
  totalPayable: number;
  investmentStatus: string;
}

function ViewRegularInvestors() {
  const [loading, setLoading] = useState(true);
  const { investmentId } = useParams();
  const [viewInvestors, setViewInvestors] = useState<Investment[]>([]);
  const [, setInvestmentTitle] = useState<string>("");
  const navigate = useNavigate();

  const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);

  useEffect(() => {
    const fetchInvestmentDetails = async () => {
      try {
        const listInvestRes = await HttpClient.get(
          `/regular-investments/booking`,
          {
            headers: {
              "x-auth-token": localStorage.getItem("token"),
            },
            params: { investmentId },
          }
        );

        const investHis = listInvestRes.data?.data?.data.map(
          (item: any, index: number) => ({
            key: item._id,
            serialNumber: index + 1,
            name:
              `${item?.user_details?.firstName} ${item?.user_details?.lastName}` ||
              "",
            email: item?.user_details?.email || "",
            phone: item?.user_details?.phone || "",
            title: item?.investment_details?.title,
            userId: item?.user_details?._id,
            createdAt: item?.createdAt,
            amount: item?.amount,
            roiExpected: item?.roiExpected,
            isRoiSettled: item?.isRoiSettled ? "Yes" : "No",
            totalPayable: item?.totalPayable,
            investmentStatus: item?.investmentStatus,
          })
        );

        setViewInvestors(investHis);
        setInvestmentTitle(investHis?.title);
      } catch (error: any) {
        ErrorHandler(error);
      } finally {
        setLoading(false);
      }
    };

    fetchInvestmentDetails();
  }, [investmentId]);

  const handleNavigateToInvestmentPlan = () => {
    navigate("/admin/investment/regular_investment_plan");
  };

  const handleRowClick = (record: any) => {
    navigate(`/admin/allCustomers/${record.userId}`);
  };

  const viewColumns: ColumnsType = [
    {
      key: "serialNumber",
      title: "S/N",
      dataIndex: "serialNumber",
    },
    {
      key: "createdAt",
      title: "Date",
      dataIndex: "createdAt",
      render: (dateTime: string) => {
        const date = new Date(dateTime);
        const formattedDate = format(date, "MMM dd, yyyy");
        const formattedTime = format(date, "hh:mm a");

        return (
          <div className="text-textcolor" style={{ width: "100px" }}>
            <div className="font-PoppinsRegular">{formattedDate}</div>
            <div className="font-PoppinsLight">{formattedTime}</div>
          </div>
        );
      },
    },
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      render: (name: string) => {
        return (
          <div className="capitalize" style={{ width: "100px" }}>
            {name}
          </div>
        );
      },
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
    },
    {
      title: "Phone No.",
      key: "phone",
      dataIndex: "phone",
    },
    {
      title: "Amount",
      key: "amount",
      dataIndex: "amount",
      render: (amount: number) => formatCurrency(amount),
    },
    {
      title: "Expected RoI",
      key: "roiExpected",
      dataIndex: "roiExpected",
      render: (roiExpected: number) => formatCurrency(roiExpected),
    },
    {
      title: "RoI Settled",
      key: "isRoiSettled",
      dataIndex: "isRoiSettled",
      render: (isRoiSettled: boolean) => (isRoiSettled ? "Yes" : "No"),
    },
    {
      title: "Total Payable",
      key: "totalPayable",
      dataIndex: "totalPayable",
      render: (totalPayable: number) => formatCurrency(totalPayable),
    },
    {
      title: "Status",
      key: "investmentStatus",
      dataIndex: "investmentStatus",
      className: "capitalize",
      render: (investmentStatus: string) =>
        investmentStatus === "completed" ||
        investmentStatus === "matured" ||
        investmentStatus === "settled" ? (
          <span className="text-center text-[0.8em] rounded-[30px] py-[0.35rem] px-[0.9rem] bg-opacity-[28%] text-primary bg-primary font-PoppinsRegular">
            {investmentStatus}
          </span>
        ) : investmentStatus === "pending" ? (
          <span className="text-center text-[0.8em] rounded-[30px] py-[0.35rem] px-[0.9rem] bg-opacity-[28%] text-realSecondary bg-realSecondary font-PoppinsRegular">
            {investmentStatus}
          </span>
        ) : (
          <span className="text-center text-[0.8em] rounded-[30px] py-[0.35rem] px-[0.9rem] bg-opacity-[28%] bg-success text-success font-PoppinsRegular">
            {investmentStatus}
          </span>
        ),
    },
  ];

  return (
    <>
      <PageTitle title="Investment Category" />
      <div className="flex h-screen dark:bg-darkPrimary">
        <Sidebar />
        <div className="flex-1 overflow-y-auto">
          {loading ? (
            <div className="w-full h-screen bg-white dark:bg-darkPrimary flex items-center justify-center">
              <ScaleLoader color={isDarkMode ? "#FFFFFF" : "#6B006B"} />
            </div>
          ) : (
            <div className="flex-1 p-4 mt-28 shadow-md border border-transactionCard rounded-lg mx-4">
              <div className="flex flex-col md:items-center justify-between sm:flex-row sm:items-center">
                <h1 className="dark:text-white text-semibold text-textcolor font-PoppinsBold block mb-4">
                  Investors Details
                </h1>
                <button
                  className="dark:border-white dark:text-white border text-center cursor-pointer border-primary text-primary px-4 py-2 rounded-md align-middle sm:text-[14px] whitespace-nowrap"
                  onClick={handleNavigateToInvestmentPlan}
                >
                  <IoArrowBackOutline size={20} className="inline mr-2" />
                  Back to Investment Plan
                </button>
              </div>
              <div className="dark:bg-white overflow-x-auto mt-4">
                <Table
                  dataSource={viewInvestors}
                  columns={viewColumns}
                  onRow={(record) => ({
                    onClick: () => handleRowClick(record),
                    className: "cursor-pointer",
                  })}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ViewRegularInvestors;
