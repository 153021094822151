import React, { useState } from "react";
import { useField } from "formik";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

interface CustomSelectProps {
  name: string;
  options: { value: string; label: string; logo?: string }[];
  label?: string | boolean;
  placeholder: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const SelectInputWithLogo: React.FC<CustomSelectProps> = ({
  name,
  options,
  label,
  placeholder,
  onChange,
}) => {
  const [field, meta, helpers] = useField(name);
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (value: string) => {
    helpers.setValue(value);
    setIsOpen(false);

    if (onChange) {
      const event = {
        target: {
          name,
          value,
        },
      } as React.ChangeEvent<HTMLInputElement>;
      onChange(event);
    }
  };

  return (
    <div className="relative pb-4">
      {label && (
        <div>
          <label className="font-PoppinsMedium text-[14px]" htmlFor={name}>
            {label}
          </label>
        </div>
      )}
      <div className="relative" onClick={() => setIsOpen(!isOpen)}>
        <div
          className={`sm:p-3.5 p-3 w-full ${
            isOpen ? "border-primary" : "border-authbordercolor"
          } text-[15px] outline-none border-[1.5px] dark:bg-darkPrimary dark:text-white rounded-[8px] font-PoppinsMedium cursor-pointer text-textcolor`}
        >
          {field.value
            ? options.find((option) => option.value === field.value)?.label
            : placeholder}
        </div>
        <div className="absolute top-4 right-4">
          {isOpen ? <IoIosArrowUp size={22} /> : <IoIosArrowDown size={22} />}
        </div>
      </div>
      {isOpen && (
        <div className="absolute z-10 w-full bg-white border border-authbordercolor rounded-[8px] mt-1 shadow-lg max-h-48 overflow-y-auto">
          {options.map((option, index) => (
            <div
              key={option.value}
              className={`p-2 hover:bg-primary dark:bg-darkPrimary dark:hover:bg-white dark:hover:text-darkPrimary flex items-center gap-2 font-PoppinsMedium text-[14px] hover:text-white cursor-pointer ${
                index === 0 ? "rounded-t-[8px]" : ""
              } ${index === options.length - 1 ? "rounded-b-[8px]" : ""}`}
              onClick={() => handleSelect(option.value)}
            >
              {option.logo && (
                <img src={option.logo} className="w-4 h-4" alt="logo" />
              )}
              {option.label}
            </div>
          ))}
        </div>
      )}
      {meta.touched && meta.error ? (
        <div className="text-error text-[12px] mt-1">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default SelectInputWithLogo;
