import React, { useEffect, useState } from "react";
import ToastComponent from "../../../../components/ToastComponent";
import { HttpClient } from "../../../../api/HttpClient";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage } from "formik";
import TextInput from "../../../../components/TextInput";
import TextAreaInput from "../../../../components/TextAreaInput";
import FileInput from "../../../../components/FileInput";
import CreateBtn from "../CreateBtn";
import ErrorHandler from "../../../ErrorHandler";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { ScaleLoader } from "react-spinners";
import { CreateInvestData } from "../../../../assets/Typeprops";
import SelectInput from "../../../../components/SelectInput";

type MyComponentProps = {
  onBack?: () => void;
  record?: CreateInvestData;
  onSubmit?: (formData: CreateInvestData) => void;
};

interface CategoryType {
  _id: string;
  key?: string;
  name: string;
}

const CreateRegularPlan: React.FC<MyComponentProps> = ({ record }) => {
  const [isImageSelected, setIsImageSelected] = useState<boolean>(true);
  const [categoryList, setCategoryList] = useState<CategoryType[]>([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);

  const validationSchema = Yup.object({
    investmentTitle: Yup.string().required("Title is required"),
    investmentDescription: Yup.string().required("Description is required"),
    investmentDisclaimer: Yup.string(),
    investmentCategory: Yup.string().required("Category is required"),
    investmentRate: Yup.number()
      .min(1, "Rate must not be less than 1")
      .max(100, "Rate must not be greater than 100")
      .required("Rate is required"),
    investmentLiquidationFee: Yup.number()
      .min(0, "Liquidation fee must not be less than 0")
      .required("Liquidation fee is required"),
    investmentClaim: Yup.string().required("Claim is required"),
    investmentStatus: Yup.string().required("Status is required"),
    investmentPreviewImage: Yup.mixed().nullable(),
    investmentMinimumAmount:Yup.number().optional(),
  });

  const handleNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any) => void,
    field: string
  ) => {
    const value = e.target.value;
    const filteredValue = value.replace(/[^0-9.]/g, "");
    setFieldValue(field, filteredValue);
  };

  const handleSubmit = async (
    values: CreateInvestData,
    { setSubmitting, resetForm }: any
  ) => {
    const investData = new FormData();

    Object.entries(values).forEach(([key, value]) => {
      if (key === "investmentPreviewImage" && value) {
        investData.append("investmentPreviewImage", value as File);
      } else if (value !== undefined && value !== null) {
        investData.append(key, value as string | Blob);
      }
    });

    try {
      const url = "/regular-investments/plan";
      const token = localStorage.getItem("token");

      const res = await HttpClient(url, {
        method: "POST",
        headers: {
          "x-auth-token": token,
          "Content-Type": "multipart/form-data",
        },
        data: investData,
      });

      if (res) {
        ToastComponent.success(
          res.data.message || "Investment plan submitted successfully!"
        );
        resetForm();
        navigate("/admin/investment/regular_investment_plan");
      } else {
        ToastComponent.error(
          "Failed to submit investment plan. Please try again."
        );
      }
    } catch (error: any) {
      ErrorHandler(error);
    } finally {
      setSubmitting(false);
    }
  };

  const fetchCategory = async () => {
    setLoading(true);
    try {
      const catRes = await HttpClient.get("/investments/category", {
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      });

      setCategoryList(catRes.data.data);
    } catch (error: any) {
      ErrorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategory();
  }, []);

  const handleCreateCategory = () => {
    navigate("/admin/investment/add_category");
  };

  return (
    <div className="h-full flex">
      {loading ? (
        <div className="w-full h-screen bg-white dark:bg-darkPrimary flex items-center justify-center">
          <ScaleLoader color={isDarkMode ? "#FFFFFF" : "#6B006B"} />
        </div>
      ) : (
        <div className=" flex-grow flex flex-col overflow-y-auto p-8 w-full md:w-3/4">
          <Formik
            initialValues={{
              investmentTitle: record?.investmentTitle || "",
              investmentDescription: record?.investmentDescription || "",
              investmentDisclaimer: record?.investmentDisclaimer || "The information provided by Affluence is for informational purposes only and does not constitute personalized investment advice. All investments carry inherent risks, and the value of investments can fluctuate. Past performance is not indicative of future results. Before making any investment decisions, we recommend consulting with a qualified financial advisor to assess your individual circumstances and risk tolerance.",
              investmentCategory: record?.investmentCategory || "",
              investmentRate: record?.investmentRate || "",
              investmentMinimumAmount: record?.investmentMinimumAmount || "",
              investmentLiquidationFee: record?.investmentLiquidationFee || 0,
              investmentClaim: record?.investmentClaim || "",
              investmentStatus: record?.investmentStatus || "pending",
              investmentPreviewImage: record?.investmentPreviewImage || null,
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, isValid, dirty }) => (
              <Form className="w-full">
                <div className="mb-4">
                  <label
                    htmlFor="investmentTitle"
                    className="dark:text-white block text-primary font-PoppinsLight text-[14px] mb-1"
                  >
                    Title
                  </label>
                  <TextInput
                    type="text"
                    name="investmentTitle"
                    placeholder="Enter investment title"
                    className="w-full h-12 border border-transactionCard font-PoppinsRegular px-2 py-1 rounded-lg focus:outline-none focus:border-primary"
                  />
                </div>

                <div className="mb-4">
                  <label
                    htmlFor="investmentDescription"
                    className="dark:text-white block text-primary font-PoppinsLight text-[14px] mb-1"
                  >
                    Description
                  </label>
                  <TextAreaInput
                    as="textarea"
                    name="investmentDescription"
                    placeholder="Enter Investment Description"
                    className="w-full border border-transactionCard font-PoppinsRegular h-40 px-2 py-1 rounded-lg focus:outline-none focus:border-primary"
                  />
                </div>

                <div className="mb-4">
                  <label
                    htmlFor="investmentDisclaimer"
                    className="dark:text-white block text-primary font-PoppinsLight text-[14px] mb-1"
                  >
                    Disclaimer
                  </label>
                  <TextAreaInput
                    as="textarea"
                    name="investmentDisclaimer"
                    placeholder="Enter Investment Disclaimer"
                    className="w-full border border-transactionCard font-PoppinsRegular h-40 px-2 py-1 rounded-lg focus:outline-none focus:border-primary"
                  />
                </div>
                <div className="grid gap-4 mb-4 grid-cols-1 md:grid-cols-2">
                  <div className="mb-4">
                    <label
                      htmlFor="investmentCategory"
                      className="dark:text-white block text-primary font-PoppinsLight text-[14px] mb-1"
                    >
                      Category
                    </label>
                    {categoryList.length > 0 ? (
                      <div className="relative w-full">
                        <SelectInput
                          name="investmentCategory"
                          options={categoryList.map((category) => ({
                            value: category.name,
                            label: category.name,
                          }))}
                          placeholder="Select Category"
                        />
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="dark:bg-white dark:text-textcolor w-full h-12 px-4 py-2 bg-primary text-white font-PoppinsLight rounded-lg hover:bg-primary-dark focus:outline-none"
                        onClick={handleCreateCategory}
                      >
                        Create a New Category
                      </button>
                    )}
                    </div>
                    <div className="mb-4">
                    <label
                      htmlFor="investmentMinimumAmount"
                      className="dark:text-white block text-primary font-PoppinsLight text-[14px] mb-1"
                    >
                      Minimum Amount
                    </label>
                    <TextInput
                      type="number"
                      name="investmentMinimumAmount"
                      placeholder="1000"
                      className="w-full border border-transactionCard font-PoppinsRegular h-12 px-2 rounded-lg focus:outline-none focus:border-primary"
                      onChange={(e) =>
                        handleNumberChange(
                          e,
                          setFieldValue,
                          "investmentMinimumAmount"
                        )
                      }
                    />
                  </div>
                </div>

                <div className="grid gap-4 mb-4 grid-cols-1 md:grid-cols-2">
                  <div className="mb-4">
                    <label
                      htmlFor="investmentLiquidationFee"
                      className="dark:text-white block text-primary font-PoppinsLight text-[14px] mb-1"
                    >
                      Liquidation Fee
                    </label>
                    <TextInput
                      type="number"
                      name="investmentLiquidationFee"
                      placeholder="10"
                      className="w-full border border-transactionCard font-PoppinsRegular h-12 px-2 rounded-lg focus:outline-none focus:border-primary"
                      onChange={(e) =>
                        handleNumberChange(
                          e,
                          setFieldValue,
                          "investmentLiquidationFee"
                        )
                      }
                    />
                  </div>
                  <div className="w-full block">
                    <label
                      htmlFor="investmentRate"
                      className="dark:text-white block text-primary font-PoppinsLight text-[14px] mb-1"
                    >
                      Rate
                    </label>
                    <TextInput
                      type="number"
                      name="investmentRate"
                      placeholder="10"
                      className="w-full border border-transactionCard font-PoppinsRegular h-12 px-2 rounded-lg focus:outline-none focus:border-primary"
                      onChange={(e) =>
                        handleNumberChange(e, setFieldValue, "investmentRate")
                      }
                    />
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                  <div className="w-full">
                    <label
                      htmlFor="investmentClaim"
                      className="dark:text-white block text-primary font-PoppinsLight text-[14px] mb-1"
                    >
                      Claim
                    </label>
                    <SelectInput
                      name="investmentClaim"
                      options={[
                        { value: "YES", label: "Yes" },
                        { value: "NO", label: "No" },
                      ]}
                      placeholder="Select Claim"
                    />
                  </div>

                  <div className="w-full">
                    <label
                      htmlFor="investmentStatus"
                      className="dark:text-white block text-primary font-PoppinsLight text-[14px] mb-1"
                    >
                      Status
                    </label>
                    <SelectInput
                      name="investmentStatus"
                      options={[
                        { value: "pending", label: "Pending" },
                        { value: "running", label: "Running" },
                      ]}
                      placeholder="Select Status"
                    />
                  </div>
                </div>

                <div className="flex justify-center items-center mb-4">
                  <div className="dark:text-textcolor dark:border-white">
                    <FileInput
                      label="Kindly Upload Investment Image"
                      name="investmentPreviewImage"
                      className=" w-full border-2 border-dashed p-4 text-center cursor-pointer border-primary rounded-lg"
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        const file = event.target.files?.[0];
                        setFieldValue("investmentPreviewImage", file);
                        setIsImageSelected(!!file);
                      }}
                    />

                    <ErrorMessage
                      name="image"
                      component="div"
                      className="text-error text-[13px] font-PoppinsLight"
                    />
                  </div>
                </div>

                <div className="flex justify-start mt-8 gap-4">
                  <CreateBtn
                    type="submit"
                    className="font-PoppinsMedium rounded-lg text-success hover:before:bg-success border-success relative h-[50px] w-40 overflow-hidden border  bg-white px-3 transition-all before:absolute before:bottom-0 before:left-0 before:top-0 before:z-0 before:h-full before:w-0 before:bg-success before:transition-all before:duration-500 hover:text-white hover:before:left-0 hover:before:w-full"
                    isImageSelected={isImageSelected}
                    disabled={!isValid || !dirty}
                  >
                    <span className="relative z-10">
                      {record ? "Update" : "Submit"}
                    </span>
                  </CreateBtn>
                  <button
                    type="reset"
                    className="font-PoppinsMedium rounded-lg text-error hover:before:bg-error border-error relative h-[50px] w-40 overflow-hidden border  bg-white px-3 transition-all before:absolute before:bottom-0 before:left-0 before:top-0 before:z-0 before:h-full before:w-0 before:bg-error before:transition-all before:duration-500 hover:text-white hover:before:left-0 hover:before:w-full"
                  >
                    <span className="relative z-10">Cancel</span>
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
};

export default CreateRegularPlan;
