import { FC } from "react";
import { IconType } from "react-icons";
import { TiTick } from "react-icons/ti";

interface AccountSettingsProps {
  children: any;
  icon1?: IconType;
  onClick?: () => void;
  label?: string;
  description?: string;
  comingSoon?: boolean;
  isSet?: boolean;
}
const SetAccountSettingsCard: FC<AccountSettingsProps> = ({
  children,
  icon1: IconComponent1,
  onClick,
  label,
  description,
  comingSoon,
  isSet,
}) => {
  return (
    <div className="mb-2">
      <h1 className="text-[12px] font-PoppinsRegular pb-1">{label}</h1>

      <div
        className="p-4 rounded-[4px] cursor-pointer flex items-center justify-between border-[1.5px] border-transactionCard
    "
        onClick={onClick}
      >
        <div className="dark:text-white flex items-center space-x-5">
          <div className="dark:text-white md:text-[22px] sm:text-[20px] text-primary text-[18px]">
            {IconComponent1 && <IconComponent1 />}
          </div>
          <div>
            <h1 className="font-PoppinsRegular text-[14px]">{children}</h1>
            {description && (
              <h1 className="font-PoppinsLight text-[13px] pt-2">
                {description}
              </h1>
            )}
          </div>
        </div>
        {comingSoon && (
          <div className="text-[10px] dark:text-white text-secondary bg-secondary bg-opacity-20 px-2 py-1 dark:bg-white dark:bg-opacity-20 rounded-full">
            Coming Soon
          </div>
        )}
        {isSet && (
          <div className="bg-success h-5 rounded-full w-5 flex justify-center items-center">
            <TiTick className="text-white" />
          </div>
        )}
      </div>
    </div>
  );
};

export default SetAccountSettingsCard;
