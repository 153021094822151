import { FC } from "react";
import { IconType } from "react-icons";

interface TransactionCardProps {
  transactionActivity: React.ReactNode | null;
  transactionTime: string;
  transactionAmount: string;
  icon?: React.ElementType; // Change here
  iconClassname: string;
  isInFlow?: boolean;
  paymentStatus?: string;
  responseMsg?: string;
}

const TransactionCard: FC<TransactionCardProps> = ({
  transactionActivity,
  transactionTime,
  transactionAmount,
  icon: IconComponent,
  iconClassname,
  isInFlow,
  paymentStatus,
}) => {
  const amountSign =
    isInFlow && paymentStatus === "successful"
      ? "+"
      : !isInFlow && paymentStatus === "successful"
      ? "-"
      : "";

  return (
    <div className="border border-transactionCard mb-3 rounded-[8px] flex justify-between p-4">
      <div className="flex items-center gap-5">
        <div className={iconClassname}>
          {IconComponent && <IconComponent size={22} className="icon" />}
        </div>
        <div className="font-PoppinsMedium sm:text-[15px] text-[13px]">
          <h1 className="dark:text-white">{transactionActivity}</h1>
          <h1 className="text-disabledtext text-[12px]">{transactionTime}</h1>
        </div>
      </div>
      <div>
        <h1
          className={`font-PoppinsMedium sm:text-[15px] text-[13px] ${
            isInFlow && paymentStatus === "successful"
              ? "text-success"
              : "text-error"
          }`}
        >
          {amountSign} {transactionAmount}
        </h1>
        <div
          className={`font-normal sm:text-[12px] text-center text-[10px] bg-opacity-25 py-[2px] px-2 rounded-[30px] ${
            paymentStatus === "successful"
              ? "text-success bg-success"
              : "text-error bg-error"
          }`}
        >
          {paymentStatus}
        </div>
      </div>
    </div>
  );
};

export default TransactionCard;
