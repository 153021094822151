import React, { useEffect, useState } from "react";
import { format, parseISO } from "date-fns";
import Table, { ColumnsType } from "antd/es/table";
import { Input, Select } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { ScaleLoader } from "react-spinners";
import PageTitle from "../../components/Pagetitle";
import Sidebar from "../sidebar/Sidebar";
import { HttpClient } from "../../api/HttpClient";
import errorHandler from "../ErrorHandler";
import { useNavigate } from "react-router-dom";
import Modal from "../Modal";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import TextInput from "../../components/TextInput";
import TextAreaInput from "../../components/TextAreaInput";
import ToastComponent from "../../components/ToastComponent";

type CustomerKycDetails = {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  kycDetails: {
    _id: string;
    user: string;
    createdAt?: string;
    kycApprovalStatus: boolean;
    kycLevel: number;
    kycLevelOneStatus: string;
    kycLevelTwoStatus: string;
    kycLevelThreeStatus: string;
    updatedAt: string;
  };
};

function AdminKYC() {
  const [allUsersKyc, setUsersKyc] = useState<CustomerKycDetails[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchStatus, setSearchStatus] = useState("");
  const [searchLevel, setSearchLevel] = useState("");
  const [filteredKyc, setFilteredKyc] = useState<CustomerKycDetails[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const navigate = useNavigate();

  const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);

  const pendingKycEmailValidation = Yup.object({
    subject: Yup.string().required("Email Subject is required"),
    body: Yup.string().required("Message is required"),
  });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const allUserdataRes = await HttpClient.get("/admin/view-users", {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          params: {
            dir: "desc",
          },
        });
        const dataWithSerialNumbers = allUserdataRes.data?.data.map(
          (item: any, index: number) => ({
            key: item._id,
            serialNumber: index + 1,
            name: item?.firstName + " " + item?.lastName,
            email: item?.email,
            phone: item?.phone,
            date: item?.kycDetails?.createdAt,
            kycApprovalStatus: item?.kycDetails?.kycApprovalStatus,
            kycLevel: item?.kycDetails?.kycLevel,
            kycLevelOneStatus: item?.kycDetails?.kycLevelOneStatus,
            kycLevelTwoStatus: item?.kycDetails?.kycLevelTwoStatus,
            kycLevelThreeStatus: item?.kycDetails?.kycLevelThreeStatus,
          })
        );

        setUsersKyc(dataWithSerialNumbers);
        setFilteredKyc(allUserdataRes.data?.data);
      } catch (error: any) {
        errorHandler(error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    const filteredData = allUsersKyc.filter((user) => {
      const kycDetail = user?.kycDetails;

      let statusMatch = false;
      if (searchStatus === "") {
        statusMatch = true;
      } else if (searchStatus.toLowerCase() === "approved") {
        statusMatch = kycDetail?.kycApprovalStatus === true;
      } else if (
        searchStatus.toLowerCase() === "in view" ||
        searchStatus.toLowerCase() === "rejected"
      ) {
        statusMatch = kycDetail?.kycApprovalStatus === false;
      }
      const levelMatch =
        searchLevel === "" || kycDetail?.kycLevel;

      return statusMatch && levelMatch;
    });
    setFilteredKyc(filteredData);
  }, [searchStatus, searchLevel, allUsersKyc]);

  const columns: ColumnsType<CustomerKycDetails> = [
    {
      title: "S/N",
      dataIndex: "serialNumber",
      key: "serialNumber",
    },
    {
      title: "Registration Date",
      key: "date",
      dataIndex: "date",
      render: (date: string | undefined) => {
        if (!date) return "";
        const formattedDate = format(parseISO(date), "MMM d, yyyy");
        const formattedTime = format(parseISO(date), "hh:mm a");
        return (
          <div className="text-textcolor" style={{ width: "100px" }}>
            <div className="font-PoppinsRegular">{formattedDate}</div>
            <div className="font-PoppinsLight">{formattedTime}</div>
          </div>
        );
      },
    },
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      render: (name: string) => {
        return <div style={{ width: "100px" }}>{name}</div>;
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone No.",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "KYC Level",
      dataIndex: "kycLevel",
      key: "kycLevel",
    },
    {
      title: "Level 1",
      dataIndex: "kycLevelOneStatus",
      key: "kycLevelOneStatus",
      render: (kycLevelOneStatus: string) => (
        <div
          className={`capitalize ${
            kycLevelOneStatus === "pending"
              ? "text-realSecondary"
              : kycLevelOneStatus === "approved"
              ? "text-success"
              : "text-textcolor"
          }`}
        >
          {kycLevelOneStatus}
        </div>
      ),
    },
    {
      title: "Level 2",
      dataIndex: "kycLevelTwoStatus",
      key: "kycLevelTwoStatus",
      render: (kycLevelTwoStatus: string) => (
        <div
          className={`capitalize ${
            kycLevelTwoStatus === "pending"
              ? "text-realSecondary"
              : kycLevelTwoStatus === "approved"
              ? "text-success"
              : "text-textcolor"
          }`}
        >
          {kycLevelTwoStatus}
        </div>
      ),
    },
    {
      title: "Level 3",
      dataIndex: "kycLevelThreeStatus",
      key: "kycLevelThreeStatus",
      render: (kycLevelThreeStatus: string) => (
        <div
          className={`capitalize ${
            kycLevelThreeStatus === "pending"
              ? "text-realSecondary"
              : kycLevelThreeStatus === "approved"
              ? "text-success"
              : "text-textcolor"
          }`}
        >
          {kycLevelThreeStatus}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "kycApprovalStatus",
      key: "kycApprovalStatus",
      render: (kycApprovalStatus: boolean | undefined) => (
        <div
          className={`text-center text-[0.8em] rounded-[30px] py-[0.35rem] px-[0.9rem] bg-opacity-[28%] ${
            kycApprovalStatus === true
              ? "text-success bg-success"
              : kycApprovalStatus === false
              ? "text-textcolor bg-textcolor"
              : "text-textcolor"
          }`}
        >
          {kycApprovalStatus === true
            ? "Approved"
            : kycApprovalStatus === false
            ? "In view"
            : ""}
        </div>
      ),
    },
  ];

  const handleRowClick = (record: any) => {
    navigate(`/admin/allCustomers/${record.key}`);
  };

  const handleSubmit = async (values: any) => {
    try {
      const formData = new FormData();
      formData.append("subject", values.subject);
      formData.append("body", values.body);

      const pendingKycEmailRes = await HttpClient.post(
        "/admin/send-email-to-pending-kyc-users",
        formData,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      if (pendingKycEmailRes) {
        ToastComponent.success(
          pendingKycEmailRes.data.message ||
            "Promotional Email sent successfully"
        );
      } else {
        ToastComponent.error("Failed to send Promotional Email");
      }
      setIsModalVisible(false);
    } catch (error: any) {
      errorHandler(error);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const pagination = {
    pageSize: 5,
    total: 0,
  };

  return (
    <>
      <PageTitle title="Admin KYC" />
      <div className="flex h-screen">
        <Sidebar />
        <div className="flex-1 overflow-y-auto">
          {loading ? (
            <div className="w-full h-screen bg-white dark:bg-darkPrimary flex items-center justify-center">
              <ScaleLoader color={isDarkMode ? "#FFF" : "#6B006B"} />
            </div>
          ) : (
            <div className="mx-4 my-4 mt-28 bg-white dark:bg-darkPrimary">
              <div className="p-4 shadow-md border border-transactionCard rounded-lg">
                <div className="flex flex-col gap-4 items-start sm:items-center sm:flex-row sm:flex-wrap md:grid md:grid-cols-2 lg:flex lg:items-center lg:justify-between mb-4">
                  <span className="font-PoppinsBold text-textcolor text-semibold dark:text-white">
                    KYC Details
                  </span>
                  <div className="md:flex md:gap-2">
                    <Input
                      placeholder="Search Status"
                      value={searchStatus}
                      onChange={(e) => setSearchStatus(e.target.value)}
                      className="mb-2 md:mb-0 lg:mb-0 w-full md:w-52 lg:w-52"
                    />
                    <Input
                      placeholder="Search Level"
                      value={searchLevel}
                      onChange={(e) => setSearchLevel(e.target.value)}
                      className="mb-2 md:mb-0 lg:mb-0 w-full md:w-52 lg:w-52"
                    />
                  </div>
                  <button
                    onClick={() => setIsModalVisible(true)}
                    className="w-full sm:w-auto lg:ml-auto py-2 px-4 align-middle border border-primary rounded-lg font-PoppinsMedium text-center text-primary"
                  >
                    Mail Unverified Users
                  </button>
                </div>
                <div className="dark:bg-white mt-4 overflow-x-auto">
                  <Table
                    columns={columns}
                    dataSource={filteredKyc}
                    onRow={(record) => ({
                      onClick: () => handleRowClick(record),
                      className: "cursor-pointer",
                    })}
                    pagination={pagination}
                  />
                </div>
              </div>
            </div>
          )}

          {isModalVisible && (
            <Modal
              onClose={handleCancel}
              className="w-full sm:w-[500px] md:w-[700px] lg:w-[800px] p-4"
            >
              <h2 className="text-center font-PoppinsRegular mb-4">
                Mail Unverified Users
              </h2>
              <Formik
                initialValues={{
                  subject: "",
                  body: "",
                }}
                validationSchema={pendingKycEmailValidation}
                onSubmit={handleSubmit}
              >
                <Form className="w-full">
                  <div className="flex flex-col sm:flex-row justify-center mb-4">
                    <label
                      htmlFor="subject"
                      className="text-textcolor font-PoppinsMedium mb-1 sm:mb-0 sm:mr-6 dark:text-white"
                    >
                      Subject:
                    </label>
                    <div className="w-full">
                      <TextInput
                        type="text"
                        name="subject"
                        placeholder="Enter the subject"
                      />
                    </div>
                  </div>

                  <div className="flex flex-col sm:flex-row justify-center">
                    <label
                      htmlFor="body"
                      className="text-textcolor font-PoppinsMedium mb-1 sm:mb-0 sm:mr-2 dark:text-white"
                    >
                      Message:
                    </label>
                    <div className="w-full">
                      <TextAreaInput
                        type="text"
                        name="body"
                        placeholder="Enter your message"
                        className="h-80"
                      />
                    </div>
                  </div>

                  <div className="w-full flex justify-end mt-8">
                    <button
                      type="submit"
                      className="w-full sm:w-full md:w-40 font-PoppinsMedium rounded-lg text-primary hover:before:bg-primary border-primary relative h-[50px] overflow-hidden border bg-white px-3 transition-all before:absolute before:bottom-0 before:left-0 before:top-0 before:z-0 before:h-full before:w-0 before:bg-primary before:transition-all before:duration-500 hover:text-white hover:before:left-0 hover:before:w-full"
                    >
                      <span className="relative z-10">Send</span>
                    </button>
                  </div>
                </Form>
              </Formik>
            </Modal>
          )}
        </div>
      </div>
    </>
  );
}

export default AdminKYC;
