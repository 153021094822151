import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { HttpClient } from "../../../api/HttpClient";
import { TiArrowSortedUp } from "react-icons/ti";
import { TiArrowSortedDown } from "react-icons/ti";
import ErrorHandler from "../../ErrorHandler";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

ChartJS.register(ArcElement, Tooltip, Legend);
interface TransactionStats {
  total_value: number;
  success_value: number;
  failed_value: number;
  success_value_percentage: string;
  failed_value_percentage: string;
  total_volume: number;
  success_volume: number;
  failed_volume: number;
  success_volume_percentage: string;
  failed_volume_percentage: string;
}
type TransactionCardProps = {
  title?: string;
  stats: TransactionStats;
  isDoughnut?: boolean;
};

const TransactionCard: React.FC<TransactionCardProps> = ({
  title,
  stats,
  isDoughnut = false,
}) => {
  const {
    total_value,
    success_value,
    failed_value,
    success_value_percentage,
    failed_value_percentage,
    total_volume,
    success_volume,
    failed_volume,
    success_volume_percentage,
    failed_volume_percentage,
  } = stats;
  const chartData = {
    datasets: [
      {
        data:
          title === "Transaction Volume"
            ? [success_volume, failed_volume]
            : [success_value, failed_value],
        backgroundColor: ["#009306", "#E80404"],
        hoverOffset: 4,
      },
    ],
  };

  return (
    <div className="p-5 rounded-md border border-transactionCard items-center">
      <span className="text-semibold text-textcolor font-PoppinsSemiBold block mb-4 text-center dark:text-white">
        {title}
      </span>
      <div className="flex flex-col items-center p-4">
        <div className="block items-center text-center">
          {title === "Transaction Value" ? (
            <>
              <h1 className="text-success font-PoppinsSemiBold mb-1">
                ₦{success_value.toLocaleString()}
              </h1>

              <h1 className="flex items-center text-success font-PoppinsSemiBold">
                {success_value_percentage}% <TiArrowSortedUp size={25} />{" "}
                <span className="pl-4 text-textcolor font-PoppinsRegular dark:text-white">
                  Successful Percentage
                </span>
              </h1>
            </>
          ) : title === "Transaction Volume" ? (
            <>
              <h1 className="text-success font-PoppinsSemiBold mb-1">
                {success_volume.toLocaleString()}
              </h1>
              <h1 className="flex items-center text-success font-PoppinsSemiBold">
                {success_volume_percentage}% <TiArrowSortedUp size={25} />{" "}
                <span className="pl-4 text-textcolor font-PoppinsRegular dark:text-white">
                  Successful Percentage
                </span>
              </h1>
            </>
          ) : null}
        </div>

        {isDoughnut ? (
          <div className="relative w-[200px] h-[200px]">
            <div className="absolute inset-0 flex flex-col items-center justify-center z-10">
              {title === "Transaction Value" ? (
                <>
                  <h3 className="font-PoppinsRegular text-[1rem]">Total</h3>
                  <div className="font-PoppinsRegular text-[1rem] text-textcolor">
                    ₦{total_value.toLocaleString()}
                  </div>
                </>
              ) : (
                <>
                  <h3 className="font-PoppinsRegular text-[1rem]">Total</h3>
                  <div className="font-PoppinsRegular text-[1rem] text-textcolor">
                    {total_volume.toLocaleString()}
                  </div>
                </>
              )}
            </div>

            <Doughnut
              data={chartData}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    position: "top",
                  },
                  tooltip: {
                    callbacks: {
                      label: (context) => {
                        return `${context.label}: ${context.raw}`;
                      },
                    },
                  },
                },
                cutout: "80%",
              }}
            />
          </div>
        ) : null}

        <div className="block items-center text-center">
          {title === "Transaction Value" ? (
            <>
              <h1 className="text-error font-PoppinsSemiBold mb-1 mt-2">
                ₦{failed_value.toLocaleString()}{" "}
              </h1>

              <h1 className="flex items-center text-error font-PoppinsSemiBold">
                {failed_value_percentage}% <TiArrowSortedDown size={25} />{" "}
                <span className="pl-4 text-textcolor font-PoppinsRegular dark:text-white">
                  Failed Percentage
                </span>
              </h1>
            </>
          ) : title === "Transaction Volume" ? (
            <>
              <h1 className="text-error font-PoppinsSemiBold mt-2 mb-1">
                {failed_volume.toLocaleString()}{" "}
              </h1>
              <h1 className="flex items-center text-error font-PoppinsSemiBold">
                {failed_volume_percentage}% <TiArrowSortedDown size={25} />{" "}
                <span className="pl-4 text-textcolor font-PoppinsRegular dark:text-white">
                  Failed Percentage
                </span>
              </h1>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export function OneUserTransact() {
  const [transVal, setTransVal] = useState<TransactionStats>({
    total_value: 0,
    success_value: 0,
    failed_value: 0,
    success_value_percentage: "0.00%",
    failed_value_percentage: "0.00%",
    total_volume: 0,
    success_volume: 0,
    failed_volume: 0,
    success_volume_percentage: "0.00%",
    failed_volume_percentage: "0.00%",
  });
  const { customerId } = useParams<{ customerId: string }>();
  const [loading, setLoading] = useState<boolean>(true);

  const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);

  useEffect(() => {
    const fetchTransactionData = async () => {
      setLoading(true);
      try {
        const userDataRes = await HttpClient.get(
          `/admin/view-user/${customerId}`,
          {
            headers: {
              "x-auth-token": localStorage.getItem("token"),
            },
          }
        );

        const users = userDataRes.data?.data;
        const userId = users?._id;

        const transacStatRes = await HttpClient.get(
          "/transactions/statistics",
          {
            headers: {
              "x-auth-token": localStorage.getItem("token"),
            },
            params: {
              userId: userId,
            },
          }
        );

        setTransVal(transacStatRes.data?.data);
      } catch (error: any) {
        ErrorHandler(error);
      } finally {
        setLoading(false);
      }
    };

    fetchTransactionData();
  }, [customerId]);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-8 dark:text-white">
      <TransactionCard
        title="Transaction Value"
        stats={{
          total_value: transVal.total_value,
          success_value: transVal.success_value,
          failed_value: transVal.failed_value,
          success_value_percentage: transVal.success_value_percentage,
          failed_value_percentage: transVal.failed_value_percentage,
          total_volume: transVal.total_volume,
          success_volume: transVal.success_volume,
          failed_volume: transVal.failed_volume,
          success_volume_percentage: transVal.success_volume_percentage,
          failed_volume_percentage: transVal.failed_volume_percentage,
        }}
        isDoughnut={true}
      />
      <TransactionCard
        title="Transaction Volume"
        stats={{
          total_value: transVal.total_value,
          success_value: transVal.success_value,
          failed_value: transVal.failed_value,
          success_value_percentage: transVal.success_value_percentage,
          failed_value_percentage: transVal.failed_value_percentage,
          total_volume: transVal.total_volume,
          success_volume: transVal.success_volume,
          failed_volume: transVal.failed_volume,
          success_volume_percentage: transVal.success_volume_percentage,
          failed_volume_percentage: transVal.failed_volume_percentage,
        }}
        isDoughnut={true}
      />
    </div>
  );
  
}
export function AllUserTransact() {
  const [transVal, setTransVal] = useState<TransactionStats>({
    total_value: 0,
    success_value: 0,
    failed_value: 0,
    success_value_percentage: "0.00%",
    failed_value_percentage: "0.00%",
    total_volume: 0,
    success_volume: 0,
    failed_volume: 0,
    success_volume_percentage: "0.00%",
    failed_volume_percentage: "0.00%",
  });
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchTransactionData = async () => {
      setLoading(true);
      try {
        const transacStatRes = await HttpClient.get(
          "/transactions/statistics",
          {
            headers: {
              "x-auth-token": localStorage.getItem("token"),
            },
          }
        );

        setTransVal(transacStatRes.data?.data);
      } catch (error: any) {
        ErrorHandler(error);
      } finally {
        setLoading(false);
      }
    };

    fetchTransactionData();
  }, []);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-8 dark:text-white">
      <TransactionCard
        title="Transaction Value"
        stats={{
          total_value: transVal.total_value,
          success_value: transVal.success_value,
          failed_value: transVal.failed_value,
          success_value_percentage: transVal.success_value_percentage,
          failed_value_percentage: transVal.failed_value_percentage,
          total_volume: transVal.total_volume,
          success_volume: transVal.success_volume,
          failed_volume: transVal.failed_volume,
          success_volume_percentage: transVal.success_volume_percentage,
          failed_volume_percentage: transVal.failed_volume_percentage,
        }}
        isDoughnut={true}
      />
      <TransactionCard
        title="Transaction Volume"
        stats={{
          total_value: transVal.total_value,
          success_value: transVal.success_value,
          failed_value: transVal.failed_value,
          success_value_percentage: transVal.success_value_percentage,
          failed_value_percentage: transVal.failed_value_percentage,
          total_volume: transVal.total_volume,
          success_volume: transVal.success_volume,
          failed_volume: transVal.failed_volume,
          success_volume_percentage: transVal.success_volume_percentage,
          failed_volume_percentage: transVal.failed_volume_percentage,
        }}
        isDoughnut={true}
      />
    </div>
  );
  
}



