import { useCallback, useEffect, useState } from "react";
import Sidebar from "../../sidebar/Sidebar";
import { ScaleLoader } from "react-spinners";
import { Input, Table, TablePaginationConfig } from "antd";
import { ColumnsType } from "antd/es/table";
import PageTitle from "../../../components/Pagetitle";
import { HttpClient } from "../../../api/HttpClient";
import { format } from "date-fns";
import ErrorHandler from "../../ErrorHandler";
import { formatCurrency } from "../../../components/CurrencyFormat";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

interface WithdrawalsData {
  _id?: string;
  key?: string;
  transactionDate: string;
  paymentGateway: string;
  transactionId: string;
  amount: number;
  paymentStatus: string;
  paymentMethod: string;
  user_details: {
    firstName: string;
    lastName: string;
    email: string;
  };
  transactionType: string;
}

function Payouts() {
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState<WithdrawalsData[]>([]);
  const [withdrawals, setWithdrawals] = useState<WithdrawalsData[]>([]);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 5,
    total: 0,
  });
  const [filters, setFilters] = useState({
    name: "",
    email: "",
    transactionId: "",
  });

  const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);

  const applyFilters = useCallback(
    (data: WithdrawalsData[]) => {
      let filtered = data;

      if (filters.name) {
        filtered = filtered.filter((item) => {
          return (
            item.user_details.firstName +
            " " +
            item.user_details.lastName
              .toLowerCase()
              .includes(filters.name.toLowerCase())
          );
        });
      }
      if (filters.email) {
        filtered = filtered.filter((item) => {
          return item.user_details.email
            .toLowerCase()
            .includes(filters.email.toLowerCase());
        });
      }
      if (filters.transactionId) {
        filtered = filtered.filter((item) => {
          return item.transactionId
            .toLowerCase()
            .includes(filters.transactionId.toLowerCase());
        });
      }

      setFilteredData(filtered);
      setPagination((prev) => ({
        ...prev,
        total: filtered.length,
      }));
    },
    [filters]
  );

  const fetchWithdrawalsData = useCallback(async () => {
    try {
      const withdrawalsRes = await HttpClient.get(`/transactions/history`, {
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          page: "",
          limit: "",
          search: "withdrawal",
          status: "success",
          isInFlow: "false",
        },
      });

      const withdrawalsData = withdrawalsRes.data?.data?.data.map(
        (item: any, index: number) => ({
          key: item._id,
          serialNumber: index + 1,
          name:
            item.user_details?.firstName + " " + item.user_details?.lastName ||
            "",
          email: item.user_details?.email || "",
          transactionDate: item?.transactionDate || "",
          paymentGateway: item?.paymentGateway || "",
          transactionId: item?.transactionId || "",
          amount: item?.amount || "0",
          paymentStatus: item?.paymentStatus || "",
          transactionDetails: item?.transactionDetails,
          transactionType: item?.transactionType || "",
          paymentMethod: item?.paymentMethod || "",
          user_details: item?.user_details,
        })
      );

      setWithdrawals(withdrawalsData);
      setPagination((prev) => ({
        ...prev,
        total: withdrawalsRes.data.data.total,
      }));
      applyFilters(withdrawalsData);
    } catch (error: any) {
      ErrorHandler(error);
    } finally {
      setLoading(false);
    }
  }, [applyFilters]);

  useEffect(() => {
    fetchWithdrawalsData();
  }, [fetchWithdrawalsData]);

  useEffect(() => {
    applyFilters(withdrawals);
  }, [filters, applyFilters, withdrawals]);

  const handleFilterChange = (field: string, value: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));

    if (!filters.name && !filters.email && !filters.transactionId) {
      setFilteredData(withdrawals);
    }
  };

  const columns: ColumnsType<WithdrawalsData> = [
    {
      key: "serialNumber",
      title: "S/N",
      dataIndex: "serialNumber",
      className: "text-textcolor font-PoppinsRegular",
    },
    {
      key: "transactionDate",
      title: "Date",
      dataIndex: "transactionDate",
      render: (dateTime: string) => {
        const date = new Date(dateTime);
        const formattedDate = format(date, "MMM dd, yyyy");
        const formattedTime = format(date, "hh:mm a");

        return (
          <div className="text-textcolor" style={{ width: "100px" }}>
            <div className="font-PoppinsRegular">{formattedDate}</div>
            <div className="font-PoppinsLight">{formattedTime}</div>
          </div>
        );
      },
    },
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
      render: (name: string) => {
        return (
          <div style={{ width: "100px" }} className="capitalize">
            {name}
          </div>
        );
      },
      filterDropdown: () => (
        <Input
          placeholder="John Doe"
          value={filters.name}
          onChange={(e) => handleFilterChange("name", e.target.value)}
        />
      ),
      onFilterDropdownVisibleChange: () => applyFilters(withdrawals),
    },
    {
      key: "email",
      title: "Email",
      dataIndex: "email",
      className: "text-textcolor font-PoppinsRegular",
      filterDropdown: () => (
        <Input
          placeholder="johndoe@example.com"
          value={filters.email}
          onChange={(e) => handleFilterChange("email", e.target.value)}
        />
      ),
      onFilterDropdownVisibleChange: () => applyFilters(withdrawals),
    },
    {
      key: "paymentGateway",
      title: "Payment Gateway",
      dataIndex: "paymentGateway",
      render: (paymentGateway: string) => {
        return (
          <div style={{ width: "100px" }} className="capitalize">
            {paymentGateway}
          </div>
        );
      },
    },
    {
      key: "paymentMethod",
      title: " Payment Method",
      dataIndex: "paymentMethod",
      render: (paymentMethod: string) => {
        return (
          <div style={{ width: "100px" }} className="capitalize">
            {paymentMethod}
          </div>
        );
      },
    },
    {
      title: "Transaction ID",
      key: "transactionId",
      dataIndex: "transactionId",
      className: "text-textcolor font-PoppinsRegular",
      filterDropdown: () => (
        <Input
          placeholder="12345"
          value={filters.transactionId}
          onChange={(e) => handleFilterChange("transactionId", e.target.value)}
        />
      ),
      onFilterDropdownVisibleChange: () => applyFilters(withdrawals),
    },
    {
      key: "transactionType",
      title: "Transaction Type",
      dataIndex: "transactionType",
      render: (transactionType: string) => (
        <span
          className={`capitalize ${
            transactionType === "wallet"
              ? "text-primary"
              : transactionType === "investments" ||
                transactionType === "standard_investments" ||
                transactionType === "flexible_investments" ||
                transactionType === "kids_investments" ||
                transactionType === "regular_investments" ||
                transactionType === "project_investments"
              ? "text-investment"
              : transactionType === "deposit"
              ? "text-deposit"
              : transactionType === "withdrawals"
              ? "text-primary"
              : transactionType === "profit"
              ? "text-secondary"
              : "text-referral"
          }`} style={{ width: "100px" }}
        >
          {transactionType.replace(/_/g, " ")}
        </span>
      ),
    },
    {
      key: "amount",
      title: "Amount",
      dataIndex: "amount",
      className: "text-textcolor font-PoppinsRegular",
      render: (amount: number) => formatCurrency(amount),
    },
    {
      key: "paymentStatus",
      title: "Status",
      dataIndex: "paymentStatus",
      render: (status: string) => {
        const baseClass =
          "text-[0.8em] capitalize rounded-[15px] font-PoppinsMedium px-[0.9rem] py-[0.35rem] bg-opacity-[28%] text-center inline-block";
        const statusClass =
          status === "successful"
            ? `bg-success text-success ${baseClass}`
            : status === "failed"
            ? `bg-error text-error ${baseClass}`
            : `bg-secondary text-secondary ${baseClass}`;

        return <div className={statusClass}>{status}</div>;
      },
    },
  ];

  return (
    <>
      <PageTitle title="Affluence - Admin: Withdrawals Log" />
      <div className="flex h-screen dark:bg-darkPrimary">
        <Sidebar />
        <div className="flex-1 overflow-y-auto">
          {loading ? (
            <div className="w-full h-screen bg-white dark:bg-darkPrimary flex items-center justify-center">
              <ScaleLoader color={isDarkMode ? "#FFFFFF" : "#6B006B"} />
            </div>
          ) : (
            <div className="p-4 mt-28">
              <div className="p-4 shadow-md border border-transactionCard rounded-lg">
                <span className="dark:text-white text-semibold text-textcolor font-PoppinsBold block mb-4">
                  All Withdrawals
                </span>
                <div className="dark:bg-white overflow-x-auto">
                  <Table
                    columns={columns}
                    dataSource={filteredData}
                    pagination={pagination}
                    onChange={(newPagination) => setPagination(newPagination)}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Payouts;
