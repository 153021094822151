import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  FaArrowAltCircleLeft,
  FaArrowAltCircleRight,
  FaPowerOff,
} from "react-icons/fa";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import Logo from "../../assets/img/affluence-logo.png";
import SLogo from "../../assets/img/affluence-logo-small.png";
import DarkLogo from "../../assets/img/logo1.png";
import { sidebarLinks } from "./SidebarLinks";
import { RiMenu2Fill } from "react-icons/ri";
import { HttpClient } from "../../api/HttpClient";
import ToastComponent from "../../components/ToastComponent";
import { isAxiosError } from "axios";
import { ScaleLoader } from "react-spinners";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";

const Sidebar = () => {
  const [largeSideBar, setLargeSideBar] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isTablet, setIsTablet] = useState(
    window.innerWidth >= 768 && window.innerWidth < 1024
  );
  const [openDropdown, setOpenDropdown] = useState<number | null>(null);
  const [isLogingOut, setIsLogingOut] = useState(false);
  const [openNav, setOpenNav] = useState(false);
  const router = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      setIsTablet(window.innerWidth >= 768 && window.innerWidth < 1024);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);

  const handleSetLargeSideBar = () => {
    setLargeSideBar((prev) => !prev);
  };

  const handleLogout = async () => {
    setIsLogingOut(true);
    try {
      await HttpClient.post("/users/logout");
      localStorage.removeItem("token");
      navigate("/login");
    } catch (error) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      }
    } finally {
      setIsLogingOut(false);
    }
  };

  const handleDropdownToggle = (index: number) => {
    setOpenDropdown((prev) => (prev === index ? null : index));
  };

  const handleNavToggle = () => {
    setOpenNav((prev) => !prev);
  };

  const closeNavOnLinkClick = () => {
    if (isMobile) setOpenNav(false);
  };

  const renderLogo = () => {
    if (isDarkMode) {
      return largeSideBar ? (
        <img src={SLogo} className="w-[30px]" alt="affluence logo" />
      ) : (
        <img src={DarkLogo} className="w-[150px]" alt="affluence logo" />
      );
    }
    return largeSideBar ? (
      <img src={SLogo} className="w-[30px]" alt="affluence logo" />
    ) : (
      <img src={Logo} className="w-[150px]" alt="affluence logo" />
    );
  };

  const sidebarWidth = isTablet
    ? "w-[25%]"
    : largeSideBar
    ? "w-[80px]"
    : "w-[20%]";

  // Mobile view sidebar rendering
  if (isMobile) {
    return openNav ? (
      isLogingOut ? (
        <div className="w-full h-screen bg-white dark:bg-darkPrimary flex items-center justify-center relative">
          <div className="flex flex-col justify-center items-center">
            <ScaleLoader color={isDarkMode ? "#FFFFFF" : "#6B006B"} />
            <h1 className="dark:text-white text-textcolor mt-7">
              Log Out Processing...
            </h1>
          </div>
        </div>
      ) : (
        <div className="fixed inset-0 bg-[black] bg-opacity-[50%] z-50">
          <div className="dark:bg-darkPrimary bg-white h-screen w-full max-w-[300px] relative py-6 px-4 flex flex-col justify-between overflow-y-auto pt-[4rem]">
            <div>
              <div className="dark:text-white flex items-center justify-between">
                {renderLogo()}
                <button
                  onClick={handleNavToggle}
                  className="focus:outline-none"
                >
                  <IoClose size={24} />
                </button>
              </div>
              <div className="pt-[3rem]">
                {sidebarLinks.map((link: any, index: any) => (
                  <div key={index}>
                    {link.submenu ? (
                      <div>
                        <button
                          onClick={() => handleDropdownToggle(index)}
                          className={`flex items-center gap-3 mb-6 text-lg py-2 px-4 rounded ${
                            router.pathname.startsWith(link.to)
                              ? "bg-primary text-white"
                              : "text-textcolor dark:text-white"
                          }`}
                        >
                          {link.icon}
                          <span>{link.label}</span>
                          {openDropdown === index ? (
                            <IoIosArrowDown size={20} className="ml-auto" />
                          ) : (
                            <IoIosArrowForward size={20} className="ml-auto" />
                          )}
                        </button>
                        {openDropdown === index && (
                          <div className="pl-4 shadow-md">
                            {link.submenu.map((subLink: any, subIndex: any) => (
                              <Link
                                key={subIndex}
                                to={subLink.to}
                                className={`block py-2 px-4 ${
                                  router.pathname === subLink.to
                                    ? "bg-primary text-white"
                                    : "text-textcolor dark:text-white"
                                }`}
                              >
                                {subLink.label}
                              </Link>
                            ))}
                          </div>
                        )}
                      </div>
                    ) : (
                      <Link
                        to={link.to}
                        onClick={closeNavOnLinkClick}
                        className={`flex items-center gap-3 mb-6 text-lg py-2 px-4 rounded ${
                          router.pathname.startsWith(link.to)
                            ? "bg-primary text-white"
                            : "text-textcolor dark:text-white"
                        }`}
                      >
                        {link.icon}
                        <span>{link.label}</span>
                      </Link>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <button
              onClick={handleLogout}
              className="flex items-center text-error gap-3 text-lg py-2 px-4"
            >
              <FaPowerOff size={24} />
              <span>Logout</span>
            </button>
          </div>
        </div>
      )
    ) : (
      <button
        className="text-primary absolute top-4 left-4 dark:text-white"
        onClick={handleNavToggle}
      >
        <RiMenu2Fill size={30} />
      </button>
    );
  }

  // Desktop view sidebar rendering
  return (
    <>
      {isLogingOut ? (
        <div className="w-full h-screen bg-white dark:bg-darkPrimary flex items-center justify-center relative">
          <div className="flex flex-col justify-center items-center">
            <ScaleLoader color={isDarkMode ? "#FFFFFF" : "#6B006B"} />
            <h1 className="dark:text-white text-textcolor mt-7">
              Log Out Processing...
            </h1>
          </div>
        </div>
      ) : (
        <div
          className={`border-r-[1.5px] bg-white dark:bg-darkPrimary border-opacity-30 ${sidebarWidth} ${
            largeSideBar ? "w-[80px]" : "w-[20%]"
          } relative border-placeholdercolor hidden md:block overflow-y-auto overflow-x-hidden`}
        >
          <div
            onClick={handleSetLargeSideBar}
            className="text-primary absolute cursor-pointer right-[-4px] top-10"
          >
            {largeSideBar ? (
              <FaArrowAltCircleRight size={30} />
            ) : (
              <FaArrowAltCircleLeft size={30} />
            )}
          </div>
          <div className="flex flex-col justify-between h-full pt-[4rem]">
            <div>
              <div className="flex justify-center">{renderLogo()}</div>
              <div className="pt-[3rem]">
                {sidebarLinks.map((link, index) => (
                  <div key={index}>
                    {link.submenu ? (
                      <div>
                        <button
                          onClick={() => handleDropdownToggle(index)}
                          className={`flex items-center gap-3 mb-6 text-lg py-2 px-4 rounded ${
                            router.pathname.startsWith(link.to)
                              ? "bg-primary text-white"
                              : "text-textcolor dark:text-white"
                          }`}
                        >
                          {link.icon}
                          <span
                            className={`ml-2 ${
                              largeSideBar ? "hidden" : "block"
                            }`}
                          >
                            {link.label}
                          </span>
                          {openDropdown === index ? (
                            <IoIosArrowDown
                              size={20}
                              className="right-[-4px]"
                            />
                          ) : (
                            <IoIosArrowForward
                              size={20}
                              className="items-end"
                            />
                          )}
                        </button>
                        {openDropdown === index && (
                          <div className="pl-4 shadow-md dark:shadow-textcolor">
                            {link.submenu.map((subLink, subIndex) => (
                              <Link
                                key={subIndex}
                                to={subLink.to}
                                className={`block py-2 px-4 ${
                                  router.pathname === subLink.to
                                    ? "bg-primary text-white"
                                    : "text-textcolor dark:text-white"
                                }`}
                              >
                                {subLink.label}
                              </Link>
                            ))}
                          </div>
                        )}
                      </div>
                    ) : (
                      <Link
                        to={link.to}
                        className={`flex items-center gap-3 mb-6 text-lg py-2 px-4 rounded ${
                          router.pathname.startsWith(link.to)
                            ? "bg-primary text-white"
                            : "text-textcolor dark:text-white"
                        }`}
                      >
                        {link.icon}
                        <span
                          className={`ml-2 ${
                            largeSideBar ? "hidden" : "block"
                          }`}
                        >
                          {link.label}
                        </span>
                      </Link>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <button
              onClick={handleLogout}
              className="flex items-center text-error gap-3 text-lg py-2 px-4"
            >
              <FaPowerOff size={24} />
              <span className={`${largeSideBar ? "hidden" : "block"}`}>
                Logout
              </span>
            </button>
          </div>
        </div>
      )}
    </>
  );
};
export default Sidebar;
