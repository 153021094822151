import { useEffect, useState } from "react";
import { Table, Input, Menu, Dropdown, Tooltip } from "antd";
import Sidebar from "../../../sidebar/Sidebar";
import { ColumnsType } from "antd/es/table";
import { IoMdAdd } from "react-icons/io";
import CreateRegularPlan from "./CreateRegularPlan";
import { FaRegEye } from "react-icons/fa";
import PageTitle from "../../../../components/Pagetitle";
import { HttpClient } from "../../../../api/HttpClient";
import { ScaleLoader } from "react-spinners";
import { format } from "date-fns";
import { MdOutlineMarkEmailUnread } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";
import { PiUsersThree } from "react-icons/pi";
import Errorhandler from "../../../ErrorHandler";
import Modal from "../../../Modal";
import ToastComponent from "../../../../components/ToastComponent";
import * as Yup from "yup";
import TextInput from "../../../../components/TextInput";
import { Formik, Form } from "formik";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { InvestData } from "../../../../assets/Typeprops";
import TextAreaInput from "../../../../components/TextAreaInput";

function RegularInvestmentPlan() {
  const [data, setData] = useState<InvestData[]>([]);
  const [isCreatePlanVisible, setCreatePlanVisible] = useState(false);
  const [editingRecord, setEditingRecord] = useState<InvestData | undefined>(
    undefined
  );
  const [loading, setLoading] = useState(true);
  const [filteredInvest, setFilteredInvest] = useState<InvestData[]>([]);
  const [searchCat, setSearchCat] = useState("");
  const [searchTitle, setSearchTitle] = useState("");
  const [searchStatus, setSearchStatus] = useState("");
  const [fetchInvest, setFetchInvest] = useState<InvestData[]>([]);
  const navigate = useNavigate();
  const [refreshData, setRefreshData] = useState(false);
  const [selectedInvestment, setSelectedInvestment] =
    useState<InvestData | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);

  const otpSchema = Yup.object().shape({
    otp: Yup.string().required("OTP is required"),
  });

  const sendEmailValidation = Yup.object({
    subject: Yup.string().required("Email Subject is required"),
    body: Yup.string().required("Message is required"),
  });

  const fetchData = async () => {
    try {
      const investRes = await HttpClient.get("regular-investments/plan", {
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      });

      const investments = investRes.data?.data?.data.map(
        (item: any, index: number) => ({
          key: item._id,
          serialNumber: index + 1,
          title: item?.title,
          category: item?.category,
          rate: item?.rate,
          status: item?.status,
          numberOfInvestors: item?.numberOfInvestors,
          disclaimer: item?.disclaimer || "",
          liquidationFee: item?.liquidationFee || 0,
          createdAt: item?.createdAt,
        })
      );

      setData(investments);
      setFetchInvest(investments);
    } catch (error: any) {
      Errorhandler(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [refreshData]);

  useEffect(() => {
    const filteredData = fetchInvest.filter((item: InvestData) => {
      const category = item.category.toLowerCase();
      const title = item.title.toLowerCase();
      const status = item.status.toLowerCase();
      return (
        category.includes(searchCat.toLowerCase()) &&
        title.includes(searchTitle.toLowerCase()) &&
        status.includes(searchStatus.toLowerCase())
      );
    });
    setFilteredInvest(filteredData);
  }, [searchCat, searchTitle, searchStatus, data]);

  const handleToggleView = () => {
    setCreatePlanVisible((prev) => !prev);
    setRefreshData((prev) => !prev);
  };

  const handleViewInvestment = (e: any, investmentId: string) => {
    if (e.key === "viewInvestors") {
      navigate(
        `/admin/investment/regular_investment_plan/view_investors/${investmentId}`
      );
    }
  };

  const fetchAdminsAndGenerateOTP = async () => {
    try {
      const adminEmail = localStorage.getItem("adminEmail");
      const adminRole = localStorage.getItem("adminRole");

      if (!adminRole || adminRole !== "admin") {
        throw new Error("Only admins can generate OTPs.");
      }

      if (!adminEmail) {
        throw new Error("Admin email is missing.");
      }

      const otpResponse = await HttpClient.post("/users/generate-token", {
        email: adminEmail,
      });

      ToastComponent.success(
        otpResponse.data.message || "OTP sent successfully!"
      );
    } catch (error: any) {
      Errorhandler(error);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedInvestment(null);
  };

  const showDeleteModal = (investment: InvestData) => {
    setSelectedInvestment(investment);
    setIsModalVisible(true);
  };

  const handleDeleteConfirm = async (values: { otp: string }) => {
    if (!selectedInvestment) return;
    try {
      const investmentId = selectedInvestment.key;
      const deleteRes = await HttpClient.delete(
        `/investments/plan/${investmentId}`,
        {
          headers: { "x-auth-token": localStorage.getItem("token") },
          data: {
            otp: values.otp,
          },
        }
      );
      ToastComponent.success(
        deleteRes.data.message || "Investment deleted successfully"
      );

      setRefreshData((prev) => !prev);

      setIsModalVisible(false);
      setSelectedInvestment(null);
    } catch (error: any) {
      Errorhandler(error);
    }
  };

  const handleSubmit = async (values: any) => {
    try {
      const formData = new FormData();
      formData.append("subject", values.subject);
      formData.append("body", values.body);

      const pendingKycEmailRes = await HttpClient.post(
        "/admin/send-email-to-pending-kyc-users",
        formData,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      if (pendingKycEmailRes) {
        ToastComponent.success(
          pendingKycEmailRes.data.message ||
            "Promotional Email sent successfully"
        );
      } else {
        ToastComponent.error("Failed to send Promotional Email");
      }
      setIsModalVisible(false);
    } catch (error: any) {
      Errorhandler(error);
    }
  };

  const menu = (investment: InvestData) => {
    return (
      <Menu>
        <Menu.Item
          onClick={() =>
            handleViewInvestment({ key: "viewInvestors" }, investment.key)
          }
          key="viewInvestors"
          className="relative group"
        >
          <Link
            to={`/admin/investment/regular_investment_plan/view_investors/${investment._id}`}
            className="flex gap-2 items-center"
          >
            <PiUsersThree size={20} />
            <span className="text-textcolor font-PoppinsRegular">
              View Investors ({investment?.numberOfInvestors || 0} Investors)
            </span>
          </Link>
          <div className="absolute inset-0 bg-primary opacity-0 group-hover:opacity-30"></div>
        </Menu.Item>

        <Menu.Item onClick={() => setIsModalVisible(true)}>
          <div className="flex gap-2 items-center">
            <MdOutlineMarkEmailUnread size={20} />
            <span className="text-textcolor font-PoppinsRegular">
              Send Mail
            </span>
          </div>
          <div className="absolute inset-0 bg-primary opacity-0 group-hover:opacity-30"></div>
        </Menu.Item>
      </Menu>
    );
  };

  const columns: ColumnsType<InvestData> = [
    {
      key: "serialNumber",
      title: "S/N",
      dataIndex: "serialNumber",
      render: (text: string, record: InvestData) => (
        <div className="flex items-center gap-6">
          <span>{text}</span>
          <Dropdown overlay={menu(record)} trigger={["click"]}>
            <a
              onClick={(e) => e.preventDefault()}
              className="hover:text-primary border rounded-full text-xxs p-0.2"
            >
              <DownOutlined />
            </a>
          </Dropdown>
        </div>
      ),
    },
    {
      key: "title",
      title: "Title",
      dataIndex: "title",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Title"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => {
              confirm();
            }}
            style={{ marginBottom: 8, display: "block" }}
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <button
              onClick={() => {
                if (clearFilters) {
                  clearFilters();
                }
                confirm();
              }}
              style={{ width: "48%" }}
            >
              Reset
            </button>
            <button
              onClick={() => {
                confirm();
              }}
              style={{ width: "48%" }}
            >
              Apply
            </button>
          </div>
        </div>
      ),
      onFilter: (value, record) =>
        record.title.toLowerCase().includes((value as string).toLowerCase()),
    },
    {
      key: "category",
      title: "Category",
      dataIndex: "category",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Category"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => {
              confirm();
            }}
            style={{ marginBottom: 8, display: "block" }}
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <button
              onClick={() => {
                if (clearFilters) {
                  clearFilters();
                }
                confirm();
              }}
              style={{ width: "48%" }}
            >
              Reset
            </button>
            <button
              onClick={() => {
                confirm();
              }}
              style={{ width: "48%" }}
            >
              Apply
            </button>
          </div>
        </div>
      ),
      onFilter: (value, record) =>
        record.category.toLowerCase().includes((value as string).toLowerCase()),
    },
    {
      key: "disclaimer",
      title: "Disclaimer",
      dataIndex: "disclaimer",
      className: "capitalize",
      render: (disclaimer: string) => (
        <Tooltip title={disclaimer}>
          <span>
            {disclaimer.length > 10
              ? `${disclaimer.substring(0, 10)}...`
              : disclaimer}
          </span>
        </Tooltip>
      ),
    },
    {
      key: "rate",
      title: "Rate",
      dataIndex: "rate",
      render: (rate: number) => `${rate}%`,
    },
    {
      key: "liquidationFee",
      title: "Liquidation Fee",
      dataIndex: "liquidationFee",
      render: (liquidationFee: number) => `${liquidationFee}%`,
    },
    {
      key: "createdAt",
      title: "Created Date",
      dataIndex: "createdAt",
      render: (dateTime: string) => {
        const date = new Date(dateTime);
        const formattedDate = format(date, "MMM dd, yyyy");

        return (
          <div className="text-textcolor">
            <div className="font-PoppinsRegular">{formattedDate}</div>
          </div>
        );
      },
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      render: (status: string) => (
        <span
          className={`px-[0.9rem] py-[0.35rem] capitalize rounded-[30px] bg-opacity-[28%] text-center ${
            status === "pending"
              ? "bg-realSecondary text-realSecondary"
              : status === "running"
              ? "bg-success text-success"
              : status === "matured"
              ? "bg-primary text-primary"
              : "bg-error text-error"
          } `}
          style={{
            minWidth: "100px",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {status}
        </span>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Status"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => {
              confirm();
            }}
            style={{ marginBottom: 8, display: "block" }}
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <button
              onClick={() => {
                if (clearFilters) {
                  clearFilters();
                }
                confirm();
              }}
              style={{ width: "48%" }}
            >
              Reset
            </button>
            <button
              onClick={() => {
                confirm();
              }}
              style={{ width: "48%" }}
            >
              Enter
            </button>
          </div>
        </div>
      ),
      onFilter: (value, record) =>
        record.status.toLowerCase().includes((value as string).toLowerCase()),
    },
    {
      key: "actions",
      title: "Actions",
      render: (_, record: InvestData) => (
        <div className="flex space-x-2" key={record._id}>
          {/* <button
            className="bg-success text-white  px-5 py-1 rounded-md"
            onClick={() => handleEdit(record)}
          >
            Edit
          </button> */}
          <button
            className="bg-error text-white px-3 py-1 rounded-md"
            onClick={() => showDeleteModal(record)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  const pagination = {
    pageSize: 5,
  };

  return (
    <>
      <PageTitle title="Affluence - Admin: Investment Plan" />
      <div className="flex h-screen dark:bg-darkPrimary">
        <Sidebar />
        <div className="flex-1 overflow-y-auto">
          {loading ? (
            <div className="w-full h-screen bg-white dark:bg-darkPrimary flex items-center justify-center">
              <ScaleLoader color={isDarkMode ? "#FFFFFF" : "#6B006B"} />
            </div>
          ) : (
            <div className="flex-1 p-4 mt-28 shadow-md border border-transactionCard rounded-lg mx-4">
              <div className="flex flex-col md:flex-row justify-between">
                <span className="dark:text-white font-PoppinsBold text-textcolor text-semibold mb-4 md:mb-0">
                  Regular Investment Plans
                </span>
                <button
                  className={`py-2 px-4 rounded-md align-middle mb-4 md:mb-0 lg:mb-0 ${
                    isCreatePlanVisible
                      ? "border border-primary text-primary"
                      : "bg-primary text-white"
                  }`}
                  onClick={() => handleToggleView()}
                >
                  {isCreatePlanVisible ? (
                    <>
                      <FaRegEye
                        size={20}
                        className="dark:text-white inline mr-2 "
                      />
                      <span className="dark:text-white dark:border-white">
                        View Investment Plans
                      </span>
                    </>
                  ) : (
                    <>
                      <IoMdAdd className="inline mr-2 text-white" size={20} />
                      Add Investment Plan
                    </>
                  )}
                </button>
              </div>

              {isCreatePlanVisible ? (
                <div className="mb-4">
                  <CreateRegularPlan />
                </div>
              ) : (
                <>
                  <div className="md:flex md:gap-2">
                    <Input
                      placeholder="Search Title"
                      value={searchTitle}
                      className="mb-2 md:mb-0 lg:mb-0 w-full md:w-52 lg:w-52"
                      onChange={(e) => setSearchTitle(e.target.value)}
                    />
                    <Input
                      placeholder="Search Category"
                      value={searchCat}
                      onChange={(e) => setSearchCat(e.target.value)}
                      className="mb-2 md:mb-0 lg:mb-0 w-full md:w-52 lg:w-52"
                    />
                    <Input
                      placeholder="Search Status"
                      value={searchStatus}
                      onChange={(e) => setSearchStatus(e.target.value)}
                      className="mb-2 md:mb-0 lg:mb-0 w-full md:w-52 lg:w-52"
                    />
                  </div>

                  <div className="dark:bg-white overflow-x-auto w-full">
                    <Table
                      columns={columns}
                      dataSource={filteredInvest}
                      pagination={pagination}
                      className="font-PoppinsRegular text-textcolor mt-6"
                    />
                  </div>
                </>
              )}
            </div>
          )}
        </div>

        {isModalVisible && (
          <Modal onClose={handleCancel}>
            <h2 className="text-primary font-PoppinsSemiBold">
              Confirm Delete
            </h2>
            <div className="mt-4 text-textcolor">
              <span className="font-PoppinsSemiBold">
                {" "}
                Kindly note that all investment transactions of users will be
                deleted!
              </span>
              <br />
              <span className="mt-6">Are you sure you want to delete</span>{" "}
              <span className="text-primary font-PoppinsMedium">
                {selectedInvestment?.title}
              </span>
              ?{" "}
            </div>
            <Formik
              initialValues={{
                otp: "",
              }}
              validationSchema={otpSchema}
              onSubmit={handleDeleteConfirm}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="mb-4 mt-6">
                    <label
                      htmlFor="otp"
                      className="block text-primary font-PoppinsLight text-[14px] mb-1"
                    >
                      Enter OTP
                    </label>
                    <TextInput
                      type="text"
                      name="otp"
                      placeholder="1W2E3RR"
                      className="w-full h-12 border border-transactionCard text-textcolor font-PoppinsRegular px-2 py-1 rounded-lg focus:outline-none focus:border-primary"
                    />

                    <button
                      onClick={fetchAdminsAndGenerateOTP}
                      type="button"
                      className="font-PoppinsLight hover:text-primary text-textcolor text-[13px] underline"
                    >
                      Click to generate OTP
                    </button>
                  </div>

                  <div className="flex justify-end space-x-4 mt-8">
                    <button
                      className="text-error"
                      onClick={handleCancel}
                      type="button"
                    >
                      Cancel
                    </button>
                    <button
                      className="text-success"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Confirm
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </Modal>
        )}

        {isModalVisible && (
          <Modal
            onClose={handleCancel}
            className="w-full sm:w-[500px] md:w-[700px] lg:w-[800px] p-4"
          >
            <h2 className="text-center font-PoppinsRegular mb-4">
              Email Customer
            </h2>
            <Formik
              initialValues={{
                subject: "",
                body: "",
              }}
              validationSchema={sendEmailValidation}
              onSubmit={handleSubmit}
            >
              <Form className="w-full">
                <div className="flex flex-col sm:flex-row justify-center mb-4">
                  <label
                    htmlFor="subject"
                    className="text-textcolor font-PoppinsMedium mb-1 sm:mb-0 sm:mr-6 dark:text-white"
                  >
                    Subject:
                  </label>
                  <div className="w-full">
                    <TextInput
                      type="text"
                      name="subject"
                      placeholder="Enter the subject"
                    />
                  </div>
                </div>

                <div className="flex flex-col sm:flex-row justify-center">
                  <label
                    htmlFor="body"
                    className="text-textcolor font-PoppinsMedium mb-1 sm:mb-0 sm:mr-2 dark:text-white"
                  >
                    Message:
                  </label>
                  <div className="w-full">
                    <TextAreaInput
                      type="text"
                      name="body"
                      placeholder="Enter your message"
                      className="h-80"
                    />
                  </div>
                </div>

                <div className="w-full flex justify-end mt-8">
                  <button
                    type="submit"
                    className="w-full sm:w-full md:w-40 font-PoppinsMedium rounded-lg text-primary hover:before:bg-primary border-primary relative h-[50px] overflow-hidden border bg-white px-3 transition-all before:absolute before:bottom-0 before:left-0 before:top-0 before:z-0 before:h-full before:w-0 before:bg-primary before:transition-all before:duration-500 hover:text-white hover:before:left-0 hover:before:w-full"
                  >
                    <span className="relative z-10">Send</span>
                  </button>
                </div>
              </Form>
            </Formik>
          </Modal>
        )}
      </div>
    </>
  );
}

export default RegularInvestmentPlan;
