import React, { useRef, useState, useEffect } from "react";
import { useField, useFormikContext } from "formik";
import { LiaIdCardSolid } from "react-icons/lia";

interface CustomFileInputProps {
  label: string;
  name: string;
  initialPreviewUrl?: string;
  className?: string;
  examples?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const FileInput: React.FC<CustomFileInputProps> = ({
  label,
  name,
  initialPreviewUrl,
  examples,
}) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta, helpers] = useField<File | null>(name);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(
    initialPreviewUrl || null
  );
  const [fileName, setFileName] = useState<string | null>(null);

  const handleSelectFile = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files && event.currentTarget.files[0];
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      helpers.setValue(file);
      setFieldValue(name, file);
      setPreviewUrl(fileUrl);

      if (
        file.type === "image/jpg" ||
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "application/pdf"
      ) {
        setFileName(file.name);
      } else {
        setPreviewUrl(null);
        setFileName(null);
      }
    } else {
      setPreviewUrl(null);
      setFileName(null);
    }
  };

  useEffect(() => {
    if (initialPreviewUrl) {
      setPreviewUrl(initialPreviewUrl);
    }
  }, [initialPreviewUrl]);

  return (
    <div className="w-full xms:max-w-[200px] md:max-w-[250px]">
      <div className="text-[10px] pb-1 font-PoppinsSemiBold dark:text-white">
        {label}
      </div>
      <div className="text-[10px] pb-3 font-PoppinsSemiBold dark:text-white">
        {examples}
      </div>
      <div
        onClick={handleSelectFile}
        className="border-dashed border relative flex items-center justify-center dark:border-white dark:bg-white dark:bg-opacity-[5%] border-primary bg-primary bg-opacity-[5%] cursor-pointer p-2 w-full h-[200px] rounded-md mb-4"
      >
        {previewUrl ? (
          previewUrl.startsWith("blob:") && fileName ? (
            previewUrl.includes(".pdf") ? (
              <div className="flex items-center justify-center w-full h-full">
                <div className="text-wrap break-all font-PoppinsLight text-[14px] text-textcolor">
                  {fileName}
                </div>
              </div>
            ) : (
              <img
                src={previewUrl}
                alt="Selected file"
                className="max-w-full max-h-[200px]"
              />
            )
          ) : (
            <img
              src={previewUrl}
              alt="Fetched file"
              className="max-w-full max-h-[200px]"
            />
          )
        ) : (
          <div className="text-[14px] flex flex-col items-center font-PoppinsLight text-center text-textcolor">
            <LiaIdCardSolid
              size={35}
              className="text-primary dark:text-white dark:bg-opacity-50 bg-opacity-50 mb-3"
            />
            <div className="dark:text-white">{label}</div>
            <div className="text-disabledtext">JPG & PNG Supported</div>
          </div>
        )}
        <input
          type="file"
          id={name}
          name={name}
          accept=".jpg,.jpeg,.png,.pdf"
          onChange={handleChange}
          ref={inputRef}
          className="hidden"
        />
      </div>
    </div>
  );
};

export default FileInput;
