import { useCallback, useEffect, useState } from "react";
import ProfilePicture from "../components/ProfilePicture";
import { HttpClient } from "../api/HttpClient";
import { isAxiosError } from "axios";
import ToastComponent from "../components/ToastComponent";
import { RiLockPasswordLine, RiMenu2Fill } from "react-icons/ri";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { MoonLoader, ScaleLoader } from "react-spinners";
import PageTitle from "../components/Pagetitle";
import { IoIosArrowForward, IoIosWarning, IoMdPower } from "react-icons/io";
import { FiPhone, FiShare2, FiUserCheck } from "react-icons/fi";
import Patt from "../assets/img/endless-constellation.svg";
import {
  MdOutlineAccountBalance,
  MdOutlineAccountBalanceWallet,
  MdOutlineDarkMode,
  MdOutlineLightMode,
  MdOutlinePercent,
} from "react-icons/md";
import { setIsAuthenticated } from "../store/authSlice";
import * as Yup from "yup";
import AccountSettingsCard from "../components/AccountSettingsCard";
import { AiOutlineCamera, AiOutlineUser } from "react-icons/ai";
import { FaFacebook, FaQuestion } from "react-icons/fa6";
import Modal from "../components/Modal";
import SetAccountSettingsCard from "../components/SetAccountSettingsCard";
import {
  IoMailOutline,
  IoNotificationsOutline,
  IoSettingsOutline,
} from "react-icons/io5";
import { GrFingerPrint } from "react-icons/gr";
import { TbPasswordMobilePhone } from "react-icons/tb";
import { FaRegQuestionCircle } from "react-icons/fa";
import { Form, Formik } from "formik";
import TextInputWithLabel from "../components/TextInputWithLabel";
import AuthButton from "../components/AuthButton";
import FaqCard from "../components/FaqCard";
import { BsTwitterX } from "react-icons/bs";
import { LuInstagram } from "react-icons/lu";
import { SlLocationPin } from "react-icons/sl";
import { ConfigProvider, Switch } from "antd";
import { MoonFilled } from "@ant-design/icons";
import { SunOutlined } from "@ant-design/icons";
import { toggleTheme } from "../store/themeSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import {
  Account,
  AccountDetails,
  AuthenticatedLayoutContext,
} from "../assets/Typeprops";
import PasswordInput from "../components/PasswordInput";
import SelectInputWithLogo from "../components/SelectInputWithLogo";
import AccountNumberInput from "../components/AccountNumberInput";

const AccountPage = () => {
  const [accountData, setAccountData] = useState<Account | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenTodayRate, setIsOpenTodayRate] = useState(false);
  const [isOpenAccountSettings, setIsOpenAccountSettings] = useState(false);
  const [isShowAccountDetails, setIsShowAccountDetails] = useState(false);
  const [isOpenProfileData, setIsOpenProfileData] = useState(false);
  const [isOpenChangePassword, setIsOpenChangePassword] = useState(false);
  const [isOpenChangePin, SetIsOpenChangePin] = useState(false);
  const [isOpenSecurityQuestion, setIsOpenSecurityQuestion] = useState(false);
  const [isOpenFaq, setIsOpenFaq] = useState(false);
  const [isOpenAccountDetailsModal, setIsOpenAccountDetailsModal] =
    useState(false);
  const [isOpenContactUs, setIsOpenContactUs] = useState(false);
  const [changing, setChanging] = useState(false);
  const [setting, setSetting] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);
  const [bankList, setBankList] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [accountDetails, setAccountDetails] = useState<[]>([]);
  const [consentSwitch, setConsentSwitch] = useState(false);
  const [loggingOut, setIsLoggingOut] = useState(false);
  const fetchUserData = useCallback(async () => {
    setIsLoading(true);

    try {
      const [userDataRes, bankListRes, accountDetailsRes] = await Promise.all([
        HttpClient.get("/users/me", {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }),
        HttpClient.get("/payments/providus/banks", {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }),
        HttpClient.get("/users/settlement-account", {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }),
      ]);

      console.log(userDataRes);
      console.log(bankListRes);
      console.log(accountDetailsRes);
      setAccountData(userDataRes?.data?.data);

      const banks = bankListRes?.data?.data?.banks || [];
      const options = banks.map((bank: any) => ({
        value: bank.bankName,
        label: bank.bankName,
        logo: null,
      }));
      setBankList(options);

      setAccountDetails(accountDetailsRes?.data?.data);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      }
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  const hideUsersBalance = useCallback(async () => {
    const userId = accountData?.account?.user;
    if (!userId) return;

    const newHideBalance = !accountData?.hideBalance;

    setAccountData((prev) => {
      if (!prev) return null;
      return {
        ...prev,
        hideBalance: newHideBalance,
      };
    });

    try {
      await HttpClient.get(`/users/hide-show-balance/${userId}`);
    } catch (error) {
      setAccountData((prev) => {
        if (!prev) return null;

        return {
          ...prev,
          hideBalance: !newHideBalance,
        };
      });
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      }
    }
  }, [accountData]);
  const profileDataSchema = Yup.object().shape({
    username: Yup.string().required(),
    firstname: Yup.string().required(),
    lastname: Yup.string().required(),
    email: Yup.string().email().required(),
    phone: Yup.string().required(),
  });
  const changePasswordSchema = Yup.object().shape({
    password: Yup.string().required("Enter your password"),
    newPassword: Yup.string().required("Enter your new password"),
    confirmNewPassword: Yup.string()
      .required("Enter your confirmation password")
      .test(
        "matches",
        "New Passwords do not match",
        function (confirmNewPassword) {
          const { newPassword } = this.parent;
          return confirmNewPassword === newPassword;
        }
      ),
  });
  const bankDetailsSchema = Yup.object().shape({
    beneficiaryBank: Yup.string().required("Select a Valid Bank Name"),
    accountNumber: Yup.string()
      .required("Account Number required")
      .max(10, "Account Number must be 10 digits"),
    accountName: Yup.string().required("Account Name required"),
  });
  const setPinSchema = Yup.object().shape({
    accountPin: Yup.string().required("Enter transaction pin"),
    accountPassword: Yup.string().required("Enter your password"),
    otp: Yup.string().required("Enter otp"),
  });
  const setSecurityQuestionSchema = Yup.object().shape({
    question: Yup.string().required("Enter Security Question"),
    answer: Yup.string().required("Enter answer"),
    accountPassword: Yup.string().required("Enter Account Password"),
    otp: Yup.string().required("Enter otp"),
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
  const handleToggle = (checked: boolean) => {
    dispatch(toggleTheme());
  };
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedFile(event.target.files[0]);
    }
  };
  const handleSetSecurityQuestion = async (
    values: any,
    {
      setFieldValue,
    }: {
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean
      ) => void;
    }
  ) => {
    setSetting(true);
    try {
      const res = await HttpClient.post("/users/security-question", values, {
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      });
      ToastComponent.success(res.data.message);
      setIsOpenSecurityQuestion(false);
      setIsOpenAccountSettings(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      } else if (error instanceof Error) {
        ToastComponent.error(error.message);
      } else {
        ToastComponent.error("An unknown error occurred");
      }
      setFieldValue("accountPassword", "", false);
      setFieldValue("otp", "", false);
    } finally {
      setSetting(false);
    }
  };
  const handleUpdateAccountPin = async (
    values: any,
    {
      setFieldValue,
    }: {
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean
      ) => void;
    }
  ) => {
    setChanging(true);
    try {
      const res = await HttpClient.post("/users/update-account-pin", values, {
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      });
      ToastComponent.success(res.data.message);
      SetIsOpenChangePin(false);
      SetIsOpenChangePin(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      } else if (error instanceof Error) {
        ToastComponent.error(error.message);
      } else {
        ToastComponent.error("An unknown error occurred");
      }
      setFieldValue("accountPassword", "", false);
      setFieldValue("otp", "", false);
    } finally {
      setChanging(false);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) return;

    const formData = new FormData();
    formData.append("profile_photo", selectedFile);

    try {
      await HttpClient.post("/users/update-profile-photo", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      });
      setSelectedFile(null);
      fetchUserData();
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };
  const handleChangePassword = useCallback(
    async (values: any) => {
      setChanging(true);
      try {
        const changePasswordRes = await HttpClient.post(
          "/users/update-password",
          values,
          {
            headers: {
              "x-auth-token": localStorage.getItem("token"),
            },
          }
        );
        ToastComponent.success(changePasswordRes.data.message);
        navigate("/login");
        localStorage.removeItem("token");
      } catch (error: unknown) {
        if (isAxiosError(error)) {
          ToastComponent.error(error.response?.data.message);
        } else if (error instanceof Error) {
          ToastComponent.error(error.message);
        } else {
          ToastComponent.error("An unknown error occurred");
        }
      } finally {
        setChanging(false);
      }
    },
    [navigate]
  );
  const generateOTP = async () => {
    setIsRequesting(true);
    try {
      const res = await HttpClient.post("/users/generate-token", {
        email: accountData?.email,
      });
      ToastComponent.success(res.data.message);
    } catch (error) {
    } finally {
      setIsRequesting(false);
    }
  };
  const [showToolTip, setShowToolTip] = useState(false);

  const { toggleNav } = useOutletContext<AuthenticatedLayoutContext>();
  const handleSaveAccountDetails = async (values: any) => {
    setIsSaving(true);
    const data = {
      bankName: values.beneficiaryBank,
      accountNumber: values.accountNumber,
      accountName: values.accountName,
    };
    try {
      const saveAccountDetailsRes = await HttpClient.post(
        "/users/settlement-account",
        data,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      ToastComponent.success(saveAccountDetailsRes.data.message);
      setIsOpenAccountDetailsModal(false);
      fetchUserData();
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      } else if (error instanceof Error) {
        ToastComponent.error(error.message);
      } else {
        ToastComponent.error("An unknown error occurred");
      }
    } finally {
      setIsSaving(false);
    }
  };
  const location = useLocation();
  useEffect(() => {
    if (location.state?.openSecurityQuestionModal) {
      setIsOpenSecurityQuestion(true);
    }
  }, [location.state]);
  useEffect(() => {
    if (location.state?.openSettlementAcctModal) {
      setIsOpenAccountDetailsModal(true);
    }
  }, [location.state]);
  useEffect(() => {
    if (location.state?.openSetTransactionPinModal) {
      SetIsOpenChangePin(true);
    }
  }, [location.state]);
  useEffect(() => {
    if (location.state?.openShowProfileModal) {
      setIsOpenProfileData(true);
    }
  }, [location.state]);
  const handleLogout = async () => {
    setIsLoggingOut(true);
    try {
      await HttpClient.post("/users/logout");
      localStorage.removeItem("token");
      dispatch(setIsAuthenticated(false));
      navigate("/login");
    } catch (error) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      }
    } finally {
      setIsLoggingOut(false);
    }
  };

  return (
    <>
      <PageTitle title="Affluence - My Account" />
      {isLoading ? (
        <div className="w-full h-screen bg-white dark:bg-darkPrimary flex items-center justify-center">
          <ScaleLoader color={isDarkMode ? "#FFFFFF" : "#6B006B"} />
        </div>
      ) : (
        <>
          {loggingOut ? (
            <div className="w-full h-screen bg-white dark:bg-darkPrimary flex items-center justify-center relative">
              <div className="flex flex-col justify-center items-center">
                <ScaleLoader color={isDarkMode ? "#FFFFFF" : "#6B006B"} />
                <h1 className="dark:text-white text-textcolor mt-7">
                  Log Out Processing...
                </h1>
              </div>
            </div>
          ) : (
            <div className="text-textcolor dark:text-white bg-white dark:bg-darkPrimary pb-[70px] pt-[4rem] overflow-hidden">
              <button onClick={toggleNav} className="md:hidden block px-[3%]">
                <RiMenu2Fill
                  size={30}
                  className="text-primary dark:text-white"
                />
              </button>
              <div className="pb-7 px-[3%] flex items-center justify-between">
                <div className="md:text-[24px] sm:text-[22px] text-[20px]">
                  <h1 className=" font-PoppinsBold">My Account</h1>
                  <h1 className=" font-PoppinsMedium text-[13px]">
                    {accountData?.firstName} {accountData?.lastName}
                  </h1>
                </div>
                <ProfilePicture
                  profilePicsClass="cursor-pointer"
                  onClick={() => setIsOpenProfileData(true)}
                  profilePics={accountData?.photo}
                />
              </div>
              <div className="px-[3%] block xxs:flex justify-between gap-2 pt-7">
                <div className="md:w-[45%] xxs:w-[48%] w-full">
                  <AccountSettingsCard
                    icon1={MdOutlinePercent}
                    icon2={IoIosArrowForward}
                    iconClass1=""
                    onClick={() => setIsOpenTodayRate(true)}
                  >
                    Today's Rate
                  </AccountSettingsCard>
                  <AccountSettingsCard
                    icon1={AiOutlineUser}
                    icon2={IoIosArrowForward}
                    iconClass1=""
                    onClick={() => setIsOpenAccountSettings(true)}
                  >
                    My Account Settings
                  </AccountSettingsCard>
                  <AccountSettingsCard
                    icon1={FiUserCheck}
                    icon2={IoIosArrowForward}
                    iconClass1=""
                    onClick={() => navigate("/kyc")}
                  >
                    Update KYC
                  </AccountSettingsCard>
                  <AccountSettingsCard
                    icon1={FiShare2}
                    icon2={IoIosArrowForward}
                    iconClass1=""
                    comingSoon={true}
                  >
                    Refer & Earn
                  </AccountSettingsCard>
                  <div
                    className="p-4 rounded-[4px] mb-2 flex items-center justify-between border-[1.5px] border-transactionCard
            "
                    //   onClick={onClick}
                  >
                    <div className="flex items-center space-x-5">
                      <h1 className="font-PoppinsRegular text-[14px]">
                        {accountData?.hideBalance
                          ? "Show Wallet Balance"
                          : "Hide Wallet Balance"}
                      </h1>
                    </div>
                    <div className="md:text-[22px] sm:text-[20px] text-[18px]">
                      <ConfigProvider
                        theme={{
                          token: {
                            colorPrimary: "#6B006B",
                          },
                        }}
                      >
                        <Switch
                          checked={accountData?.hideBalance}
                          onChange={hideUsersBalance}
                        />
                      </ConfigProvider>
                    </div>
                  </div>
                  <div
                    className="p-4 rounded-[4px] mb-2 flex items-center justify-between border-[1.5px] border-transactionCard
            "
                    //   onClick={onClick}
                  >
                    <div className="flex items-center space-x-5">
                      <div className="md:text-[22px] sm:text-[20px] text-primary dark:text-white p-2 rounded-full bg-primary dark:bg-white dark:bg-opacity-25 bg-opacity-25 text-[18px]">
                        {isDarkMode ? (
                          <MdOutlineLightMode />
                        ) : (
                          <MdOutlineDarkMode />
                        )}
                      </div>
                      <h1 className="font-PoppinsRegular text-[14px]">
                        {isDarkMode ? "Disable Dark Mode" : "Enable Dark Mode"}
                      </h1>
                    </div>
                    <div className="md:text-[22px] sm:text-[20px] text-[18px]">
                      <ConfigProvider
                        theme={{
                          token: {
                            colorPrimary: "#6B006B",
                          },
                        }}
                      >
                        <Switch
                          checked={isDarkMode}
                          onChange={handleToggle}
                          checkedChildren={<MoonFilled size={20} />}
                          unCheckedChildren={<SunOutlined size={20} />}
                        />
                      </ConfigProvider>
                    </div>
                  </div>
                  <AccountSettingsCard
                    icon1={FaQuestion}
                    icon2={IoIosArrowForward}
                    iconClass1=""
                    onClick={() => setIsOpenFaq(true)}
                  >
                    FAQ
                  </AccountSettingsCard>
                  <AccountSettingsCard
                    icon1={FiPhone}
                    icon2={IoIosArrowForward}
                    iconClass1=""
                    onClick={() => setIsOpenContactUs(true)}
                  >
                    Contact Us
                  </AccountSettingsCard>
                  <AccountSettingsCard
                    onClick={handleLogout}
                    icon1={IoMdPower}
                    icon2={IoIosArrowForward}
                    iconClass1="logout"
                  >
                    Log Out
                  </AccountSettingsCard>
                </div>
                <div className="md:w-[45%] xxs:w-[48%] w-full">
                  {accountDetails.length > 0 ? (
                    <AccountSettingsCard
                      icon1={MdOutlineAccountBalance}
                      icon2={IoIosArrowForward}
                      iconClass1=""
                      onClick={() => setIsShowAccountDetails(true)}
                    >
                      View Account Details
                    </AccountSettingsCard>
                  ) : (
                    <AccountSettingsCard
                      icon1={MdOutlineAccountBalance}
                      icon2={IoIosArrowForward}
                      iconClass1=""
                      onClick={() => setIsOpenAccountDetailsModal(true)}
                    >
                      Set Bank Account Details
                    </AccountSettingsCard>
                  )}
                </div>
              </div>
              {isShowAccountDetails && (
                <Modal
                  isOpenModal={() => setIsShowAccountDetails(true)}
                  closeModal={() => setIsShowAccountDetails(false)}
                >
                  <div className="px-4 pt-[120px]">
                    <h1 className="text-[20px] pb-8 dark:text-white text-primary font-PoppinsBold">
                      Withdrawal Account
                    </h1>
                    <h1 className="text-[11px] dark:text-white text-textcolor font-PoppinsRegular">
                      This account receives funds withdrawn from the Affluence
                      wallets.
                    </h1>
                    <h1 className="text-[11px] flex items-center gap-2 dark:text-white text-textcolor font-PoppinsRegular">
                      <IoIosWarning className="text-[14px] text-secondary" />
                      Please make sure that provided account details are
                      correct.
                    </h1>
                    <div
                      className="relative rounded-[15px] mt-8 w-full h-[200px] bg-cover bg-center bg-no-repeat"
                      style={{ backgroundImage: `url(${Patt})` }}
                    >
                      {accountDetails?.map((details: AccountDetails) => (
                        <div className="h-full text-white ">
                          <div className="flex flex-col items-center h-full justify-center">
                            <h1 className="font-PoppinsRegular pb-5 text-[12px]">
                              {details.bankName}
                            </h1>
                            <h1 className="font-PoppinsBold tracking-[5px] text-center text-[30px]">
                              {details.accountNumber}
                            </h1>
                            <h1 className="font-PoppinsRegular uppercase text-[12px]">
                              {details.accountName}
                            </h1>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </Modal>
              )}
              {isOpenAccountDetailsModal && (
                <Modal
                  isOpenModal={() => setIsOpenAccountDetailsModal(true)}
                  closeModal={() => setIsOpenAccountDetailsModal(false)}
                >
                  <div className="px-4 pt-[120px]">
                    <h1 className="text-[20px] font-PoppinsBold">
                      Link Bank Account
                    </h1>
                    <div className="mt-7">
                      <Formik
                        initialValues={{
                          accountNumber: "",
                          beneficiaryBank: "",
                          accountName: "",
                        }}
                        validationSchema={bankDetailsSchema}
                        onSubmit={handleSaveAccountDetails}
                        validateOnChange={true}
                        validateOnBlur={true}
                      >
                        {({ isValid, dirty }) => (
                          <Form>
                            <AccountNumberInput
                              name="accountNumber"
                              type="text"
                              placeholder="e.g 098765432"
                              label="Account Number"
                            />
                            <SelectInputWithLogo
                              options={bankList}
                              name="beneficiaryBank"
                              label="Select Bank Name"
                              placeholder="Select Bank"
                            />

                            <TextInputWithLabel
                              name="accountName"
                              type="text"
                              label="Account Name"
                            />
                            <ConfigProvider
                              theme={{
                                token: {
                                  colorPrimary: "#6B006B",
                                },
                              }}
                            >
                              <div className="flex items-center space-x-4 justify-between mb-4">
                                <Switch
                                  checked={consentSwitch}
                                  onChange={() =>
                                    setConsentSwitch((prev) => !prev)
                                  }
                                />
                                <span className="text-primary text-[13px] font-PoppinsMedium dark:text-white">
                                  I agree that the account details provided are
                                  correct and the company will not be liable for
                                  any loss of fund if the account details
                                  provided are wrong.
                                </span>
                              </div>
                            </ConfigProvider>
                            <AuthButton
                              isLoading={isSaving}
                              disabled={!isValid || !dirty || !consentSwitch}
                            >
                              Proceed
                            </AuthButton>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </Modal>
              )}
              {isOpenTodayRate && (
                <Modal
                  isOpenModal={() => setIsOpenTodayRate(true)}
                  closeModal={() => setIsOpenTodayRate(false)}
                >
                  <div className="px-4 pt-[120px]">
                    <h1 className="text-[20px] font-PoppinsBold">
                      Today's Rate
                    </h1>
                    <h1 className="text-[11px] font-PoppinsRegular">
                      Below are the current rates as of September, 2024. The
                      rates are subject to changes.
                    </h1>
                    <div className="border border-transactionCard px-3 py-5 flex mt-10 rounded-[4px] space-x-5">
                      <MdOutlineAccountBalanceWallet className="text-primary text-[25px]" />
                      <div>
                        <h1 className="text-[14px] pb-1 font-PoppinsMedium">
                          Affluence Secure Fund 1 Interest
                        </h1>
                        <h1 className="text-[11px] font-PoppinsMedium">
                          18.2% per annum
                        </h1>
                      </div>
                    </div>
                  </div>
                </Modal>
              )}
              {isOpenAccountSettings && (
                <Modal
                  isOpenModal={() => setIsOpenAccountSettings(true)}
                  closeModal={() => setIsOpenAccountSettings(false)}
                >
                  <div className="px-4 pt-[120px]">
                    <h1 className="text-[20px] font-PoppinsBold">
                      Account Settings
                    </h1>
                    <div className="mt-10">
                      <SetAccountSettingsCard
                        onClick={() => setIsOpenProfileData(true)}
                        icon1={IoSettingsOutline}
                      >
                        Profile Update
                      </SetAccountSettingsCard>
                      <SetAccountSettingsCard
                        onClick={() => setIsOpenChangePassword(true)}
                        icon1={RiLockPasswordLine}
                      >
                        Change Password
                      </SetAccountSettingsCard>
                      <SetAccountSettingsCard
                        onClick={() => SetIsOpenChangePin(true)}
                        icon1={TbPasswordMobilePhone}
                        isSet={accountData?.changePinStatus}
                      >
                        Set Transaction Pin
                      </SetAccountSettingsCard>
                      <SetAccountSettingsCard
                        comingSoon={true}
                        icon1={GrFingerPrint}
                      >
                        Enable FingerPrint
                      </SetAccountSettingsCard>
                      <SetAccountSettingsCard
                        onClick={() => setIsOpenSecurityQuestion(true)}
                        icon1={FaRegQuestionCircle}
                        isSet={accountData?.securityQuestionStatus}
                      >
                        Security Questions
                      </SetAccountSettingsCard>
                      <SetAccountSettingsCard
                        comingSoon={true}
                        icon1={IoNotificationsOutline}
                      >
                        Notifications
                      </SetAccountSettingsCard>
                    </div>
                  </div>
                </Modal>
              )}
              {isOpenProfileData && (
                <Modal
                  isOpenModal={() => setIsOpenProfileData(true)}
                  closeModal={() => setIsOpenProfileData(false)}
                >
                  <div className="px-4 pt-[120px]">
                    <h1 className="text-[20px] font-PoppinsBold">
                      Profile Data
                    </h1>
                    <div className="mt-10">
                      <div className="inline-flex items-end gap-4 relative">
                        <div className="h-[100px] w-[100px] overflow-hidden rounded-full">
                          <img
                            src={accountData?.photo}
                            className="w-full h-full aspect-square object-cover"
                            alt="profile_picture"
                          />
                        </div>

                        <div
                          onMouseEnter={() => setShowToolTip(true)}
                          onMouseLeave={() => setShowToolTip(false)}
                          className="bg-white rounded-full p-2"
                        >
                          <label htmlFor="file-input">
                            <AiOutlineCamera className="text-[24px] text-darkPrimary cursor-pointer" />
                          </label>
                          <input
                            id="file-input"
                            type="file"
                            accept="image/*"
                            className="hidden"
                            onChange={handleFileChange}
                          />
                        </div>
                        {showToolTip && (
                          <div className="absolute bottom-[50px] right-[-110px] ">
                            <div className="bg-white relative font-PoppinsLight text-[11px] py-1 px-3 rounded-[4px] text-darkPrimary">
                              Click to change picture
                            </div>
                            <div className="tooltip-arrow"></div>
                          </div>
                        )}
                      </div>

                      {selectedFile && (
                        <div className="mt-2">
                          <button
                            onClick={handleUpload}
                            className="sm:p-2 p-1 rounded-[8px] dark:bg-white bg-primary pulseupload dark:text-darkPrimary text-white font-PoppinsMedium text-[12px] flex justify-center items-center"
                          >
                            Upload Selected Picture
                          </button>
                        </div>
                      )}
                      <div className="text-center pt-5 text-referral text-[10px] font-PoppinsRegular">
                        Only your profile picture can be updated. To update your
                        account details, kindly write to
                        affluenceng.app@gmail.com
                      </div>
                      <Formik
                        initialValues={{
                          username: accountData?.username,
                          firstname: accountData?.firstName,
                          lastname: accountData?.lastName,
                          email: accountData?.email,
                          phone: accountData?.phone,
                        }}
                        validationSchema={profileDataSchema}
                        onSubmit={(values) => console.log(values)}
                      >
                        <Form>
                          <TextInputWithLabel
                            name="username"
                            type="text"
                            label="Username"
                            readOnly={true}
                          />
                          <TextInputWithLabel
                            name="firstname"
                            type="text"
                            label="First Name"
                            readOnly={true}
                          />
                          <TextInputWithLabel
                            name="lastname"
                            type="text"
                            label="Last Name"
                            readOnly={true}
                          />
                          <TextInputWithLabel
                            name="email"
                            type="email"
                            label="Email Address"
                            readOnly={true}
                          />
                          <TextInputWithLabel
                            name="phone"
                            type="number"
                            label="Phone Number"
                            readOnly={true}
                          />
                        </Form>
                      </Formik>
                    </div>
                  </div>
                </Modal>
              )}
              {isOpenChangePassword && (
                <Modal
                  isOpenModal={() => setIsOpenChangePassword(true)}
                  closeModal={() => setIsOpenChangePassword(false)}
                >
                  <div className="px-4 pt-[120px]">
                    <h1 className="text-[20px] font-PoppinsBold">
                      Change Password
                    </h1>
                    <div>
                      <div className="py-7 text-referral text-[12px] font-PoppinsRegular">
                        Please be informed that you will be logged out. Log back
                        in with your{" "}
                        <span className="uppercase font-PoppinsSemiBold">
                          new password.
                        </span>
                      </div>
                      <Formik
                        initialValues={{
                          password: "",
                          newPassword: "",
                          confirmNewPassword: "",
                        }}
                        validationSchema={changePasswordSchema}
                        onSubmit={handleChangePassword}
                      >
                        {({ isValid, dirty }) => (
                          <Form>
                            <PasswordInput
                              name="password"
                              type="password"
                              label="Current Password"
                              updatePass="top-[35px] right-[13px]"
                            />
                            <PasswordInput
                              name="newPassword"
                              type="password"
                              label="New Password"
                              updatePass="top-[35px] right-[13px]"
                            />
                            <PasswordInput
                              name="confirmNewPassword"
                              type="password"
                              label="Confirm New Password"
                              updatePass="top-[35px] right-[13px]"
                            />
                            <AuthButton
                              isLoading={changing}
                              disabled={!isValid || !dirty}
                            >
                              Update Password
                            </AuthButton>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </Modal>
              )}
              {isOpenChangePin && (
                <Modal
                  isOpenModal={() => SetIsOpenChangePin(true)}
                  closeModal={() => SetIsOpenChangePin(false)}
                >
                  <div className="px-4 pt-[120px]">
                    <div className="flex items-center justify-between pb-[60px]">
                      <h1 className="text-[20px] font-PoppinsBold">
                        Set Transaction Pin
                      </h1>
                    </div>
                    <div>
                      <Formik
                        initialValues={{
                          accountPin: "",
                          accountPassword: "",
                          otp: "",
                        }}
                        validationSchema={setPinSchema}
                        onSubmit={handleUpdateAccountPin}
                      >
                        {({ isValid, dirty }) => (
                          <Form>
                            <PasswordInput
                              name="accountPin"
                              type="password"
                              placeholder="4-digit pin"
                              label="Enter Transaction Pin"
                              updatePass="top-[35px] right-[13px]"
                            />
                            <PasswordInput
                              name="accountPassword"
                              type="password"
                              label="Enter Account Password"
                              updatePass="top-[35px] right-[13px]"
                            />
                            <PasswordInput
                              name="otp"
                              type="password"
                              label="Enter OTP"
                              updatePass="top-[35px] right-[13px]"
                            />
                            {isRequesting ? (
                              <div className="mb-5">
                                <MoonLoader color="#6B006B" size={17} />
                              </div>
                            ) : (
                              <button
                                onClick={generateOTP}
                                type="button"
                                className="mb-5 text-[13px] underline"
                              >
                                Click to generate OTP
                              </button>
                            )}
                            <AuthButton
                              isLoading={changing}
                              disabled={!isValid || !dirty}
                            >
                              Set Pin
                            </AuthButton>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </Modal>
              )}
              {isOpenFaq && (
                <Modal
                  isOpenModal={() => setIsOpenFaq(true)}
                  closeModal={() => setIsOpenFaq(false)}
                >
                  <div className="px-4 pt-[120px]">
                    <h1 className="text-[20px] font-PoppinsBold">
                      Frequently Asked Questions
                    </h1>
                    <div className="mt-10">
                      <FaqCard
                        question="What is Affluence?"
                        answer="at non nisl. Lorem elit fringilla Morbi Quisque dui. ullamcorper Praesent turpis Donec non, risus id sit felis, id massa at faucibus Quisque ipsum Ut dui. hendrerit quam nisi eget gravida orci Nam sodales. lobortis, Nam Quisque vitae elit at, dui."
                      />
                      <FaqCard
                        question="Why should I save on Affluence?"
                        answer="at non nisl. Lorem elit fringilla Morbi Quisque dui. ullamcorper Praesent turpis Donec non, risus id sit felis, id massa at faucibus Quisque ipsum Ut dui. hendrerit quam nisi eget gravida orci Nam sodales. lobortis, Nam Quisque vitae elit at, dui."
                      />
                      <FaqCard
                        question="How are you able to offer higher returns than traditional banks?"
                        answer="at non nisl. Lorem elit fringilla Morbi Quisque dui. ullamcorper Praesent turpis Donec non, risus id sit felis, id massa at faucibus Quisque ipsum Ut dui. hendrerit quam nisi eget gravida orci Nam sodales. lobortis, Nam Quisque vitae elit at, dui."
                      />
                      <FaqCard
                        question="What is/are the interest rate(s) on Affluence?"
                        answer="at non nisl. Lorem elit fringilla Morbi Quisque dui. ullamcorper Praesent turpis Donec non, risus id sit felis, id massa at faucibus Quisque ipsum Ut dui. hendrerit quam nisi eget gravida orci Nam sodales. lobortis, Nam Quisque vitae elit at, dui."
                      />
                      <FaqCard
                        question="How secure is my information?"
                        answer="at non nisl. Lorem elit fringilla Morbi Quisque dui. ullamcorper Praesent turpis Donec non, risus id sit felis, id massa at faucibus Quisque ipsum Ut dui. hendrerit quam nisi eget gravida orci Nam sodales. lobortis, Nam Quisque vitae elit at, dui."
                      />
                      <h1 className="pt-5 font-PoppinsMedium text-[13px]">
                        If you have any further questions or inquiries, please
                        don't hesitate to contact us at{" "}
                        <span className="text-primary dark:text-secondary underline">
                          affluenceng.app@gmail.com
                        </span>
                      </h1>
                    </div>
                  </div>
                </Modal>
              )}
              {isOpenContactUs && (
                <Modal
                  isOpenModal={() => setIsOpenContactUs(true)}
                  closeModal={() => setIsOpenContactUs(false)}
                >
                  <div className="px-4 pt-[120px]">
                    <h1 className="text-[20px] font-PoppinsBold">Contact Us</h1>
                    <div className="mt-10">
                      <SetAccountSettingsCard
                        label="Phone Number"
                        children="+234 8976543210"
                        icon1={FiPhone}
                      />
                      <SetAccountSettingsCard
                        label="Email Address"
                        children="affluenceng.app@gmail.com"
                        icon1={IoMailOutline}
                      />
                      <SetAccountSettingsCard
                        children="Facebook"
                        icon1={FaFacebook}
                      />
                      <SetAccountSettingsCard
                        children="Twitter"
                        icon1={BsTwitterX}
                      />
                      <SetAccountSettingsCard
                        children="Instagram"
                        icon1={LuInstagram}
                      />
                      <SetAccountSettingsCard
                        children="Office Address"
                        icon1={SlLocationPin}
                        description="B1, Apo Sunrise Plaza, Apo Resettlement, Abuja, Nigeria."
                      />
                    </div>
                  </div>
                </Modal>
              )}
              {isOpenSecurityQuestion && (
                <Modal
                  isOpenModal={() => setIsOpenSecurityQuestion(true)}
                  closeModal={() => setIsOpenSecurityQuestion(false)}
                >
                  <div className="px-4 pt-[120px]">
                    <div className="flex items-center justify-between pb-[60px]">
                      <h1 className="text-[20px] font-PoppinsBold">
                        Security Question
                      </h1>
                    </div>
                    <div>
                      <Formik
                        initialValues={{
                          question: "",
                          answer: "",
                          accountPassword: "",
                          otp: "",
                        }}
                        validationSchema={setSecurityQuestionSchema}
                        onSubmit={handleSetSecurityQuestion}
                      >
                        {({ isValid, dirty }) => (
                          <Form>
                            <PasswordInput
                              name="question"
                              type="text"
                              placeholder="e.g: How are you?"
                              label="Enter Security Question"
                            />
                            <PasswordInput
                              name="answer"
                              type="password"
                              placeholder="Answer"
                              label="Enter Answer"
                              updatePass="top-[35px] right-[13px]"
                            />
                            <PasswordInput
                              name="accountPassword"
                              type="password"
                              placeholder="******"
                              label="Enter Account Password"
                              updatePass="top-[35px] right-[13px]"
                            />
                            <PasswordInput
                              name="otp"
                              type="password"
                              label="Enter OTP"
                              updatePass="top-[35px] right-[13px]"
                            />
                            {isRequesting ? (
                              <div className="mb-5">
                                <MoonLoader color="#6B006B" size={17} />
                              </div>
                            ) : (
                              <button
                                onClick={generateOTP}
                                type="button"
                                className="mb-5 text-[13px] underline"
                              >
                                Click to generate OTP
                              </button>
                            )}
                            <AuthButton
                              isLoading={setting}
                              disabled={!isValid || !dirty}
                            >
                              Set Security Question
                            </AuthButton>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </Modal>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default AccountPage;
