import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import Login from "./authPages/Login";
import Signup from "./authPages/Signup";
import ForgotPassword from "./authPages/ForgotPassword";
import ResetPassword from "./authPages/ResetPassword";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EmailVerification from "./authPages/EmailVerification";
import KYC from "./dashboard/KYC";
import HomePage from "./dashboard/Homepage";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./store/store";
import AuthenticatedLayout from "./AuthenticatedLayout";
import FundWallet from "./dashboard/FundWallet";
import Savings from "./dashboard/Savings";
import TabbedPage from "./savings/SavingsTabContent";
import AccountPage from "./dashboard/AccountPage";
import { useEffect } from "react";
import { setTheme } from "./store/themeSlice";

// ADMIN URLs
import AdminDashboard from "./admin/dashboard/Home";
import FixedInvestmentPlan from "./admin/dashboard/investment/fixedInvestment/FixedInvestmentPlan";
import FixedInvestmentLog from "./admin/dashboard/investment/fixedInvestment/FixedInvestmentLog";
import AllCustomers from "./admin/dashboard/customers/AllCustomers";
import OneCustomer from "./admin/dashboard/customers/OneCustomer";
import TransactionLog from "./admin/dashboard/transaction/TransactionLog";
import PaymentGateway from "./admin/dashboard/transaction/PaymentGateway";
import FixedInvestmentCategory from "./admin/dashboard/investment/InvestmentCategory";
import ViewProjectInvestors from "./admin/dashboard/investment/fixedInvestment/ViewFixedInvestors";
import Deposits from "./admin/dashboard/Deposits";
import WithdrawalLog from "./admin/dashboard/withdrawals/WithdrawalLog";
import WithdrawalReq from "./admin/dashboard/withdrawals/WithdrawalReq";
import Audit from "./admin/dashboard/Audit";
import SavingsLog from "./admin/dashboard/savings/SavingsLog";
import SavingsPlan from "./admin/dashboard/savings/SavingsPlan";
import SavingsCategory from "./admin/dashboard/savings/SavingsCategory";
import Configuration from "./admin/dashboard/Configuration";
import WalletFunding from "./admin/dashboard/transaction/WalletFunding";
import { HttpClient, setupInterceptors } from "./api/HttpClient";
import { setIsAuthenticated, setRole } from "./store/authSlice";
import TokenExpirationHandler from "./api/TokenExpirationHandler";
import ComingSoon from "./ComingSoon";
import InvestmentOptions from "./dashboard/InvestmentOptions";
import FlexibleInvestments from "./dashboard/InvestmentTypes/FlexibleInvestments/FlexibleInvestments";
import StandardInvestments from "./dashboard/InvestmentTypes/StandardInvestments/StandardInvestments";
import KidsInvestments from "./dashboard/InvestmentTypes/KidsInvestments/KidsInvestments";
import ProjectInvestments from "./dashboard/InvestmentTypes/ProjectInvestments/ProjectInvestments";
import RegularInvestLog from "./admin/dashboard/investment/regularInvestment/RegularInvestLog";
import KidsInvestPlan from "./admin/dashboard/investment/kidsInvestment/KidsInvestPlan";
import KidsInvestLog from "./admin/dashboard/investment/kidsInvestment/KidsInvestLog";
import RegularInvestmentPlan from "./admin/dashboard/investment/regularInvestment/RegularInvestPlan";
import PromotionalEmails from "./admin/dashboard/PromotionalEmail";
import ViewFlexInvestors from "./admin/dashboard/investment/emergencyFund/ViewEmergencyFundInvestors";
import ViewRegularInvestors from "./admin/dashboard/investment/regularInvestment/ViewRegularInvestors";
import AdminKyc from "./admin/dashboard/KYC";
import EmergencyFundPlan from "./admin/dashboard/investment/emergencyFund/EmergencyFundPlan";
import EmergencyFundLog from "./admin/dashboard/investment/emergencyFund/EmergencyFundLog"; 

function App() {
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );
  const role = useSelector((state: RootState) => state.auth.role);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
  // const [statusCode, setStatusCode] = useState<number | null>(null);
  useEffect(() => {
    const theme = localStorage.getItem("theme");
    if (theme) {
      dispatch(setTheme(theme === "dark"));
    } else {
      const prefersDark = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      dispatch(setTheme(prefersDark));
    }
  }, [dispatch]);

  useEffect(() => {
    document.documentElement.classList.toggle("dark", isDarkMode);
  }, [isDarkMode]);

  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        const response = await HttpClient.get("/users/me", {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        });
        // setStatusCode(response.status);
        const data = response.data.roles;
        if (data) {
          dispatch(setIsAuthenticated(true));
          dispatch(setRole(data.role));
        }
      } catch (error) {
        console.error("Failed to fetch user role:", error);
      }
    };

    fetchUserRole();
  }, [dispatch]);
  useEffect(() => {
    setupInterceptors(navigate, dispatch);
  }, [navigate, dispatch]);
  return (
    <>
      <ToastContainer />
      <Routes>
        {/* Public Routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/sign_up" element={<Signup />} />
        <Route path="/forgot" element={<ForgotPassword />} />
        <Route path="/reset" element={<ResetPassword />} />
        <Route path="/verify" element={<EmailVerification />} />

        {/* Role-based Routing */}
        {isAuthenticated ? (
          role === "admin" ? (
            // Admin routes
            <>
              <Route path="/admin/home" element={<AdminDashboard />} />
              <Route
                path="/admin/investment/fixed_investment_plan"
                element={<FixedInvestmentPlan />}
              />
              <Route
                path="/admin/investment/fixed_investment_log"
                element={<FixedInvestmentLog />}
              />
              <Route
                path="/admin/investment/add_fixed_category"
                element={<FixedInvestmentCategory />}
              />
              <Route
                path="/admin/investment/fixed_investment_plan/view_investors/:investmentId"
                element={<ViewProjectInvestors />}
              />
              <Route
                path="/admin/investment/regular_investment_plan"
                element={<RegularInvestmentPlan />}
              />
              <Route
                path="/admin/investment/regular_investment_log"
                element={<RegularInvestLog />}
              />
              <Route
                path="/admin/investment/regular_investment_plan/view_investors/:investmentId"
                element={<ViewRegularInvestors />}
              />
              <Route
                path="/admin/investment/emergency_fund_plan"
                element={<EmergencyFundPlan />}
              />
              <Route
                path="/admin/investment/emergency_fund_log"
                element={<EmergencyFundLog />}
              />
              <Route
                path="/admin/investment/flexible_investment_plan/view_investors/:investmentId"
                element={<ViewFlexInvestors />}
              />
              <Route
                path="/admin/investment/kids_investment_plan"
                element={<KidsInvestPlan />}
              />
              <Route
                path="/admin/investment/kids_investment_log"
                element={<KidsInvestLog />}
              />
              <Route path="/admin/allCustomers" element={<AllCustomers />} />
              <Route
                path="/admin/allCustomers/:customerId"
                element={<OneCustomer />}
              />
              <Route path="/admin/deposits" element={<Deposits />} />
              <Route
                path="/admin/withdrawal/withdrawal_log"
                element={<WithdrawalLog />}
              />
              <Route
                path="/admin/withdrawal/withdrawal_request"
                element={<WithdrawalReq />}
              />
              <Route
                path="/admin/savings/savings_plan"
                element={<SavingsPlan />}
              />
              <Route
                path="/admin/savings/savings_log"
                element={<SavingsLog />}
              />
              <Route
                path="/admin/savings/savings_category"
                element={<SavingsCategory />}
              />
              <Route
                path="/admin/transactions/transaction_log"
                element={<TransactionLog />}
              />
              <Route
                path="/admin/transactions/payment_gateway"
                element={<PaymentGateway />}
              />
              <Route
                path="/admin/transactions/wallet_funding"
                element={<WalletFunding />}
              />
              <Route path="/admin/audit" element={<Audit />} />
              <Route path="/admin/configuration" element={<Configuration />} />
              <Route
                path="/admin/promotional_emails"
                element={<PromotionalEmails />}
              />
              <Route path="/admin/kyc" element={<AdminKyc />} />
            </>
          ) : (
            // Regular user routes
            <Route element={<AuthenticatedLayout />}>
              <Route path="/" element={<HomePage />} />
              <Route path="/kyc" element={<KYC />} />
              <Route path="/my_account" element={<AccountPage />} />
              <Route path="/fund_wallet" element={<FundWallet />} />
              <Route path="/wallets" element={<Savings />} />
              <Route path="/support" element={<ComingSoon />} />
              <Route path="/investments" element={<InvestmentOptions />} />
              <Route
                path="/investments/project-investments/:minorTab"
                element={<ProjectInvestments />}
              />
              <Route
                path="/investments/standard-investments/:minorTab"
                element={<StandardInvestments />}
              />
              <Route
                path="/investments/flexible-investments/:minorTab"
                element={<FlexibleInvestments />}
              />
              <Route
                path="/investments/kids-investments/:minorTab"
                element={<KidsInvestments />}
              />
              <Route path="/wallets/:tab" element={<TabbedPage />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Route>
          )
        ) : (
          <Route path="*" element={<Navigate to="/login" />} />
        )}
      </Routes>
    </>
  );
}

export default App;
