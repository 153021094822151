import { FC, useMemo, useState } from "react";
import { IoIosSearch, IoMdAddCircle } from "react-icons/io";
import { InvestmentItem } from "../../../../assets/Typeprops";
import PageTitle from "../../../../components/Pagetitle";
import { formatCurrency } from "../../../../components/CurrencyFormat";
import Logo from "../../../../assets/img/affluence-logo-small.png";
import Modal from "../../../../components/Modal";
import { highlightText } from "../../../../components/HighLight";
import Default from "../../../../assets/img/default-img.webp";
import { RootState } from "../../../../store/store";
import { useSelector } from "react-redux";
import { TiWarningOutline } from "react-icons/ti";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import ReactPaginate from "react-paginate";
import { MoonLoader } from "react-spinners";
import InvestmentBtn from "../../../../components/InvestmentBtn";
import { ConfigProvider, Switch } from "antd";
import TextInputWithLabel from "../../../../components/TextInputWithLabel";
import SelectInputWithLabelAndPlaceholder from "../../../../components/SelectInputWithLabelAndPlaceholder";
import SelectInputWithLabel from "../../../../components/SelectInputWithLabel";
import { Form, Formik } from "formik";
import { isAxiosError } from "axios";
import ToastComponent from "../../../../components/ToastComponent";
import { HttpClient } from "../../../../api/HttpClient";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
interface ActiveInvestmentsProps {
  data: InvestmentItem[];
  setCategoryTab: any;
  categoryTab?: string | null;
  setSearchQuery: any;
  handleFollow: any;
  searchQuery: any;
  currentPage?: number | undefined;
  setCurrentPage: any;
  following: any;
  totalPages?: number | undefined;
  accountData: any;
}
const ActiveInvestments: FC<ActiveInvestmentsProps> = ({
  data,
  setCategoryTab,
  categoryTab,
  setSearchQuery,
  searchQuery,
  currentPage,
  totalPages,
  setCurrentPage,
  handleFollow,
  following,
  accountData,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedInvestment, setSelectedInvestment] =
    useState<InvestmentItem | null>(null);
  const [onHoverFollowBtn, setOnHoverFollowBtn] = useState(false);
  const [aboutModal, setAboutModal] = useState(false);
  const [investNowModal, setInvestNowModal] = useState(false);
  const [switch1, setSwitch1] = useState(false);
  const [switch2, setSwitch2] = useState(false);
  const [isBooking, setIsBooking] = useState(false);
  const navigate = useNavigate();
  const openShowInvestmentModal = (investment: InvestmentItem) => {
    setSelectedInvestment(investment);
    setIsModalOpen(true);
  };
  const closeShowInvestmentModal = () => {
    setIsModalOpen(false);
    setSelectedInvestment(null);
  };
  const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };
  const handlePageChange = ({ selected }: any) => {
    setCurrentPage(selected);
  };
  const openInvestNowModal = (investment: InvestmentItem) => {
    setSelectedInvestment(investment);
    setInvestNowModal(true);
  };
  const closeInvestNowModal = () => {
    setInvestNowModal(false);
  };
  const handleBookInvestment = async (values: any) => {
    setIsBooking(true);
    try {
      const bookingRes = await HttpClient.post(
        "/flexible-investments/booking",
        { ...values, id: selectedInvestment?._id },
        {
          headers: { "x-auth-token": localStorage.getItem("token") },
        }
      );
      navigate("/investments/project-investments/my_investments");
      setInvestNowModal(false);
      setIsModalOpen(false);
      ToastComponent.success(bookingRes.data.message);
    } catch (error) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      }
    } finally {
      setIsBooking(false);
    }
  };
  const wallet = [
    {
      value: "balance",
      label: `Balance (${
        accountData?.account?.balance
          ? formatCurrency(accountData.account.balance)
          : "₦0"
      })`,
    },
    {
      value: "profit",
      label: `Profits (${
        accountData?.account?.profit
          ? formatCurrency(accountData.account.profit)
          : "₦0"
      })`,
    },
    {
      value: "referral",
      label: `Referrals (${
        accountData?.account?.referral
          ? formatCurrency(accountData.account.referral)
          : "₦0"
      })`,
    },
  ];
  const durationNumberData = useMemo(() => {
    return Array.from({ length: 366 }, (_, i) => {
      const value = i + 1;
      return { value: value, label: `${value}` };
    });
  }, []);
  const durationIntervalData = [
    {
      value: "days",
      label: "Day(s)",
    },
    {
      value: "weeks",
      label: "Week(s)",
    },
    {
      value: "months",
      label: "Month(s)",
    },
  ];
  const bookInvestmentSchema = Yup.object().shape({
    amount: Yup.number()
      .required("Please type an amount")
      .min(5000, "Please select an amount not less than ₦5,000")
      .test(
        "is-multiple-of-5000",
        "Amount must be a multiple of ₦5,000",
        (value) => {
          return value % 5000 === 0;
        }
      ),
    durationNumber: Yup.number(),
    durationInterval: Yup.string().required("Please select a duration"),
    fundingSource: Yup.string().required("Please select a funding source"),
    answer: Yup.string().required("Please enter security answer"),
  });
  return (
    <>
      <PageTitle title="Affluence - Running Investments" />
      <div className="pt-6">
        <div className="relative mx-[3%]">
          <input
            type="search"
            placeholder="Search Investment"
            value={searchQuery}
            onChange={handleSearchChange}
            className="bg-searchBar border dark:text-darkPrimary border-authbordercolor font-PoppinsLight text-[12px] outline-none placeholder:text-authbordercolor w-full sm:py-3 sm:px-10 p-2.5 rounded-[8px]"
          />
          <IoIosSearch className="text-authbordercolor text-[22px] absolute left-2.5 top-2.5" />
        </div>
        <div className="border-b-[1.5px] border-transactionCard pb-5">
          <div className="flex text-[12px] font-PoppinsMedium justify-between items-center gap-10 pt-6 px-[3%]">
            <div
              onClick={() => setCategoryTab(null)}
              className={`border-[1.5px] cursor-pointer py-0.5 border-textcolor rounded-[10px] w-full text-center ${
                categoryTab === null ? "bg-primary text-white" : ""
              }`}
            >
              All
            </div>
            <div
              onClick={() => setCategoryTab("Fixed Income")}
              className={`border-[1.5px] cursor-pointer py-0.5 border-textcolor rounded-[10px] w-full text-center ${
                categoryTab === "Fixed Income" ? "bg-primary text-white" : ""
              }`}
            >
              Fixed Income
            </div>
            <div
              onClick={() => setCategoryTab("Real Estate")}
              className={`border-[1.5px] cursor-pointer py-0.5 border-textcolor rounded-[10px] w-full text-center ${
                categoryTab === "Real Estate" ? "bg-primary text-white" : ""
              }`}
            >
              Real Estate
            </div>
            <div
              onClick={() => setCategoryTab("Agriculture")}
              className={`border-[1.5px] cursor-pointer py-0.5 border-textcolor rounded-[10px] w-full text-center ${
                categoryTab === "Agriculture" ? "bg-primary text-white" : ""
              }`}
            >
              Agriculture
            </div>
            <div
              onClick={() => setCategoryTab("Transportation")}
              className={`border-[1.5px] cursor-pointer py-0.5 border-textcolor rounded-[10px] w-full text-center ${
                categoryTab === "Transportation" ? "bg-primary text-white" : ""
              }`}
            >
              Transportation
            </div>
          </div>
        </div>
        <div>
          <>
            {data && data.length > 0 ? (
              data?.map((item, index) => (
                <div
                  key={index}
                  className="border-y-[1.5px] cursor-pointer w-full border-transactionCard dark:border-textcolor mb-2 py-2 dark:shadow-[#000000] shadow-transactionCard shadow-sm"
                  onClick={() => openShowInvestmentModal(item)}
                >
                  <div className="flex items-center gap-[50px] px-[3%]">
                    <div className="w-[200px] h-[150px] relative">
                      <img
                        src={
                          item?.previewImage
                            ? `${process.env.REACT_APP_FILES_URL}/${item.previewImage}`
                            : item?.previewImage === ""
                            ? Default
                            : Default
                        }
                        className="object-cover w-full h-full rounded-[10px]"
                        alt="investment-img"
                      />
                    </div>
                    <div>
                      <h1 className="font-PoppinsSemiBold uppercase text-[18px] pb-2">
                        {highlightText(item.title, searchQuery)}
                      </h1>
                      <h1 className="text-success font-PoppinsSemiBold text-[12px]">
                        {item.rate}%{" "}
                        <span className=" font-PoppinsRegular text-[10px] text-tabletext">
                          returns in your desired duration
                        </span>
                      </h1>
                      <div className="flex gap-x-9 mt-3 items-center">
                        <div>
                          <h1 className="text-[12px] text-center font-PoppinsMedium">
                            <span>{item.numberOfInvestors}</span>
                          </h1>
                          <h1 className="font-PoppinsMedium text-center text-[9px] text-tabletext">
                            {item.numberOfInvestors === 1
                              ? "Investor"
                              : "Investors"}
                          </h1>
                        </div>
                        <div
                          className={`${
                            item.status === "running"
                              ? "text-referral bg-referral"
                              : item.status === "matured"
                              ? isDarkMode
                                ? "text-[#C8A2C8] bg-[#C8A2C8]"
                                : "text-primary bg-primary"
                              : item.status === "pending"
                              ? "text-secondary bg-secondary"
                              : "text-error bg-error"
                          } bg-opacity-[28%] inline-block py-1 px-4 rounded-[10px] font-PoppinsMedium text-[10px]`}
                        >
                          {item.status}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center pt-[40px]">
                <div>
                  <h1 className="font-PoppinsBold pb-3 sm:text-[24px] xs:text-[22px] text-[20px] text-primary">
                    There are no running investments currently!
                  </h1>
                </div>
              </div>
            )}
            <ReactPaginate
              breakLabel={<span className="mr-4">...</span>}
              nextLabel={
                (currentPage ?? 0) < (totalPages ?? 0) - 1 && (
                  <span className="w-8 h-8 flex items-center justify-center bg-authbordercolor dark:text-white bg-opacity-25 rounded-[4px]">
                    <BsChevronRight />
                  </span>
                )
              }
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              pageCount={totalPages ?? 0} // Provide a fallback value of 0 if totalPages is undefined
              previousLabel={
                (currentPage ?? 0) > 0 && (
                  <span className="w-8 h-8 flex mr-3 items-center justify-center bg-authbordercolor dark:text-white bg-opacity-25 rounded-[4px]">
                    <BsChevronLeft />
                  </span>
                )
              }
              renderOnZeroPageCount={null}
              containerClassName="flex items-center justify-center mt-8 mb-4"
              pageClassName="element"
              activeClassName="active-element"
            />
          </>
          {isModalOpen && selectedInvestment && (
            <Modal
              isOpenModal={openShowInvestmentModal}
              closeModal={closeShowInvestmentModal}
            >
              <div
                className="flex items-center mt-[90px] justify-center"
                style={{
                  backgroundImage: `url(${
                    selectedInvestment?.previewImage
                      ? `${process.env.REACT_APP_FILES_URL}/${selectedInvestment.previewImage}`
                      : selectedInvestment?.previewImage === ""
                      ? Default
                      : Default
                  })`,
                  height: "250px",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <div className="bg-white opacity-50 inline-block p-4 rounded-[8px]">
                  <img src={Logo} className="w-[30px]" alt="" />
                </div>
              </div>
              <div className="px-4">
                <div className="bg-success mt-6 mb-8 py-1 px-4 inline-block text-[12px] font-PoppinsLight rounded-[10px] bg-opacity-[28%] text-success">
                  VERIFIED INVESTMENT
                </div>
                <div className="w-1/2">
                  <div>
                    <h1 className="font-PoppinsBold uppercase text-[20px]">
                      {selectedInvestment.title}
                    </h1>
                  </div>
                </div>
                <div className="flex flex-col gap-4 mt-5">
                  {selectedInvestment.status === "stopped" ? (
                    <button className="bg-error cursor-not-allowed border-[1.5px] border-error text-white font-PoppinsSemiBold w-full py-4 rounded-[8px]">
                      STOPPED
                    </button>
                  ) : selectedInvestment.status === "running" ? (
                    <button
                      onClick={() => openInvestNowModal(selectedInvestment)}
                      className="bg-investment border-[1.5px] border-investment text-white font-PoppinsSemiBold w-full py-4 rounded-[8px]"
                    >
                      INVEST NOW
                    </button>
                  ) : null}
                  {selectedInvestment.status === "stopped" ? (
                    <button
                      onClick={() => setAboutModal(true)}
                      className="border-[1.5px] border-error text-error font-PoppinsSemiBold py-4 w-full rounded-[8px]"
                    >
                      ABOUT THIS INVESTMENT
                    </button>
                  ) : (
                    <button
                      onClick={() => setAboutModal(true)}
                      className="border-[1.5px] border-investment text-investment font-PoppinsSemiBold py-4 w-full rounded-[8px]"
                    >
                      ABOUT THIS INVESTMENT
                    </button>
                  )}
                </div>
                <div
                  className={`text-[14px] font-PoppinsRegular text-center mt-3 ${
                    selectedInvestment.status === "stopped"
                      ? "text-error"
                      : selectedInvestment.status === "pending"
                      ? "text-success"
                      : selectedInvestment.status === "running"
                      ? "text-referral"
                      : "text-success"
                  }`}
                >
                  {selectedInvestment?.status === "stopped"
                    ? "This investment is currently not available. Please look for other investment plans."
                    : selectedInvestment?.status === "pending"
                    ? "This investment plan is not running yet."
                    : selectedInvestment?.status === "running"
                    ? "This investment plan is already running for all Investors."
                    : null}
                </div>

                <hr className="text-authbordercolor my-10" />
                <button
                  onClick={handleFollow}
                  onMouseEnter={() => setOnHoverFollowBtn(true)}
                  onMouseLeave={() => setOnHoverFollowBtn(false)}
                  className="font-PoppinsMedium text-[13px] flex items-center justify-center mb-7 bg-primary text-white py-3 px-4 w-full rounded-[8px]"
                >
                  {following ? (
                    <MoonLoader color="#ffffff" size={17} />
                  ) : onHoverFollowBtn ? (
                    <IoMdAddCircle className="text-white text-[19.5px]" />
                  ) : (
                    <span>Follow Investment</span>
                  )}
                </button>
                <div>
                  <div className="flex justify-center gap-7 mb-5">
                    <div className="w-full border-[1.5px] rounded-[8px] p-3 border-transactionCard">
                      <h1 className="text-[13px] font-PoppinsMedium text-disabledtext">
                        Investment Type
                      </h1>
                      <h1 className="text-[13px] font-PoppinsMedium">
                        {selectedInvestment.category}
                      </h1>
                    </div>
                    <div className="w-full border-[1.5px] rounded-[8px] p-3 border-transactionCard">
                      <h1 className="text-[13px] font-PoppinsMedium text-disabledtext">
                        Payout Type
                      </h1>
                      <h1 className="text-[13px] font-PoppinsMedium">
                        Capital + profit to be paid
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          )}
          {aboutModal && (
            <Modal
              isOpenModal={() => setAboutModal(true)}
              closeModal={() => setAboutModal(false)}
            >
              <div className="px-4 pt-[120px]">
                <div>
                  <h1 className="text-primary dark:text-white text-[25px] font-PoppinsSemiBold">
                    About Investment
                  </h1>
                  <div className="py-2">
                    <p className="text-[13px] font-PoppinsRegular text-disabledtext dark:text-disabled">
                      Read about this investment below
                    </p>
                    {selectedInvestment?.disclaimer && (
                      <div className="mt-4">
                        <h1 className="font-PoppinsMedium text-[14px] mb-1 flex items-center gap-2">
                          Disclaimer <TiWarningOutline className="text-error" />
                        </h1>
                        <p className="font-PoppinsLight text-[13px]">
                          {selectedInvestment?.disclaimer}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <div
                    className="flex items-center my-7 justify-center"
                    style={{
                      backgroundImage: `url(${
                        selectedInvestment?.previewImage
                          ? `${process.env.REACT_APP_FILES_URL}/${selectedInvestment.previewImage}`
                          : selectedInvestment?.previewImage === ""
                          ? Default
                          : Default
                      })`,
                      height: "250px",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }}
                  >
                    <div className="bg-white opacity-50 inline-block p-4 rounded-[8px]">
                      <img src={Logo} className="w-[30px]" alt="" />
                    </div>
                  </div>
                  <p className="text-white text-[13px]">
                    {selectedInvestment?.description}
                  </p>
                </div>
              </div>
            </Modal>
          )}
          {investNowModal && selectedInvestment && (
            <Modal
              isOpenModal={openInvestNowModal}
              closeModal={closeInvestNowModal}
            >
              <div className="mt-[120px] px-4">
                <h1 className="text-primary dark:text-white text-[25px] font-PoppinsSemiBold">
                  Invest Now
                </h1>
                <div className="bg-primary rounded-[8px] mt-4 p-4 text-white">
                  <h1 className="text-[18px] font-PoppinsMedium">
                    Information
                  </h1>
                  <h1 className="font-PoppinsRegular text-[14px]">
                    Fill the form below to start investing
                  </h1>
                </div>
                <h1 className="mt-[60px] mb-[20px] font-PoppinsMedium text-[14px] text-textcolor dark:text-white">
                  Please enter an Amount in multiple of ₦5,000 in{"  "}
                  <span className="uppercase">{selectedInvestment.title}</span>
                </h1>
                <Formik
                  initialValues={{
                    amount: "",
                    durationNumber: "",
                    durationInterval: "",
                    fundingSource: "",
                    answer: "",
                  }}
                  onSubmit={handleBookInvestment}
                  validationSchema={bookInvestmentSchema}
                  enableReinitialize={false}
                >
                  {({ values, isValid, dirty }) => (
                    <Form>
                      <TextInputWithLabel
                        label="Amount(in multiple of ₦5,000)"
                        name="amount"
                        type="number"
                      />
                      <SelectInputWithLabel
                        name="durationNumber"
                        options={durationNumberData}
                        label="Duration number"
                      />
                      <SelectInputWithLabelAndPlaceholder
                        name="durationInterval"
                        options={durationIntervalData}
                        label="Duration Interval(in days, weeks or months)"
                        placeholder="Select Duration Interval"
                      />
                      <SelectInputWithLabelAndPlaceholder
                        name="fundingSource"
                        options={wallet}
                        placeholder="Select a funding source"
                        label="Choose a Source of Funds"
                      />
                      {values.amount &&
                        values.durationNumber &&
                        values.durationInterval &&
                        values.fundingSource && (
                          <TextInputWithLabel
                            name="answer"
                            type="text"
                            label="Answer for the security question set by you"
                          />
                        )}
                      <ConfigProvider
                        theme={{
                          token: {
                            colorPrimary: "#6B006B",
                          },
                        }}
                      >
                        {values.answer !== "" && (
                          <div>
                            <div className="flex items-center space-x-4 justify-between mb-4">
                              <Switch
                                checked={switch1}
                                onChange={() => setSwitch1((prev) => !prev)}
                              />
                              <span className="text-primary text-[13px] font-PoppinsMedium dark:text-white">
                                {`I confirm my purchase of this investment for ${formatCurrency(
                                  Number(values.amount)
                                )}. I agree to retain this investment for ${
                                  values.durationNumber
                                } ${
                                  Number(values.durationNumber) === 1
                                    ? values.durationInterval.slice(0, -1) // Singular form
                                    : values.durationInterval // Plural form
                                } before cashing out.`}
                              </span>
                            </div>
                            <div className="flex items-center justify-between space-x-4 mb-4">
                              <Switch
                                checked={switch2}
                                onChange={() => setSwitch2((prev) => !prev)}
                              />
                              <span className="text-primary text-[13px] font-PoppinsMedium dark:text-white">
                                I acknowledge that I have read and comprehended
                                the investment information. I recognize that
                                investing in third-party instruments carries
                                risks, which may, in rare instances, involve
                                loss of capital and lack of liquidity.
                              </span>
                            </div>
                          </div>
                        )}
                      </ConfigProvider>
                      <div>
                        <InvestmentBtn
                          disabled={!isValid || !dirty || !switch1 || !switch2}
                          isLoading={isBooking}
                        >
                          INVEST NOW
                        </InvestmentBtn>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Modal>
          )}
        </div>
      </div>
    </>
  );
};

export default ActiveInvestments;
