import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Account,
  InterestData,
  MyInvestmentItem,
} from "../../../../assets/Typeprops";
import { Form, Formik } from "formik";
import Modal from "../../../../components/Modal";
import { formatDate } from "../../../../components/DateFormat";
import { formatCurrency } from "../../../../components/CurrencyFormat";
import ToastComponent from "../../../../components/ToastComponent";
import { isAxiosError } from "axios";
import { HttpClient } from "../../../../api/HttpClient";
import Logo from "../../../../assets/img/affluence-logo-small.png";
import { MoonLoader, ScaleLoader } from "react-spinners";
import { RootState } from "../../../../store/store";
import { useSelector } from "react-redux";
import AuthButton from "../../../../components/AuthButton";
import PasswordInput from "../../../../components/PasswordInput";
import * as Yup from "yup";
import GlowingButton from "../../../../components/GlowBtn";
import Default from "../../../../assets/img/default-img.webp";
import { TiCancel, TiWarningOutline } from "react-icons/ti";
import { IoIosArrowForward } from "react-icons/io";
import { IoEye } from "react-icons/io5";
import InvestmentBtn from "../../../../components/InvestmentBtn";
import { ConfigProvider, Switch } from "antd";
import TextInputWithLabel from "../../../../components/TextInputWithLabel";
import SelectInputWithLabelAndPlaceholder from "../../../../components/SelectInputWithLabelAndPlaceholder";
import { GiPadlockOpen } from "react-icons/gi";
import { FiRefreshCcw } from "react-icons/fi";
import { FaHandHoldingUsd } from "react-icons/fa";
import { RiFundsLine } from "react-icons/ri";
import { CiCircleQuestion } from "react-icons/ci";

const MyInvestmentMobile = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedInvestment, setSelectedInvestment] =
    useState<MyInvestmentItem | null>(null);
  const [viewInterestsModal, setviewInterestsModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [investmentData, setInvestmentData] = useState<MyInvestmentItem[]>([]);
  const [filterCategory, setFilterCategory] = useState<string | null>(null);
  const [selectedInvestmentIndex, setSelectedInvestmentIndex] = useState<
    string | null
  >(null);
  const [showEnterTransactionPin, setShowTransactionPin] = useState(false);
  const [pin, setPin] = useState(false);
  const [aboutModal, setAboutModal] = useState(false);
  const [breakInvestmentModal, setBreakInvestmentModal] = useState(false);
  const [modalInvestments, setModalInvestments] = useState<MyInvestmentItem[]>(
    []
  );
  const [investNowModal, setInvestNowModal] = useState(false);
  const [isLoadingInterest, setIsLoadingInterest] = useState(false);
  const [accountData, setAccountData] = useState<Account | null>(null);
  const [bookingLoading, setBookingLoading] = useState(false);
  const [viewMore, setViewMore] = useState(false);
  const [viewInterestsData, setViewInterestsData] =
    useState<InterestData | null>(null);
  const [switch1, setSwitch1] = useState(false);
  const [switch2, setSwitch2] = useState(false);
  const [topupModal, setTopupModal] = useState(false);
  const [rollOverLoading, setRollOverLoading] = useState(false);
  const [rollOverModal, setRollOverModal] = useState(false);
  const openShowInvestmentModal = (
    investment: MyInvestmentItem,
    index: string
  ) => {
    setSelectedInvestment(investment);
    setSelectedInvestmentIndex(index);
    setIsModalOpen(true);
  };

  const closeShowInvestmentModal = () => {
    setIsModalOpen(false);
    setSelectedInvestment(null);
  };
  const openTopupModal = (investment: MyInvestmentItem, index: string) => {
    setSelectedInvestment(investment);
    setSelectedInvestmentIndex(index);
    setTopupModal(true);
  };
  const closeTopupModal = () => {
    setTopupModal(false);
    setSelectedInvestment(null);
  };
  const openRollOverModal = (investment: MyInvestmentItem, index: string) => {
    setSelectedInvestment(investment);
    setSelectedInvestmentIndex(index);
    setRollOverModal(true);
  };
  const closeRollOverModal = () => {
    setRollOverModal(false);
    setSelectedInvestment(null);
  };
  const openInvestNowModal = (investment: MyInvestmentItem, index: string) => {
    setSelectedInvestment(investment);
    setSelectedInvestmentIndex(index);
    setInvestNowModal(true);
  };

  const closeInvestNowModal = () => {
    setInvestNowModal(false);
  };
  const bookInvestmentSchema = Yup.object().shape({
    units: Yup.number(),
    fundingSource: Yup.string().required("Please select a funding source"),
  });
  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const [userRes, investmentRes] = await Promise.all([
        HttpClient.get("/users/me", {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }),
        HttpClient.get("/regular-investments/booking", {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }),
      ]);
      setInvestmentData(investmentRes?.data?.data?.data);
      setAccountData(userRes?.data?.data);
    } catch (error) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      }
    } finally {
      setIsLoading(false);
    }
  }, []);
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  const handleBookInvestment = async (values: any) => {
    setBookingLoading(true);
    try {
      const bookingRes = await HttpClient.post(
        "/regular-investments/booking",
        { ...values, id: selectedInvestment?.investment_details._id },
        {
          headers: { "x-auth-token": localStorage.getItem("token") },
        }
      );
      fetchData();
      setIsModalOpen(false);
      setInvestNowModal(false);
      ToastComponent.success(bookingRes.data.message);
    } catch (error) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      }
    } finally {
      setBookingLoading(false);
    }
  };
  const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
  const filteredData = useMemo(() => {
    let result = investmentData;
    if (filterCategory) {
      result = result.filter(
        (item) => item.investment_details.status === filterCategory
      );
    }
    return result;
  }, [filterCategory, investmentData]);
  const wallet = [
    {
      value: "balance",
      label: `Balance (${
        accountData?.account?.balance
          ? formatCurrency(accountData.account.balance)
          : "₦0"
      })`,
    },
    {
      value: "profit",
      label: `Profits (${
        accountData?.account?.profit
          ? formatCurrency(accountData.account.profit)
          : "₦0"
      })`,
    },
    {
      value: "referral",
      label: `Referrals (${
        accountData?.account?.referral
          ? formatCurrency(accountData.account.referral)
          : "₦0"
      })`,
    },
  ];
  const setPinSchema = Yup.object().shape({
    pin: Yup.string().required("Enter transaction pin"),
  });
  const handleClaimProfit = async (
    values: any,
    {
      setFieldValue,
    }: {
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean
      ) => void;
    }
  ) => {
    setPin(true);
    try {
      const res = await HttpClient.put(
        `/regular-investments/claim-roi/${selectedInvestment?.investment_details._id}`,
        values,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      ToastComponent.success(res.data.message);
      setIsModalOpen(false);
      setShowTransactionPin(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      } else if (error instanceof Error) {
        ToastComponent.error(error.message);
      } else {
        ToastComponent.error("An unknown error occurred");
      }
      setFieldValue("pin", "", false);
    } finally {
      setPin(false);
    }
  };
  const topUpSchema = Yup.object().shape({
    amount: Yup.number()
      .required("Please type an amount")
      .min(10000, "Please select an amount not less than ₦10,000")
      .test(
        "is-multiple-of-10000",
        "Amount must be a multiple of ₦10,000",
        (value) => {
          return value % 10000 === 0;
        }
      ),
    fundingSource: Yup.string().required("Please select a funding source"),
    answer: Yup.string().required("Please enter security answer"),
  });
  const handleTopUp = async (values: any) => {
    setBookingLoading(true);
    try {
      const topUpRes = await HttpClient.post(
        "/regular-investments/booking-topup",
        { ...values, id: selectedInvestment?.investment_details._id },
        {
          headers: { "x-auth-token": localStorage.getItem("token") },
        }
      );
      fetchData();
      setIsModalOpen(false);
      setTopupModal(false);
      ToastComponent.success(topUpRes.data.message);
    } catch (error) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      }
    } finally {
      setBookingLoading(false);
    }
  };
  const breakInvestmentSchema = Yup.object().shape({
    pin: Yup.string()
      .required("Enter transaction pin")
      .max(4, "Transaction Pin length must be equal to 4"),
  });
  const handleBreakInvestment = async (
    values: any,
    {
      setFieldValue,
    }: {
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean
      ) => void;
    }
  ) => {
    setPin(true);
    try {
      const res = await HttpClient.put(
        `/regular-investments/break-roi/${selectedInvestment?.investment_details._id}`,
        values,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      ToastComponent.success(res.data.message);
      setBreakInvestmentModal(false);
      setIsModalOpen(false);
      fetchData();
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      } else if (error instanceof Error) {
        ToastComponent.error(error.message);
      } else {
        ToastComponent.error("An unknown error occurred");
      }
      setFieldValue("pin", "", false);
    } finally {
      setPin(false);
    }
  };

  const fetchAllModalInvestment = useCallback(async () => {
    setIsLoading(true);
    try {
      const investmentRes = await HttpClient.get(
        "/regular-investments/booking",
        {
          params: {
            page: 1,
            limit: "",
            dir: "desc",
            sort: "updatedAt",
            search: "",
          },
          headers: { "x-auth-token": localStorage.getItem("token") },
        }
      );
      setModalInvestments(investmentRes?.data?.data?.data);
    } catch (error) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      }
    } finally {
      setIsLoading(false);
    }
  }, []);
  useEffect(() => {
    if (viewMore) {
      fetchAllModalInvestment();
    }
  }, [fetchAllModalInvestment, viewMore]);
  const handleViewInterests = async () => {
    setIsLoadingInterest(true);
    try {
      const viewInterestsRes = await HttpClient.get(
        `regular-investments/view-interests/${selectedInvestment?._id}`,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      setviewInterestsModal(true);
      setViewInterestsData(viewInterestsRes?.data?.data);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      } else if (error instanceof Error) {
        ToastComponent.error(error.message);
      } else {
        ToastComponent.error("An unknown error occurred");
      }
    } finally {
      setIsLoadingInterest(false);
    }
  };

  const rollOverSchema = Yup.object().shape({
    fundingSource: Yup.string().required("Please select a funding source"),
    answer: Yup.string().required("Please enter security answer"),
  });
  const rollOverFunding = [
    {
      value: "capitalandroi",
      label: "Capital + ROI",
    },
    {
      value: "capital",
      label: "CAPITAL only",
    },
    {
      value: "roi",
      label: "ROI only",
    },
  ];
  const handleRollOver = async (values: any) => {
    setRollOverLoading(true);
    try {
      const rollOverRes = await HttpClient.post(
        `regular-investments/rollover/${selectedInvestment?.investment_details._id}`,
        { ...values },
        {
          headers: { "x-auth-token": localStorage.getItem("token") },
        }
      );
      fetchData();
      setIsModalOpen(false);
      setRollOverModal(false);
      ToastComponent.success(rollOverRes.data.message);
    } catch (error) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      }
    } finally {
      setRollOverLoading(false);
    }
  };
  return (
    <div className="text-textcolor dark:text-white">
      <div className="flex text-[10px] font-PoppinsMedium justify-between items-center gap-10 mx-[3%] pt-2">
        <div
          onClick={() => setFilterCategory(null)}
          className={`border-[1.5px] cursor-pointer py-0.3 border-textcolor rounded-[10px] w-full text-center ${
            filterCategory === null ? "bg-primary text-white" : ""
          }`}
        >
          All
        </div>
        <div
          onClick={() => setFilterCategory("running")}
          className={`border-[1.5px] cursor-pointer py-0.3 border-textcolor rounded-[10px] w-full text-center ${
            filterCategory === "running" ? "bg-primary text-white" : ""
          }`}
        >
          Running
        </div>
        <div
          onClick={() => setFilterCategory("followed")}
          className={`border-[1.5px] cursor-pointer py-0.3 border-textcolor rounded-[10px] w-full text-center ${
            filterCategory === "followed" ? "bg-primary text-white" : ""
          }`}
        >
          Followed
        </div>
        <div
          onClick={() => setFilterCategory("matured")}
          className={`border-[1.5px] cursor-pointer py-0.3 border-textcolor rounded-[10px] w-full text-center ${
            filterCategory === "matured" ? "bg-primary text-white" : ""
          }`}
        >
          Matured
        </div>
      </div>

      {filteredData && (
        <div className="flex justify-end pt-3 mr-[3%]">
          <h1
            onClick={() => setViewMore(true)}
            className="font-PoppinsRegular flex items-center space-x-1 cursor-pointer text-[11px] dark:text-white text-primary"
          >
            View more
            <IoIosArrowForward />
          </h1>
        </div>
      )}
      <div className="flex gap-3 pl-[3%] pt-5 overflow-x-scroll hide-scroll">
        {isLoading ? (
          <div className="w-full h-[50vh] bg-white dark:bg-darkPrimary flex items-center justify-center relative">
            <ScaleLoader color={isDarkMode ? "#FFFFFF" : "#6B006B"} />
          </div>
        ) : filteredData && filteredData.length > 0 ? (
          filteredData.map((item, index) => (
            <div
              key={index}
              className="inline-block cursor-pointer w-[150px]"
              onClick={() => openShowInvestmentModal(item, item._id)}
            >
              <div className="w-[150px] h-[100px] relative">
                <img
                  src={
                    item.investment_details?.previewImage
                      ? item.investment_details?.previewImage
                      : item.investment_details?.previewImage === ""
                      ? Default
                      : Default
                  }
                  className="w-full h-full bg-cover object-cover rounded-[10px]"
                  alt="investment-img"
                />
                <div
                  className={`${
                    item.investmentStatus === "running"
                      ? "bg-referral"
                      : item.investmentStatus === "completed"
                      ? "bg-success"
                      : item.investmentStatus === "matured"
                      ? isDarkMode
                        ? "bg-[#C8A2C8]"
                        : "bg-primary"
                      : item.investmentStatus === "pending"
                      ? "bg-secondary"
                      : "bg-error"
                  } text-white rounded-[20px] absolute top-2 right-2 font-PoppinsMedium text-[8px] justify-center items-center inline-flex py-[0.7px] px-2`}
                >
                  {item.investmentStatus === "running"
                    ? "RUNNING"
                    : item.investmentStatus === "matured"
                    ? "MATURED"
                    : item.investmentStatus === "pending"
                    ? "PENDING"
                    : "STOPPED"}
                </div>
              </div>
              <div className="pt-2">
                <h1 className="text-textcolor dark:text-white  font-PoppinsRegular text-[10px]">
                  NUMBER OF INVESTORS:{" "}
                  <span className="font-PoppinsSemiBold">
                    {item.investment_details.numberOfInvestors}
                  </span>
                </h1>
                <h1 className="text-textcolor dark:text-white  uppercase font-PoppinsSemiBold text-[12px] whitespace-nowrap overflow-hidden text-ellipsis w-[150px]">
                  {item?.investment_details.title}
                </h1>
                <h1 className="font-PoppinsRegular text-[11px] dark:text-white  text-textcolor">
                  Interest rate at{" "}
                  <span className="font-PoppinsSemiBold text-success">{`${item.investment_details.rate}%`}</span>
                </h1>
                <h1 className="font-PoppinsRegular text-[11px] dark:text-white  text-textcolor">
                  Returns in your desired duration
                </h1>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center w-full pt-[40px]">
            <div>
              <h1 className="font-PoppinsBold pb-3 text-[20px] text-primary">
                You have no investment currently!
              </h1>
              <h1 className="font-PoppinsRegular pb-2 px-[3%] dark:text-white text-[12px]">
                Start earning interest by investing in our various investment
                options.
              </h1>
            </div>
          </div>
        )}
      </div>
      {viewMore && (
        <Modal
          isOpenModal={() => setViewMore(true)}
          closeModal={() => setViewMore(false)}
        >
          <div className="px-4 pt-[120px]">
            <h1 className="text-[20px] font-PoppinsBold">All Investments</h1>
            {isLoading ? (
              <div className="w-full h-[50vh] bg-white dark:bg-darkPrimary flex items-center justify-center">
                <ScaleLoader color={isDarkMode ? "#FFFFFF" : "#6B006B"} />
              </div>
            ) : (
              <div className="mt-10">
                {modalInvestments.length > 0 ? (
                  <div>
                    {modalInvestments?.map((item, index) => (
                      <div
                        key={index}
                        className="border-[1.5px] cursor-pointer border-transactionCard dark:border-textcolor my-2 py-2 shadow-sm"
                        onClick={() => openShowInvestmentModal(item, item._id)}
                      >
                        <div className="flex items-center gap-[50px] px-[3%]">
                          <div className="w-[150px] h-[100px] relative">
                            <img
                              src={`${process.env.REACT_APP_FILES_URL}/${item.investment_details.previewImage}`}
                              className="w-full h-full bg-cover object-cover rounded-[10px]"
                              alt="investment-img"
                            />
                          </div>
                          <div>
                            <h1 className="font-PoppinsSemiBold uppercase text-[12px] pb-2">
                              {item.investment_details?.title}
                            </h1>
                            <h1 className="text-success font-PoppinsSemiBold text-[12px]">
                              {item.investment_details?.rate}%{" "}
                              <span className=" font-PoppinsRegular text-[10px] text-tabletext">
                                {`returns in ${item.investment_details?.durationNumber} ${item.investment_details?.durationInterval}`}
                              </span>
                            </h1>
                            <div className="flex items-center gap-9 py-2">
                              <div>
                                <h1 className="text-textcolor dark:text-white  font-PoppinsRegular text-[10px]">
                                  NUMBER OF INVESTORS:{" "}
                                  <span className="font-PoppinsSemiBold">
                                    {item.investment_details?.numberOfInvestors}
                                  </span>
                                </h1>
                              </div>
                            </div>
                            <div
                              className={`${
                                item.investmentStatus === "running"
                                  ? "text-referral bg-referral"
                                  : item.investmentStatus === "matured"
                                  ? "text-success bg-success"
                                  : item.investmentStatus === "pending"
                                  ? "text-secondary bg-secondary"
                                  : "text-error bg-error"
                              } bg-opacity-[28%] inline-block py-[0.7px] px-2 rounded-[10px] font-PoppinsMedium text-[8px]`}
                            >
                              {item.investmentStatus === "running"
                                ? "RUNNING"
                                : item.investmentStatus === "matured"
                                ? "MATURED"
                                : item.investmentStatus === "pending"
                                ? "PENDING"
                                : "STOPPED"}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="text-center text-tabletext text-[12px]">
                    No investments found.
                  </div>
                )}
              </div>
            )}
          </div>
        </Modal>
      )}

      {isModalOpen &&
        selectedInvestment &&
        selectedInvestmentIndex !== null && (
          <Modal
            isOpenModal={openShowInvestmentModal}
            closeModal={closeShowInvestmentModal}
          >
            <div
              className="flex items-center mt-[90px] justify-center"
              style={{
                backgroundImage: `url(${
                  selectedInvestment?.investment_details?.previewImage
                    ? `${process.env.REACT_APP_FILES_URL}/${selectedInvestment.investment_details.previewImage}`
                    : selectedInvestment.investment_details.previewImage === ""
                    ? Default
                    : Default
                })`,

                height: "250px",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            >
              <div className="bg-white opacity-50 inline-block p-4 rounded-[8px]">
                <img src={Logo} className="w-[30px]" alt="" />
              </div>
            </div>
            <div className="px-4">
              <div className="bg-success mt-6 mb-8 py-1 px-4 inline-block text-[12px] font-PoppinsLight rounded-[10px] bg-opacity-[28%] text-success">
                VERIFIED INVESTMENT
              </div>
              <div className="flex justify-between">
                <div>
                  <h1 className="font-PoppinsBold uppercase w-[80%] text-[20px]">
                    {selectedInvestment.investment_details.title}
                  </h1>
                </div>

                <div>
                  <h1 className="font-PoppinsBold uppercase text-center text-[20px] text-success">
                    {selectedInvestment.investment_details.numberOfInvestors}
                  </h1>

                  <h1 className="font-PoppinsMedium text-center uppercase text-tabletext text-[9px]">
                    {selectedInvestment.investment_details.numberOfInvestors ===
                    1
                      ? "Investor"
                      : "Investors"}
                  </h1>
                </div>
              </div>
              <div className="flex flex-col gap-4 mt-5">
                {selectedInvestment?.investmentStatus === "stopped" ? (
                  <button className="bg-error cursor-not-allowed border-[1.5px] border-error text-white font-PoppinsSemiBold w-full py-4 rounded-[8px]">
                    <div className="flex items-center gap-2">
                      <TiCancel />
                      STOPPED
                    </div>
                  </button>
                ) : selectedInvestment?.investmentStatus ===
                  "pending" ? null : selectedInvestment.investmentStatus ===
                  "running" ? (
                  <>
                    <div className="flex gap-3">
                      <button
                        onClick={handleViewInterests}
                        className="bg-referral flex items-center justify-center border-[1.5px] border-referral text-white font-PoppinsSemiBold w-full h-[55px] rounded-[8px]"
                      >
                        {isLoadingInterest ? (
                          <MoonLoader color="#ffffff" size={17} />
                        ) : (
                          <div className="flex items-center gap-2">
                            <IoEye />
                            VIEW INTERESTS
                          </div>
                        )}
                      </button>
                      <button
                        onClick={() =>
                          openTopupModal(
                            selectedInvestment,
                            selectedInvestmentIndex
                          )
                        }
                        className="bg-success flex items-center justify-center border-[1.5px] border-success text-white font-PoppinsSemiBold w-full h-[55px] rounded-[8px]"
                      >
                        <div className="flex items-center gap-2">
                          <RiFundsLine />
                          TOP UP
                        </div>
                      </button>
                    </div>
                  </>
                ) : selectedInvestment.investmentStatus === "broken" ? (
                  <button
                    onClick={() =>
                      openInvestNowModal(
                        selectedInvestment,
                        selectedInvestmentIndex
                      )
                    }
                    className="bg-referral border-[1.5px] border-referral text-white font-PoppinsSemiBold w-full py-4 rounded-[8px]"
                  >
                    RESUME INVESTMENT
                  </button>
                ) : selectedInvestment.investmentStatus === "completed" ? (
                  <button
                    onClick={() =>
                      openInvestNowModal(
                        selectedInvestment,
                        selectedInvestmentIndex
                      )
                    }
                    className="bg-referral border-[1.5px] border-referral text-white font-PoppinsSemiBold w-full py-4 rounded-[8px]"
                  >
                    RESUME INVESTMENT
                  </button>
                ) : selectedInvestment.isRoiSettled === false ? (
                  <div className="flex gap-3">
                    <GlowingButton
                      onClick={() => setShowTransactionPin(true)}
                      isLoading={false}
                      disabled={false}
                    >
                      <div className="flex items-center gap-2">
                        <FaHandHoldingUsd />
                        CLAIM
                      </div>
                    </GlowingButton>
                    <button
                      onClick={() =>
                        openRollOverModal(
                          selectedInvestment,
                          selectedInvestmentIndex
                        )
                      }
                      className="bg-primary flex items-center justify-center border-[1.5px] border-primary text-white font-PoppinsSemiBold w-full h-[55px] rounded-[8px]"
                    >
                      <div className="flex items-center gap-2">
                        <FiRefreshCcw />
                        ROLL OVER
                      </div>
                    </button>
                  </div>
                ) : (
                  <button className="dark:bg-[#C8A2C8] cursor-not-allowed border-[1.5px] dark:border-[#C8A2C8] dark:bg-opacity-10 dark:text-[#C8A2C8] bg-opacity-10 text-primary bg-primary border-primary font-PoppinsSemiBold w-full py-4 rounded-[8px]">
                    CLAIMED
                  </button>
                )}
                {selectedInvestment.investmentStatus === "pending" ? (
                  <button
                    onClick={() => setAboutModal(true)}
                    className="border-[1.5px] font-PoppinsSemiBold py-4 w-full rounded-[8px] border-investment text-investment"
                  >
                    <div className="flex justify-center items-center gap-2">
                      <CiCircleQuestion className="text-[20px]" />
                      ABOUT INVESTMENT
                    </div>
                  </button>
                ) : selectedInvestment.investmentStatus === "stopped" ? (
                  <button
                    onClick={() => setAboutModal(true)}
                    className="border-[1.5px] font-PoppinsSemiBold py-4 w-full rounded-[8px] border-error text-error"
                  >
                    <div className="flex justify-center items-center gap-2">
                      <CiCircleQuestion className="text-[20px]" />
                      ABOUT INVESTMENT
                    </div>
                  </button>
                ) : selectedInvestment.investmentStatus === "running" ? (
                  <div className="flex items-center gap-3">
                    <button
                      onClick={() => setAboutModal(true)}
                      className="border-[1.5px] font-PoppinsSemiBold py-4 w-full rounded-[8px] border-investment text-investment"
                    >
                      <div className="flex justify-center items-center gap-2">
                        <CiCircleQuestion className="text-[20px]" />
                        ABOUT INVESTMENT
                      </div>
                    </button>
                    <button
                      onClick={() => setBreakInvestmentModal(true)}
                      className="bg-error flex items-center justify-center border-[1.5px] border-error text-white font-PoppinsSemiBold w-full h-[55px] rounded-[8px]"
                    >
                      <div className="flex items-center gap-2">
                        <GiPadlockOpen />
                        BREAK INVESTMENT
                      </div>
                    </button>
                  </div>
                ) : (
                  <button
                    onClick={() => setAboutModal(true)}
                    className="border-[1.5px] font-PoppinsSemiBold py-4 w-full rounded-[8px] border-investment text-investment"
                  >
                    <div className="flex justify-center items-center gap-2">
                      <CiCircleQuestion className="text-[20px]" />
                      ABOUT INVESTMENT
                    </div>
                  </button>
                )}
              </div>

              <div
                className={`text-[14px] font-PoppinsRegular text-center mt-3 ${
                  selectedInvestment.investmentStatus === "stopped"
                    ? "text-error"
                    : selectedInvestment.investmentStatus === "pending"
                    ? "text-success"
                    : selectedInvestment.investmentStatus === "running"
                    ? "text-referral"
                    : "text-success"
                }`}
              >
                {selectedInvestment.investmentStatus === "stopped"
                  ? "This investment is currently not available. Please look for other investment plans."
                  : selectedInvestment.investmentStatus === "pending"
                  ? "This investment plan is not running yet."
                  : selectedInvestment.investmentStatus === "running"
                  ? "This investment plan is already running for all Investors."
                  : null}
              </div>

              <hr className="text-authbordercolor my-10" />
              <div>
                <div className="flex justify-center gap-7 mb-5">
                  <div className="w-full border-[1.5px] rounded-[8px] p-3 border-transactionCard">
                    <h1 className="text-[13px] font-PoppinsMedium text-disabledtext">
                      Investment Type
                    </h1>
                    <h1 className="text-[13px] font-PoppinsMedium">
                      {selectedInvestment.investment_details.category}
                    </h1>
                  </div>
                  <div className="w-full border-[1.5px] rounded-[8px] p-3 border-transactionCard">
                    <h1 className="text-[13px] font-PoppinsMedium text-disabledtext">
                      Start Date
                    </h1>
                    <h1 className="text-[13px] font-PoppinsMedium">
                      {formatDate(selectedInvestment.startDate)}
                    </h1>
                  </div>
                </div>
                <div className="flex justify-center gap-7">
                  <div className="w-full border-[1.5px] rounded-[8px] p-3 border-transactionCard">
                    <h1 className="text-[13px] font-PoppinsMedium text-disabledtext">
                      Maturity Date
                    </h1>
                    <h1 className="text-[13px] font-PoppinsMedium">
                      {formatDate(selectedInvestment.maturityDate)}
                    </h1>
                  </div>
                  <div className="w-full border-[1.5px] rounded-[8px] p-3 border-transactionCard">
                    <h1 className="text-[13px] font-PoppinsMedium text-disabledtext">
                      Payout Type
                    </h1>
                    <h1 className="text-[13px] font-PoppinsMedium">
                      Capital + profit to be paid
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        )}

      {viewInterestsModal && (
        <Modal
          isOpenModal={() => setviewInterestsModal(true)}
          closeModal={() => setviewInterestsModal(false)}
        >
          <div className="mt-[120px] px-4">
            <h1 className="text-primary dark:text-white text-[25px] font-PoppinsSemiBold">
              Running Investment Interests Breakdown
            </h1>
            <div className="mt-6 h-full dark:text-white text-textcolor">
              <div>
                {viewInterestsData?.length === 0 ? (
                  <div className="md:mt-[90px] mt-[70px] flex items-center justify-center">
                    <div className="text-primary bg-primary bg-opacity-20 p-3 rounded-[10px] dark:bg-white dark:bg-opacity-20 dark:text-white font-PoppinsBold text-[14px] text-center">
                      You have no investment interests currently!
                    </div>
                  </div>
                ) : (
                  <div>
                    {viewInterestsData?.map((item) => (
                      <div className="flex items-center dark:bg-textcolor bg-transactionCard rounded-[8px] shadow-sm mb-4 justify-between p-3">
                        <div>
                          <h1 className="font-PoppinsRegular text-[11px]">
                            Date
                          </h1>
                          <h1 className="font-PoppinsSemiBold text-[14px]">
                            {formatDate(item.interestDate)}
                          </h1>
                        </div>
                        <div>
                          <h1 className="font-PoppinsRegular text-[11px]">
                            Interest
                          </h1>
                          <h1 className="font-PoppinsSemiBold text-success text-[14px]">
                            {formatCurrency(item.roi)}
                          </h1>
                        </div>
                        <div>
                          <h1 className="font-PoppinsRegular text-[11px]">
                            Balance
                          </h1>
                          <h1 className="font-PoppinsSemiBold text-[14px]">
                            {formatCurrency(item.amount)}
                          </h1>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal>
      )}

      {rollOverModal &&
        selectedInvestment &&
        selectedInvestmentIndex !== null && (
          <Modal
            isOpenModal={openRollOverModal}
            closeModal={closeRollOverModal}
          >
            <div className="mt-[120px] px-4">
              <h1 className="text-primary dark:text-white text-[25px] font-PoppinsSemiBold">
                Roll Over Investment
              </h1>
              <div className="bg-primary rounded-[8px] mt-4 mb-8 p-4 text-white">
                <h1 className="text-[18px] font-PoppinsMedium">Information</h1>
                <h1 className="font-PoppinsRegular text-[14px]">
                  Fill the form below to rollover investment
                </h1>
              </div>

              <Formik
                initialValues={{
                  fundingSource: "",
                  answer: "",
                }}
                onSubmit={handleRollOver}
                validationSchema={rollOverSchema}
              >
                {({ values, isValid, dirty }) => (
                  <Form>
                    <SelectInputWithLabelAndPlaceholder
                      name="fundingSource"
                      options={rollOverFunding}
                      placeholder="Select a funding source"
                      label="Choose a Source of Funds"
                    />
                    {values.fundingSource && (
                      <PasswordInput
                        name="answer"
                        type="password"
                        updatePass="sm:top-[35px] top-3 right-3.5"
                        label="Answer for the security question set by you"
                      />
                    )}

                    <div>
                      <InvestmentBtn
                        disabled={!isValid || !dirty}
                        isLoading={rollOverLoading}
                      >
                        ROLL OVER
                      </InvestmentBtn>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Modal>
        )}

      {investNowModal &&
        selectedInvestment &&
        selectedInvestmentIndex !== null && (
          <Modal
            isOpenModal={openInvestNowModal}
            closeModal={closeInvestNowModal}
          >
            <div className="mt-[120px] px-4">
              <h1 className="text-primary dark:text-white text-[25px] font-PoppinsSemiBold">
                Invest Now
              </h1>
              <div className="bg-primary rounded-[8px] mt-4 p-4 text-white">
                <h1 className="text-[18px] font-PoppinsMedium">Information</h1>
                <h1 className="font-PoppinsRegular text-[14px]">
                  Fill the form below to start investing
                </h1>
              </div>
              <h1 className="mt-[60px] mb-[20px] font-PoppinsMedium text-[14px] text-textcolor dark:text-white">
                {`Number of units to purchase ${formatCurrency(
                  selectedInvestment.investment_details.amount
                )}
                    per unit in `}
                <span className="uppercase">
                  {selectedInvestment.investment_details.title}
                </span>
              </h1>
              <Formik
                initialValues={{
                  units: "",
                  fundingSource: "",
                  answer: "",
                }}
                onSubmit={handleBookInvestment}
                validationSchema={bookInvestmentSchema}
              >
                {({ values, isValid, dirty }) => (
                  <Form>
                    <TextInputWithLabel
                      label="Amount(in multiple of ₦10,000)"
                      name="amount"
                      type="number"
                    />
                    <SelectInputWithLabelAndPlaceholder
                      name="fundingSource"
                      options={wallet}
                      placeholder="Select a funding source"
                      label="Choose a Source of Funds"
                    />
                    {values.units && values.fundingSource && (
                      <PasswordInput
                        name="answer"
                        type="password"
                        label="Answer for the security question set by you"
                      />
                    )}
                    <ConfigProvider
                      theme={{
                        token: {
                          colorPrimary: "#6B006B",
                        },
                      }}
                    >
                      {values.answer !== "" && (
                        <div>
                          <div className="flex items-center space-x-4 justify-between mb-4">
                            <Switch
                              checked={switch1}
                              onChange={() => setSwitch1((prev) => !prev)}
                            />
                            <span className="text-primary text-[13px] font-PoppinsMedium dark:text-white">
                              {`I confirm my purchase of ${values.units} ${
                                values.units === "1" ? "unit" : "units"
                              } of this investment for ${formatCurrency(
                                Number(values.units) *
                                  selectedInvestment.investment_details.amount
                              )}. I agree to retain my investment until ${formatDate(
                                selectedInvestment.investment_details.endDate
                              )} before cashing out.`}
                            </span>
                          </div>
                          <div className="flex items-center justify-between space-x-4 mb-4">
                            <Switch
                              checked={switch2}
                              onChange={() => setSwitch2((prev) => !prev)}
                            />
                            <span className="text-primary text-[13px] font-PoppinsMedium dark:text-white">
                              I acknowledge that I have read and comprehended
                              the investment information. I recognize that
                              investing in third-party instruments carries
                              risks, which may, in rare instances, involve loss
                              of capital and lack of liquidity.
                            </span>
                          </div>
                        </div>
                      )}
                    </ConfigProvider>
                    <div>
                      <InvestmentBtn
                        disabled={!isValid || !dirty || !switch1 || !switch2}
                        isLoading={bookingLoading}
                      >
                        INVEST NOW
                      </InvestmentBtn>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Modal>
        )}

      {topupModal && selectedInvestment && selectedInvestmentIndex !== null && (
        <Modal isOpenModal={openTopupModal} closeModal={closeTopupModal}>
          <div className="mt-[120px] px-4">
            <h1 className="text-primary dark:text-white text-[25px] font-PoppinsSemiBold">
              Top Up Investment
            </h1>
            <div className="bg-primary rounded-[8px] mt-4 p-4 text-white">
              <h1 className="text-[18px] font-PoppinsMedium">Information</h1>
              <h1 className="font-PoppinsRegular text-[14px]">
                Fill the form below to top up investment
              </h1>
            </div>
            <h1 className="mt-[60px] mb-[20px] font-PoppinsMedium text-[14px] text-textcolor dark:text-white">
              {`Top up your investment in `}
              <span className="uppercase">
                {selectedInvestment.investment_details.title}
              </span>
            </h1>
            <Formik
              initialValues={{
                amount: "",
                fundingSource: "",
                answer: "",
              }}
              onSubmit={handleTopUp}
              validationSchema={topUpSchema}
            >
              {({ values, isValid, dirty }) => (
                <Form>
                  <TextInputWithLabel
                    label="Amount(in multiple of ₦10,000)"
                    name="amount"
                    type="number"
                  />
                  <SelectInputWithLabelAndPlaceholder
                    name="fundingSource"
                    options={wallet}
                    placeholder="Select a funding source"
                    label="Choose a Source of Funds"
                  />
                  {values.amount && values.fundingSource && (
                    <PasswordInput
                      name="answer"
                      type="password"
                      label="Answer for the security question set by you"
                    />
                  )}
                  <ConfigProvider
                    theme={{
                      token: {
                        colorPrimary: "#6B006B",
                      },
                    }}
                  >
                    {values.answer !== "" && (
                      <div>
                        <div className="flex items-center space-x-4 mb-4">
                          <Switch
                            checked={switch1}
                            onChange={() => setSwitch1((prev) => !prev)}
                          />
                          <span className="text-primary text-[13px] font-PoppinsMedium dark:text-white">
                            {`I confirm my top up of this investment for ${formatCurrency(
                              Number(values.amount)
                            )}.`}
                          </span>
                        </div>
                        <div className="flex items-center justify-between space-x-4 mb-4">
                          <Switch
                            checked={switch2}
                            onChange={() => setSwitch2((prev) => !prev)}
                          />
                          <span className="text-primary text-[13px] font-PoppinsMedium dark:text-white">
                            I acknowledge that I have read and comprehended the
                            investment information. I recognize that investing
                            in third-party instruments carries risks, which may,
                            in rare instances, involve loss of capital and lack
                            of liquidity.
                          </span>
                        </div>
                      </div>
                    )}
                  </ConfigProvider>
                  <div>
                    <InvestmentBtn
                      disabled={!isValid || !dirty || !switch1 || !switch2}
                      isLoading={bookingLoading}
                    >
                      TOP UP
                    </InvestmentBtn>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal>
      )}
      {showEnterTransactionPin && (
        <Modal
          isOpenModal={() => setShowTransactionPin(true)}
          closeModal={() => setShowTransactionPin(false)}
        >
          <div className="px-4 pt-[120px]">
            <div className="pb-[60px]">
              <h1 className="text-[20px] font-PoppinsBold">Transaction Pin</h1>
              <h1 className="font-PoppinsLight pt-3 text-[14px]">
                Enter Transaction pin to claim profit from investment
              </h1>
            </div>
            <div>
              <Formik
                initialValues={{
                  pin: "",
                }}
                validationSchema={setPinSchema}
                onSubmit={handleClaimProfit}
              >
                {({ isValid, dirty }) => (
                  <Form>
                    <PasswordInput
                      name="pin"
                      type="password"
                      placeholder="4-digit pin"
                      label="Enter Transaction Pin"
                      updatePass="top-[35px] right-[13px]"
                    />
                    <AuthButton isLoading={pin} disabled={!isValid || !dirty}>
                      CLAIM
                    </AuthButton>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Modal>
      )}
      {breakInvestmentModal && (
        <Modal
          isOpenModal={() => setBreakInvestmentModal(true)}
          closeModal={() => setBreakInvestmentModal(false)}
        >
          <div className="px-4 pt-[120px]">
            <div className="pb-[60px]">
              <h1 className="text-[20px] font-PoppinsBold">Transaction Pin</h1>
              <h1 className="font-PoppinsLight pt-3 text-[14px]">
                Enter Transaction pin to Break Investment
              </h1>
              <div className="mt-4 p-2 rounded-[8px] text-white bg-error">
                <h1 className="font-PoppinsMedium text-[14px] mb-1 flex items-center gap-2">
                  Warning <TiWarningOutline />
                </h1>
                <p className="font-PoppinsLight text-[13px]">
                  A fee of 3.5% fee will be charged on your accurred interests
                  and your capital with the balance on your accurred interests
                  will be paid.
                </p>
              </div>
            </div>
            <div>
              <Formik
                initialValues={{
                  pin: "",
                }}
                validationSchema={breakInvestmentSchema}
                onSubmit={handleBreakInvestment}
              >
                {({ isValid, dirty }) => (
                  <Form>
                    <PasswordInput
                      name="pin"
                      type="password"
                      placeholder="4-digit pin"
                      label="Enter Transaction Pin"
                      updatePass="top-[35px] right-[13px]"
                    />
                    <AuthButton isLoading={pin} disabled={!isValid || !dirty}>
                      Proceed
                    </AuthButton>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Modal>
      )}
      {aboutModal && selectedInvestment && (
        <Modal
          isOpenModal={() => setAboutModal(true)}
          closeModal={() => setAboutModal(false)}
        >
          <div className="px-4 pt-[120px]">
            <div>
              <h1 className="text-primary dark:text-white text-[25px] font-PoppinsSemiBold">
                About Investment
              </h1>
              <div className="py-2">
                <p className="text-[13px] font-PoppinsRegular text-disabledtext dark:text-disabled">
                  Read about this investment below
                </p>
              </div>
            </div>
            <div>
              <div
                className="flex items-center my-7 justify-center"
                style={{
                  backgroundImage: `url(${
                    selectedInvestment?.investment_details?.previewImage
                      ? `${process.env.REACT_APP_FILES_URL}/${selectedInvestment.investment_details.previewImage}`
                      : selectedInvestment.investment_details.previewImage ===
                        ""
                      ? Default
                      : Default
                  })`,

                  height: "250px",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <div className="bg-white opacity-50 inline-block p-4 rounded-[8px]">
                  <img src={Logo} className="w-[30px]" alt="" />
                </div>
              </div>
              <p className="dark:text-white text-textcolor text-[13px]">
                {selectedInvestment.investment_details.description}
              </p>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default MyInvestmentMobile;
