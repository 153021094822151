import { useEffect, useState } from "react";
import Sidebar from "../sidebar/Sidebar";
import PageTitle from "../../components/Pagetitle";
import { PiHandDepositBold, PiHandWithdrawLight } from "react-icons/pi";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { AiOutlineBank } from "react-icons/ai";
import { LiaPercentageSolid } from "react-icons/lia";
import { GiTakeMyMoney } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import NoData from "../../components/NoDataIllustration";
import NoTransaction from "../../assets/img/Wavy_Tech-31_Single-03.jpg";
import {
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { HttpClient } from "../../api/HttpClient";
import ErrorHandler from "../ErrorHandler";
import { ScaleLoader } from "react-spinners";
import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  DoughnutController,
  ChartOptions,
  ChartData as ChartJsData,
} from "chart.js";
import { formatCurrency } from "../../components/CurrencyFormat";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { AllUserTransact } from "./customers/OneUserTransact";
import Slider from "react-slick";

ChartJS.register(ArcElement, DoughnutController);

type UserInfoType = {
  _id?: string;
  allUsers: number;
  activeUsers: number;
  inactiveUsers: number;
  kycVerifiedUsers: number;
  kycUnverifiedUsers: number;
  userTotalBalance: number;
  userTotalDeposits: number;
  userTotalWithdrawals: number;
  userTotalProfits: number;
  userTotalReferrals: number;
  userTotalProjectInvestments: number;
  userTotalRegularInvestments: number;
  userTotalFlexibleInvestments: number;
  userTotalKidsInvestments: number;
  userTotalSavings: number;
};
interface DashboardStatusCharts {
  name?: string;
  value?: number;
  fill?: string;
  labels?: string[];
  datasets?: {
    data?: number[];
    backgroundColor?: string[];
    borderColor?: string[];
    borderWidth?: number;
  }[];
}

interface ChartData {
  labels: string[];
  datasets: Array<{
    label: string;
    data: number[];
    backgroundColor: string[];
    borderColor: string[];
    borderWidth: number;
  }>;
}

interface TransactionLogData {
  _id?: string;
  key?: string;
  amount: number;
  transactionRefCode: string;
  transactionDate: string;
  responseMsg: string;
  transactionType: string;
  isInFlow?: boolean;
  user_details: {
    firstName: string;
    lastName: string;
    email: string;
  };
}

function Home() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [usersInfo, setUsersInfo] = useState<UserInfoType | null>(null);
  const [totalDeposit, setTotalDeposit] = useState(null);
  const [totalWithdrawal, setTotalWithdrawal] = useState(null);
  const [paymentGatewayChart, setPaymentGatewayChart] = useState<
    DashboardStatusCharts[]
  >([]);
  const [investChart, setInvestChart] = useState<DashboardStatusCharts[]>([]);
  const [depositChart, setDepositChart] = useState<DashboardStatusCharts[]>([]);
  const [recentAct, setRecentAct] = useState<TransactionLogData[]>([]);
  const [paymentMthdChart, setPaymentMthdChart] = useState<ChartData>({
    labels: [],
    datasets: [
      {
        label: "",
        data: [],
        backgroundColor: ["#009306", "#6B006B", "#0357D1", "#C59832"],
        borderColor: ["#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
        borderWidth: 2,
      },
    ],
  });
  const [savingsChart, setSavingsChart] = useState<ChartData>({
    labels: [],
    datasets: [
      {
        label: "amount",
        data: [],
        backgroundColor: ["#009306", "#E80404"],
        borderColor: ["#FFFFFF", "#FFFFFF"],
        borderWidth: 2,
      },
    ],
  });
  const [withdrawalChart, setWithdrawalChart] = useState<ChartData>({
    labels: [],
    datasets: [
      {
        label: "amount",
        data: [],
        backgroundColor: ["#009306", "#E80404"],
        borderColor: ["#FFFFFF", "#FFFFFF"],
        borderWidth: 2,
      },
    ],
  });

  const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 965,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: true,
        },
      },
    ],
  };

  const doughnutOptions: ChartOptions<"doughnut"> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        labels: {
          color: isDarkMode ? "#FFFFFF" : "#3D3C3A",
          font: {
            size: 14,
          },
          boxWidth: 10,
          padding: 10,
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const isCurrency = context.dataset.label === "amount";
            const value = context.raw as number;
            const total = context.dataset.data.reduce((sum, val) => sum + val, 0);
            const percentage = ((value / total) * 100).toFixed(2);
  
            return isCurrency
              ? `₦${value} (${percentage}%)`
              : `${value} (${percentage}%)`;
          },
        },
      },
    },
    cutout: "80%",
  };

  const centerTextPlugin = (isDarkMode: boolean) => ({
    id: "centerTextPlugin",
    beforeDraw: (chart: any) => {
      const { ctx, chartArea, data } = chart;
      const { top, bottom, left, right } = chartArea;

      ctx.save();
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";

      const total = data.datasets[0].data.reduce(
        (acc: number, value: number) => acc + value,
        0
      );

      const isCurrency = data.datasets[0].label === "amount";
      const totalText = isCurrency ? `₦${total.toLocaleString()}` : `${total}`;

      const centerX = (left + right) / 2;
      const centerY = (top + bottom) / 2;

      ctx.font = "14px PoppinsMedium";
      ctx.fillStyle = isDarkMode ? "#FFFFFF" : "#3D3C3A";

      ctx.fillText("Total:", centerX, centerY - 10);

      ctx.fillText(totalText, centerX, centerY + 10);

      ctx.restore();
    },
  });

  const fetchAllUserWallets = async (
    search: string = "",
    isInFlow?: boolean,
    status?: string
  ) => {
    try {
      const walletRes = await HttpClient.get("/transactions/statistics", {
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          search,
          isInFlow,
          status,
        },
      });

      const walletsData = walletRes.data?.data?.total_value;

      if (isInFlow === true || isInFlow === false) {
        return walletsData;
      } else {
        return {
          searchKey: search,
          data: walletsData,
        };
      }
    } catch (error: any) {
      ErrorHandler(error);
    }
  };

  const fetchAllUserDetails = async () => {
    try {
      const allUsersInfoRes = await HttpClient.get("/admin/users-statistics", {
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      });

      return allUsersInfoRes.data?.data;
    } catch (error: any) {
      ErrorHandler(error);
    }
  };

  const fetchInvestData = async () => {
    const investRes = await HttpClient.get("/investments/status-statistics", {
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      params: {
        search: "",
        startdate: "",
        enddate: "",
      },
    });
    return investRes.data?.data;
  };

  const fetchTransactionHistory = async (
    search?: string,
    isInFlow?: boolean,
    status?: string
  ) => {
    const transRes = await HttpClient.get(`/transactions/history`, {
      headers: { "x-auth-token": localStorage.getItem("token") },
      params: {
        search: search || "",
        dir: "desc",
        isInflow: isInFlow,
        status: status,
      },
    });
    return transRes.data?.data?.data;
  };

  const formatInvestData = (investData: any) => [
    {
      name: "All",
      value: investData.all_investment_data,
      fill: "#0357D1",
    },
    {
      name: "Pending",
      value: investData.pending_investment_data,
      fill: "#C59832",
    },
    {
      name: "Running",
      value: investData.running_investment_data,
      fill: "#009306",
    },
    {
      name: "Matured",
      value: investData.matured_investment_data,
      fill: "#6B006B",
    },
    {
      name: "Sold Out",
      value: investData.sold_out_investment_data,
      fill: "#E80404",
    },
  ];

  const formatGatewayData = (gatewayData: any) => {
    const gatewayCounts = gatewayData.reduce((acc: any, transaction: any) => {
      const gateway = transaction.paymentGateway;
      acc[gateway] = (acc[gateway] || 0) + 1;
      return acc;
    }, {});
    return [
      {
        name: "Flutterwave",
        value: gatewayCounts.flutterwave,
        fill: "#EB5634",
      },
      { name: "Paystack", value: gatewayCounts.paystack, fill: "#3FA6DB" },
      { name: "Providus", value: gatewayCounts.providus, fill: "#f7b944" },
    ];
  };

  const formatDepositData = (depositData: any) => {
    const depositCounts = depositData.reduce((acc: any, transaction: any) => {
      const date = new Date(transaction.transactionDate);
      const formattedMonthYear = date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
      });
      const formattedDate = formattedMonthYear.replace(/(\w+)( \d+)/, "$1,$2");

      acc[formattedDate] = (acc[formattedDate] || 0) + transaction.amount;
      return acc;
    }, {});

    return Object.keys(depositCounts)
      .map((date) => ({
        name: date,
        value: depositCounts[date],
        fill: "#A03DF0",
      }))
      .sort((a, b) => new Date(a.name).getTime() - new Date(b.name).getTime());
  };

  const formatSavingsData = (savingsData: any) => {
    const savingsCounts = savingsData.reduce(
      (acc: any, transaction: any) => {
        const status = transaction.paymentStatus;

        if (status === "successful") {
          acc.successful += transaction.amount;
        } else if (status === "failed") {
          acc.failed += transaction.amount;
        }

        return acc;
      },
      { successful: 0, failed: 0 }
    );

    return {
      labels: [
        `${formatCurrency(savingsCounts.successful)} Successful`,
        `${formatCurrency(savingsCounts.failed)} Failed`,
      ],
      datasets: [
        {
          label: "amount",
          data: [savingsCounts.successful, savingsCounts.failed],
          backgroundColor: ["#009306", "#E80404"],
          borderColor: ["#FFFFFF", "#FFFFFF"],
          borderWidth: 2,
        },
      ],
    };
  };

  const formatWithdrawalData = (withdrawalData: any) => {
    const withdrawalCounts = withdrawalData.reduce(
      (acc: any, transaction: any) => {
        const status = transaction.paymentStatus;

        if (status === "successful") {
          acc.successful += transaction.amount;
        } else if (status === "failed") {
          acc.failed += transaction.amount;
        }

        return acc;
      },
      { successful: 0, failed: 0 }
    );

    return {
      labels: [
        `${formatCurrency(withdrawalCounts.successful)} Successful`,
        `${formatCurrency(withdrawalCounts.failed)} Failed`,
      ],
      datasets: [
        {
          label: "amount",
          data: [withdrawalCounts.successful, withdrawalCounts.failed],
          backgroundColor: ["#009306", "#E80404"],
          borderColor: ["#FFFFFF", "#FFFFFF"],
          borderWidth: 2,
        },
      ],
    };
  };

  const formatRecentActData = (recentActData: any) => {
    return recentActData.map((transaction: any) => {
      const date = new Date(transaction.transactionDate);
      const formattedDate = date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });

      return {
        key: transaction._id,
        amount: transaction?.amount,
        transactionRefCode: transaction?.transactionRefCode,
        transactionDate: formattedDate,
        responseMsg: transaction?.responseMsg,
        transactionType: transaction?.transactionType,
        isInFlow: transaction?.isInFlow,
        name:
          transaction.user_details?.firstName +
          " " +
          transaction.user_details?.lastName,
        user_details: transaction?.user_details,
      };
    });
  };

  useEffect(() => {
    const fetchAllData = async () => {
      setLoading(true);
      try {
        const investData = fetchInvestData();
        const depositsData = fetchTransactionHistory("wallet", true, "success");
        // const savingsData = fetchTransactionHistory("savings");
        const withdrawalData = fetchTransactionHistory("wallet", false);
        const methodData = fetchTransactionHistory();
        const gatewayData = fetchTransactionHistory();
        const recentActData = fetchTransactionHistory();
        const userDetails = fetchAllUserDetails();
        const depositTotal = fetchAllUserWallets("wallet", true, "success");
        const withdrawalTotal = fetchAllUserWallets("withdrawal", false, "success");
  
        const results = await Promise.allSettled([
          investData, depositsData, withdrawalData,
          methodData, gatewayData, recentActData,
          userDetails, depositTotal, withdrawalTotal
        ]);
  
        const [
          investDataResult, depositsDataResult, withdrawalDataResult,
          methodDataResult, gatewayDataResult, recentActDataResult,
          userDetailsResult, depositTotalResult, withdrawalTotalResult
        ] = results.map((res) => res.status === "fulfilled" ? res.value : null);
  
        if (investDataResult) setInvestChart(formatInvestData(investDataResult));
        if (depositsDataResult) setDepositChart(formatDepositData(depositsDataResult));
        // if (savingsDataResult) setSavingsChart(formatSavingsData(savingsDataResult));
        if (withdrawalDataResult) setWithdrawalChart(formatWithdrawalData(withdrawalDataResult));
        if (gatewayDataResult) setPaymentGatewayChart(formatGatewayData(gatewayDataResult));
        if (userDetailsResult) setUsersInfo(userDetailsResult);
        if (depositTotalResult) setTotalDeposit(depositTotalResult);
        if (withdrawalTotalResult) setTotalWithdrawal(withdrawalTotalResult);
        if (methodDataResult) {
          const paymentMethodCounts = methodDataResult.reduce((acc: any, transaction: any) => {
            const method = transaction.paymentMethod;
            acc[method] = (acc[method] || 0) + 1;
            return acc;
          }, {});
          
          setPaymentMthdChart({
            labels: Object.keys(paymentMethodCounts),
            datasets: [{
              label: '',
              data: Object.values(paymentMethodCounts),
              backgroundColor: ["#009306", "#6B006B", "#0357D1", "#C59832", "#A03DF0"],
              borderColor: ["#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
              borderWidth: 1,
            }]
          });
        }
        if (recentActDataResult) {
          setRecentAct(formatRecentActData(recentActDataResult.sort((a: any, b: any) => new Date(b.transactionDate).getTime() - new Date(a.transactionDate).getTime()).slice(0, 3)));
        }
  
      } catch (error: any) {
        ErrorHandler(error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchAllData();
  }, []);
  
  
  const handleViewAllDeposits = () => {
    navigate("/admin/deposits");
  };

  const handleViewAllSavings = () => {
    navigate("/admin/savings");
  };

  const handleViewAllPayouts = () => {
    navigate("/admin/withdrawal/withdrawal_log");
  };

  const handleViewAllPaymentGateways = () => {
    navigate("/admin/transactions/payment_gateway");
  };

  const handleViewTransactions = () => {
    navigate("/admin/transactions/transaction_log");
  };

  const totalInvestment =
    (usersInfo?.userTotalFlexibleInvestments || 0) +
    (usersInfo?.userTotalKidsInvestments || 0) +
    (usersInfo?.userTotalProjectInvestments || 0) +
    (usersInfo?.userTotalRegularInvestments || 0);

  return (
    <>
      <PageTitle title="Admin - Home" />
      <div className="flex h-screen">
        <Sidebar />
        <div className="flex-1 overflow-x-hidden overflow-y-auto dark:bg-darkPrimary">
          {loading ? (
            <div className="w-full h-screen bg-white dark:bg-darkPrimary flex items-center justify-center">
              <ScaleLoader color={isDarkMode ? "#FFFFFF" : "#6B006B"} />
            </div>
          ) : (
            <div>
              <div className="flex-1 mx-4 my-4 mt-28 bg-white dark:bg-darkPrimary">
                {/* USERS ACTIVENESS */}
                <div className="slider-container">
                  <Slider {...settings}>
                    <div className="border-investment border text-investment p-5 h-[100px] block rounded-[8px]">
                      <div className="flex items-center gap-2">
                        <div>
                          <h1 className="text-[12px] font-PoppinsMedium">
                            All Customers
                          </h1>
                          <h1 className="text-[25px] font-PoppinsSemiBold">
                            {usersInfo?.allUsers || 0}
                          </h1>
                        </div>
                      </div>
                    </div>

                    <div className="border-referral border text-referral p-5 h-[100px] block rounded-[8px]">
                      <div className="flex items-center gap-2">
                        <div>
                          <h1 className="text-[12px] font-PoppinsMedium">
                            Active Customers
                          </h1>
                          <h1 className="text-[25px] font-PoppinsSemiBold">
                            {usersInfo?.activeUsers || 0}
                          </h1>
                        </div>
                      </div>
                    </div>

                    <div className="border-error border text-error p-5 h-[100px] block rounded-[8px]">
                      <div className="flex items-center gap-2">
                        <div>
                          <h1 className="text-[12px] font-PoppinsMedium">
                            Inactive Customers
                          </h1>
                          <h1 className="text-[25px] font-PoppinsSemiBold">
                            {usersInfo?.inactiveUsers || 0}
                          </h1>
                        </div>
                      </div>
                    </div>

                    <div className="border-primary border text-primary p-5 h-[100px] block rounded-[8px] dark:border-white dark:text-white">
                      <div className="flex items-center gap-2">
                        <div>
                          <h1 className="text-[12px] font-PoppinsMedium">
                            KYC Verified Customers
                          </h1>
                          <h1 className="text-[25px] font-PoppinsSemiBold">
                            {usersInfo?.kycVerifiedUsers || 0}
                          </h1>
                        </div>
                      </div>
                    </div>

                    <div className="border-error border text-error p-5 h-[100px] block rounded-[8px]">
                      <div className="flex items-center gap-2">
                        <div>
                          <h1 className="text-[12px] font-PoppinsMedium">
                            KYC Unverified Customers
                          </h1>
                          <h1 className="text-[25px] font-PoppinsSemiBold">
                            {usersInfo?.kycUnverifiedUsers || 0}
                          </h1>
                        </div>
                      </div>
                    </div>
                  </Slider>
                </div>

                {/* USERS BALANCES */}
                <div className="mt-8">
                  <div>
                    <div className="grid lg:grid-cols-3 gap-3 lg:gap-6 overflow-x-auto whitespace-nowrap md:grid-cols-2 grid-cols-1 sm:overflow-visible mb-4">
                      <div className="bg-primary bg-opacity-5 p-5 rounded-[8px] border border-primary">
                        <div className="bg-primary text-white p-5 h-[120px] block rounded-[8px]">
                          <div className="flex items-center gap-2">
                            <MdOutlineAccountBalanceWallet size={25} />
                            <div>
                              <h1 className="text-[12px] font-PoppinsMedium">
                                Total Balance
                              </h1>
                              <h1 className="text-[25px] font-PoppinsSemiBold">
                                {formatCurrency(
                                  usersInfo?.userTotalBalance || 0
                                )}
                              </h1>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="bg-investment bg-opacity-5 p-5 rounded-[8px] border border-investment">
                        <div className="bg-investment text-white p-5 h-[120px] block rounded-[8px]">
                          <div className="flex items-center gap-2">
                            <AiOutlineBank size={25} />
                            <div>
                              <h1 className="text-[12px] font-PoppinsMedium">
                                Total Investment
                              </h1>
                              <h1 className="text-[25px] font-PoppinsSemiBold">
                                {formatCurrency(totalInvestment || 0)}
                              </h1>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="bg-secondary bg-opacity-5 p-5 rounded-[8px] border border-secondary">
                        <div className="bg-secondary text-white p-5 h-[120px] block rounded-[8px]">
                          <div className="flex items-center gap-2">
                            <LiaPercentageSolid size={25} />
                            <div>
                              <h1 className="text-[12px] font-PoppinsMedium">
                                Total Profit
                              </h1>
                              <h1 className="text-[25px] font-PoppinsSemiBold">
                                {formatCurrency(
                                  usersInfo?.userTotalProfits || 0
                                )}
                              </h1>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="bg-deposit bg-opacity-5 p-5 rounded-[8px] border border-deposit">
                        <div className="bg-deposit text-white p-5 h-[120px] block rounded-[8px]">
                          <div className="flex items-center gap-2">
                            <PiHandDepositBold size={25} />
                            <div>
                              <h1 className="text-[12px] font-PoppinsMedium">
                                Total Deposit
                              </h1>
                              <h1 className="text-[25px] font-PoppinsSemiBold">
                                {formatCurrency(totalDeposit || 0)}
                              </h1>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="bg-primary bg-opacity-5 p-5 rounded-[8px] border border-primary">
                        <div className="bg-primary text-white p-5 h-[120px] block rounded-[8px]">
                          <div className="flex items-center gap-2">
                            <PiHandWithdrawLight size={25} />
                            <div>
                              <h1 className="text-[12px] font-PoppinsMedium">
                                Total Withdrawal
                              </h1>
                              <h1 className="text-[25px] font-PoppinsSemiBold">
                                {formatCurrency(totalWithdrawal || 0)}
                              </h1>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="bg-referral bg-opacity-5 p-5 rounded-[8px] border border-referral">
                        <div className="bg-referral text-white p-5 h-[120px] block rounded-[8px]">
                          <div className="flex items-center gap-2">
                            <GiTakeMyMoney size={25} />
                            <div>
                              <h1 className="text-[12px] font-PoppinsMedium">
                                Total Referral
                              </h1>
                              <h1 className="text-[25px] font-PoppinsSemiBold">
                                {formatCurrency(
                                  usersInfo?.userTotalReferrals || 0
                                )}
                              </h1>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="grid lg:grid-cols-3 gap-3 lg:gap-6 overflow-x-auto whitespace-nowrap md:grid-cols-2 grid-cols-1 sm:overflow-visible mb-4">
                  <div className="bg-primary bg-opacity-5 p-5 rounded-[8px] border border-primary">
                    <div className="bg-primary text-white p-5 h-[120px] block rounded-[8px]">
                      <div className="flex items-center gap-2">
                        <MdOutlineAccountBalanceWallet size={25} />
                        <div>
                          <h1 className="text-[12px] font-PoppinsMedium">
                            Total Balance
                          </h1>
                          <h1 className="text-[25px] font-PoppinsSemiBold">
                            {formatCurrency(usersInfo?.userTotalBalance || 0)}
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-investment bg-opacity-5 p-5 rounded-[8px] border border-investment">
                    <div className="bg-investment text-white p-5 h-[120px] block rounded-[8px]">
                      <div className="flex items-center gap-2">
                        <AiOutlineBank size={25} />
                        <div>
                          <h1 className="text-[12px] font-PoppinsMedium">
                            Total Investment
                          </h1>
                          <h1 className="text-[25px] font-PoppinsSemiBold">
                            {formatCurrency(totalInvestment || 0)}
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-secondary bg-opacity-5 p-5 rounded-[8px] border border-secondary">
                    <div className="bg-secondary text-white p-5 h-[120px] block rounded-[8px]">
                      <div className="flex items-center gap-2">
                        <LiaPercentageSolid size={25} />
                        <div>
                          <h1 className="text-[12px] font-PoppinsMedium">
                            Total Profit
                          </h1>
                          <h1 className="text-[25px] font-PoppinsSemiBold">
                            {formatCurrency(usersInfo?.userTotalProfits || 0)}
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid lg:grid-cols-[3fr,2fr] lg:gap-6">
                  <div>
                    <div className="bg-deposit bg-opacity-5 p-5 rounded-[8px] border border-deposit mb-4">
                      <div className="bg-deposit text-white p-5 h-[120px] block rounded-[8px]">
                        <div className="flex items-center gap-2">
                          <PiHandDepositBold size={25} />
                          <div>
                            <h1 className="text-[12px] font-PoppinsMedium">
                              Total Deposit
                            </h1>
                            <h1 className="text-[25px] font-PoppinsSemiBold">
                              {formatCurrency(
                                usersInfo?.userTotalDeposits || 0
                              )}
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="bg-realSecondary bg-opacity-5 p-5 rounded-[8px] border border-realSecondary mb-4">
                      <div className="bg-realSecondary text-white p-5 h-[120px] block rounded-[8px]">
                        <div className="flex items-center gap-2">
                          <LiaPercentageSolid size={25} />
                          <div>
                            <h1 className="text-[12px] font-PoppinsMedium">
                              Total Savings
                            </h1>
                            <h1 className="text-[25px] font-PoppinsSemiBold">
                              {formatCurrency(usersInfo?.userTotalSavings || 0)}
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="bg-primary bg-opacity-5 p-5 rounded-[8px] border border-primary mb-4">
                      <div className="bg-primary text-white p-5 h-[120px] block rounded-[8px]">
                        <div className="flex items-center gap-2">
                          <PiHandWithdrawLight size={25} />
                          <div>
                            <h1 className="text-[12px] font-PoppinsMedium">
                              Total Withdrawal
                            </h1>
                            <h1 className="text-[25px] font-PoppinsSemiBold">
                              {formatCurrency(totalWithdrawal || 0)}
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="bg-referral bg-opacity-5 p-5 rounded-[8px] border border-referral mb-4">
                      <div className="bg-referral text-white p-5 h-[120px] block rounded-[8px]">
                        <div className="flex items-center gap-2">
                          <GiTakeMyMoney size={25} />
                          <div>
                            <h1 className="text-[12px] font-PoppinsMedium">
                              Total Referral
                            </h1>
                            <h1 className="text-[25px] font-PoppinsSemiBold">
                              {formatCurrency(
                                usersInfo?.userTotalReferrals || 0
                              )}
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="border border-transactionCard shadow-md p-4">
                    <div className="border-investment border text-investment p-5 h-[100px] block rounded-[8px] mb-4">
                      <div className="flex items-center gap-2">
                        <div>
                          <h1 className="text-[12px] font-PoppinsMedium">
                            All Customers
                          </h1>
                          <h1 className="text-[25px] font-PoppinsSemiBold">
                            {usersInfo?.allUsers || 0}
                          </h1>
                        </div>
                      </div>
                    </div>

                    <div className="border-realSecondary border text-realSecondary p-5 h-[100px] block rounded-[8px] mb-4">
                      <div className="flex items-center gap-2">
                        <div>
                          <h1 className="text-[12px] font-PoppinsMedium">
                            Active Customers
                          </h1>
                          <h1 className="text-[25px] font-PoppinsSemiBold">
                            {usersInfo?.activeUsers || 0}
                          </h1>
                        </div>
                      </div>
                    </div>

                    <div className="border-deposit border text-deposit p-5 h-[100px] block rounded-[8px] mb-4">
                      <div className="flex items-center gap-2">
                        <div>
                          <h1 className="text-[12px] font-PoppinsMedium">
                            Inactive Customers
                          </h1>
                          <h1 className="text-[25px] font-PoppinsSemiBold">
                            {usersInfo?.inactiveUsers || 0}
                          </h1>
                        </div>
                      </div>
                    </div>

                    <div className="border-primary border text-primary p-5 h-[100px] block rounded-[8px] dark:border-white dark:text-white mb-4">
                      <div className="flex items-center gap-2">
                        <div>
                          <h1 className="text-[12px] font-PoppinsMedium">
                            KYC Verified Customers
                          </h1>
                          <h1 className="text-[25px] font-PoppinsSemiBold">
                            {usersInfo?.kycVerifiedUsers || 0}
                          </h1>
                        </div>
                      </div>
                    </div>

                    <div className="border-referral border text-referral p-5 h-[100px] block rounded-[8px]">
                      <div className="flex items-center gap-2">
                        <div>
                          <h1 className="text-[12px] font-PoppinsMedium">
                            KYC Unverified Customers
                          </h1>
                          <h1 className="text-[25px] font-PoppinsSemiBold">
                            {usersInfo?.kycUnverifiedUsers || 0}
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

                {/* INVESTMENT and BALANCE CARDS */}
                <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4 mt-8">
                  <div className="border border-transactionCard p-4 rounded-lg">
                    <div className="flex font-PoppinsSemiBold  justify-between">
                      <span className="text-textcolor block dark:text-white">
                        Investments
                      </span>
                    </div>
                    {investChart && investChart.length > 0 ? (
                      <div className="overflow-x-auto mt-8 dark:text-white px-0 md:px-4 lg:px-4">
                        <ResponsiveContainer width="100%" height={300}>
                          <BarChart
                            data={investChart}
                            className="text-textcolor"
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                              dataKey="name"
                              tick={{
                                fill: isDarkMode ? "#ffffff" : "#3D3C3A",
                              }}
                            />
                            <YAxis
                              tick={{
                                fill: isDarkMode ? "#ffffff" : "#3D3C3A",
                              }}
                            />
                            <Tooltip
                              formatter={(value: number) => `${value}`}
                            />
                            <Bar dataKey="value" />
                          </BarChart>
                        </ResponsiveContainer>
                      </div>
                    ) : (
                      <NoData paragraph="Oops! No data found" />
                    )}
                  </div>

                  <div className="border border-transactionCard p-4 rounded-lg">
                    <div className="flex justify-between font-PoppinsSemiBold">
                      <span className="text-textcolor block dark:text-white">
                        Deposits
                      </span>
                      <button
                        className="text-investment text-sm hover:text-opacity-50"
                        onClick={handleViewAllDeposits}
                      >
                        View More
                      </button>
                    </div>
                    {depositChart && depositChart.length > 0 ? (
                      <div className="overflow-x-auto mt-8">
                        <ResponsiveContainer width="100%" height={300}>
                          <BarChart data={depositChart} margin={{ left: 20 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                              dataKey="name"
                              tick={{ fill: isDarkMode ? "#fff" : "#3D3C3A" }}
                            />
                            <YAxis
                              tickFormatter={(value) => formatCurrency(value)}
                              tick={{ fill: isDarkMode ? "#fff" : "#3D3C3A" }}
                            />
                            <Tooltip
                              formatter={(value: number) =>
                                formatCurrency(value)
                              }
                            />
                            <Bar dataKey="value" />
                          </BarChart>
                        </ResponsiveContainer>
                      </div>
                    ) : (
                      <NoData paragraph="Oops! No data found" />
                    )}
                  </div>
                </div>

                {/* SAVINGS AND WITHDRAWALS */}
                <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4 mt-8">
                  <div className="border border-transactionCard p-4 rounded-lg">
                    <div className="flex font-PoppinsSemiBold  justify-between">
                      <span className="text-textcolor block dark:text-white">
                        Savings
                      </span>
                      <button
                        className="text-investment text-sm hover:text-opacity-50"
                        onClick={handleViewAllSavings}
                      >
                        View More
                      </button>
                    </div>
                    {savingsChart &&
                    savingsChart.datasets &&
                    savingsChart.datasets.length > 0 ? (
                      <div className="pt-4 flex justify-center items-center">
                        <div className="w-[250px] h-[200px] md:w-[350px] md:h-[300px]">
                          <Doughnut
                            data={savingsChart}
                            options={doughnutOptions}
                            plugins={[centerTextPlugin(isDarkMode)]}
                          />
                        </div>
                      </div>
                    ) : (
                      <NoData paragraph="Oops! No data found" />
                    )}
                  </div>

                  <div className="border border-transactionCard p-4 rounded-lg">
                    <div className="flex justify-between font-PoppinsSemiBold">
                      <span className="text-textcolor block dark:text-white">
                        Withdrawals
                      </span>
                      <button
                        className="text-investment text-sm hover:text-opacity-50"
                        onClick={handleViewAllPayouts}
                      >
                        View More
                      </button>
                    </div>
                    {withdrawalChart &&
                    withdrawalChart.datasets &&
                    withdrawalChart.datasets.length > 0 ? (
                      <div className="pt-4 flex justify-center items-center">
                        <div className="w-[250px] h-[200px] md:w-[350px] md:h-[300px]">
                          <Doughnut
                            data={withdrawalChart}
                            plugins={[centerTextPlugin(isDarkMode)]}
                            options={doughnutOptions}
                          />
                        </div>
                      </div>
                    ) : (
                      <NoData paragraph="Oops! No data found" />
                    )}
                  </div>
                </div>

                {/* PAYMENT GATEWAYS AND PAYMENT METHODS */}
                <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4 mt-8">
                  <div className="border border-transactionCard p-4 rounded-lg">
                    <div className="flex font-PoppinsSemiBold  justify-between">
                      <span className="text-textcolor block dark:text-white">
                        Payment Gateways
                      </span>
                      <button
                        className="text-success text-sm hover:text-opacity-50"
                        onClick={handleViewAllPaymentGateways}
                      >
                        View More
                      </button>
                    </div>
                    {paymentGatewayChart && paymentGatewayChart.length > 0 ? (
                      <ResponsiveContainer width="100%" height={300}>
                        <PieChart>
                          <Pie
                            data={paymentGatewayChart}
                            dataKey="value"
                            nameKey="name"
                            cx="50%"
                            cy="50%"
                            outerRadius={window.innerWidth < 768 ? 60 : 100}
                            label
                          >
                            {paymentGatewayChart.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={entry.fill} />
                            ))}
                          </Pie>
                          <Tooltip formatter={(value) => `${value}`} />
                          <Legend
                            verticalAlign="top"
                            align="center"
                            layout="horizontal"
                            style={{ marginTop: '20px' }}
                          />
                        </PieChart>
                      </ResponsiveContainer>
                    ) : (
                      <NoData paragraph="Oops! No data found" />
                    )}
                  </div>

                  <div className="border border-transactionCard p-4 rounded-lg">
                    <span className="text-textcolor font-PoppinsSemiBold block dark:text-white">
                      Payment Methods
                    </span>
                    {paymentMthdChart &&
                    paymentMthdChart.datasets &&
                    paymentMthdChart.datasets.length > 0 ? (
                      <div className="pt-4 flex justify-center items-center">
                        <div className="w-[250px] h-[200px] md:w-[350px] md:h-[300px]">
                          <Doughnut
                            data={paymentMthdChart}
                            plugins={[centerTextPlugin(isDarkMode)]}
                            options={doughnutOptions}
                          />
                        </div>
                      </div>
                    ) : (
                      <NoData paragraph="Oops! No data found" />
                    )}
                  </div>
                </div>

                <AllUserTransact />

                {/* RECENT ACTIVITIES */}
                <div className="border border-transactionCard p-4 rounded-lg mt-8">
                  <div className="flex font-PoppinsSemiBold justify-between text-textcolor">
                    <span className="dark:text-white">Recent Activities</span>
                    <button
                      onClick={handleViewTransactions}
                      className="text-success cursor-pointer hover:text-opacity-50"
                    >
                      View More
                    </button>
                  </div>

                  {recentAct.length > 0 ? (
                    recentAct.map((transaction) => (
                      <div
                        key={transaction?._id}
                        className="flex flex-col md:flex-row justify-between font-PoppinsLight text-textcolor border border-transactionCard px-4 py-4 rounded-lg mt-8"
                      >
                        <div>
                          <p className="font-PoppinsSemiBold dark:text-white">
                            {transaction.user_details?.firstName +
                              " " +
                              transaction.user_details?.lastName}
                          </p>
                          <p className="dark:text-white">
                            {transaction?.transactionRefCode}
                          </p>
                          <p
                            className={`${
                              transaction?.isInFlow
                                ? "text-success"
                                : "text-error"
                            }`}
                          >
                            {transaction?.isInFlow ? "Credit" : "Debit"}
                          </p>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-1">
                          {" "}
                          <p
                            className={`capitalize ${
                              transaction.transactionType === "wallet"
                                ? "text-primary"
                                : transaction.transactionType === "deposits"
                                ? "text-deposit"
                                : transaction.transactionType === "withdrawals"
                                ? "text-primary"
                                : transaction.transactionType === "savings"
                                ? "text-secondary"
                                : transaction.transactionType === "profits"
                                ? "text-secondary"
                                : transaction.transactionType ===
                                    "standard_investments" ||
                                  transaction.transactionType ===
                                    "flexible_investments" ||
                                  transaction.transactionType ===
                                    "kids_investments" ||
                                  transaction.transactionType ===
                                    "project_investments" ||
                                  transaction.transactionType ===
                                    "regular_investments" ||
                                  transaction.transactionType === "investments"
                                ? "text-investment"
                                : transaction.transactionType === "referral"
                                ? "text-referral"
                                : "text-textcolor"
                            }`}
                          >
                            {transaction?.transactionType.replace(/_/g, " ")}
                          </p>
                          <div className="flex flex-col dark:text-white">
                            <p>{transaction?.transactionDate}</p>
                          </div>
                          <p className="font-PoppinsSemiBold dark:text-white">
                            {formatCurrency(transaction?.amount)}
                          </p>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="flex items-center justify-center py-5">
                      <div>
                        <div className="flex items-center justify-center">
                          <img
                            src={NoTransaction}
                            className="w-[200px]"
                            alt="illustrator"
                          />
                        </div>
                        <h1 className="font-PoppinsBold pb-3 text-[20px] text-primary">
                          No transactions currently!
                        </h1>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Home;
