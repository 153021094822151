import { useCallback, useEffect, useState } from "react";
import Sidebar from "../../sidebar/Sidebar";
import { ScaleLoader } from "react-spinners";
import { Input, Table, TablePaginationConfig } from "antd";
import { ColumnsType } from "antd/es/table";
import PageTitle from "../../../components/Pagetitle";
import { HttpClient } from "../../../api/HttpClient";
import { format } from "date-fns";
import Modal from "../../../components/Modal";
import ErrorHandler from "../../ErrorHandler";
import { formatCurrency } from "../../../components/CurrencyFormat";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

interface PaymentGatewayData {
  _id?: string;
  key?: string;
  tx_ref: string;
  created_at: string;
  transactionDate: string;
  paymentGateway: string;
  paymentMethod: string;
  transactionId: string;
  amount: number;
  status: string;
  paymentStatus: string;
  transactionType: string;
  meta: {
    __CheckoutInitAddress: string;
    customerId: string;
    originatoraccountnumber: string;
    bankname: string;
  };
  user_details: {
    id: string;
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
  };
  transactionDetails: {
    sessionId: string;
    accountNumber: string;
    tranRemarks: string;
    sourceBankName: string;
  };
}

function PaymentGateway() {
  const [loading, setLoading] = useState(true);
  const [paymentDetails, setPaymentDetails] =
    useState<PaymentGatewayData | null>(null);
  const [payGateway, setPayGateway] = useState<PaymentGatewayData[]>([]);
  const [filteredData, setFilteredData] = useState<PaymentGatewayData[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTransaction, setSelectedTransaction] =
    useState<PaymentGatewayData | null>(null);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 5,
    total: 0,
  });
  const [filters, setFilters] = useState({
    name: "",
    email: "",
    paymentGateway: "",
    transactionId: "",
  });

  const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);

  const applyFilters = useCallback(
    (data: PaymentGatewayData[]) => {
      let filtered = data;

      if (filters.name) {
        filtered = filtered.filter(
          (item) =>
            item.user_details.firstName +
            " " +
            item.user_details.lastName
              .toLowerCase()
              .includes(filters.name.toLowerCase())
        );
      }
      if (filters.email) {
        filtered = filtered.filter((item) =>
          item.user_details.email
            .toLowerCase()
            .includes(filters.email.toLowerCase())
        );
      }
      if (filters.paymentGateway) {
        filtered = filtered.filter((item) =>
          item.paymentGateway
            .toLowerCase()
            .includes(filters.paymentGateway.toLowerCase())
        );
      }
      if (filters.transactionId) {
        filtered = filtered.filter((item) =>
          item.transactionId
            .toLowerCase()
            .includes(filters.transactionId.toLowerCase())
        );
      }

      setFilteredData(filtered);
      setPagination((prev) => ({
        ...prev,
        total: filtered.length,
      }));
    },
    [filters]
  );

  const fetchGatewaysData = useCallback(async () => {
    try {
      const payGatewayRes = await HttpClient.get(`/transactions/history`, {
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          page: "",
          limit: "",
          dir: "desc",
          search: "providus",
          sort: "",
        },
      });

      const paymentGateways = payGatewayRes.data.data.data.map(
        (item: any, index: number) => ({
          key: item._id,
          serialNumber: index + 1,
          name:
            item?.user_details?.firstName +
              " " +
              item?.user_details?.lastName || "",
          email: item?.user_details?.email || "",
          phone: item?.user_details?.phone || "",
          transactionDate: item?.transactionDate || "",
          paymentGateway: item?.paymentGateway || "",
          paymentMethod: item?.paymentMethod || "",
          transactionId: item?.transactionId || "",
          amount: item?.amount || "0",
          paymentStatus: item?.paymentStatus || "",
          transactionType: item?.transactionType || "",
          sessionId: item?.transactionDetails?.sessionId || "",
          transactionDetails: item?.transactionDetails,
          user_details: item?.user_details,
        })
      );

      setPayGateway(paymentGateways);
      setPagination((prev) => ({
        ...prev,
        total: payGatewayRes.data?.data?.total,
      }));
      applyFilters(paymentGateways);
    } catch (error: any) {
      ErrorHandler(error);
    } finally {
      setLoading(false);
    }
  }, [applyFilters]);

  useEffect(() => {
    fetchGatewaysData();
  }, [fetchGatewaysData]);

  useEffect(() => {
    applyFilters(payGateway);
  }, [filters, payGateway, applyFilters]);

  const handleFilterChange = (field: string, value: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
  };

  const handleTransactionClick = (transactionId: string) => {
    const transaction = payGateway.find(
      (item) => item.transactionId === transactionId
    );
    if (transaction) {
      setSelectedTransaction(transaction);
      setIsModalVisible(true);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedTransaction(null);
  };

  const columns: ColumnsType<PaymentGatewayData> = [
    {
      key: "serialNumber",
      title: "S/N",
      dataIndex: "serialNumber",
      className: "text-textcolor font-PoppinsRegular",
    },
    {
      key: "transactionDate",
      title: "Date",
      dataIndex: "transactionDate",
      render: (dateTime: string) => {
        const date = new Date(dateTime);
        const formattedDate = format(date, "MMM dd, yyyy");
        const formattedTime = format(date, "hh:mm a");

        return (
          <div className="text-textcolor" style={{ width: "100px" }}>
            <div className="font-PoppinsRegular">{formattedDate}</div>
            <div className="font-PoppinsLight">{formattedTime}</div>
          </div>
        );
      },
    },
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
      className: "capitalize text-textcolor font-PoppinsRegular",
      filterDropdown: () => (
        <Input
          placeholder="John Doe"
          value={filters.name}
          onChange={(e) => handleFilterChange("name", e.target.value)}
        />
      ),
      onFilterDropdownVisibleChange: () => applyFilters(payGateway),
    },
    {
      key: "email",
      title: "Email",
      dataIndex: "email",
      className: "text-textcolor font-PoppinsRegular",
      filterDropdown: () => (
        <Input
          placeholder="johndoe@example.com"
          value={filters.email}
          onChange={(e) => handleFilterChange("email", e.target.value)}
        />
      ),
      onFilterDropdownVisibleChange: () => applyFilters(payGateway),
    },
    {
      key: "phone",
      title: "Phone No.",
      dataIndex: "phone",
      className: "text-textcolor font-PoppinsRegular",
    },
    {
      key: "paymentGateway",
      title: "Payment Gateway",
      dataIndex: "paymentGateway",
      className: "capitalize text-textcolor font-PoppinsRegular",
      filterDropdown: () => (
        <Input
          placeholder="Flutterwave"
          value={filters.paymentGateway}
          onChange={(e) => handleFilterChange("paymentGateway", e.target.value)}
        />
      ),
      onFilterDropdownVisibleChange: () => applyFilters(payGateway),
    },
    {
      key: "paymentMethod",
      title: "Payment Method",
      dataIndex: "paymentMethod",
      className: "capitalize text-textcolor font-PoppinsRegular",
      render(paymentMethod: string) {
        return (
          <div style={{ width: "150px" }}>
            {paymentMethod === "bank_transfer" ? "Bank Transfer" : "USSD"}
          </div>
        );
      },
    },
    {
      title: "Transaction ID",
      key: "transactionId",
      dataIndex: "transactionId",
      className: "text-investment font-PoppinsRegular cursor-pointer",
      render: (transactionId: string) => (
        <span onClick={() => handleTransactionClick(transactionId)}>
          {transactionId}
        </span>
      ),
      filterDropdown: () => (
        <Input
          placeholder="Search Transaction ID"
          value={filters?.transactionId}
          onChange={(e) => handleFilterChange("transactionId", e.target.value)}
        />
      ),
      onFilterDropdownVisibleChange: () => applyFilters(payGateway),
    },
    {
      key: "amount",
      title: "Amount",
      dataIndex: "amount",
      className: "text-textcolor font-PoppinsRegular",
      render: (amount: number) => formatCurrency(amount),
    },
    {
      key: "paymentStatus",
      title: "Status",
      dataIndex: "paymentStatus",
      render: (status: string) => {
        const baseClass =
          "text-[0.8em] capitalize rounded-[15px] font-PoppinsMedium px-[0.9rem] py-[0.35rem] bg-opacity-[28%] text-center inline-block";
        const statusClass =
          status === "successful"
            ? `bg-success text-success ${baseClass}`
            : status === "failed"
            ? `bg-error text-error ${baseClass}`
            : `bg-secondary text-secondary ${baseClass}`;

        return <div className={statusClass}>{status}</div>;
      },
    },
  ];

  return (
    <>
      <PageTitle title="Affluence - Admin: Transaction Log" />
      <div className="flex h-screen dark:bg-darkPrimary">
        <Sidebar />
        <div className="flex-1 overflow-y-auto">
          {loading ? (
            <div className="w-full h-screen bg-white dark:bg-darkPrimary flex items-center justify-center">
              <ScaleLoader color={isDarkMode ? "#FFFFFF" : "#6B006B"} />
            </div>
          ) : (
            <div className="p-4 mt-28">
              <div className="p-4 shadow-md border border-transactionCard rounded-lg">
                <span className="dark:text-white text-semibold text-textcolor font-PoppinsBold block mb-4">
                  Payment Gateway
                </span>
                <div className="dark:bg-white overflow-x-auto">
                  <Table
                    columns={columns}
                    dataSource={filteredData}
                    pagination={pagination}
                    onChange={(newPagination) => setPagination(newPagination)}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <Modal isOpenModal={isModalVisible} closeModal={handleCancel}>
        {isModalVisible && selectedTransaction && (
          <div className="dark:text-white text-textcolor py-8 px-4 mt-28">
            <div className="mb-6">
              <h1 className="dark:text-white text-textcolor text-xl font-PoppinsSemiBold">
                Payment Details
              </h1>
            </div>

            <div>
              <p className="font-PoppinsSemiBold mb-2">
                Name:{" "}
                <span className="dark:text-white font-PoppinsLight text-primary">
                  {selectedTransaction?.user_details?.firstName +
                    " " +
                    selectedTransaction?.user_details?.lastName}
                </span>
              </p>
              <p className="font-PoppinsSemiBold mb-2">
                Email:{" "}
                <span className="dark:text-white font-PoppinsLight text-primary">
                  {selectedTransaction?.user_details?.email}
                </span>
              </p>
              <p className="font-PoppinsSemiBold mb-2">
                Phone Number:{" "}
                <span className="dark:text-white font-PoppinsLight text-primary">
                  {selectedTransaction?.user_details?.phone}
                </span>
              </p>
              <p className="font-PoppinsSemiBold mb-2">
                Bank Name:{" "}
                <span className="font-PoppinsLight text-primary">
                  {selectedTransaction?.transactionDetails?.sourceBankName}
                </span>
              </p>
              <p className="font-PoppinsSemiBold mb-2">
                Session ID:{" "}
                <span className="dark:text-white font-PoppinsLight text-primary">
                  {selectedTransaction?.transactionDetails?.sessionId}
                </span>
              </p>
              <p className="font-PoppinsSemiBold mb-2">
                Account Number:{" "}
                <span className="dark:text-white font-PoppinsLight text-primary">
                  {selectedTransaction?.transactionDetails?.accountNumber}
                </span>
              </p>
              <p className="font-PoppinsSemiBold mb-2">
                Amount:{" "}
                <span className="dark:text-white font-PoppinsLight text-primary">
                  {formatCurrency(selectedTransaction?.amount)}
                </span>
              </p>
              <p className="font-PoppinsSemiBold mb-2">
                Status:{" "}
                <span className="capitalize font-PoppinsLight text-primary">
                  {selectedTransaction?.paymentStatus}
                </span>
              </p>
              <p className="font-PoppinsSemiBold mb-2">
                Payment Type:{" "}
                <span className="font-PoppinsLight capitalize text-primary">
                  {selectedTransaction?.transactionType}
                </span>
              </p>
              <p className="font-PoppinsSemiBold mb-2">
                Transaction Ref:{" "}
                <span className="dark:text-white font-PoppinsLight text-primary">
                  {selectedTransaction?.tx_ref}
                </span>
              </p>
              <p className="font-PoppinsSemiBold mb-2">
                Date:{" "}
                <span className="dark:text-white font-PoppinsLight text-primary">
                  {new Date(
                    selectedTransaction?.transactionDate
                  ).toLocaleString()}
                </span>
              </p>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
}

export default PaymentGateway;
