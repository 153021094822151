import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Account,
  InvestmentItem,
  MyInvestmentItem,
} from "../../../../assets/Typeprops";
import InvestmentBtn from "../../../../components/InvestmentBtn";
import SelectInputWithLabelAndPlaceholder from "../../../../components/SelectInputWithLabelAndPlaceholder";
import SelectInputWithLabel from "../../../../components/SelectInputWithLabel";
import { Form, Formik } from "formik";
import Modal from "../../../../components/Modal";
import { formatDate } from "../../../../components/DateFormat";
import { formatCurrency } from "../../../../components/CurrencyFormat";
import ToastComponent from "../../../../components/ToastComponent";
import { isAxiosError } from "axios";
import { HttpClient } from "../../../../api/HttpClient";
import Logo from "../../../../assets/img/affluence-logo-small.png";
import { ScaleLoader } from "react-spinners";
import { RootState } from "../../../../store/store";
import { useSelector } from "react-redux";
import AuthButton from "../../../../components/AuthButton";
import PasswordInput from "../../../../components/PasswordInput";
import * as Yup from "yup";
import GlowingButton from "../../../../components/GlowBtn";
import Default from "../../../../assets/img/default-img.webp";
import { TiWarningOutline } from "react-icons/ti";
import { IoIosArrowForward } from "react-icons/io";
import { ConfigProvider, Switch } from "antd";
import TextInputWithLabel from "../../../../components/TextInputWithLabel";

const MyInvestmentMobile = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedInvestment, setSelectedInvestment] =
    useState<MyInvestmentItem | null>(null);
  const [investNowModal, setInvestNowModal] = useState(false);
  const [isBooking, setIsBooking] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [investmentData, setInvestmentData] = useState<MyInvestmentItem[]>([]);
  const [filterCategory, setFilterCategory] = useState<string | null>(null);
  const [selectedInvestmentIndex, setSelectedInvestmentIndex] = useState<
    string | null
  >(null);
  const [showEnterTransactionPin, setShowTransactionPin] = useState(false);
  const [pin, setPin] = useState(false);
  const [aboutModal, setAboutModal] = useState(false);
  const [breakInvestmentModal, setBreakInvestmentModal] = useState(false);
  const [modalInvestments, setModalInvestments] = useState<MyInvestmentItem[]>(
    []
  );
  const [accountData, setAccountData] = useState<Account | null>(null);
  const [viewMore, setViewMore] = useState(false);
  const navigate = useNavigate();
  const openShowInvestmentModal = (
    investment: MyInvestmentItem,
    index: string
  ) => {
    setSelectedInvestment(investment);
    setSelectedInvestmentIndex(index);
    setIsModalOpen(true);
  };

  const closeShowInvestmentModal = () => {
    setIsModalOpen(false);
    setSelectedInvestment(null);
  };

  const openInvestNowModal = (investment: MyInvestmentItem, index: string) => {
    setSelectedInvestment(investment);
    setSelectedInvestmentIndex(index);
    setInvestNowModal(true);
  };
  const closeInvestNowModal = () => {
    setInvestNowModal(false);
  };
  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const [userRes, investmentRes] = await Promise.all([
        HttpClient.get("/users/me", {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }),
        HttpClient.get("/investments/booking", {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }),
      ]);
      setInvestmentData(investmentRes?.data?.data?.data);
      setAccountData(userRes?.data?.data);
    } catch (error) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      }
    } finally {
      setIsLoading(false);
    }
  }, []);
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  const units = Array.from(
    { length: selectedInvestment?.investment_details.unitsRemaining ?? 0 },
    (_, i) => {
      const value = i + 1;
      return { value: value, label: `${value} units` };
    }
  );
  const handleBookInvestment = async (values: any) => {
    setIsBooking(true);
    try {
      const bookInvestRes = await HttpClient.post(
        "/investments/booking",
        { ...values, id: selectedInvestment?.investment_details._id },
        {
          headers: { "x-auth-token": localStorage.getItem("token") },
        }
      );
      navigate("/investments/my_investments");
      setInvestNowModal(false);
      setIsModalOpen(false);
      ToastComponent.success(bookInvestRes.data.message);
    } catch (error) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      }
    } finally {
      setIsBooking(false);
    }
  };
  const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
  const filteredData = useMemo(() => {
    let result = investmentData;
    if (filterCategory) {
      result = result.filter(
        (item) => item.investment_details.status === filterCategory
      );
    }
    return result;
  }, [filterCategory, investmentData]);
  const wallet = [
    {
      value: "balance",
      label: `Balance (${
        accountData?.account?.balance
          ? formatCurrency(accountData.account.balance)
          : "₦0"
      })`,
    },
    {
      value: "profit",
      label: `Profits (${
        accountData?.account?.profit
          ? formatCurrency(accountData.account.profit)
          : "₦0"
      })`,
    },
    {
      value: "referral",
      label: `Referrals (${
        accountData?.account?.referral
          ? formatCurrency(accountData.account.referral)
          : "₦0"
      })`,
    },
  ];
  const setPinSchema = Yup.object().shape({
    pin: Yup.string().required("Enter transaction pin"),
  });
  const handleClaimProfit = async (
    values: any,
    {
      setFieldValue,
    }: {
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean
      ) => void;
    }
  ) => {
    setPin(true);
    try {
      const res = await HttpClient.put(
        `/investments/claim-roi/${selectedInvestment?.investment_details._id}`,
        values,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      ToastComponent.success(res.data.message);
      setIsModalOpen(false);
      setShowTransactionPin(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      } else if (error instanceof Error) {
        ToastComponent.error(error.message);
      } else {
        ToastComponent.error("An unknown error occurred");
      }
      setFieldValue("pin", "", false);
    } finally {
      setPin(false);
    }
  };
  const breakInvestmentSchema = Yup.object().shape({
    pin: Yup.string()
      .required("Enter transaction pin")
      .max(4, "Transaction Pin length must be equal to 4"),
  });
  const handleBreakInvestment = async (
    values: any,
    {
      setFieldValue,
    }: {
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean
      ) => void;
    }
  ) => {
    setPin(true);
    try {
      const res = await HttpClient.put(
        `/investments/break-roi/${selectedInvestment?.investment_details._id}`,
        values,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      ToastComponent.success(res.data.message);
      setBreakInvestmentModal(false);
      setIsModalOpen(false);
      setInvestNowModal(false);
      fetchData();
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      } else if (error instanceof Error) {
        ToastComponent.error(error.message);
      } else {
        ToastComponent.error("An unknown error occurred");
      }
      setFieldValue("pin", "", false);
    } finally {
      setPin(false);
    }
  };

  const fetchAllModalInvestment = useCallback(async () => {
    setIsLoading(true);
    try {
      const investmentRes = await HttpClient.get("/investments/booking", {
        params: {
          page: 1,
          limit: "",
          dir: "desc",
          sort: "updatedAt",
          search: "",
        },
        headers: { "x-auth-token": localStorage.getItem("token") },
      });
      setModalInvestments(investmentRes?.data?.data?.data);
    } catch (error) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      }
    } finally {
      setIsLoading(false);
    }
  }, []);
  useEffect(() => {
    if (viewMore) {
      fetchAllModalInvestment();
    }
  }, [fetchAllModalInvestment, viewMore]);
  const bookInvestmentSchema = Yup.object().shape({
    units: Yup.number(),
    fundingSource: Yup.string().required("Please select a funding source"),
  });
  const [switch1, setSwitch1] = useState(false);
  const [switch2, setSwitch2] = useState(false);
  return (
    <div className="text-textcolor">
      <div className="flex text-[10px] font-PoppinsMedium justify-between items-center gap-10 mx-[3%] pt-2">
        <div
          onClick={() => setFilterCategory(null)}
          className={`border-[1.5px] cursor-pointer py-0.3 border-textcolor rounded-[10px] w-full text-center ${
            filterCategory === null ? "bg-primary text-white" : ""
          }`}
        >
          All
        </div>
        <div
          onClick={() => setFilterCategory("running")}
          className={`border-[1.5px] cursor-pointer py-0.3 border-textcolor rounded-[10px] w-full text-center ${
            filterCategory === "running" ? "bg-primary text-white" : ""
          }`}
        >
          Running
        </div>
        <div
          onClick={() => setFilterCategory("followed")}
          className={`border-[1.5px] cursor-pointer py-0.3 border-textcolor rounded-[10px] w-full text-center ${
            filterCategory === "followed" ? "bg-primary text-white" : ""
          }`}
        >
          Followed
        </div>
        <div
          onClick={() => setFilterCategory("matured")}
          className={`border-[1.5px] cursor-pointer py-0.3 border-textcolor rounded-[10px] w-full text-center ${
            filterCategory === "matured" ? "bg-primary text-white" : ""
          }`}
        >
          Matured
        </div>
      </div>

      {filteredData && (
        <div className="flex justify-end pt-3 mr-[3%]">
          <h1
            onClick={() => setViewMore(true)}
            className="font-PoppinsRegular flex items-center space-x-1 cursor-pointer text-[11px] dark:text-white text-primary"
          >
            View more
            <IoIosArrowForward />
          </h1>
        </div>
      )}
      <div className="flex gap-3 pl-[3%] pt-5 overflow-x-scroll hide-scroll">
        {isLoading ? (
          <div className="w-full h-[50vh] bg-white dark:bg-darkPrimary flex items-center justify-center relative">
            <ScaleLoader color={isDarkMode ? "#FFFFFF" : "#6B006B"} />
          </div>
        ) : filteredData && filteredData.length > 0 ? (
          filteredData.map((item, index) => (
            <div
              key={index}
              className="inline-block cursor-pointer w-[150px]"
              onClick={() => openShowInvestmentModal(item, item._id)}
            >
              <div className="w-[150px] h-[100px] relative">
                <img
                  src={
                    item.investment_details?.previewImage
                      ? `${process.env.REACT_APP_FILES_URL}/${item.investment_details.previewImage}`
                      : item.investment_details?.previewImage === ""
                      ? Default
                      : Default
                  }
                  className="w-full h-full bg-cover object-cover rounded-[10px]"
                  alt="investment-img"
                />
                <div
                  className={`${
                    item.investment_details.status === "running"
                      ? "bg-referral"
                      : item.investment_details.status === "matured"
                      ? "bg-success"
                      : item.investment_details.status === "pending"
                      ? "bg-secondary"
                      : "bg-error"
                  } text-white rounded-[20px] absolute top-2 right-2 font-PoppinsMedium text-[8px] justify-center items-center inline-flex py-[0.7px] px-2`}
                >
                  {item.investment_details.status === "running"
                    ? "RUNNING"
                    : item.investment_details.status === "matured"
                    ? "MATURED"
                    : item.investment_details.status === "pending"
                    ? "PENDING"
                    : "SOLD OUT"}
                </div>
              </div>
              <div className="pt-2">
                {item.investment_details.status === "pending" ? (
                  <h1 className="text-textcolor dark:text-white font-PoppinsRegular text-[10px]">
                    UNIT BOUGHT:{" "}
                    <span className="font-PoppinsSemiBold">
                      {item.investment_details?.numberOfUnits}
                    </span>
                  </h1>
                ) : item.investment_details.status === "running" ? (
                  <h1 className="text-textcolor dark:text-white  font-PoppinsRegular text-[10px]">
                    NUMBER OF INVESTORS:{" "}
                    <span className="font-PoppinsSemiBold">
                      {item.investment_details.numberOfInvestors}
                    </span>
                  </h1>
                ) : item.investment_details.status === "sold out" ? (
                  <h1 className="text-textcolor dark:text-white  font-PoppinsRegular text-[10px]">
                    NUMBER OF INVESTORS:{" "}
                    <span className="font-PoppinsSemiBold">
                      {item.investment_details.numberOfInvestors}
                    </span>
                  </h1>
                ) : null}
                <h1 className="text-textcolor dark:text-white uppercase font-PoppinsSemiBold text-[12px] whitespace-nowrap overflow-hidden text-ellipsis w-[150px]">
                  {item.investment_details.title}
                </h1>
                <h1 className="font-PoppinsRegular text-[11px] dark:text-white text-textcolor">
                  Interest rate at{" "}
                  <span className="font-PoppinsSemiBold text-success">
                    {`${item.investment_details.rate}%`}
                  </span>
                </h1>
                <h1 className="font-PoppinsRegular text-[11px] dark:text-white text-textcolor">
                  {`Returns in ${item.investment_details.durationNumber} ${item.investment_details.durationInterval}`}
                </h1>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center w-full pt-[40px]">
            <div>
              <h1 className="font-PoppinsBold pb-3 text-[20px] text-primary">
                You have no investment currently!
              </h1>
              <h1 className="font-PoppinsRegular pb-2 px-[3%] dark:text-white text-[12px]">
                Start earning interest by investing in our various investment
                options.
              </h1>
              <div className="flex items-center gap-4 justify-center pt-5">
                <button className="bg-primary border-[1.5px] text-[12px] border-primary text-white font-PoppinsSemiBold sm:px-8 xs:px-6 px-4 sm:py-2 py-1 rounded-[8px]">
                  Invest Now
                </button>
                <button className="border-[1.5px] border-primary text-[12px] text-primary font-PoppinsSemiBold sm:px-8 xs:px-6 px-4 sm:py-2 py-1 rounded-[8px]">
                  Learn More
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {viewMore && (
        <Modal
          isOpenModal={() => setViewMore(true)}
          closeModal={() => setViewMore(false)}
        >
          <div className="px-4 pt-[120px]">
            <h1 className="text-[20px] font-PoppinsBold">All Investments</h1>
            {isLoading ? (
              <div className="w-full h-[50vh] bg-white dark:bg-darkPrimary flex items-center justify-center">
                <ScaleLoader color={isDarkMode ? "#FFFFFF" : "#6B006B"} />
              </div>
            ) : (
              <div className="mt-10">
                {modalInvestments.length > 0 ? (
                  <div>
                    {modalInvestments?.map((item, index) => (
                      <div
                        key={index}
                        className="border-[1.5px] cursor-pointer border-transactionCard dark:border-textcolor my-2 py-2 shadow-sm"
                        onClick={() => openShowInvestmentModal(item, item._id)}
                      >
                        <div className="flex items-center gap-[50px] px-[3%]">
                          <div className="w-[150px] h-[100px] relative">
                            <img
                              src={
                                item?.investment_details?.previewImage
                                  ? `${process.env.REACT_APP_FILES_URL}/${item.investment_details.previewImage}`
                                  : item?.investment_details?.previewImage ===
                                    ""
                                  ? Default
                                  : Default
                              }
                              className="w-full h-full bg-cover object-cover rounded-[10px]"
                              alt="investment-img"
                            />
                          </div>
                          <div>
                            <h1 className="font-PoppinsSemiBold uppercase text-[12px] pb-2">
                              {item.investment_details?.title}
                            </h1>
                            <h1 className="text-success font-PoppinsSemiBold text-[12px]">
                              {item.investment_details?.rate}%{" "}
                              <span className=" font-PoppinsRegular text-[10px] text-tabletext">
                                {`returns in ${item.investment_details?.durationNumber} ${item.investment_details?.durationInterval}`}
                              </span>
                            </h1>
                            <div className="flex items-center gap-9 py-2">
                              <div>
                                <h1 className=" font-PoppinsSemiBold text-[12px] text-primary">
                                  {item?.amount
                                    ? formatCurrency(item?.amount)
                                    : "₦0"}
                                </h1>
                                <h1 className="font-PoppinsMedium text-tabletext text-[9px]">
                                  per unit
                                </h1>
                              </div>
                              <div>
                                {item.investment_details?.status ===
                                "pending" ? (
                                  <h1 className="text-textcolor dark:text-white font-PoppinsRegular text-[10px]">
                                    UNIT BOUGHT:{" "}
                                    <span className="font-PoppinsSemiBold">
                                      {item.investment_details?.numberOfUnits}
                                    </span>
                                  </h1>
                                ) : item.investment_details?.status ===
                                  "running" ? (
                                  <h1 className="text-textcolor dark:text-white  font-PoppinsRegular text-[10px]">
                                    NUMBER OF INVESTORS:{" "}
                                    <span className="font-PoppinsSemiBold">
                                      {
                                        item.investment_details
                                          ?.numberOfInvestors
                                      }
                                    </span>
                                  </h1>
                                ) : item.investment_details?.status ===
                                  "sold out" ? (
                                  <h1 className="text-textcolor dark:text-white  font-PoppinsRegular text-[10px]">
                                    NUMBER OF INVESTORS:{" "}
                                    <span className="font-PoppinsSemiBold">
                                      {
                                        item.investment_details
                                          ?.numberOfInvestors
                                      }
                                    </span>
                                  </h1>
                                ) : null}
                              </div>
                            </div>
                            <div
                              className={`${
                                item.investment_details?.status === "running"
                                  ? "text-referral bg-referral"
                                  : item.investment_details?.status ===
                                    "matured"
                                  ? "text-success bg-success"
                                  : item.investment_details?.status ===
                                    "pending"
                                  ? "text-secondary bg-secondary"
                                  : "text-error bg-error"
                              } bg-opacity-[28%] inline-block py-[0.7px] px-2 rounded-[10px] font-PoppinsMedium text-[8px]`}
                            >
                              {item.investment_details?.status === "running"
                                ? "RUNNING"
                                : item.investment_details?.status === "matured"
                                ? "MATURED"
                                : item.investment_details?.status === "pending"
                                ? "PENDING"
                                : "SOLD OUT"}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="text-center text-tabletext text-[12px]">
                    No investments found.
                  </div>
                )}
              </div>
            )}
          </div>
        </Modal>
      )}
      {isModalOpen &&
        selectedInvestment &&
        selectedInvestmentIndex !== null && (
          <Modal
            isOpenModal={openShowInvestmentModal}
            closeModal={closeShowInvestmentModal}
          >
            <div
              className="flex items-center mt-[90px] justify-center"
              style={{
                backgroundImage: `url(${
                  selectedInvestment?.investment_details?.previewImage
                    ? `${process.env.REACT_APP_FILES_URL}/${selectedInvestment.investment_details.previewImage}`
                    : selectedInvestment?.investment_details?.previewImage ===
                      ""
                    ? Default
                    : Default
                })`,
                height: "250px",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            >
              <div className="bg-white opacity-50 inline-block p-4 rounded-[8px]">
                <img src={Logo} className="w-[30px]" alt="" />
              </div>
            </div>
            <div className="px-4">
              <div className="bg-success mt-6 mb-8 py-1 px-4 inline-block text-[12px] font-PoppinsLight rounded-[10px] bg-opacity-[28%] text-success">
                VERIFIED INVESTMENT
              </div>
              <div className="flex justify-between">
                <div>
                  <h1 className="font-PoppinsBold dark:text-white uppercase w-[80%] text-[20px]">
                    {selectedInvestment.investment_details.title}
                  </h1>
                </div>
                {selectedInvestment.investment_details.status === "pending" ? (
                  <div>
                    <h1 className="font-PoppinsBold uppercase text-[20px] text-success">
                      {selectedInvestment.investment_details.amount
                        ? formatCurrency(
                            selectedInvestment.investment_details.amount
                          )
                        : "₦0"}
                    </h1>
                    <h1 className="font-PoppinsMedium text-tabletext text-[9px]">
                      per unit
                    </h1>
                  </div>
                ) : selectedInvestment.investment_details.status ===
                  "soldout" ? (
                  <div>
                    <h1 className="font-PoppinsBold uppercase text-[20px] text-success">
                      {selectedInvestment.investment_details.amount
                        ? formatCurrency(
                            selectedInvestment.investment_details.amount
                          )
                        : "₦0"}
                    </h1>
                    <h1 className="font-PoppinsMedium text-tabletext text-[9px]">
                      per unit
                    </h1>
                  </div>
                ) : (
                  <div>
                    <h1 className="font-PoppinsBold uppercase text-center text-[20px] text-success">
                      {selectedInvestment.investment_details.numberOfInvestors}
                    </h1>
                    <h1 className="font-PoppinsMedium text-center uppercase text-tabletext text-[9px]">
                      {selectedInvestment.investment_details
                        .numberOfInvestors === 1
                        ? "Investor"
                        : "Investors"}
                    </h1>
                  </div>
                )}
              </div>
              <div className="flex flex-col gap-4 mt-5">
                {selectedInvestment.investment_details.status === "sold out" ? (
                  <button className="bg-error cursor-not-allowed border-[1.5px] border-error text-white font-PoppinsSemiBold w-full py-4 rounded-[8px]">
                    SOLD OUT
                  </button>
                ) : selectedInvestment.investment_details.status ===
                  "pending" ? (
                  <button
                    onClick={() =>
                      openInvestNowModal(
                        selectedInvestment,
                        selectedInvestmentIndex
                      )
                    }
                    className="bg-investment border-[1.5px] border-investment text-white font-PoppinsSemiBold w-full py-4 rounded-[8px]"
                  >
                    INVEST NOW
                  </button>
                ) : selectedInvestment.investment_details.status ===
                  "running" ? (
                  selectedInvestment.investmentStatus === "broken" ? (
                    <button className="bg-referral border-[1.5px] cursor-not-allowed border-referral bg-opacity-[28%] text-white font-PoppinsSemiBold w-full py-4 rounded-[8px]">
                      INVESTMENT BROKEN
                    </button>
                  ) : (
                    <button
                      onClick={() => setBreakInvestmentModal(true)}
                      className="bg-referral border-[1.5px]  border-referral text-white font-PoppinsSemiBold w-full py-4 rounded-[8px]"
                    >
                      BREAK INVESTMENT
                    </button>
                  )
                ) : selectedInvestment.isRoiSettled === false ? (
                  <GlowingButton
                    onClick={() => setShowTransactionPin(true)}
                    isLoading={false}
                    disabled={false}
                  >
                    CLAIM
                  </GlowingButton>
                ) : (
                  <button className="dark:bg-[#C8A2C8] cursor-not-allowed border-[1.5px] dark:border-[#C8A2C8] dark:bg-opacity-10 dark:text-[#C8A2C8] bg-opacity-10 text-primary bg-primary border-primary font-PoppinsSemiBold w-full py-4 rounded-[8px]">
                    CLAIMED
                  </button>
                )}
                {selectedInvestment.investment_details.status === "sold out" ? (
                  <button
                    onClick={() => setAboutModal(true)}
                    className="border-[1.5px] border-error text-error font-PoppinsSemiBold py-4 w-full rounded-[8px]"
                  >
                    ABOUT THIS INVESTMENT
                  </button>
                ) : (
                  <button
                    onClick={() => setAboutModal(true)}
                    className="border-[1.5px] border-investment text-investment font-PoppinsSemiBold py-4 w-full rounded-[8px]"
                  >
                    ABOUT THIS INVESTMENT
                  </button>
                )}
              </div>
              <div
                className={`text-[14px] font-PoppinsRegular text-center mt-3 ${
                  selectedInvestment.investment_details.status === "sold out"
                    ? "text-error"
                    : selectedInvestment.investment_details.status === "pending"
                    ? "text-success"
                    : selectedInvestment.investment_details.status === "running"
                    ? "text-referral"
                    : "text-success"
                }`}
              >
                {selectedInvestment.investment_details.status === "sold out"
                  ? "This investment is currently sold out to Investors. Please look for other investment plans."
                  : selectedInvestment.investment_details.status === "pending"
                  ? "This investment plan is still available for purchase."
                  : selectedInvestment.investment_details.status === "running"
                  ? "This investment plan is already running for all Investors."
                  : "This investment plan is still available for purchase."}
              </div>

              <hr className="text-authbordercolor my-10" />
              <div>
                <div className="flex justify-center gap-7 mb-5">
                  <div className="w-full border-[1.5px] rounded-[8px] p-3 border-transactionCard">
                    <h1 className="text-[13px] font-PoppinsMedium text-disabledtext">
                      Investment Type
                    </h1>
                    <h1 className="text-[13px] dark:text-white font-PoppinsMedium">
                      {selectedInvestment.investment_details.category}
                    </h1>
                  </div>
                  <div className="w-full border-[1.5px] rounded-[8px] p-3 border-transactionCard">
                    <h1 className="text-[13px] font-PoppinsMedium text-disabledtext">
                      Start Date
                    </h1>
                    <h1 className="text-[13px] dark:text-white font-PoppinsMedium">
                      {formatDate(
                        selectedInvestment.investment_details.startDate
                      )}
                    </h1>
                  </div>
                </div>
                <div className="flex justify-center mb-5 gap-7">
                  <div className="w-full border-[1.5px] rounded-[8px] p-3 border-transactionCard">
                    <h1 className="text-[13px] font-PoppinsMedium text-disabledtext">
                      Maturity Date
                    </h1>
                    <h1 className="text-[13px] dark:text-white font-PoppinsMedium">
                      {formatDate(
                        selectedInvestment.investment_details.endDate
                      )}
                    </h1>
                  </div>
                  <div className="w-full border-[1.5px] rounded-[8px] p-3 border-transactionCard">
                    <h1 className="text-[13px] font-PoppinsMedium text-disabledtext">
                      Payout Type
                    </h1>
                    <h1 className="text-[13px] dark:text-white font-PoppinsMedium">
                      Capital + profit to be paid
                    </h1>
                  </div>
                </div>
                <div className="flex justify-center gap-7 mb-5">
                  <div className="w-full border-[1.5px] rounded-[8px] p-3 border-transactionCard">
                    <h1 className="text-[13px] font-PoppinsMedium text-disabledtext">
                      Amount Invested
                    </h1>
                    <h1 className="text-[13px] dark:text-white font-PoppinsMedium">
                      {formatCurrency(selectedInvestment.amount)}
                    </h1>
                  </div>
                  <div className="w-full border-[1.5px] rounded-[8px] p-3 border-transactionCard">
                    <h1 className="text-[13px] font-PoppinsMedium text-disabledtext">
                      ROI
                    </h1>
                    <h1 className="text-[13px] dark:text-white font-PoppinsMedium">
                      {formatCurrency(selectedInvestment.roiExpected)}
                    </h1>
                  </div>
                </div>
                <div className="flex justify-center gap-7 mb-5">
                  <div className="w-full border-[1.5px] rounded-[8px] p-3 border-transactionCard">
                    <h1 className="text-[13px] font-PoppinsMedium text-disabledtext">
                      Amount Expected at Maturity
                    </h1>
                    <h1 className="text-[13px] dark:text-white font-PoppinsMedium">
                      {formatCurrency(selectedInvestment.totalPayable)}
                    </h1>
                  </div>
                  <div className="w-full border-[1.5px] rounded-[8px] p-3 border-transactionCard">
                    <h1 className="text-[13px] font-PoppinsMedium text-disabledtext">
                      Interest rate
                    </h1>
                    <h1 className="text-[13px] dark:text-white font-PoppinsMedium">
                      {`(${selectedInvestment.investment_details.rate}%)`}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        )}
      {breakInvestmentModal && (
        <Modal
          isOpenModal={() => setBreakInvestmentModal(true)}
          closeModal={() => setBreakInvestmentModal(false)}
        >
          <div className="px-4 pt-[120px]">
            <div className="pb-[60px]">
              <h1 className="text-[20px] font-PoppinsBold">Transaction Pin</h1>
              <h1 className="font-PoppinsLight pt-3 text-[14px]">
                Enter Transaction pin to Break Investment
              </h1>
              <div className="mt-4 p-2 rounded-[8px] text-white bg-error">
                <h1 className="font-PoppinsMedium text-[14px] mb-1 flex items-center gap-2">
                  Warning <TiWarningOutline />
                </h1>
                <p className="font-PoppinsLight text-[13px]">
                  If you choose to break your current investment plan, a fee of
                  2.5% will be charged on the total amount used in your
                  investment purchase and ROI will not be paid.
                </p>
              </div>
            </div>
            <div>
              <Formik
                initialValues={{
                  pin: "",
                }}
                validationSchema={breakInvestmentSchema}
                onSubmit={handleBreakInvestment}
              >
                {({ isValid, dirty }) => (
                  <Form>
                    <PasswordInput
                      name="pin"
                      type="password"
                      placeholder="4-digit pin"
                      label="Enter Transaction Pin"
                      updatePass="top-[35px] right-[13px]"
                    />
                    <AuthButton isLoading={pin} disabled={!isValid || !dirty}>
                      Proceed
                    </AuthButton>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Modal>
      )}

      {investNowModal && selectedInvestment && (
        <Modal
          isOpenModal={openInvestNowModal}
          closeModal={closeInvestNowModal}
        >
          <div className="mt-[120px] px-4">
            <h1 className="text-primary text-[25px] font-PoppinsSemiBold">
              Invest Now
            </h1>
            <div className="bg-investment rounded-[8px] mt-4 p-4 text-white">
              <h1 className="text-[18px] font-PoppinsMedium">Information</h1>
              <h1 className="font-PoppinsRegular text-[14px]">
                Fill the form below to purchase units from this investment.
              </h1>
            </div>
            <h1 className="mt-[60px] mb-[20px] font-PoppinsMedium text-[14px] text-textcolor">
              Number of units to purchase @ &#8358;
              {selectedInvestment.amount} per unit in{" "}
              <span className="uppercase">
                {selectedInvestment.investment_details.title}
              </span>
            </h1>
            <Formik
              initialValues={{
                units: "",
                fundingSource: "",
                answer: "",
              }}
              onSubmit={handleBookInvestment}
              validationSchema={bookInvestmentSchema}
              enableReinitialize={false}
            >
              {({ values, isValid, dirty }) => (
                <Form>
                  <SelectInputWithLabel
                    name="units"
                    options={units}
                    label={false}
                  />
                  <SelectInputWithLabelAndPlaceholder
                    name="fundingSource"
                    options={wallet}
                    placeholder="Select a funding source"
                    label="Choose a Source of Funds"
                  />
                  {values.units && values.fundingSource && (
                    <TextInputWithLabel
                      name="answer"
                      type="text"
                      label="Answer for the security question set by you"
                    />
                  )}
                  <ConfigProvider
                    theme={{
                      token: {
                        colorPrimary: "#6B006B",
                      },
                    }}
                  >
                    {values.answer !== "" && (
                      <div>
                        <div className="flex items-center space-x-4 justify-between mb-4">
                          <Switch
                            checked={switch1}
                            onChange={() => setSwitch1((prev) => !prev)}
                          />
                          <span className="text-primary text-[13px] font-PoppinsMedium dark:text-white">
                            {`I confirm my purchase of ${values.units} ${
                              values.units === "1" ? "unit" : "units"
                            } of this investment for ${formatCurrency(
                              Number(values.units) * selectedInvestment.amount
                            )}. I agree to retain my units until ${formatDate(
                              selectedInvestment.investment_details.endDate
                            )} before cashing out.`}
                          </span>
                        </div>
                        <div className="flex items-center justify-between space-x-4 mb-4">
                          <Switch
                            checked={switch2}
                            onChange={() => setSwitch2((prev) => !prev)}
                          />
                          <span className="text-primary text-[13px] font-PoppinsMedium dark:text-white">
                            I acknowledge that I have read and comprehended the
                            investment information. I recognize that investing
                            in third-party instruments carries risks, which may,
                            in rare instances, involve loss of capital and lack
                            of liquidity.
                          </span>
                        </div>
                      </div>
                    )}
                  </ConfigProvider>
                  <div>
                    <InvestmentBtn
                      disabled={!isValid || !dirty || !switch1 || !switch2}
                      isLoading={isBooking}
                    >
                      INVEST NOW
                    </InvestmentBtn>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal>
      )}
      {showEnterTransactionPin && (
        <Modal
          isOpenModal={() => setShowTransactionPin(true)}
          closeModal={() => setShowTransactionPin(false)}
        >
          <div className="px-4 pt-[120px]">
            <div className="pb-[60px]">
              <h1 className="text-[20px] font-PoppinsBold">Transaction Pin</h1>
              <h1 className="font-PoppinsLight pt-3 text-[14px]">
                Enter Transaction pin to claim profit from investment
              </h1>
            </div>
            <div>
              <Formik
                initialValues={{
                  pin: "",
                }}
                validationSchema={setPinSchema}
                onSubmit={handleClaimProfit}
              >
                {({ isValid, dirty }) => (
                  <Form>
                    <PasswordInput
                      name="pin"
                      type="password"
                      placeholder="4-digit pin"
                      label="Enter Transaction Pin"
                      updatePass="top-[35px] right-[13px]"
                    />
                    <AuthButton isLoading={pin} disabled={!isValid || !dirty}>
                      CLAIM
                    </AuthButton>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Modal>
      )}
      {aboutModal && selectedInvestment && (
        <Modal
          isOpenModal={() => setAboutModal(true)}
          closeModal={() => setAboutModal(false)}
        >
          <div className="px-4 pt-[120px]">
            <div>
              <h1 className="text-primary dark:text-white text-[25px] font-PoppinsSemiBold">
                About Investment
              </h1>
              <div className="py-2">
                <p className="text-[13px] font-PoppinsRegular text-disabledtext dark:text-disabled">
                  Read about this investment below
                </p>
                {/* {selectedInvestment.disclaimer && (
                  <div className="mt-4">
                    <h1 className="font-PoppinsMedium text-[14px] mb-1 flex items-center gap-2">
                      Disclaimer <TiWarningOutline className="text-error" />
                    </h1>
                    <p className="font-PoppinsLight text-[13px]">
                      {selectedInvestment?.disclaimer}
                    </p>
                  </div>
                )} */}
              </div>
            </div>
            <div>
              <div
                className="flex items-center my-7 justify-center"
                style={{
                  backgroundImage: `url(${
                    selectedInvestment.investment_details.previewImage
                      ? `${process.env.REACT_APP_FILES_URL}/${selectedInvestment.investment_details.previewImage}`
                      : selectedInvestment.investment_details.previewImage ===
                        ""
                      ? Default
                      : Default
                  })`,
                  height: "250px",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <div className="bg-white opacity-50 inline-block p-4 rounded-[8px]">
                  <img src={Logo} className="w-[30px]" alt="" />
                </div>
              </div>
              <p className="dark:text-white text-textcolor text-[13px]">
                {selectedInvestment?.investment_details.description}
              </p>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default MyInvestmentMobile;
