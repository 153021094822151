import React, { useCallback, useEffect, useState } from "react";
import PageTitle from "../../../components/Pagetitle";
import Sidebar from "../../sidebar/Sidebar";
import { Table, Menu, Dropdown } from "antd";
import { ColumnsType } from "antd/es/table";
import { ScaleLoader } from "react-spinners";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { HttpClient } from "../../../api/HttpClient";
import ToastComponent from "../../../components/ToastComponent";
import errorHandler from "../../ErrorHandler";
import { format } from "date-fns";
import { formatCurrency } from "../../../components/CurrencyFormat";
import Modal from "../../Modal";
import { Field, Form, Formik } from "formik";
import TextInput from "../../../components/TextInput";
import SelectInput from "../../../components/SelectInput";
import PasswordInput from "../../../components/PasswordInput";
import * as Yup from "yup";
import { DownOutlined } from "@ant-design/icons";
import { HiOutlineXMark } from "react-icons/hi2";
import { IoCheckmark } from "react-icons/io5";

type WithdrawalLog = {
  _id: string;
  key: string;
  firstName: string;
  lastName: string;
  email: string;
  walletDetails: {
    balance: number;
    profit: number;
    referral: number;
  };
  transaction: string;
  settlementAccountDetails: {
    bankName: string;
    accountName: string;
    accountNumber: string;
  };
  amount: number;
  paymentStatus: string;
  createdAt: string;
  updatedAt: string;
};

type BankDetails = {
  bankName: string;
  accountName: string;
  accountNumber: string;
};

function WithdrawalReq() {
  const [loading, setLoading] = useState(true);
  const [withdrawalReq, setWithdrawalReq] = useState<WithdrawalLog[]>([]);
  const [isWithdrawModalVisApp, setIsWithdrawModalVisApp] = useState(false);
  const [selectedUser, setSelectedUser] = useState<BankDetails | null>(null);
  const [customerId, setCustomerId] = useState("");
  const [action, setAction] = useState("approve");

  const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);

  const creditDebitSchema = Yup.object().shape({
    action: Yup.string().required("Approval Action is required"),
    otp: Yup.string().required("OTP is required"),
    id: Yup.string().required("Withdrawal ID is required"),
    pin: Yup.string().required("Your pin is required"),
    comment: Yup.string().optional(),
  });

  const fetchWithdrawalReq = useCallback(async () => {
    setLoading(true);
    try {
      const withLogRes = await HttpClient.get(
        "/transactions/withdrawal-request",
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );

      const data = withLogRes.data?.data?.data;
      const userIds = data?.map((item: any) => item._id);

      setCustomerId(userIds);

      const withdrawalLog = withLogRes.data?.data?.data.map(
        (item: any, index: number) => ({
          key: item._id,
          serialNumber: index + 1,
          name: item?.firstName + " " + item?.lastName,
          email: item?.email,
          amount: item?.amount,
          balance: item?.walletDetails?.balance,
          profit: item?.walletDetails?.profit,
          referral: item?.walletDetails?.referral,
          walletDetails: item?.walletDetails,
          fundingSource: item?.fundingSource,
          createdAt: item?.createdAt,
          paymentStatus: item?.paymentStatus,
          settlementAccountDetails: item?.settlementAccountDetails,
          transaction: item?.transaction,
        })
      );
      if (withLogRes.status === 200) {
        setWithdrawalReq(withdrawalLog);
      } else {
        ToastComponent.error(withLogRes.data?.message);
      }
    } catch (error: any) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchWithdrawalReq();
  }, [fetchWithdrawalReq]);

  const fetchAdminsAndGenerateOTP = async () => {
    try {
      const adminEmail = localStorage.getItem("adminEmail");
      const adminRole = localStorage.getItem("adminRole");

      if (!adminRole || adminRole !== "admin") {
        throw new Error("Only admins can generate OTPs.");
      }

      if (!adminEmail) {
        throw new Error("Admin email is missing.");
      }

      const otpResponse = await HttpClient.post("/users/generate-token", {
        email: adminEmail,
      });

      ToastComponent.success(
        otpResponse.data.message || "OTP sent successfully!"
      );
    } catch (error: any) {
      errorHandler(error);
    }
  };

  const handleSubmit = async (
    values: any,
    {
      setFieldValue,
    }: {
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean
      ) => void;
    }
  ) => {
    try {
      const formData = new FormData();
      formData.append("action", values.action);
      formData.append("otp", values.otp);
      formData.append("id", values.id);
      formData.append("pin", values.pin);

      const creditDebitRes = await HttpClient.post(
        "/admin/approve-or-decline-withdrawal-request",
        formData,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      if (creditDebitRes) {
        ToastComponent.success(
          creditDebitRes.data.message || "Operation Successful"
        );
        setIsWithdrawModalVisApp(false);
        fetchWithdrawalReq();
      } else {
        ToastComponent.error("Operation Failed");
      }
    } catch (error: any) {
      errorHandler(error);
    }
  };

  const closeWithdrawModal = () => {
    setIsWithdrawModalVisApp(false);
  };

  const menu = (record: WithdrawalLog) => {
    return (
      <Menu className="w-[200px]">
        <Menu.Item
          onClick={() => {
            setSelectedUser(record.settlementAccountDetails);
            setCustomerId(record.key);
            setIsWithdrawModalVisApp(true);
          }}
          className="relative group"
        >
          <div className="flex gap-2 items-center text-success">
            <div className="rounded-full border p-0.2 flex items-center justify-center">
              <IoCheckmark size={15} className="text-success" />
            </div>
            <span className="text-[16px] font-PoppinsRegular">Authorize</span>
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  const columns: ColumnsType<WithdrawalLog> = [
    {
      title: "S/N",
      dataIndex: "serialNumber",
      key: "serialNumber",
      render: (text: string, record: WithdrawalLog) => (
        <div className="flex items-center gap-6">
          <span>{text}</span>
          <Dropdown overlay={menu(record)} trigger={["click"]}>
            <a
              onClick={(e) => e.preventDefault()}
              className="hover:text-primary border rounded-full text-xxs p-0.2"
            >
              <DownOutlined />
            </a>
          </Dropdown>
        </div>
      ),
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (dateTime: string) => {
        const date = new Date(dateTime);
        const formattedDate = format(date, "MMM dd, yyyy");
        const formattedTime = format(date, "hh:mm a");

        return (
          <div className="text-textcolor" style={{ width: "100px" }}>
            <div className="font-PoppinsRegular">{formattedDate}</div>
            <div className="font-PoppinsLight">{formattedTime}</div>
          </div>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name: string) => {
        return <div style={{ width: "150px" }}>{name}</div>;
      },
    },
    {
      title: "Phone No.",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount: number) => formatCurrency(amount),
    },
    {
      title: "Funding Source",
      dataIndex: "fundingSource",
      key: "fundingSource",
      render: (fundingSource: string) => {
        return (
          <div className="capitalize" style={{ width: "120px" }}>
            {fundingSource}
          </div>
        );
      },
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      render: (balance: number) => formatCurrency(balance),
    },
    {
      title: "Profits",
      dataIndex: "profit",
      key: "profit",
      render: (profit: number) => formatCurrency(profit),
    },
    {
      title: "Referrals",
      dataIndex: "referral",
      key: "referral",
      render: (referral: number) => formatCurrency(referral),
    },
    {
      key: "paymentStatus",
      title: "Status",
      dataIndex: "paymentStatus",
      render: (status: string) => {
        const baseClass =
          "text-[0.8em] capitalize rounded-[15px] font-PoppinsMedium px-[0.9rem] py-[0.35rem] bg-opacity-[28%] text-center inline-block";
        const statusClass =
          status === "successful"
            ? `bg-success text-success ${baseClass}`
            : status === "declined"
            ? `bg-error text-error ${baseClass}`
            : `bg-secondary text-secondary ${baseClass}`;

        return <div className={statusClass}>{status}</div>;
      },
    },
  ];

  const pagination = {
    pageSize: 5,
  };

  return (
    <>
      <PageTitle title="Admin: Withdrawal Requests" />
      <div className="flex h-screen">
        <Sidebar />
        <div className="flex-1 overflow-y-auto dark:bg-darkPrimary">
          {loading ? (
            <div className="w-full h-screen bg-white dark:bg-darkPrimary flex items-center justify-center">
              <ScaleLoader color={isDarkMode ? "#FFFFFF" : "#6B006B"} />
            </div>
          ) : (
            <div className="flex-1 mx-4 overflow-x-hidden">
              <div className="p-4 border border-transactionCard shadow-md rounded-md mt-28">
                <span className="text-semibold text-textcolor font-PoppinsBold block p-4">
                  Withdrawal Requests
                </span>
                <div className="overflow-x-auto">
                  <Table
                    columns={columns}
                    dataSource={withdrawalReq}
                    pagination={pagination}
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        {isWithdrawModalVisApp && (
          <Modal
            onClose={closeWithdrawModal}
            className="w-full sm:w-[500px] md:w-[700px] lg:w-[800px] p-4"
          >
            <h2 className="text-textcolor text-center font-PoppinsRegular mb-4">
              Withdrawal Request
            </h2>
            <div className="flex justify-between mb-4 text-primary lg:items-center lg:justify-center lg:gap-2">
              {selectedUser && (
                <>
                  <div className="text-[10px] lg:text-[1.2em] rounded-[15px] border border-primary px-[0.9rem] py-[0.35rem] text-center inline-block">
                    <p>{selectedUser?.bankName}</p>
                  </div>
                  <div className="text-[10px] lg:text-[1.2em] rounded-[15px] border border-primary px-[0.9rem] py-[0.35rem] text-center inline-block">
                    <p>{selectedUser?.accountName}</p>
                  </div>
                  <div className="text-[10px] lg:text-[1.2em] rounded-[15px] border border-primary px-[0.9rem] py-[0.35rem] text-center inline-block">
                    <p>{selectedUser?.accountNumber}</p>
                  </div>
                </>
              )}
            </div>
            <Formik
              initialValues={{
                action: "approve",
                otp: "",
                id: customerId,
                pin: "",
                comment: "",
              }}
              validationSchema={creditDebitSchema}
              onSubmit={handleSubmit}
            >
              {({ setFieldValue, values }) => {
                const { action } = values;

                const handleActionChange = (value: string) => {
                  setFieldValue("action", value);
                };

                return (
                  <Form className="w-full">
                    <div className="mb-6">
                      <label
                        htmlFor="action"
                        className="dark:text-white block text-primary font-PoppinsLight text-[14px] mb-1"
                      >
                        Action
                      </label>
                      <SelectInput
                        name="action"
                        options={[
                          { value: "approve", label: "Approve" },
                          { value: "decline", label: "Decline" },
                        ]}
                        placeholder="Select Action"
                        onChange={(e: any) =>
                          handleActionChange(e.target.value)
                        }
                        value={action}
                      />
                    </div>

                    <div className="mb-6">
                      <Field type="hidden" name="id" value={customerId} />
                    </div>

                    <div className="mb-4">
                      <label
                        htmlFor="accountPin"
                        className="dark:text-white block text-primary font-PoppinsLight text-[14px] mb-1"
                      >
                        Transaction Pin
                      </label>
                      <PasswordInput
                        type="password"
                        name="pin"
                        placeholder="****"
                        className="w-full h-12 border border-transactionCard text-textcolor font-PoppinsRegular px-2 py-1 rounded-lg focus:outline-none focus:border-primary"
                      />
                    </div>

                    {action === "decline" && (
                      <div className="mb-6">
                        <label
                          htmlFor="comment"
                          className="dark:text-white block text-primary font-PoppinsLight text-[14px] mb-1"
                        >
                          Comment
                        </label>
                        <TextInput
                          type="text"
                          name="comment"
                          placeholder="Enter comment"
                          className="dark:text-white w-full h-12 border border-transactionCard text-textcolor font-PoppinsRegular px-2 py-1 rounded-lg focus:outline-none focus:border-primary"
                        />
                      </div>
                    )}

                    <div className="mb-6">
                      <label
                        htmlFor="otp"
                        className="dark:text-white block text-primary font-PoppinsLight text-[14px] mb-1"
                      >
                        Enter OTP
                      </label>
                      <TextInput
                        type="text"
                        name="otp"
                        placeholder="1W2E3RR"
                        className="dark:text-white w-full h-12 border border-transactionCard text-textcolor font-PoppinsRegular px-2 py-1 rounded-lg focus:outline-none focus:border-primary"
                      />

                      <button
                        onClick={fetchAdminsAndGenerateOTP}
                        type="button"
                        className="dark:text-white mb-5 font-PoppinsLight hover:text-primary text-textcolor text-[13px] underline"
                      >
                        Click to generate OTP
                      </button>
                    </div>

                    <button
                      type="submit"
                      className={`lg:justify-end py-2 px-6 font-PoppinsMedium rounded-lg ${
                        action === "approve"
                          ? "text-success border-success hover:text-white hover:before:bg-success"
                          : "text-error border-error hover:text-white hover:before:bg-error"
                      } relative overflow-hidden border bg-white transition-all before:absolute before:bottom-0 before:left-0 before:top-0 before:z-0 before:h-full before:w-0 before:transition-all before:duration-500 hover:before:left-0 hover:before:w-full md:w-40 lg:w-40 w-full`}
                    >
                      <span className="relative z-10">
                        {action === "approve" ? "Approve" : "Decline"}
                      </span>
                    </button>
                  </Form>
                );
              }}
            </Formik>
          </Modal>
        )}
      </div>
    </>
  );
}

export default WithdrawalReq;
