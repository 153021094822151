import React from "react";

type ModalProps = {
  children: React.ReactNode;
  onClose: () => void;
  className?: string;
};

const Modal = ({ children, onClose, className }: ModalProps) => {
  return (
    <div className="fixed inset-0 bg-[black] bg-opacity-[50%] flex justify-center items-center z-[1000]">
      <div className={`bg-white p-6 rounded-md shadow-lg relative ${className}`}>
        {children}
        <button
          className="absolute top-2 right-2 p-4 rounded-lg flex items-center justify-center text-primary"
          onClick={onClose}
        >
          X
        </button>
      </div>
    </div>
  );
};

export default Modal;
