"use client";
import { FC } from "react";
interface AuthButtonProps {
  isLoading?: boolean;
  disabled: boolean;
  isImageSelected?: boolean;
  children: any;
  type: string;
  className: string;
}
const CreateBtn: FC<AuthButtonProps> = ({ isLoading, isImageSelected, disabled, children }) => {
  const baseStyles =
    "py-2 px-6 rounded-lg font-PoppinsMedium text-[14px] flex justify-center items-center h-[50px] w-40";
  const enabledStyles = "font-PoppinsMedium rounded-lg text-success hover:before:bg-success border-success relative h-[50px] w-40 overflow-hidden border  bg-white px-3 transition-all before:absolute before:bottom-0 before:left-0 before:top-0 before:z-0 before:h-full before:w-0 before:bg-success before:transition-all before:duration-500 hover:text-white hover:before:left-0 hover:before:w-full";
  const disabledStyles =
    "bg-disabled text-disabledtext border border-authbordercolor cursor-not-allowed";
  const isDisabled = disabled || isLoading || !isImageSelected;

  return (
    <button
      type="submit"
      className={`${baseStyles} ${isDisabled ? disabledStyles : enabledStyles}`}
      disabled={isDisabled}
    >
      {children}
    </button>
  );
};


export default CreateBtn;
