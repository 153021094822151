import { useCallback, useEffect, useState } from "react";
import Sidebar from "../../sidebar/Sidebar";
import { ScaleLoader } from "react-spinners";
import { Table, Input } from "antd";
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import PageTitle from "../../../components/Pagetitle";
import { HttpClient } from "../../../api/HttpClient";
import dayjs from "dayjs";
import ErrorHandler from "../../ErrorHandler";
import { formatCurrency } from "../../../components/CurrencyFormat";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

interface WalletFundingData {
  _id?: string;
  key?: string;
  amount: number;
  transactionRefCode: string;
  transactionDate: string;
  responseMsg: string;
  paymentStatus: string;
  paymentGateway: string;
  paymentMethod: string;
  transactionType: string;
  isInFlow: string;
  user_details: {
    firstName: string;
    lastName: string;
    email: string;
  };
}

const WalletFunding = () => {
  const [loading, setLoading] = useState(false);
  const [walletFundLog, setWalletFundLog] = useState<WalletFundingData[]>([]);
  const [filteredData, setFilteredData] = useState<WalletFundingData[]>([]);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 5,
    total: 0,
  });
  const [filters, setFilters] = useState({
    name: "",
    email: "",
    paymentGateway: "",
  });

  const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);

  const fetchWalletFundData = useCallback(async () => {
    try {
      setLoading(true);
      const walletFundDataRes = await HttpClient.get("/transactions/history", {
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          page: "",
          limit: "",
          startdate: "",
          enddate: "",
          dir: "desc",
          search: "admin_funding",
          sort: "",
        },
      });

      const walletFundingLog = walletFundDataRes.data.data.data.map(
        (item: any, index: number) => ({
          key: item._id,
          serialNumber: index + 1,
          name:
            item?.user_details?.firstName +
              " " +
              item?.user_details?.lastName || "",
          email: item?.user_detail?.email || "",
          transactionDate: item?.transactionDate || "",
          paymentGateway: item?.paymentGateway || "",
          transactionId: item?.transactionId || "",
          amount: item?.amount || "",
          paymentStatus: item?.paymentStatus || "",
          responseMsg: item?.responseMsg || "",
          paymentMethod: item?.paymentMethod || "",
          transactionRefCode: item?.transactionRefCode || "",
          transactionType: item?.transactionType || "",
          isInFlow: item?.isInFlow || "",
          user_detail: item.user_detail,
        })
      );

      setWalletFundLog(walletFundingLog);
      setPagination((prev) => ({
        ...prev,
        total: walletFundDataRes.data.data.total,
      }));
      applyFilters(walletFundingLog);
    } catch (error: any) {
      ErrorHandler(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const applyFilters = (data: WalletFundingData[]) => {
    let filtered = data;

    if (filters.name) {
      filtered = filtered.filter(
        (item) =>
          item.user_details.firstName +
          " " +
          item.user_details.lastName
            .toLowerCase()
            .includes(filters.name.toLowerCase())
      );
    }
    if (filters.email) {
      filtered = filtered.filter((item) =>
        item.user_details.email
          .toLowerCase()
          .includes(filters.email.toLowerCase())
      );
    }
    if (filters.paymentGateway) {
      filtered = filtered.filter((item) =>
        item.paymentGateway
          .toLowerCase()
          .includes(filters.paymentGateway.toLowerCase())
      );
    }

    setFilteredData(filtered);
    setPagination((prev) => ({
      ...prev,
      total: filtered.length,
    }));
  };

  useEffect(() => {
    fetchWalletFundData();
  }, []);

  useEffect(() => {
    applyFilters(walletFundLog);
  }, [filters]);

  const handleFilterChange = (field: string, value: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
  };

  const columns: ColumnsType<WalletFundingData> = [
    {
      key: "serialNumber",
      title: "S/N",
      dataIndex: "serialNumber",
      className: "text-textcolor font-PoppinsRegular",
    },
    {
      key: "transactionDate",
      title: "Date",
      dataIndex: "transactionDate",
      render: (dateTime: string) => {
        const date = dayjs(dateTime);
        const formattedDate = date.format("MMM DD, YYYY");
        const formattedTime = date.format("hh:mm a");

        return (
          <div className="text-textcolor" style={{ width: "100px" }}>
            <div className="font-PoppinsRegular">{formattedDate}</div>
            <div className="font-PoppinsLight">{formattedTime}</div>
          </div>
        );
      },
    },
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
      render: (name: string) => (
        <div style={{ width: "100px" }} className="captalize">
          {name}
        </div>
      ),
      filterDropdown: () => (
        <Input
          placeholder="John Doe"
          value={filters.name}
          onChange={(e) => handleFilterChange("name", e.target.value)}
        />
      ),
      onFilterDropdownVisibleChange: () => applyFilters(walletFundLog),
    },
    {
      key: "transactionRefCode",
      title: "Transaction Ref.",
      dataIndex: "transactionRefCode",
    },
    {
      key: "amount",
      title: "Amount",
      dataIndex: "amount",
      render: (amount: number) => formatCurrency(amount),
    },
    {
      key: "isInFlow",
      title: "DR/CR",
      dataIndex: "isInFlow",
      render: (isInFlow: boolean) => (
        <span
          className={`font-PoppinsRegular ${
            isInFlow ? "text-success" : "text-error"
          }`}
        >
          {isInFlow ? "Credit" : "Debit"}
        </span>
      ),
    },
    {
      title: "Response Message",
      key: "responseMsg",
      dataIndex: "responseMsg",
      render: (responseMsg: string) => {
        return (
          <div style={{ width: "150px" }} className="capitalize">
            {responseMsg}
          </div>
        );
      },
    },
    {
      key: "paymentMethod",
      title: "Payment Method",
      dataIndex: "paymentMethod",
      className: "text-textcolor font-PoppinsRegular capitalize",
      render: (paymentMethod: string) => {
        return (
          <div style={{ width: "150px" }} className="capitalize">
            {paymentMethod.replace(/_/g, " ")}
          </div>
        );
      },
    },

    {
      key: "transactionType",
      title: "Payment Type",
      dataIndex: "transactionType",
      className: "text-textcolor font-PoppinsRegular capitalize",
    },
    {
      key: "paymentStatus",
      title: "Status",
      dataIndex: "paymentStatus",
      render: (paymentStatus: string) => {
        const transClass =
          "text-[0.8em] capitalize rounded-[15px] font-PoppinsMedium px-[0.9rem] py-[0.35rem] bg-opacity-[28%] text-center inline-block";
        const statusClass =
          paymentStatus === "successful"
            ? `bg-success text-success ${transClass}`
            : paymentStatus === "failed"
            ? `bg-error text-error ${transClass}`
            : `bg-secondary text-secondary ${transClass}`;

        return <div className={statusClass}>{paymentStatus}</div>;
      },
    },
  ];

  return (
    <>
      <PageTitle title="Affluence - Admin: Transaction Log" />
      <div className="flex h-screen dark:bg-darkPrimary">
        <Sidebar />
        <div className="flex-1 overflow-y-auto">
          {loading ? (
            <div className="w-full h-screen bg-white dark:bg-darkPrimary flex items-center justify-center">
              <ScaleLoader color={isDarkMode ? "#FFFFFF" : "#6B006B"} />
            </div>
          ) : (
            <div className="p-4 mt-28">
              <div className="flex-1 p-4 shadow-md border border-transactionCard rounded-lg">
                <span className="dark:text-white text-semibold text-textcolor font-PoppinsBold block mb-4">
                  Wallet Funding
                </span>
                <div className="dark:bg-white overflow-x-auto">
                  <Table
                    columns={columns}
                    dataSource={filteredData}
                    pagination={pagination}
                    onChange={(newPagination) => setPagination(newPagination)}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default WalletFunding;
