import { AiOutlineHome } from "react-icons/ai";
import { BiSupport } from "react-icons/bi";
import { FaPowerOff } from "react-icons/fa6";
import { IoBriefcaseOutline, IoClose } from "react-icons/io5";
import { LuUser2 } from "react-icons/lu";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../assets/img/affluence-logo.png";
import DarkLogo from "../assets/img/logo1.png";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import { useState } from "react";
import { HttpClient } from "../api/HttpClient";
import { isAxiosError } from "axios";
import ToastComponent from "./ToastComponent";
import { ScaleLoader } from "react-spinners";
import { setIsAuthenticated } from "../store/authSlice";
const MobileNav = ({ openNav, closeNav }: any) => {
  const navigate = useNavigate();
  const router = useLocation();
  const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
  const [isLogingOut, setIsLogingOut] = useState(false);

  const dispatch = useDispatch();
  const handleLogout = async () => {
    setIsLogingOut(true);
    try {
      await HttpClient.post("/users/logout");
      localStorage.removeItem("token");
      dispatch(setIsAuthenticated(false));
      navigate("/login");
    } catch (error) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      }
    } finally {
      setIsLogingOut(false);
    }
  };
  if (!openNav) {
    return null;
  }

  return (
    <>
      {isLogingOut ? (
        <div className="w-full h-screen bg-white dark:bg-darkPrimary flex items-center justify-center relative">
          <div className="flex flex-col justify-center items-center">
            <ScaleLoader color={isDarkMode ? "#FFFFFF" : "#6B006B"} />
            <h1 className="dark:text-white text-textcolor mt-7">
              Log Out Processing...
            </h1>
          </div>
        </div>
      ) : (
        <div className="fixed inset-0 bg-[black] bg-opacity-[50%] z-50 md:hidden block">
          <div className="bg-white dark:bg-darkPrimary h-screen w-full max-w-[370px] relative py-[60px] px-7 flex flex-col justify-between">
            <div>
              <div className="flex items-center justify-between">
                <img
                  src={isDarkMode ? DarkLogo : Logo}
                  className="w-[170px]"
                  alt="affluence-logo"
                />
                <button
                  onClick={closeNav}
                  className="focus:outline-none text-textcolor dark:text-white"
                >
                  <IoClose size={30} />
                </button>
              </div>
              <div className="pt-[3rem]">
                <Link
                  to="/"
                  onClick={closeNav}
                  className={`flex items-center gap-3 mb-7 text-[1.15em] font-PoppinsRegular py-3 px-4 rounded-[8px]  ${
                    router.pathname === "/"
                      ? "bg-primary text-white"
                      : "text-textcolor dark:text-white"
                  }`}
                >
                  <AiOutlineHome size={24} />
                  Home
                </Link>
                <Link
                  to="/wallets"
                  onClick={closeNav}
                  className={`flex items-center gap-3 mb-7 text-[1.15em] font-PoppinsRegular py-3 px-4 rounded-[8px]  ${
                    router.pathname.startsWith("/wallets")
                      ? "bg-primary text-white"
                      : "text-textcolor dark:text-white"
                  }`}
                >
                  <MdOutlineAccountBalanceWallet size={24} />
                  Wallets
                </Link>
                <Link
                  onClick={closeNav}
                  to="investments"
                  className={`flex items-center gap-3 mb-7 text-[1.15em] font-PoppinsRegular py-3 px-4 rounded-[8px]  ${
                    router.pathname.startsWith("/investments")
                      ? "bg-primary text-white"
                      : "text-textcolor dark:text-white"
                  }`}
                >
                  <IoBriefcaseOutline size={24} />
                  Investments
                </Link>
                <Link
                  to="/my_account"
                  onClick={closeNav}
                  className={`flex items-center gap-3 mb-7 text-[1.15em] font-PoppinsRegular py-3 px-4 rounded-[8px]  ${
                    router.pathname === "/my_account"
                      ? "bg-primary text-white"
                      : "text-textcolor dark:text-white"
                  }`}
                >
                  <LuUser2 size={24} />
                  Account
                </Link>
                <Link
                  onClick={closeNav}
                  to="support"
                  className={`flex items-center gap-3 mb-7 text-[1.15em] font-PoppinsRegular py-3 px-4 rounded-[8px]  ${
                    router.pathname === "/support"
                      ? "bg-primary text-white"
                      : "text-textcolor dark:text-white"
                  }`}
                >
                  <BiSupport size={24} />
                  Support
                </Link>
              </div>
            </div>
            <button
              onClick={handleLogout}
              className="flex items-center text-error gap-3 text-[1.15em] font-PoppinsRegular py-3 px-4 rounded-[8px]"
            >
              <FaPowerOff size={24} />
              Logout
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default MobileNav;
