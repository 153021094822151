import { useCallback, useEffect, useMemo, useState } from "react";
import SelectInputWithLabelAndPlaceholder from "../../../../components/SelectInputWithLabelAndPlaceholder";
import InvestmentBtn from "../../../../components/InvestmentBtn";
import SelectInputWithLabel from "../../../../components/SelectInputWithLabel";
import { Form, Formik } from "formik";
import Modal from "../../../../components/Modal";
import Logo from "../../../../assets/img/affluence-logo-small.png";
import { Account, InvestmentItem } from "../../../../assets/Typeprops";
import { formatCurrency } from "../../../../components/CurrencyFormat";
import { HttpClient } from "../../../../api/HttpClient";
import { useNavigate } from "react-router-dom";
import ToastComponent from "../../../../components/ToastComponent";
import { isAxiosError } from "axios";
import { ScaleLoader } from "react-spinners";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import ReactPaginate from "react-paginate";
import { IoIosArrowForward } from "react-icons/io";
import Default from "../../../../assets/img/default-img.webp";
import * as Yup from "yup";
import { ConfigProvider, Switch } from "antd";
import TextInputWithLabel from "../../../../components/TextInputWithLabel";
import { TiWarningOutline } from "react-icons/ti";
import PasswordInput from "../../../../components/PasswordInput";
const ActiveInvestmentMobile = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedInvestment, setSelectedInvestment] =
    useState<InvestmentItem | null>(null);
  const [investNowModal, setInvestNowModal] = useState(false);
  const [isBooking, setIsBooking] = useState(false);
  const [viewMore, setViewMore] = useState(false);
  const navigate = useNavigate();
  const [currentModalPage, setCurrentModalPage] = useState(0);
  const [viewMoreIsLoading, setViewMoreIsLoading] = useState(false);
  const [modalInvestments, setModalInvestments] = useState<InvestmentItem[]>(
    []
  );
  const [investmentData, setInvestmentData] = useState<InvestmentItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [switch1, setSwitch1] = useState(false);
  const [switch2, setSwitch2] = useState(false);
  const [accountData, setAccountData] = useState<Account | null>(null);
  const [totalModalPages, setTotalModalPages] = useState(0);
  const [aboutModal, setAboutModal] = useState(false);
  const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
  const openShowInvestmentModal = (investment: InvestmentItem) => {
    setSelectedInvestment(investment);
    setIsModalOpen(true);
  };

  const closeShowInvestmentModal = () => {
    setIsModalOpen(false);
    setSelectedInvestment(null);
  };

  const openInvestNowModal = (investment: InvestmentItem) => {
    setSelectedInvestment(investment);
    setInvestNowModal(true);
  };
  const closeInvestNowModal = () => {
    setInvestNowModal(false);
  };
  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const [userRes, investmentRes] = await Promise.all([
        HttpClient.get("/users/me", {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }),
        HttpClient.get("/regular-investments/plan", {
          params: {
            page: 1,
            limit: 10,
            dir: "desc",
            sort: "createdAt",
            search: "running",
          },
          headers: { "x-auth-token": localStorage.getItem("token") },
        }),
      ]);
      setInvestmentData(investmentRes?.data?.data?.data);
      setAccountData(userRes?.data?.data);
    } catch (error) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      }
    } finally {
      setIsLoading(false);
    }
  }, []);
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  const handleBookInvestment = async (values: any) => {
    setIsBooking(true);
    try {
      const bookInvestRes = await HttpClient.post(
        "/flexible-investments/booking",
        { ...values, id: selectedInvestment?._id },
        {
          headers: { "x-auth-token": localStorage.getItem("token") },
        }
      );
      navigate("/investments/flexible-investments/my_investments");
      setInvestNowModal(false);
      setIsModalOpen(false);
      ToastComponent.success(bookInvestRes.data.message);
    } catch (error) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      }
    } finally {
      setIsBooking(false);
    }
  };
  const bookInvestmentSchema = Yup.object().shape({
    amount: Yup.number()
      .required("Please type an amount")
      .min(10000, "Please select an amount not less than ₦10,000")
      .test(
        "is-multiple-of-10000",
        "Amount must be a multiple of ₦10,000",
        (value) => {
          return value % 10000 === 0;
        }
      ),
    fundingSource: Yup.string().required("Please select a funding source"),
    answer: Yup.string().required("Please enter security answer"),
  });
  const wallet = [
    {
      value: "balance",
      label: `Balance (${
        accountData?.account?.balance
          ? formatCurrency(accountData.account.balance)
          : "₦0"
      })`,
    },
    {
      value: "profit",
      label: `Profits (${
        accountData?.account?.profit
          ? formatCurrency(accountData.account.profit)
          : "₦0"
      })`,
    },
    {
      value: "referral",
      label: `Referrals (${
        accountData?.account?.referral
          ? formatCurrency(accountData.account.referral)
          : "₦0"
      })`,
    },
  ];
  const fetchAllModalInvestment = useCallback(async () => {
    setViewMoreIsLoading(true);
    const Page = Math.min(currentModalPage + 1, totalModalPages) || 1;
    const Limit = 10;
    try {
      const investmentRes = await HttpClient.get("/regular-investments/plan", {
        params: {
          page: Page,
          limit: Limit,
          dir: "desc",
          sort: "createdAt",
          search: "running",
        },
        headers: { "x-auth-token": localStorage.getItem("token") },
      });
      setModalInvestments(investmentRes?.data?.data?.data);
      const numOfTransactions = investmentRes?.data?.data.total;
      const totalNumOfPages = Math.ceil(numOfTransactions / Limit);
      setTotalModalPages(totalNumOfPages);
    } catch (error) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      }
    } finally {
      setViewMoreIsLoading(false);
    }
  }, [currentModalPage, totalModalPages]);
  useEffect(() => {
    if (viewMore) {
      fetchAllModalInvestment();
    }
  }, [fetchAllModalInvestment, viewMore]);
  const handlePageChange = ({ selected }: any) => {
    setCurrentModalPage(selected);
  };

  return (
    <>
      {isLoading ? (
        <div className="w-full h-screen bg-white dark:bg-darkPrimary flex items-center justify-center">
          <ScaleLoader color={isDarkMode ? "#FFFFFF" : "#6B006B"} />
        </div>
      ) : (
        <div>
          {investmentData && (
            <div className="flex justify-end pt-3 mr-[3%]">
              <h1
                onClick={() => setViewMore(true)}
                className="font-PoppinsRegular flex items-center space-x-1 cursor-pointer text-[11px] dark:text-white text-primary"
              >
                View more
                <IoIosArrowForward />
              </h1>
            </div>
          )}
          <div className="flex gap-3 pl-[3%] overflow-x-scroll hide-scroll">
            {investmentData && investmentData.length > 0 ? (
              investmentData?.map((item: any, index: any) => (
                <div
                  key={index}
                  className="inline-block cursor-pointer w-[150px]"
                  onClick={() => openShowInvestmentModal(item)}
                >
                  <div className="w-[150px] relative">
                    <img
                      src={
                        item.previewImage
                          ? `${process.env.REACT_APP_FILES_URL}/${item.previewImage}`
                          : item.previewImage === ""
                          ? Default
                          : Default
                      }
                      className="w-[150px] bg-cover rounded-[10px]"
                      alt="investment-img"
                    />
                    <div className="text-white bg-referral rounded-[20px] absolute top-2 right-2 font-PoppinsMedium text-[8px] justify-center items-center inline-flex py-[0.7px] px-2">
                      {item.status === "running" && "RUNNING"}
                    </div>
                  </div>
                  <div className="pt-2">
                    <h1 className="text-textcolor dark:text-white  font-PoppinsRegular text-[10px]">
                      NUMBER OF INVESTORS:{" "}
                      <span className="font-PoppinsSemiBold">
                        {item.numberOfInvestors}
                      </span>
                    </h1>
                    <h1 className="text-textcolor dark:text-white  uppercase font-PoppinsSemiBold text-[12px] whitespace-nowrap overflow-hidden text-ellipsis w-[150px]">
                      {item.title}
                    </h1>
                    <h1 className="font-PoppinsRegular text-[11px] dark:text-white  text-textcolor">
                      Interest rate at{" "}
                      <span className="font-PoppinsSemiBold text-success">{`${item.rate}%`}</span>
                    </h1>
                    <h1 className="font-PoppinsRegular text-[11px] dark:text-white  text-textcolor">
                      Returns
                    </h1>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center flex items-center justify-center h-[60vh]">
                <div>
                  <h1 className="font-PoppinsBold text-center pb-3 sm:text-[24px] xs:text-[22px] text-[20px] text-primary">
                    There are no running investments currently!
                  </h1>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {isModalOpen && selectedInvestment && (
        <Modal
          isOpenModal={openShowInvestmentModal}
          closeModal={closeShowInvestmentModal}
        >
          <div
            className="flex items-center mt-[90px] justify-center"
            style={{
              backgroundImage: `url(${
                selectedInvestment.previewImage
                  ? `${process.env.REACT_APP_FILES_URL}/${selectedInvestment.previewImage}`
                  : selectedInvestment.previewImage === ""
                  ? Default
                  : Default
              })`,
              height: "250px",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div className="bg-white inline-block p-4 rounded-[8px]">
              <img src={Logo} className="w-[30px]" alt="" />
            </div>
          </div>
          <div className="px-4">
            <div className="bg-success mt-6 mb-8 py-1 px-4 inline-block text-[12px] font-PoppinsLight rounded-[10px] bg-opacity-[28%] text-success">
              VERIFIED INVESTMENT
            </div>
            <div className="flex justify-between">
              <h1 className="font-PoppinsBold uppercase w-[80%] text-[20px]">
                {selectedInvestment.title}
              </h1>
            </div>
            <div className="flex flex-col gap-4 mt-5">
              <button className="bg-referral cursor-not-allowed border-[1.5px] border-referral bg-opacity-10 text-referral font-PoppinsSemiBold w-full py-4 rounded-[8px]">
                RUNNING
              </button>
              <button
                onClick={() => setAboutModal(true)}
                className="border-[1.5px] border-investment text-investment font-PoppinsSemiBold py-4 w-full rounded-[8px]"
              >
                ABOUT THIS INVESTMENT
              </button>
            </div>
            <div className="text-[14px] font-PoppinsRegular text-center mt-3 text-referral">
              This investment plan is already running for all Investors.
            </div>
            <hr className="text-authbordercolor my-10" />
            <div>
              <div className="flex justify-center gap-7 mb-5">
                <div className="w-full border-[1.5px] rounded-[8px] p-3 border-transactionCard">
                  <h1 className="text-[13px] font-PoppinsMedium text-disabledtext">
                    Investment Type
                  </h1>
                  <h1 className="text-[13px] font-PoppinsMedium">
                    {selectedInvestment.category}
                  </h1>
                </div>
                <div className="w-full border-[1.5px] rounded-[8px] p-3 border-transactionCard">
                  <h1 className="text-[13px] font-PoppinsMedium text-disabledtext">
                    Payout Type
                  </h1>
                  <h1 className="text-[13px] font-PoppinsMedium">
                    Capital + profit to be paid
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {aboutModal && (
        <Modal
          isOpenModal={() => setAboutModal(true)}
          closeModal={() => setAboutModal(false)}
        >
          <div className="px-4 pt-[120px]">
            <div>
              <h1 className="text-primary dark:text-white text-[25px] font-PoppinsSemiBold">
                About Investment
              </h1>
              <div className="py-2">
                <p className="text-[13px] font-PoppinsRegular text-disabledtext dark:text-disabled">
                  Read about this investment below
                </p>
                {selectedInvestment?.disclaimer && (
                  <div className="mt-4">
                    <h1 className="font-PoppinsMedium text-[14px] mb-1 flex items-center gap-2">
                      Disclaimer <TiWarningOutline className="text-error" />
                    </h1>
                    <p className="font-PoppinsLight dark:text-white text-textcolor text-[13px]">
                      {selectedInvestment?.disclaimer}
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div>
              <div
                className="flex items-center my-7 justify-center"
                style={{
                  backgroundImage: `url(${
                    selectedInvestment?.previewImage
                      ? `${process.env.REACT_APP_FILES_URL}/${selectedInvestment.previewImage}`
                      : selectedInvestment?.previewImage === ""
                      ? Default
                      : Default
                  })`,
                  height: "250px",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <div className="bg-white opacity-50 inline-block p-4 rounded-[8px]">
                  <img src={Logo} className="w-[30px]" alt="" />
                </div>
              </div>
              <p className="dark:text-white text-textcolor text-[13px]">
                {selectedInvestment?.description}
              </p>
            </div>
          </div>
        </Modal>
      )}
      {viewMore && (
        <Modal
          isOpenModal={() => setViewMore(true)}
          closeModal={() => setViewMore(false)}
        >
          <div className="px-4 pt-[120px]">
            <h1 className="text-[20px] font-PoppinsBold">Active Investments</h1>
            {viewMoreIsLoading ? (
              <div className="w-full h-[50vh] bg-white dark:bg-darkPrimary flex items-center justify-center">
                <ScaleLoader color={isDarkMode ? "#FFFFFF" : "#6B006B"} />
              </div>
            ) : (
              <div className="mt-10">
                {modalInvestments.length > 0 ? (
                  <div>
                    {modalInvestments?.map((item, index) => (
                      <div
                        key={index}
                        className="border-[1.5px] cursor-pointer border-transactionCard dark:border-textcolor my-2 py-2 shadow-sm"
                        onClick={() => openShowInvestmentModal(item)}
                      >
                        <div className="flex items-center gap-[50px] px-[3%]">
                          <div className="w-[150px] h-[100px] relative">
                            <img
                              src={
                                item.previewImage
                                  ? `${process.env.REACT_APP_FILES_URL}/${item.previewImage}`
                                  : item.previewImage === ""
                                  ? Default
                                  : Default
                              }
                              // src={item.previewImage}
                              className="w-full h-full bg-cover object-cover rounded-[10px]"
                              alt="investment-img"
                            />
                          </div>
                          <div>
                            <h1 className="font-PoppinsSemiBold uppercase text-[12px] pb-2">
                              {item.title}
                            </h1>
                            <h1 className="text-success font-PoppinsSemiBold text-[12px]">
                              {item.rate}%{" "}
                              <span className=" font-PoppinsRegular text-[10px] text-tabletext">
                                returns
                              </span>
                            </h1>
                            <div className="flex items-center gap-9 py-2">
                              <div>
                                <h1 className="text-textcolor dark:text-white  font-PoppinsRegular text-[10px]">
                                  NUMBER OF INVESTORS:{" "}
                                  <span className="font-PoppinsSemiBold">
                                    {item.numberOfInvestors}
                                  </span>
                                </h1>
                              </div>
                            </div>
                            <div
                              className={`${
                                item.status === "running"
                                  ? "text-referral bg-referral"
                                  : item.status === "matured"
                                  ? "text-success bg-success"
                                  : "text-error bg-error"
                              } bg-opacity-[28%] inline-block py-[0.7px] px-2 rounded-[10px] font-PoppinsMedium text-[8px]`}
                            >
                              {item.status === "running"
                                ? "RUNNING"
                                : item.status === "matured"
                                ? "MATURED"
                                : "STOPPED"}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="text-center text-tabletext text-[12px]">
                    No investments found.
                  </div>
                )}
                <ReactPaginate
                  breakLabel={<span className="mr-4">...</span>}
                  nextLabel={
                    currentModalPage < totalModalPages - 1 && (
                      <span className="w-8 h-8 flex items-center justify-center bg-authbordercolor dark:text-white bg-opacity-25 rounded-[4px]">
                        <BsChevronRight />
                      </span>
                    )
                  }
                  pageRangeDisplayed={5}
                  onPageChange={handlePageChange}
                  pageCount={totalModalPages}
                  previousLabel={
                    currentModalPage > 0 && (
                      <span className="w-8 h-8 flex mr-3 items-center justify-center bg-authbordercolor dark:text-white bg-opacity-25 rounded-[4px]">
                        <BsChevronLeft />
                      </span>
                    )
                  }
                  renderOnZeroPageCount={null}
                  containerClassName="flex items-center justify-center mt-8 mb-4"
                  pageClassName="element"
                  activeClassName="active-element"
                />
              </div>
            )}
          </div>
        </Modal>
      )}
      {investNowModal && selectedInvestment && (
        <Modal
          isOpenModal={openInvestNowModal}
          closeModal={closeInvestNowModal}
        >
          <div className="mt-[120px] px-4">
            <h1 className="text-primary dark:text-white text-[25px] font-PoppinsSemiBold">
              Invest Now
            </h1>
            <div className="bg-primary rounded-[8px] mt-4 p-4 text-white">
              <h1 className="text-[18px] font-PoppinsMedium">Information</h1>
              <h1 className="font-PoppinsRegular text-[14px]">
                Fill the form below to start investing
              </h1>
            </div>
            <h1 className="mt-[60px] mb-[20px] font-PoppinsMedium text-[14px] text-textcolor dark:text-white">
              Please enter an Amount in multiple of ₦10,000 in{"  "}
              <span className="uppercase">{selectedInvestment.title}</span>
            </h1>
            <Formik
              initialValues={{
                amount: "",
                fundingSource: "",
                answer: "",
              }}
              onSubmit={handleBookInvestment}
              validationSchema={bookInvestmentSchema}
              enableReinitialize={false}
            >
              {({ values, isValid, dirty }) => (
                <Form>
                  <TextInputWithLabel
                    label="Amount(in multiple of ₦10,000)"
                    name="amount"
                    type="number"
                  />

                  <SelectInputWithLabelAndPlaceholder
                    name="fundingSource"
                    options={wallet}
                    placeholder="Select a funding source"
                    label="Choose a Source of Funds"
                  />
                  {values.amount && values.fundingSource && (
                    <PasswordInput
                      name="answer"
                      type="password"
                      label="Answer for the security question set by you"
                    />
                  )}
                  <ConfigProvider
                    theme={{
                      token: {
                        colorPrimary: "#6B006B",
                      },
                    }}
                  >
                    {values.answer !== "" && (
                      <div>
                        <div className="flex items-center space-x-4 justify-between mb-4">
                          <Switch
                            checked={switch1}
                            onChange={() => setSwitch1((prev) => !prev)}
                          />
                          <span className="text-primary text-[13px] font-PoppinsMedium dark:text-white">
                            {`I confirm my purchase of this investment for ${formatCurrency(
                              Number(values.amount)
                            )}.`}
                          </span>
                        </div>
                        <div className="flex items-center justify-between space-x-4 mb-4">
                          <Switch
                            checked={switch2}
                            onChange={() => setSwitch2((prev) => !prev)}
                          />
                          <span className="text-primary text-[13px] font-PoppinsMedium dark:text-white">
                            I acknowledge that I have read and comprehended the
                            investment information. I recognize that investing
                            in third-party instruments carries risks, which may,
                            in rare instances, involve loss of capital and lack
                            of liquidity.
                          </span>
                        </div>
                      </div>
                    )}
                  </ConfigProvider>
                  <div>
                    <InvestmentBtn
                      disabled={!isValid || !dirty || !switch1 || !switch2}
                      isLoading={isBooking}
                    >
                      INVEST NOW
                    </InvestmentBtn>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ActiveInvestmentMobile;
