import React from "react";
import Sidebar from "../../sidebar/Sidebar";
import PageTitle from "../../../components/Pagetitle";

function SavingsPlan() {
  return (
    <>
      <PageTitle title="Affluence - Admin: Savings Plan" />
      <div className="flex h-screen dark:bg-darkPrimary">
        <Sidebar />
        <div className="flex-1 overflow-y-auto">
          <div className="flex-1 p-4 mt-28 shadow-md border border-transactionCard rounded-lg mx-4">
            <div className="flex flex-col md:flex-row justify-between">
              <span className="dark:text-white font-PoppinsBold text-textcolor text-semibold mb-4 md:mb-0">
                Savings Plan
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SavingsPlan;
