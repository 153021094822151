import { useCallback, useEffect, useMemo, useState } from "react";
import { Form, Formik } from "formik";
import Modal from "../../../../components/Modal";
import { formatDate } from "../../../../components/DateFormat";
import GlowingButton from "../../../../components/GlowBtn";
import { formatCurrency } from "../../../../components/CurrencyFormat";
import MyInvestmentCard from "../../../../components/MyInvestmentCard";
import { ScaleLoader } from "react-spinners";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { isAxiosError } from "axios";
import ToastComponent from "../../../../components/ToastComponent";
import { HttpClient } from "../../../../api/HttpClient";
import Logo from "../../../../assets/img/affluence-logo-small.png";
import * as Yup from "yup";
import { Account, MyInvestmentItem } from "../../../../assets/Typeprops";
import AuthButton from "../../../../components/AuthButton";
import PasswordInput from "../../../../components/PasswordInput";
import { TiWarningOutline } from "react-icons/ti";
import Default from "../../../../assets/img/default-img.webp";

const MyInvestments = () => {
  const [investmentData, setInvestmentData] = useState<MyInvestmentItem[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedInvestment, setSelectedInvestment] =
    useState<MyInvestmentItem | null>(null);
  // const [investNowModal, setInvestNowModal] = useState(false);
  const [selectedInvestmentIndex, setSelectedInvestmentIndex] = useState<
    string | null
  >(null);
  const [isLoading, setIsLoading] = useState(false);
  // const [bookingLoading, setBookingLoading] = useState(false);
  const [filterCategory, setFilterCategory] = useState<string | null>(null);
  const [accountData, setAccountData] = useState<Account | null>(null);
  const [followedData, setFollowedData] = useState<any | null>(null);
  const [showEnterTransactionPin, setShowTransactionPin] = useState(false);
  const [pin, setPin] = useState(false);
  const [aboutModal, setAboutModal] = useState(false);
  const [breakInvestmentModal, setBreakInvestmentModal] = useState(false);
  const openShowInvestmentModal = (
    investment: MyInvestmentItem,
    index: string
  ) => {
    setSelectedInvestment(investment);
    setSelectedInvestmentIndex(index);
    setIsModalOpen(true);
  };

  const openInvestNowModal = (investment: MyInvestmentItem, index: string) => {
    setSelectedInvestment(investment);
    setSelectedInvestmentIndex(index);
    // setInvestNowModal(true);
  };

  const closeShowInvestmentModal = () => {
    setIsModalOpen(false);
    setSelectedInvestment(null);
  };

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const [userRes, investmentRes, followedRes] = await Promise.all([
        HttpClient.get("/users/me", {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }),
        HttpClient.get("/investments/booking", {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }),
        HttpClient.get("/investments/follow", {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }),
      ]);
      setAccountData(userRes?.data?.data);
      setInvestmentData(investmentRes?.data?.data?.data);
      setFollowedData(followedRes?.data?.data);
    } catch (error) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      }
    } finally {
      setIsLoading(false);
    }
  }, []);
  console.log(followedData);
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
  const filteredData = useMemo(() => {
    if (filterCategory === "followed") {
      return followedData;
    }

    let result = investmentData;
    if (filterCategory) {
      result = result.filter(
        (item) => item.investment_details.status === filterCategory
      );
    }
    return result;
  }, [filterCategory, investmentData, followedData]);

  const setPinSchema = Yup.object().shape({
    pin: Yup.string().required("Enter transaction pin"),
  });
  const breakInvestmentSchema = Yup.object().shape({
    pin: Yup.string()
      .required("Enter transaction pin")
      .max(4, "Transaction Pin length must be equal to 4"),
  });
  const handleBreakInvestment = async (
    values: any,
    {
      setFieldValue,
    }: {
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean
      ) => void;
    }
  ) => {
    setPin(true);
    try {
      const res = await HttpClient.put(
        `/investments/break-roi/${selectedInvestment?.investment_details._id}`,
        values,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      ToastComponent.success(res.data.message);
      setBreakInvestmentModal(false);
      setIsModalOpen(false);

      fetchData();
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      } else if (error instanceof Error) {
        ToastComponent.error(error.message);
      } else {
        ToastComponent.error("An unknown error occurred");
      }
      setFieldValue("pin", "", false);
    } finally {
      setPin(false);
    }
  };
  const handleClaimProfit = async (
    values: any,
    {
      setFieldValue,
    }: {
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean
      ) => void;
    }
  ) => {
    setPin(true);
    try {
      const res = await HttpClient.put(
        `/investments/claim-roi/${selectedInvestment?.investment_details._id}`,
        values,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      ToastComponent.success(res.data.message);
      setIsModalOpen(false);
      setShowTransactionPin(false);
      fetchData();
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        ToastComponent.error(error.response?.data.message);
      } else if (error instanceof Error) {
        ToastComponent.error(error.message);
      } else {
        ToastComponent.error("An unknown error occurred");
      }
      setFieldValue("pin", "", false);
    } finally {
      setPin(false);
    }
  };

  return (
    <div className="text-textcolor dark:text-white">
      <div className="flex text-[12px] font-PoppinsMedium border-b-[1.5px] border-transactionCard dark:border-textcolor justify-between items-center gap-10 px-[3%] py-6">
        <div
          onClick={() => setFilterCategory(null)}
          className={`border-[1.5px] cursor-pointer py-0.5 border-textcolor dark:text-white rounded-[10px] w-full text-center ${
            filterCategory === null ? "bg-primary text-white" : ""
          }`}
        >
          All
        </div>
        <div
          onClick={() => setFilterCategory("running")}
          className={`border-[1.5px] cursor-pointer py-0.5 border-textcolor dark:text-white rounded-[10px] w-full text-center ${
            filterCategory === "running" ? "bg-primary text-white" : ""
          }`}
        >
          Running
        </div>
        <div
          onClick={() => setFilterCategory("followed")}
          className={`border-[1.5px] cursor-pointer py-0.5 border-textcolor dark:text-white  rounded-[10px] w-full text-center ${
            filterCategory === "followed" ? "bg-primary text-white" : ""
          }`}
        >
          Followed
        </div>
        <div
          onClick={() => setFilterCategory("matured")}
          className={`border-[1.5px] cursor-pointer py-0.5 border-textcolor dark:text-white  rounded-[10px] w-full text-center ${
            filterCategory === "matured" ? "bg-primary text-white" : ""
          }`}
        >
          Matured
        </div>
      </div>

      <div className="flex-grow flex items-center w-full">
        <div className="w-full">
          <div className="w-full">
            {isLoading ? (
              <div className="w-full h-[50vh] bg-white dark:bg-darkPrimary flex items-center justify-center relative">
                <ScaleLoader color={isDarkMode ? "#FFFFFF" : "#6B006B"} />
              </div>
            ) : filteredData && filteredData.length > 0 ? (
              filteredData.map((item: any) => (
                <div key={item._id} className="w-full">
                  <MyInvestmentCard
                    key={item._id}
                    maturityDate={formatDate(item.maturityDate)}
                    investmentImg={
                      item.investment_details?.previewImage
                        ? `${process.env.REACT_APP_FILES_URL}/${item.investment_details.previewImage}`
                        : item.investment_details?.previewImage === ""
                        ? Default
                        : Default
                    }
                    title={item.investment_details.title}
                    amount={formatCurrency(item.amount)}
                    amountExpected={formatCurrency(item.totalPayable)}
                    rate={item.investment_details.rate}
                    units={`${item.numberOfUnits} units`}
                    isShowUnits
                    status={item.investment_details.status}
                    onClick={() => openShowInvestmentModal(item, item._id)}
                    roiExpected={item.roiExpected}
                  />
                </div>
              ))
            ) : (
              <div className="text-center pt-[40px]">
                <div>
                  <h1 className="font-PoppinsBold pb-3 sm:text-[24px] xs:text-[22px] text-[20px] text-primary">
                    You have no investment currently!
                  </h1>
                  <h1 className="font-PoppinsRegular pb-2 px-[3%] dark:text-white sm:text-[16px] xs:text-[14px] text-[12px]">
                    Start earning interest by investing in our various
                    investment options.
                  </h1>
                  <div className="flex items-center gap-4 justify-center pt-5">
                    <button className="bg-primary border-[1.5px] sm:text-[16px] xs:text-[14px] text-[12px] border-primary text-white font-PoppinsSemiBold sm:px-8 xs:px-6 px-4 sm:py-2 py-1 rounded-[8px]">
                      Invest Now
                    </button>
                    <button className="border-[1.5px] border-primary sm:text-[16px] xs:text-[14px] text-[12px] text-primary font-PoppinsSemiBold sm:px-8 xs:px-6 px-4 sm:py-2 py-1 rounded-[8px]">
                      Learn More
                    </button>
                  </div>
                </div>
              </div>
            )}

            {isModalOpen &&
              selectedInvestment &&
              selectedInvestmentIndex !== null && (
                <Modal
                  isOpenModal={openShowInvestmentModal}
                  closeModal={closeShowInvestmentModal}
                >
                  <div
                    className="flex items-center mt-[90px] justify-center"
                    style={{
                      backgroundImage: `url(${
                        selectedInvestment?.investment_details?.previewImage
                          ? `${process.env.REACT_APP_FILES_URL}/${selectedInvestment.investment_details.previewImage}`
                          : selectedInvestment.investment_details
                              .previewImage === ""
                          ? Default
                          : Default
                      })`,

                      height: "250px",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }}
                  >
                    <div className="bg-white opacity-50 inline-block p-4 rounded-[8px]">
                      <img src={Logo} className="w-[30px]" alt="" />
                    </div>
                  </div>
                  <div className="px-4">
                    <div className="bg-success mt-6 mb-8 py-1 px-4 inline-block text-[12px] font-PoppinsLight rounded-[10px] bg-opacity-[28%] text-success">
                      VERIFIED INVESTMENT
                    </div>
                    <div className="flex justify-between">
                      <div>
                        <h1 className="font-PoppinsBold uppercase w-[80%] text-[20px]">
                          {selectedInvestment.investment_details.title}
                        </h1>
                      </div>
                      {selectedInvestment.investment_details.status ===
                      "pending" ? (
                        <div>
                          <h1 className="font-PoppinsBold uppercase text-[20px] text-success">
                            {selectedInvestment.investment_details.amount
                              ? formatCurrency(
                                  selectedInvestment.investment_details.amount
                                )
                              : "₦0"}
                          </h1>
                          <h1 className="font-PoppinsMedium text-tabletext text-[9px]">
                            per unit
                          </h1>
                        </div>
                      ) : selectedInvestment.investment_details.status ===
                        "soldout" ? (
                        <div>
                          <h1 className="font-PoppinsBold uppercase text-[20px] text-success">
                            {selectedInvestment.investment_details.amount
                              ? formatCurrency(
                                  selectedInvestment.investment_details.amount
                                )
                              : "₦0"}
                          </h1>
                          <h1 className="font-PoppinsMedium text-tabletext text-[9px]">
                            per unit
                          </h1>
                        </div>
                      ) : (
                        <div>
                          <h1 className="font-PoppinsBold uppercase text-center text-[20px] text-success">
                            {
                              selectedInvestment.investment_details
                                .numberOfInvestors
                            }
                          </h1>
                          <h1 className="font-PoppinsMedium text-center uppercase text-tabletext text-[9px]">
                            {selectedInvestment.investment_details
                              .numberOfInvestors === 1
                              ? "Investor"
                              : "Investors"}
                          </h1>
                        </div>
                      )}
                    </div>
                    <div className="flex flex-col gap-4 mt-5">
                      {selectedInvestment.investment_details.status ===
                      "sold out" ? (
                        <button className="bg-error cursor-not-allowed border-[1.5px] border-error text-white font-PoppinsSemiBold w-full py-4 rounded-[8px]">
                          SOLD OUT
                        </button>
                      ) : selectedInvestment.investment_details.status ===
                        "pending" ? (
                        <button
                          onClick={() =>
                            openInvestNowModal(
                              selectedInvestment,
                              selectedInvestmentIndex
                            )
                          }
                          className="bg-investment border-[1.5px] border-investment text-white font-PoppinsSemiBold w-full py-4 rounded-[8px]"
                        >
                          INVEST NOW
                        </button>
                      ) : selectedInvestment.investment_details.status ===
                        "running" ? (
                        selectedInvestment.investmentStatus === "broken" ? (
                          <button className="bg-referral border-[1.5px] cursor-not-allowed border-referral bg-opacity-[28%] text-white font-PoppinsSemiBold w-full py-4 rounded-[8px]">
                            INVESTMENT BROKEN
                          </button>
                        ) : (
                          <button
                            onClick={() => setBreakInvestmentModal(true)}
                            className="bg-referral border-[1.5px]  border-referral text-white font-PoppinsSemiBold w-full py-4 rounded-[8px]"
                          >
                            BREAK INVESTMENT
                          </button>
                        )
                      ) : selectedInvestment.isRoiSettled === false ? (
                        <GlowingButton
                          onClick={() => setShowTransactionPin(true)}
                          isLoading={false}
                          disabled={false}
                        >
                          CLAIM
                        </GlowingButton>
                      ) : (
                        <button className="dark:bg-[#C8A2C8] cursor-not-allowed border-[1.5px] dark:border-[#C8A2C8] dark:bg-opacity-10 dark:text-[#C8A2C8] bg-opacity-10 text-primary bg-primary border-primary font-PoppinsSemiBold w-full py-4 rounded-[8px]">
                          CLAIMED
                        </button>
                      )}
                      {selectedInvestment.investment_details.status ===
                      "sold out" ? (
                        <button
                          onClick={() => setAboutModal(true)}
                          className="border-[1.5px] border-error text-error font-PoppinsSemiBold py-4 w-full rounded-[8px]"
                        >
                          ABOUT THIS INVESTMENT
                        </button>
                      ) : (
                        <button
                          onClick={() => setAboutModal(true)}
                          className="border-[1.5px] border-investment text-investment font-PoppinsSemiBold py-4 w-full rounded-[8px]"
                        >
                          ABOUT THIS INVESTMENT
                        </button>
                      )}
                    </div>
                    <div
                      className={`text-[14px] font-PoppinsRegular text-center mt-3 ${
                        selectedInvestment.investment_details.status ===
                        "sold out"
                          ? "text-error"
                          : selectedInvestment.investment_details.status ===
                            "pending"
                          ? "text-success"
                          : selectedInvestment.investment_details.status ===
                            "running"
                          ? "text-referral"
                          : "text-success"
                      }`}
                    >
                      {selectedInvestment.investment_details.status ===
                      "sold out"
                        ? "This investment is currently sold out to Investors. Please look for other investment plans."
                        : selectedInvestment.investment_details.status ===
                          "pending"
                        ? "This investment plan is still available for purchase."
                        : selectedInvestment.investment_details.status ===
                          "running"
                        ? "This investment plan is already running for all Investors."
                        : "This investment plan is still available for purchase."}
                    </div>

                    <hr className="text-authbordercolor my-10" />
                    <div>
                      <div className="flex justify-center gap-7 mb-5">
                        <div className="w-full border-[1.5px] rounded-[8px] p-3 border-transactionCard">
                          <h1 className="text-[13px] font-PoppinsMedium text-disabledtext">
                            Investment Type
                          </h1>
                          <h1 className="text-[13px] font-PoppinsMedium">
                            {selectedInvestment.investment_details.category}
                          </h1>
                        </div>
                        <div className="w-full border-[1.5px] rounded-[8px] p-3 border-transactionCard">
                          <h1 className="text-[13px] font-PoppinsMedium text-disabledtext">
                            Start Date
                          </h1>
                          <h1 className="text-[13px] font-PoppinsMedium">
                            {formatDate(
                              selectedInvestment.investment_details.startDate
                            )}
                          </h1>
                        </div>
                      </div>
                      <div className="flex justify-center gap-7">
                        <div className="w-full border-[1.5px] rounded-[8px] p-3 border-transactionCard">
                          <h1 className="text-[13px] font-PoppinsMedium text-disabledtext">
                            Maturity Date
                          </h1>
                          <h1 className="text-[13px] font-PoppinsMedium">
                            {formatDate(
                              selectedInvestment.investment_details.endDate
                            )}
                          </h1>
                        </div>
                        <div className="w-full border-[1.5px] rounded-[8px] p-3 border-transactionCard">
                          <h1 className="text-[13px] font-PoppinsMedium text-disabledtext">
                            Payout Type
                          </h1>
                          <h1 className="text-[13px] font-PoppinsMedium">
                            Capital + profit to be paid
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
              )}
            {showEnterTransactionPin && (
              <Modal
                isOpenModal={() => setShowTransactionPin(true)}
                closeModal={() => setShowTransactionPin(false)}
              >
                <div className="px-4 pt-[120px]">
                  <div className="pb-[60px]">
                    <h1 className="text-[20px] font-PoppinsBold">
                      Transaction Pin
                    </h1>
                    <h1 className="font-PoppinsLight pt-3 text-[14px]">
                      Enter Transaction pin to claim profit from investment
                    </h1>
                  </div>
                  <div>
                    <Formik
                      initialValues={{
                        pin: "",
                      }}
                      validationSchema={setPinSchema}
                      onSubmit={handleClaimProfit}
                    >
                      {({ isValid, dirty }) => (
                        <Form>
                          <PasswordInput
                            name="pin"
                            type="password"
                            placeholder="4-digit pin"
                            label="Enter Transaction Pin"
                            updatePass="top-[35px] right-[13px]"
                          />
                          <AuthButton
                            isLoading={pin}
                            disabled={!isValid || !dirty}
                          >
                            CLAIM
                          </AuthButton>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </Modal>
            )}
            {breakInvestmentModal && (
              <Modal
                isOpenModal={() => setBreakInvestmentModal(true)}
                closeModal={() => setBreakInvestmentModal(false)}
              >
                <div className="px-4 pt-[120px]">
                  <div className="pb-[60px]">
                    <h1 className="text-[20px] font-PoppinsBold">
                      Transaction Pin
                    </h1>
                    <h1 className="font-PoppinsLight pt-3 text-[14px]">
                      Enter Transaction pin to Break Investment
                    </h1>
                    <div className="mt-4 p-2 rounded-[8px] text-white bg-error">
                      <h1 className="font-PoppinsMedium text-[14px] mb-1 flex items-center gap-2">
                        Warning <TiWarningOutline />
                      </h1>
                      <p className="font-PoppinsLight text-[13px]">
                        If you choose to break your current investment plan, a
                        fee of 2.5% will be charged on the total amount used in
                        your investment purchase and ROI will not be paid.
                      </p>
                    </div>
                  </div>
                  <div>
                    <Formik
                      initialValues={{
                        pin: "",
                      }}
                      validationSchema={breakInvestmentSchema}
                      onSubmit={handleBreakInvestment}
                    >
                      {({ isValid, dirty }) => (
                        <Form>
                          <PasswordInput
                            name="pin"
                            type="password"
                            placeholder="4-digit pin"
                            label="Enter Transaction Pin"
                            updatePass="top-[35px] right-[13px]"
                          />
                          <AuthButton
                            isLoading={pin}
                            disabled={!isValid || !dirty}
                          >
                            Proceed
                          </AuthButton>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </Modal>
            )}
            {aboutModal && selectedInvestment && (
              <Modal
                isOpenModal={() => setAboutModal(true)}
                closeModal={() => setAboutModal(false)}
              >
                <div className="px-4 pt-[120px]">
                  <div>
                    <h1 className="text-primary dark:text-white text-[25px] font-PoppinsSemiBold">
                      About Investment
                    </h1>
                    <div className="py-2">
                      <p className="text-[13px] font-PoppinsRegular text-disabledtext dark:text-disabled">
                        Read about this investment below
                      </p>
                    </div>
                  </div>
                  <div>
                    <div
                      className="flex items-center my-7 justify-center"
                      style={{
                        backgroundImage: `url(${
                          selectedInvestment?.investment_details?.previewImage
                            ? `${process.env.REACT_APP_FILES_URL}/${selectedInvestment.investment_details.previewImage}`
                            : selectedInvestment.investment_details
                                .previewImage === ""
                            ? Default
                            : Default
                        })`,

                        height: "250px",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                      }}
                    >
                      <div className="bg-white opacity-50 inline-block p-4 rounded-[8px]">
                        <img src={Logo} className="w-[30px]" alt="" />
                      </div>
                    </div>
                    <p className="dark:text-white text-textcolor text-[13px]">
                      {selectedInvestment?.investment_details.description}
                    </p>
                  </div>
                </div>
              </Modal>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyInvestments;
