import { Table, Dropdown, Menu, Input } from "antd";
import { ColumnsType } from "antd/es/table";
import { DownOutlined } from "@ant-design/icons";
import { useNavigate, Link } from "react-router-dom";
import Sidebar from "../../sidebar/Sidebar";
import { FaRegEye } from "react-icons/fa6";
import {
  MdBlock,
  MdDeleteOutline,
  MdOutlineManageAccounts,
  MdOutlineMarkEmailUnread,
} from "react-icons/md";
import { useEffect, useState } from "react";
import { HttpClient } from "../../../api/HttpClient";
import ToastComponent from "../../../components/ToastComponent";
import { ScaleLoader } from "react-spinners";
import PageTitle from "../../../components/Pagetitle";
import ErrorHandler from "../../ErrorHandler";
import { formatCurrency } from "../../../components/CurrencyFormat";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { format } from "date-fns";
import Modal from "../../Modal";
import TextAreaInput from "../../../components/TextAreaInput";
import TextInput from "../../../components/TextInput";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import errorHandler from "../../ErrorHandler";

interface CustomerData {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  userAccountDetails: {
    balance: number;
    deposit: number;
    investments: number;
    profit: number;
    withdrawal: number;
    createdAt: string;
    standard_investments: number;
    flexible_investments: number;
    kids_investments: number;
    project_investments: number;
  };
  kycDetails: {
    kycApprovalStatus: boolean;
    kycLevelOneStatus: string;
    kycLevelTwoStatus: string;
    kycLevelThreeStatus: string;
  };
  serialNumber: number;
}

const Customers = () => {
  const [showManageCustomer, setShowManageCustomer] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [allCustomers, setAllCustomers] = useState<CustomerData[]>([]);
  const [loading, setLoading] = useState(true);
  const [filteredCustomers, setFilteredCustomers] = useState<CustomerData[]>(
    []
  );
  const [searchName, setSearchName] = useState("");
  const [searchEmail, setSearchEmail] = useState("");
  const [selectedCustomerId, setSelectedCustomerId] = useState<string | null>(
    null
  );

  const navigate = useNavigate();

  const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);

  const sendEmailValidation = Yup.object({
    subject: Yup.string().required("Email Subject is required"),
    body: Yup.string().required("Message is required"),
  });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const allUserdataRes = await HttpClient.get("/admin/view-users", {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          params: {
            dir: "desc",
          },
        });
        const data = allUserdataRes.data?.data;
        const dataWithSerialNumbers = data.map((item: any, index: number) => ({
          ...item,
          serialNumber: index + 1,
        }));

        setAllCustomers(dataWithSerialNumbers);
      } catch (error: any) {
        ErrorHandler(error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    const filteredData = allCustomers.filter((item: CustomerData) => {
      const fullName = `${item.firstName} ${item.lastName}`.toLowerCase();
      const email = item.email.toLowerCase();
      return (
        fullName.includes(searchName.toLowerCase()) &&
        email.includes(searchEmail.toLowerCase())
      );
    });
    setFilteredCustomers(filteredData);
  }, [searchName, searchEmail, allCustomers]);

  const handleMenuClick = (e: any, customerId: string) => {
    if (e.key === "manageCustomer") {
      navigate(`/admin/allCustomers/${customerId}`);
    }
  };

  const handleBlock = async (customerId: string) => {
    try {
      const blockRes = await HttpClient.get(
        `/admin/block-unblock-user/${customerId}`,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );

      if (blockRes.data?.statusCode === 200) {
        return ToastComponent.success(
          blockRes.data.message || "User blocked Successfully"
        );
      }
    } catch (error: any) {
      ErrorHandler(error);
    }
  };

  const handleDelete = async (customerId: string) => {
    try {
      const blockRes = await HttpClient.delete(
        `/admin/delete-user/${customerId}`,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );

      if (blockRes.data?.statusCode === 200) {
        return ToastComponent.success(
          blockRes.data?.message || "User Deleted Successfully"
        );
      }
    } catch (error: any) {
      ErrorHandler(error);
    }
  };

  const handleToggleView = () => {
    setShowManageCustomer((prev) => !prev);
    navigate("/admin/allCustomers");
  };

  const menu = (customer: CustomerData) => (
    <Menu>
      <Menu.Item
        onClick={() => handleMenuClick({ key: "manageCustomer" }, customer._id)}
        key="manageCustomer"
        className="relative group"
      >
        <div className="flex gap-2 items-center">
          <MdOutlineManageAccounts size={20} />
          <span className="text-textcolor font-PoppinsRegular">
            Manage Customer
          </span>
        </div>
        <div className="absolute inset-0 bg-primary opacity-0 group-hover:opacity-30"></div>
      </Menu.Item>

      <Menu.Item
        onClick={() => {
          setSelectedCustomerId(customer._id);
          setIsModalVisible(true);
        }}
      >
        <div className="flex gap-2 items-center">
          <MdOutlineMarkEmailUnread size={20} />
          <span className="text-textcolor font-PoppinsRegular">Send Mail</span>
        </div>
        <div className="absolute inset-0 bg-primary opacity-0 group-hover:opacity-30"></div>
      </Menu.Item>

      <Menu.Item
        key="block"
        className="relative group"
        onClick={() => handleBlock(customer._id)}
      >
        <div className="flex gap-2 items-center">
          <MdBlock size={20} />
          <span className="text-textcolor font-PoppinsRegular">Block</span>
        </div>
        <div className="absolute inset-0 bg-primary opacity-0 group-hover:opacity-30"></div>
      </Menu.Item>

      <Menu.Item
        key="delete"
        className="relative group"
        onClick={() => handleDelete(customer._id)}
      >
        <div className="flex gap-2 items-center">
          <MdDeleteOutline size={20} />
          <span className="text-textcolor font-PoppinsRegular">Delete</span>
        </div>
        <div className="absolute inset-0 bg-primary opacity-0 group-hover:opacity-30"></div>
      </Menu.Item>
    </Menu>
  );

  const handleSubmit = async (values: any) => {
    try {
      const formData = new FormData();
      formData.append("subject", values.subject);
      formData.append("body", values.body);

      const sendUserMailRes = await HttpClient.post(
        `/admin/send-email-to-user/${selectedCustomerId}`,
        formData,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      if (sendUserMailRes) {
        ToastComponent.success(
          sendUserMailRes.data.message ||
            "Email sent successfully"
        );
      } else {
        ToastComponent.error("Failed to send Email");
      }
      setIsModalVisible(false);
    } catch (error: any) {
      errorHandler(error);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedCustomerId(null);
  };

  const columns: ColumnsType<CustomerData> = [
    {
      title: "S/N",
      dataIndex: "serialNumber",
      key: "serialNumber",
      render: (text: string, record: CustomerData) => (
        <div className="flex items-center gap-6">
          <span>{text}</span>
          <Dropdown overlay={menu(record)} trigger={["click"]}>
            <div
              onClick={(e) => e.preventDefault()}
              className="hover:text-primary border rounded-full text-xxs p-0.2"
            >
              <DownOutlined />
            </div>
          </Dropdown>
        </div>
      ),
    },

    {
      title: "Registration Date",
      key: "createdAt",
      dataIndex: ["userAccountDetails", "createdAt"],
      render: (createdAt: string) => {
        const date = new Date(createdAt);
        const formattedDate = format(date, "MMM dd, yyyy");
        const formattedTime = format(date, "hh:mm a");
        return (
          <div className="text-textcolor" style={{ width: "100px" }}>
            <div className="font-PoppinsRegular">{formattedDate}</div>
            <div className="font-PoppinsLight">{formattedTime}</div>
          </div>
        );
      },
    },
    {
      title: "Name",
      key: "name",
      render: (text, record) => {
        return (
          <div style={{ width: "100px" }}>
            {record.firstName} {record.lastName}
          </div>
        );
      },
    },

    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone No.",
      dataIndex: "phone",
      key: "phone",
    },

    {
      key: "balance",
      title: "Balance",
      dataIndex: ["userAccountDetails", "balance"],
      render: (balance: number) => formatCurrency(balance),
    },
    {
      key: "deposit",
      title: "Deposit",
      dataIndex: ["userAccountDetails", "deposit"],
      render: (deposit: number) => formatCurrency(deposit),
    },
    {
      title: "Investments",
      key: "investments",
      render: (_: any, record: CustomerData) => {
        const totalInvestments =
          (record.userAccountDetails.standard_investments || 0) +
          (record.userAccountDetails.flexible_investments || 0) +
          (record.userAccountDetails.kids_investments || 0) +
          (record.userAccountDetails.project_investments || 0);

        return formatCurrency(totalInvestments);
      },
    },
    {
      key: "profit",
      title: "Profit",
      dataIndex: ["userAccountDetails", "profit"],
      render: (profit: number) => formatCurrency(profit),
    },
    {
      key: "withdrawal",
      title: "Withdrawal",
      dataIndex: ["userAccountDetails", "withdrawal"],
      render: (withdrawal: number) => formatCurrency(withdrawal),
    },
  ];

  const pagination = {
    pageSize: 5,
  };

  return (
    <>
      <PageTitle title="All Customers" />
      <div className="flex h-screen">
        <Sidebar />
        <div className="flex-1 overflow-y-auto dark:bg-darkPrimary">
          {loading ? (
            <div className="w-full h-screen bg-white dark:bg-darkPrimary flex items-center justify-center">
              <ScaleLoader color={isDarkMode ? "#FFF" : "#6B006B"} />
            </div>
          ) : (
            <div className="flex-1">
              <div className="flex justify-end">
                <button
                  className={`py-2 px-4 rounded-md align-middle ${
                    showManageCustomer
                      ? "border text-primary border-primary"
                      : ""
                  }`}
                  onClick={() => handleToggleView()}
                >
                  {showManageCustomer ? (
                    <>
                      <FaRegEye size={20} className="inline mr-2" />
                      View all customers
                    </>
                  ) : null}
                </button>
              </div>
              {!showManageCustomer && (
                <div className="mt-28 p-4">
                  <div className="p-4 shadow-md border border-transactionCard rounded-lg">
                    <div className="flex flex-col gap-4 items-start sm:items-center sm:flex-row sm:flex-wrap md:grid md:grid-cols-2 lg:flex lg:items-center mb-4">
                      <span className="font-PoppinsBold text-textcolor text-semibold dark:text-white">
                        All Customers
                      </span>
                      <div className="md:flex md:gap-2">
                        <Input
                          placeholder="Search Name"
                          value={searchName}
                          onChange={(e) => setSearchName(e.target.value)}
                          className="mb-2 md:mb-0 lg:mb-0 w-full md:w-52 lg:w-52"
                        />
                        <Input
                          placeholder="Search Email"
                          value={searchEmail}
                          onChange={(e) => setSearchEmail(e.target.value)}
                          className="mb-2 md:mb-0 lg:mb-0 w-full md:w-52 lg:w-52"
                        />
                      </div>
                    </div>

                    <div className="dark:bg-white mt-4 overflow-x-auto">
                      <Table
                        columns={columns}
                        dataSource={filteredCustomers}
                        rowKey="_id"
                        pagination={pagination}
                      />
                    </div>
                  </div>
                </div>
              )}

              {isModalVisible && (
                <Modal
                  onClose={handleCancel}
                  className="w-full sm:w-[500px] md:w-[700px] lg:w-[800px] p-4"
                >
                  <h2 className="text-center font-PoppinsRegular mb-4">
                    Email Customer
                  </h2>
                  <Formik
                    initialValues={{
                      subject: "",
                      body: "",
                    }}
                    validationSchema={sendEmailValidation}
                    onSubmit={handleSubmit}
                  >
                    <Form className="w-full">
                      <div className="flex flex-col sm:flex-row justify-center mb-4">
                        <label
                          htmlFor="subject"
                          className="text-textcolor font-PoppinsMedium mb-1 sm:mb-0 sm:mr-6 dark:text-white"
                        >
                          Subject:
                        </label>
                        <div className="w-full">
                          <TextInput
                            type="text"
                            name="subject"
                            placeholder="Enter the subject"
                          />
                        </div>
                      </div>

                      <div className="flex flex-col sm:flex-row justify-center">
                        <label
                          htmlFor="body"
                          className="text-textcolor font-PoppinsMedium mb-1 sm:mb-0 sm:mr-2 dark:text-white"
                        >
                          Message:
                        </label>
                        <div className="w-full">
                          <TextAreaInput
                            type="text"
                            name="body"
                            placeholder="Enter your message"
                            className="h-80"
                          />
                        </div>
                      </div>

                      <div className="w-full flex justify-end mt-8">
                        <button
                          type="submit"
                          className="w-full sm:w-full md:w-40 font-PoppinsMedium rounded-lg text-primary hover:before:bg-primary border-primary relative h-[50px] overflow-hidden border bg-white px-3 transition-all before:absolute before:bottom-0 before:left-0 before:top-0 before:z-0 before:h-full before:w-0 before:bg-primary before:transition-all before:duration-500 hover:text-white hover:before:left-0 hover:before:w-full"
                        >
                          <span className="relative z-10">Send</span>
                        </button>
                      </div>
                    </Form>
                  </Formik>
                </Modal>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Customers;
