import { useEffect } from "react";
import { HttpClient } from "../api/HttpClient";
import { useNavigate } from "react-router-dom";
import ToastComponent from "../components/ToastComponent";
import FundWalletLoader from "../components/FundWalletLoader";

const FundWallet = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const handleTransactionSuccess = async () => {
      const getToken = () => {
        return localStorage.getItem("token");
      };
      const token = getToken();
      const urlParams = new URLSearchParams(window.location.search);
      const transactionId = urlParams.get("transaction_id");
      const status = urlParams.get("status");
      const txRef = urlParams.get("tx_ref");
      try {
        const res = await HttpClient.get(
          `/payments/flutterwave/fund-wallet?status=${status}&transaction_id=${transactionId}&tx_ref=${txRef}`,
          {
            headers: {
              "x-auth-token": token,
              "verify-affluence-hash": process.env.REACT_APP_TRANSACTION_HASH,
            },
          }
        );
        if (res.data.statusCode === 200) {
          navigate("/savings");
          ToastComponent.success(res.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    };
    handleTransactionSuccess();
  }, [navigate]);

  return (
    <>
      <FundWalletLoader />
    </>
  );
};

export default FundWallet;
