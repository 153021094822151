import { FC, useState } from "react";
import { IoIosSearch } from "react-icons/io";
import { InvestmentItem } from "../../../../assets/Typeprops";
import PageTitle from "../../../../components/Pagetitle";
import Logo from "../../../../assets/img/affluence-logo-small.png";
import Modal from "../../../../components/Modal";
import { highlightText } from "../../../../components/HighLight";
import Default from "../../../../assets/img/default-img.webp";
import { RootState } from "../../../../store/store";
import { useSelector } from "react-redux";
import { TiWarningOutline } from "react-icons/ti";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import ReactPaginate from "react-paginate";

interface ActiveInvestmentsProps {
  data: InvestmentItem[];
  setCategoryTab: any;
  categoryTab?: string | null;
  setSearchQuery: any;
  searchQuery: any;
  currentPage?: number | undefined;
  setCurrentPage: any;
  totalPages?: number | undefined;
}
const ActiveInvestments: FC<ActiveInvestmentsProps> = ({
  data,
  setCategoryTab,
  categoryTab,
  setSearchQuery,
  searchQuery,
  currentPage,
  totalPages,
  setCurrentPage,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedInvestment, setSelectedInvestment] =
    useState<InvestmentItem | null>(null);
  const [aboutModal, setAboutModal] = useState(false);

  const openShowInvestmentModal = (investment: InvestmentItem) => {
    setSelectedInvestment(investment);
    setIsModalOpen(true);
  };
  const closeShowInvestmentModal = () => {
    setIsModalOpen(false);
    setSelectedInvestment(null);
  };
  const isDarkMode = useSelector((state: RootState) => state.theme.isDarkMode);
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };
  const handlePageChange = ({ selected }: any) => {
    setCurrentPage(selected);
  };
  return (
    <>
      <PageTitle title="Affluence - Running Investments" />
      <div className="pt-6">
        <div className="relative mx-[3%]">
          <input
            type="search"
            placeholder="Search Investment"
            value={searchQuery}
            onChange={handleSearchChange}
            className="bg-searchBar border dark:text-darkPrimary border-authbordercolor font-PoppinsLight text-[12px] outline-none placeholder:text-authbordercolor w-full sm:py-3 sm:px-10 p-2.5 rounded-[8px]"
          />
          <IoIosSearch className="text-authbordercolor text-[22px] absolute left-2.5 top-2.5" />
        </div>
        <div className="border-b-[1.5px] border-transactionCard pb-5">
          <div className="flex text-[12px] font-PoppinsMedium justify-between items-center gap-10 pt-6 px-[3%]">
            <div
              onClick={() => setCategoryTab(null)}
              className={`border-[1.5px] cursor-pointer py-0.5 border-textcolor rounded-[10px] w-full text-center ${
                categoryTab === null ? "bg-primary text-white" : ""
              }`}
            >
              All
            </div>
            <div
              onClick={() => setCategoryTab("Fixed Income")}
              className={`border-[1.5px] cursor-pointer py-0.5 border-textcolor rounded-[10px] w-full text-center ${
                categoryTab === "Fixed Income" ? "bg-primary text-white" : ""
              }`}
            >
              Fixed Income
            </div>
            <div
              onClick={() => setCategoryTab("Real Estate")}
              className={`border-[1.5px] cursor-pointer py-0.5 border-textcolor rounded-[10px] w-full text-center ${
                categoryTab === "Real Estate" ? "bg-primary text-white" : ""
              }`}
            >
              Real Estate
            </div>
            <div
              onClick={() => setCategoryTab("Agriculture")}
              className={`border-[1.5px] cursor-pointer py-0.5 border-textcolor rounded-[10px] w-full text-center ${
                categoryTab === "Agriculture" ? "bg-primary text-white" : ""
              }`}
            >
              Agriculture
            </div>
            <div
              onClick={() => setCategoryTab("Transportation")}
              className={`border-[1.5px] cursor-pointer py-0.5 border-textcolor rounded-[10px] w-full text-center ${
                categoryTab === "Transportation" ? "bg-primary text-white" : ""
              }`}
            >
              Transportation
            </div>
          </div>
        </div>
        <div>
          <>
            {data && data.length > 0 ? (
              data?.map((item, index) => (
                <div
                  key={index}
                  className="border-y-[1.5px] cursor-pointer w-full border-transactionCard dark:border-textcolor mb-2 py-2 dark:shadow-[#000000] shadow-transactionCard shadow-sm"
                  onClick={() => openShowInvestmentModal(item)}
                >
                  <div className="flex items-center gap-[50px] px-[3%]">
                    <div className="w-[200px] h-[150px] relative">
                      <img
                        src={
                          item?.previewImage
                            ? `${process.env.REACT_APP_FILES_URL}/${item.previewImage}`
                            : item?.previewImage === ""
                            ? Default
                            : Default
                        }
                        className="object-cover w-full h-full rounded-[10px]"
                        alt="investment-img"
                      />
                    </div>
                    <div>
                      <h1 className="font-PoppinsSemiBold uppercase text-[18px] pb-2">
                        {highlightText(item.title, searchQuery)}
                      </h1>
                      <h1 className="text-success font-PoppinsSemiBold text-[12px]">
                        {item.rate}%{" "}
                        <span className=" font-PoppinsRegular text-[10px] text-tabletext">
                          {`returns in ${item.durationNumber} ${item.durationInterval}`}
                        </span>
                      </h1>
                      <div className="flex items-center gap-9 py-2">
                        <div>
                          <h1 className="text-[12px] text-center font-PoppinsMedium">
                            <span>{item.numberOfInvestors}</span>
                          </h1>
                          <h1 className="font-PoppinsMedium text-center text-[9px] text-tabletext">
                            {item.numberOfInvestors === 1
                              ? "Investor"
                              : "Investors"}
                          </h1>
                        </div>
                      </div>
                      <div
                        className={`${
                          item.status === "running"
                            ? "text-referral bg-referral"
                            : item.status === "matured"
                            ? isDarkMode
                              ? "text-[#C8A2C8] bg-[#C8A2C8]"
                              : "text-primary bg-primary"
                            : item.status === "pending"
                            ? "text-secondary bg-secondary"
                            : "text-error bg-error"
                        } bg-opacity-[28%] inline-block py-1 px-4 rounded-[10px] font-PoppinsMedium text-[10px]`}
                      >
                        {item.status}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center flex items-center justify-center h-[60vh]">
                <div>
                  <h1 className="font-PoppinsBold pb-3 sm:text-[24px] xs:text-[22px] text-[20px] text-primary">
                    There are no running investments currently!
                  </h1>
                </div>
              </div>
            )}
            <ReactPaginate
              breakLabel={<span className="mr-4">...</span>}
              nextLabel={
                (currentPage ?? 0) < (totalPages ?? 0) - 1 && (
                  <span className="w-8 h-8 flex items-center justify-center bg-authbordercolor dark:text-white bg-opacity-25 rounded-[4px]">
                    <BsChevronRight />
                  </span>
                )
              }
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              pageCount={totalPages ?? 0} // Provide a fallback value of 0 if totalPages is undefined
              previousLabel={
                (currentPage ?? 0) > 0 && (
                  <span className="w-8 h-8 flex mr-3 items-center justify-center bg-authbordercolor dark:text-white bg-opacity-25 rounded-[4px]">
                    <BsChevronLeft />
                  </span>
                )
              }
              renderOnZeroPageCount={null}
              containerClassName="flex items-center justify-center mt-8 mb-4"
              pageClassName="element"
              activeClassName="active-element"
            />
          </>
          {isModalOpen && selectedInvestment && (
            <Modal
              isOpenModal={openShowInvestmentModal}
              closeModal={closeShowInvestmentModal}
            >
              <div
                className="flex items-center mt-[90px] justify-center"
                style={{
                  backgroundImage: `url(${
                    selectedInvestment?.previewImage
                      ? `${process.env.REACT_APP_FILES_URL}/${selectedInvestment.previewImage}`
                      : selectedInvestment?.previewImage === ""
                      ? Default
                      : Default
                  })`,
                  height: "250px",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <div className="bg-white opacity-50 inline-block p-4 rounded-[8px]">
                  <img src={Logo} className="w-[30px]" alt="" />
                </div>
              </div>
              <div className="px-4">
                <div className="bg-success mt-6 mb-8 py-1 px-4 inline-block text-[12px] font-PoppinsLight rounded-[10px] bg-opacity-[28%] text-success">
                  VERIFIED INVESTMENT
                </div>
                <div className="flex justify-between">
                  <h1 className="font-PoppinsBold uppercase w-[80%] text-[20px]">
                    {selectedInvestment.title}
                  </h1>
                  <div>
                    <h1 className="font-PoppinsBold uppercase text-center text-[20px] text-success">
                      {selectedInvestment.numberOfInvestors}
                    </h1>
                    <h1 className="font-PoppinsMedium text-center uppercase text-tabletext text-[9px]">
                      {selectedInvestment.numberOfInvestors === 1
                        ? "Investor"
                        : "Investors"}
                    </h1>
                  </div>
                </div>
                <div className="flex flex-col gap-4 mt-5">
                  <button className="bg-referral cursor-not-allowed border-[1.5px] border-referral bg-opacity-10 text-referral font-PoppinsSemiBold w-full py-4 rounded-[8px]">
                    RUNNING
                  </button>
                  <button
                    onClick={() => setAboutModal(true)}
                    className="border-[1.5px] border-investment text-investment font-PoppinsSemiBold py-4 w-full rounded-[8px]"
                  >
                    ABOUT THIS INVESTMENT
                  </button>
                </div>
                <div className="text-[14px] font-PoppinsRegular text-center mt-3 text-referral">
                  This investment plan is already running for all Investors.
                </div>
                <hr className="text-authbordercolor my-10" />
                <div>
                  <div className="flex justify-center gap-7 mb-5">
                    <div className="w-full border-[1.5px] rounded-[8px] p-3 border-transactionCard">
                      <h1 className="text-[13px] font-PoppinsMedium text-disabledtext">
                        Investment Type
                      </h1>
                      <h1 className="text-[13px] font-PoppinsMedium">
                        {selectedInvestment.category}
                      </h1>
                    </div>
                    <div className="w-full border-[1.5px] rounded-[8px] p-3 border-transactionCard">
                      <h1 className="text-[13px] font-PoppinsMedium text-disabledtext">
                        Payout Type
                      </h1>
                      <h1 className="text-[13px] font-PoppinsMedium">
                        Capital + profit to be paid
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          )}
          {aboutModal && (
            <Modal
              isOpenModal={() => setAboutModal(true)}
              closeModal={() => setAboutModal(false)}
            >
              <div className="px-4 pt-[120px]">
                <div>
                  <h1 className="text-primary dark:text-white text-[25px] font-PoppinsSemiBold">
                    About Investment
                  </h1>
                  <div className="py-2">
                    <p className="text-[13px] font-PoppinsRegular text-disabledtext dark:text-disabled">
                      Read about this investment below
                    </p>
                    {selectedInvestment?.disclaimer && (
                      <div className="mt-4">
                        <h1 className="font-PoppinsMedium text-[14px] mb-1 flex items-center gap-2">
                          Disclaimer <TiWarningOutline className="text-error" />
                        </h1>
                        <p className="font-PoppinsLight dark:text-white text-textcolor text-[13px]">
                          {selectedInvestment?.disclaimer}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <div
                    className="flex items-center my-7 justify-center"
                    style={{
                      backgroundImage: `url(${
                        selectedInvestment?.previewImage
                          ? `${process.env.REACT_APP_FILES_URL}/${selectedInvestment.previewImage}`
                          : selectedInvestment?.previewImage === ""
                          ? Default
                          : Default
                      })`,
                      height: "250px",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }}
                  >
                    <div className="bg-white opacity-50 inline-block p-4 rounded-[8px]">
                      <img src={Logo} className="w-[30px]" alt="" />
                    </div>
                  </div>
                  <p className="dark:text-white text-textcolor text-[13px]">
                    {selectedInvestment?.description}
                  </p>
                </div>
              </div>
            </Modal>
          )}
        </div>
      </div>
    </>
  );
};

export default ActiveInvestments;
